import React, { Component } from "react";
import { RouteChildrenProps } from "react-router";
import SetUpWizardView from "../setup";
import SetupDependentsService from "./setup-dependents-service";
import { getIsSetupDone } from "../../../../cores/utils/helpers";
import { Redirect } from "react-router-dom";
import { claimManagementURL } from "../../../../cores/constants/url-config";

type Props = RouteChildrenProps;

export default class SetupDependents extends Component<any, any> {

  render() {
    return getIsSetupDone() ? (
      <Redirect to={claimManagementURL} />
    ) : (
      <SetUpWizardView
        children={
          <SetupDependentsService {...this.props} />
        }
      />
    );
  }
}
