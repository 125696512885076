import React from "react";
import styled from "styled-components";
import { SaveAlt, PictureAsPdf } from "@material-ui/icons";
import { StyledBigTitle } from "../../shared/employee-styled";
import {download, getInfoByToken} from "../../../cores/utils/helpers";
import { getBenefits, getBriefPremiumPaymentSubscriptions } from "../../../services/employee-registration-service";
import { BenefitData, PremiumInsuranceType, PremiumPaymentStatus } from "../setup-wizard/employee-type";
import {UserInfo} from "../../../cores/models/login/login-req-model";

const StyledMainContent = styled.div`
  text-align: left;
  @media (min-width: 320px) {
    padding: 0;
  }
  @media (min-width: 768px) {
    padding: 2em 1em;
  }
`;

const StyledLargerTitle = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.subheadFont};
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 1rem !important;
`;

const StyledReportBox = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #ecedf0;
`;

const StyledPictureAsPdfIcon = styled(PictureAsPdf)`
  font-size: 5rem !important;
`;

const StyledTextBox = styled.div`
  padding-left: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledDownload = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

type State = {
  benefitData: BenefitData;
  employerId: number;
  premiumPaymentData: PremiumInsuranceType[];
};
type Props = {};

export default class Documents extends React.Component<Props, State> {
  state: State = {
    benefitData: {
      enrollments: [],
      totalAmount: 0,
    } as BenefitData,
    premiumPaymentData: [],
    employerId: 0,
  };
  async componentDidMount() {
    this.getBenefitsData();
    await this.getEmployerId();
    this.getPremiumPaymentData();
  }

  async getBenefitsData() {
    let result = await getBenefits();
    let benefitData = result.data ? result.data : {};

    this.setState({ benefitData: benefitData });
  }

  async getPremiumPaymentData() {
    const result = await getBriefPremiumPaymentSubscriptions(this.state.employerId);
    const premiumPaymentData = result.data ? result.data : {};

    this.setState({ premiumPaymentData: premiumPaymentData });
  }

  getEmployerId() {
    const userInfo: UserInfo = getInfoByToken();
    this.setState({ employerId: userInfo.employerId });
  }

  isHaveBenefit(enrollment: string) {
    const { enrollments } = this.state.benefitData;
    const enrollmentsName = enrollments.map((enrollment) => enrollment.name);
    return enrollmentsName.includes(enrollment);
  }

  isHavePremiumPayment = (permiumPaymentName: string):boolean => { 
    const activePremiumPayments = 
      this.state.premiumPaymentData.filter(pp => pp.status == PremiumPaymentStatus.ACTIVE);
    const foundPremiumnPayment = activePremiumPayments.filter(pp => pp.productName.includes(permiumPaymentName));
    
    return foundPremiumnPayment && foundPremiumnPayment.length > 0;
  }

  //TODO: Get by employer benefit settings, currently, hardcode for apply for MobSquad and CompuSOLVE and Vianet
  mobSquadEmployerId = 302;
  compuSolveEmployerId = 360;
  vianetEmployerId = 543;
  isHaveMedicalBenefit = ():boolean => { return this.state.employerId === this.mobSquadEmployerId }
  isHaveTravelBenefit = ():boolean => { return this.state.employerId === this.mobSquadEmployerId }
  isHaveMobSquadGroupInsurance = ():boolean => { return this.state.employerId === this.mobSquadEmployerId }
  isHaveCompuSolveWsa = ():boolean => { return this.state.employerId === this.compuSolveEmployerId }
  isHaveVianet = ():boolean => { return this.state.employerId === this.vianetEmployerId }

  wsaRender = () => {
    if (this.isHaveCompuSolveWsa()) {
      return documentItem(documents.wsaCompuSolve);
    } else if (this.isHaveVianet()) {
      return documentItem(documents.wsaEmployeeVianet);
    }
    return documentItem(documents.wsa);
  }

  employeeHandbookRender = () => {
    if (this.isHaveVianet()) {
      return documentItem(documents.vianetEmployeeBenefitGuide);
    }
    return documentItem(documents.employee);
  }

  render() {
    return (
      <StyledMainContent>
        <StyledBigTitle>Documents</StyledBigTitle>
        <div className="container-fluid p-0">
          {this.employeeHandbookRender()}
          {this.isHaveBenefit("Healthcare Spending Account") && documentItem(documents.hsa)}
          {this.isHaveBenefit("Wellness Spending Account") && this.wsaRender()}
          {this.isHavePremiumPayment("Major Medical") && documentItem(documents.medical)}
          {this.isHavePremiumPayment("Travel") && documentItem(documents.travel)}
          {this.isHaveMobSquadGroupInsurance() && documentItem(documents.groupInsuranceMobSquad)}
        </div>
      </StyledMainContent>
    );
  }
}

const documents = {
    employee: {
      title: "Employee Handbook",
      url:
      "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Employee_Benefits_Guide.pdf",
    },
    hsa: {
      title: "BeniPlus Eligible Expenses - HSA",
      url:
      "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/BeniPlus_Eligible_Expenses_-_HSA.pdf",
    },
    wsa: {
      title: "BeniPlus Eligible Expenses - WSA",
      url:
      "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/BeniPlus_Eligible_Expenses_-_WSA.pdf",
    },
    medical: {
      title: "Major Medical",
      url:
      "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Major_Medical_Policy_Document_.pdf",
    },
    travel: {
      title: "Travel Policy",
      url:
      "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Travel_insurance_explainer.pdf",
    },
    groupInsuranceMobSquad: {
      title: "Group Insurance Benefits - MobSquad",
      url:
      "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Policy+Booklet+for+Mobsquad+-+FGBL000L001A.pdf",
    },
    wsaCompuSolve: {
      title: "Compu SOLVE Eligible Expenses - WSA",
      url:
      "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/BeniPlus+Wellness+Spending+Account+(WSA)+Eligible+Expenses.pdf"
    },
    vianetEmployeeBenefitGuide: {
      title: "Employee Benefit Guide - Vianet",
      url: "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Employee+Benefits+Guide-+ViaNet+WSA.pdf"
    },
    wsaEmployeeVianet: {
      title: "Employee Vianet - WSA Eligible Benefits",
      url: "https://beniplus-api-prod.s3.ca-central-1.amazonaws.com/default/Vianet+WSA+Eligible+Expenses+List.pdf"
    },
}

function documentItem(data: {title: string, url: string}) {
  return <div className="row">
    <StyledLargerTitle className="col-12 mt-3">
      {data.title}
    </StyledLargerTitle>
    <div className="col-lg-4 mt-2">
      <StyledReportBox>
        <div className="w-100">
          <StyledDownload
              onClick={() => download(data.url)}
              title="Download"
          >
            <SaveAlt className="float-right cur"/>
          </StyledDownload>
        </div>

        <div className="w-100 d-flex">
          <div>
            <StyledPictureAsPdfIcon/>
          </div>
          <StyledTextBox>
            <div className="fw-bold">{data.title}</div>
            <div className="mt-2">
              Click to download "{data.title}"
            </div>
          </StyledTextBox>
        </div>
      </StyledReportBox>
    </div>
  </div>;
}
