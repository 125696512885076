import React, { Component } from "react";
import { RouteChildrenProps } from "react-router";
import {
  BenefitData,
  BenefitInfo,
  BenefitCoverage,
  IncreaseLimit
} from "../employee-type";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import {
  getAllInfoEmployee,
  getBenefits
} from "../../../../services/employee-registration-service";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import ReviewReAllocationView from "./review-re-allocation-view";
import { postReAllocationComplete } from "../../../../services/auth-service";
import { publishMessage } from "../../../../cores/utils/message";
import {
  getReAllocationStatus,
  setIsSetupDone,
  setReAllocationStatus
} from "../../../../cores/utils/helpers";
import {getPendingAmountByCategory} from "../../../../services/wallet-service";
import {
  claimManagementURL
} from "../../../../cores/constants/url-config";
import { getWalletBalance } from "../../receipt/claim-function";
import {Balance, PendingAmountByCategory} from "../../view-list-claim/list-claim-type";
import {Redirect} from "react-router-dom";

type State = {
  benefitData: BenefitData;
  benefitInfo: BenefitInfo[];
  isProRated: boolean;
  balances: Balance[];
  pendingAmountByCategory: PendingAmountByCategory;
};

type Props = RouteChildrenProps;

export default class ReviewReAllocation extends Component<Props, State> {
  state: State = {
    benefitData: {
      enrollments: [],
      totalAmount: 0,
    } as BenefitData,
    benefitInfo: [],
    isProRated: false,
    balances: [],
    pendingAmountByCategory: {}
  };

  async componentDidMount() {
    addLoading();

    await this.getBenefitsData();
    await getAllInfoEmployee().then(async (result) => {
      const balances = await getWalletBalance();
      this.setDataWhenReloadPage(result, balances);
    });

    removeLoading();
  }

  setDataWhenReloadPage = (result: any, balances: Balance[]) => {
    let benefitCoverage = [];

    if (result.data.benefitCoverage) {
      // Change value usd to cent of amount benefit coverage
      (result.data.benefitCoverage.benefitCoverages as Array<any>).forEach(
        (f) => {
          f.proRatedAmount = centToUsd(f.proRatedAmount)
          f.amount = centToUsd(f.amount)
          // f.amountLastYear = centToUsd(f.amountLastYear)
          // f.proRatedAmountLastYear = centToUsd(f.proRatedAmountLastYear)
          f.rolloverAmount = centToUsd(f.rolloverAmount)
        }
      );
      benefitCoverage = result.data.benefitCoverage.benefitCoverages;
    }

    this.setState({
      benefitInfo: benefitCoverage,
      isProRated: result.data.proRated,
      balances
    });
  };

  async getBenefitsData() {
    let result = await getBenefits();
    let benefitData = result.data ? result.data : [];

    const resultPendingAmount = await getPendingAmountByCategory();
    const pendingAmount = resultPendingAmount.data ? resultPendingAmount.data : {};

    this.setState({
      benefitData,
      pendingAmountByCategory: pendingAmount
    });
  }

  findBenefitsById = (id: number): BenefitCoverage => {
    let index = this.state.benefitData.enrollments.findIndex((benefit) => {
      return benefit.id === id;
    });

    if (index > -1) {
      return this.state.benefitData.enrollments[index];
    } else {
      return { id: -1, name: "", limit: 0, total: 0 };
    }
  };

  finishSetup = () => {
    addLoading()
    postReAllocationComplete()
      .then(async () => {
        setIsSetupDone("True");
        setReAllocationStatus("NONE");
        publishMessage({
          variant: "success",
          message: "Your has been re-allocate success!",
        });
        removeLoading()
        this.props.history.push(claimManagementURL);
      })
      .catch((error) => {
        removeLoading()
        publishMessage({
          message: error.response.data.message,
          variant: "error",
        });
      });
  };

  render() {
    return (getReAllocationStatus() !== "REQUIRED" ?
      <Redirect to={claimManagementURL} /> :
      <ReviewReAllocationView
        benefitInfo={this.state.benefitInfo}
        findBenefitsById={this.findBenefitsById}
        finishSetup={this.finishSetup}
        balances={this.state.balances}
        pendingAmountByCategory={this.state.pendingAmountByCategory}
        isProRated={this.state.isProRated} />
    );
  }
}
