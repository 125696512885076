import API_URL, { signInURL } from "../constants/url-config";
import axios, { AxiosResponse, AxiosInstance } from "axios";
import { authorizationHeader, signOut } from "./helpers";
import { publishMessage, MessageData } from "./message";
import { removeLoading } from "./loading";

const axiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: API_URL,
    headers: Object.assign({}, authorizationHeader()),
  });
  return instance;
};

export const getFileAsync = async (
  url: string,
  params?: object,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return callApi(
    axiosInstance().get(url, {
      params: params,
      responseType: "arraybuffer",
    }),
    handleErrorAutomatic
  );
};

export const getAsync = async (
  url: string,
  params?: object,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return callApi(
    axiosInstance().get(url, {
      params: params,
    }),
    handleErrorAutomatic
  );
};

export const getWithoutHeaderAsync = async (
  url: string,
  params?: object,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return callApi(
    axiosInstance().get(url, {
      params: params,
      headers: null,
    }),
    handleErrorAutomatic
  );
};

export const postAsync = async (
  url: string,
  json: object,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return callApi(axiosInstance().post(url, json), handleErrorAutomatic);
};

export const putAsync = async (
  url: string,
  json: object,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return callApi(axiosInstance().put(url, json), handleErrorAutomatic);
};

export const deleteAsync = async (
  url: string,
  handleErrorAutomatic: boolean = true
): Promise<AxiosResponse> => {
  return callApi(axiosInstance().delete(url), handleErrorAutomatic);
};
function callApi(
  promise: Promise<AxiosResponse>,
  handleErrorAutomatic: boolean = true
): AxiosResponse<any> | PromiseLike<AxiosResponse<any>> {
  if (handleErrorAutomatic) {
    return promise.then(handleSuccess).catch(handleError);
  }
  return promise;
}

function handleSuccess(
  res: AxiosResponse<any>
): AxiosResponse<any> | PromiseLike<AxiosResponse<any>> {
  return Promise.resolve(res);
}

export function handleError(error: any) {
  let data = {} as MessageData;
  if (error.response && error.response.data && error.response.data.message) {
    if (error.response.data.status === 401) {
      signOut();
      window.location.href = signInURL;
    } else if (
      error.response.data.status === 500 ||
      error.response.data.status === 403
    ) {
      data.message = "We’ve run into a problem. Please try again later";
    } else {
      data.message = error.response.data.message;
    }
  } else {
    data.message =
      "System error. We apologize for the inconvenience. Please try again.";
  }
  data.variant = "error";
  publishMessage(data);
  removeLoading();
  return Promise.reject(error);
}
