import React from "react";
import styled from "styled-components";

export interface Checkbox {
  classInput?: string;
  classLabel?: string;
  classSpan?: string;
  name?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onChangeCheckbox?: (e: any) => void;
  title?: string | JSX.Element;
  value?: number | string;
  disabled?: boolean;
}

const StyledSpanTitle = styled.span`
  position: relative;
  top: -4px;
`;

const StyledCheckBox = styled.label`
  input:checked ~ .check-mark {
    background-color: ${(props) => props.theme.primaryColor};
  };
  input:disabled ~ .check-mark {
    background-color: ${(props) => props.theme.grey[200]}!important;
  }
  line-height: 21px;
`;

export const CheckBox = (props: Checkbox) => (
  <StyledCheckBox
    className={
      "container-checkbox " + (props.classLabel ? props.classLabel : "")
    }
  >
    <StyledSpanTitle className={props.classSpan ? props.classSpan : ""}>
      {props.title}
    </StyledSpanTitle>
    <input
      type="checkbox"
      className={props.classInput}
      checked={props.checked}
      name={props.name}
      onChange={props.onChangeCheckbox}
      defaultChecked={props.defaultChecked}
      value={props.value}
      tabIndex={-1}
      role="application"
      aria-hidden="true"
      disabled={props.disabled || false}
    />
    <span className="check-mark"></span>
  </StyledCheckBox>
);
