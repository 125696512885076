import React, { Component } from "react";

import { RouteChildrenProps } from "react-router";
import {
  relationships,
  BenefitData,
  EmployeeSignUpData,
  BenefitInfo,
  BankingInfo,
  Dependent,
  Relationship,
  defaultDependent,
  BenefitCoverage,
  EmployeeBasicInformation
} from "../employee-type";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import {
  getAllInfoEmployee,
  getBenefits
} from "../../../../services/employee-registration-service";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import ReviewInformationView from "./review-information-view";
import SetUpWizardView from "../setup";
import { postFinishSetup } from "../../../../services/auth-service";
import { publishMessage, MessageData } from "../../../../cores/utils/message";
import { Redirect } from "react-router-dom";
import {
  getIsSetupDone,
  setIsSetupDone,
  setReAllocationStatus
} from "../../../../cores/utils/helpers";
import { claimManagementURL } from "../../../../cores/constants/url-config";

type State = {
  benefitData: BenefitData;
  employeeSignUp: EmployeeSignUpData;
  benefitInfo: BenefitInfo[];
  bankingInfo: BankingInfo;
  dependents: Dependent[];
  relationships: Relationship[];
  basicInfo: EmployeeBasicInformation;
  redirect: boolean;
  isProRated: boolean;
};

type Props = RouteChildrenProps;

export default class ReviewInformation extends Component<Props, State> {
  state: State = {
    benefitData: {
      enrollments: [],
      totalAmount: 0,
    } as BenefitData,
    employeeSignUp: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      rePassword: "",
      token: "",
    },
    dependents: [
      {
        firstName: "",
        lastName: "",
        relationship: "",
        dateOfBirth: null,
      },
    ],
    relationships: relationships,
    basicInfo: {
      gender: null,
      dateOfBirth: "",
      phoneNumber: "",
      homeAddress: "",
      cityName: "",
      provinceId: 0,
      provinceName: "",
      postCode: null,
      socialInsuranceNumber:  null,
    },
    benefitInfo: [],
    bankingInfo: {
      voidCheque: "",
      transitNumber: "",
      bankNumber: "",
      accountNumber: "",
      agreements: [],
      employeeId: 0,
      eSignature: "",
      date: new Date() || null,
    },
    redirect: false,
    isProRated: false
  };

  async componentDidMount() {
    //add loading
    addLoading();

    if (getIsSetupDone() === "True") {
      this.setState({ redirect: true})
      return;
    }

    await this.getBenefitsData();
    await getAllInfoEmployee().then((result) => {
      this.setDataWhenReloadPage(result);
    });

    removeLoading();
  }

  setDataWhenReloadPage = (result: any) => {
    let benefitCoverage = [];

    if (result.data.benefitCoverage) {
      // change value usd to cent of amount benefit coverage
      (result.data.benefitCoverage.benefitCoverages as Array<any>).forEach(
        (f) => {
          f.proRatedAmount = centToUsd(f.proRatedAmount)
          f.amount = centToUsd(f.amount)
        }
      );
      benefitCoverage = result.data.benefitCoverage.benefitCoverages;
    }
    let dependents = result.data.dependents.length
      ? result.data.dependents
      : [defaultDependent()];
    let personalInfo: EmployeeSignUpData = result.data.personalInfo;

    let employeeBanking: BankingInfo = result.data.employeeBanking;

    if (employeeBanking.bankNumber) {
      employeeBanking.voidCheque = result.data.employeeBanking.voidChequeUrl;
    } else {
      employeeBanking.voidCheque = "";
    }

    let basicInfo: EmployeeBasicInformation = result.data.basicInformation

    this.setState({
      benefitInfo: benefitCoverage,
      dependents: dependents,
      bankingInfo: employeeBanking,
      employeeSignUp: personalInfo,
      isProRated: result.data.proRated,
      basicInfo: basicInfo,
    });
  };

  async getBenefitsData() {
    let result = await getBenefits();
    let benefitData = result.data ? result.data : [];

    this.setState({ benefitData: benefitData });
  }

  findBenefitsById = (id: number): BenefitCoverage => {
    let index = this.state.benefitData.enrollments.findIndex((benefit) => {
      return benefit.id === id;
    });

    if (index > -1) {
      return this.state.benefitData.enrollments[index];
    } else {
      return { id: -1, name: "", limit: 0, total: 0, proRatedAmount: 0 };
    }
  };

  findRelationshipById = (id: string) => {
    let relationship = this.state.relationships.find((relationship) => {
      return relationship.id === id;
    });

    return relationship ? relationship.name : "Other";
  };

  finishSetup = () => {
    postFinishSetup()
      .then(() => {
        setIsSetupDone("True");
        setReAllocationStatus("NONE");
        publishMessage({
          variant: "success",
          message: "Thank you, your account has been set up!",
        });
        this.setState({ redirect: true });
      })
      .catch((error) => {
        //$("#finishSetup").removeAttr("disabled");
        let data = {} as MessageData;
        if (error.response && error.response.data && error.response.data) {
          if (error.response.status === 500) {
            publishMessage({
              message: error.response.data.message,
              variant: "error",
            });
            return;
          }

          data.message = "You have to set up the following information: ";

          let errorParts: string[] = []; 

          if (!error.response.data.hasBankingInfo) {
            errorParts.push("banking info");
          }
          if (!error.response.data.hasBenefitCoverages) {
            errorParts.push("benefit coverages");
          }
          if (!error.response.data.hasBasicInformation) {
            errorParts.push("basic information");
          }
            
          data.message += (errorParts.join(", ") + ".");
        } else {
          data.message = error + "";
        }
        data.variant = "error";
        publishMessage(data);
      });
  };

  render() {
    return this.state.redirect ? (
      <Redirect to={claimManagementURL + "?default"} />
    ) : (
      <SetUpWizardView
        children={
          <ReviewInformationView
            bankingInfo={this.state.bankingInfo}
            benefitInfo={this.state.benefitInfo}
            employeeSignUp={this.state.employeeSignUp}
            dependents={this.state.dependents}
            basicInfo={this.state.basicInfo}
            findBenefitsById={this.findBenefitsById}
            findRelationshipById={this.findRelationshipById}
            finishSetup={this.finishSetup}
            isProRated={this.state.isProRated}
          />
        }
      />
    );
  }
}
