import React from "react";
import styled from "styled-components";
import {
  StyledContentBox,
  StyledSpanBigTitle,
  StyledMainContent,
} from "../../shared/employee-styled";
import Stepper from "./stepper";

const StyledSpanMessage = styled.span`
  position: relative;
  top: 6px;
`;
const StyledPage = styled.div`
  max-width: 900px;
  margin: 3rem auto;
`;

type State = {
  activeStep: number;
};

export default class SetUpWizardView extends React.Component<any, State> {
  state = {
    activeStep: 0,
  };

  componentDidMount() {
    let page = window.location.pathname.split("/");
    let step = this.getStep(page[page.length - 1]);
    this.setState({ activeStep: step });
  }

  getStep = (stepId: string) => {
    let step = 0;

    if (stepId === "basic-information") {
      step = 0;
    } else if (stepId === "benefit-coverage") {
      step = 1;
    } else if (stepId === "personal-banking") {
      step = 2;
    } else if (stepId === "dependents") {
      step = 3;
    } else if (stepId === "review-information") {
      step = 4;
    }

    return step;
  };

  handleChangeStep = (stepId: string) => {
    let step = this.getStep(stepId);

    this.setState({ activeStep: step });
  };

  render() {
    return (
      <StyledMainContent>
        <StyledContentBox>
          <div className="row">
            <div className="col-md-3">
              <StyledSpanBigTitle>Setup Wizard</StyledSpanBigTitle>
            </div>
            <div className="col-md-9">
              <StyledSpanMessage>
                Please complete these step before you can use your program.
              </StyledSpanMessage>
            </div>
          </div>

          <div className="mt-3">
            <Stepper
              stepper={stepper}
              activeStep={this.state.activeStep}
              handleChangeStep={this.handleChangeStep}
            />
          </div>
          <hr />

          <StyledPage>{this.props.children}</StyledPage>
        </StyledContentBox>
      </StyledMainContent>
    );
  }
}

const stepper = [
  { id: "basic-information", name: "Basic Information" },
  { id: "benefit-coverage", name: "Benefit Coverage" },
  { id: "personal-banking", name: "Personal Banking" },
  { id: "dependents", name: "Dependents" },
  { id: "review-information", name: "Review Information" },
];
