import React from "react";
import Logo from "../../cores/config/component/images/white-logo.png";
import NavItem from "./nav-item";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import FolderIcon from "@material-ui/icons/Folder";
import MarketplaceIcon from "@material-ui/icons/FiberManualRecord";
import {
  getIsSetupDone,
  getReAllocationStatus
} from "../../cores/utils/helpers";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import {
  privacyURL,
  termsOfServicesURL,
  homePage,
  claimManagementURL,
  walletsURL,
  walletSearchParam,
  profileURL,
  documentsURL,
  marketplaceURL
} from "../../cores/constants/url-config";
import { isBeniplusUI } from "../../cores/config/component/ui-config";

type EmployerSidebarProps = {
  handleDrawerToggle?: () => void;
};

const StyledDivLogo = styled.div`
  height: 60px;
  padding: 15px 21px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const StyledImgLogo = styled.img`
  height: 50px;
  position: absolute;
  left: 28px;
  top: 8px;
  @media (max-width: 600px) {
    height: 40px;
  }
`;

const navItemList = [
  {
    to: claimManagementURL,
    label: "Claims",
    iconClass: <HomeOutlinedIcon />
  },
  {
    to: profileURL,
    label: "Profile",
    iconClass: <PermIdentityIcon />
  },
  {
    to: walletsURL + walletSearchParam,
    label: "Wallet",
    iconClass: <AccountBalanceWalletOutlinedIcon />
  },
  {
    to: documentsURL,
    label: "Documents",
    iconClass: <FolderIcon />
  },
  // {
  //   to: marketplaceURL,
  //   label: "Marketplace",
  //   iconClass: <MarketplaceIcon />
  // }
];

export const SideBar = (props: EmployerSidebarProps) => {
  return (
    <div>
      {/* logo */}
      <StyledDivLogo>
        <Link to={homePage}>
          <StyledImgLogo src={Logo} alt="logo" />
        </Link>
      </StyledDivLogo>

      {/* top nav */}
      {
        !getIsSetupDone() ? (
          // when didn't setup
          <ul className="nav flex-column top-nav">
            <NavItem
              handleDrawerToggle={props.handleDrawerToggle}
              to="/employee"
              label="Setup Wizard"
              iconClass={<HomeWorkIcon />}
            />
          </ul>
        ) : getReAllocationStatus() !== "NONE" ? (
          <ul className="nav flex-column top-nav">
            <NavItem
              handleDrawerToggle={props.handleDrawerToggle}
              to="/re-allocation"
              label="Re-Allocation"
              iconClass={<HomeWorkIcon />}
            />
          </ul>
        ) : (
              // when done setup
              <ul className="nav flex-column top-nav">
                {navItemList.map(item => (
                  <NavItem
                    key={`sidebar-nav-item-${item.label}`}
                    to={item.to}
                    handleDrawerToggle={props.handleDrawerToggle}
                    label={item.label}
                    iconClass={item.iconClass}
                  />
                ))}
              </ul>
            )
      }

      {/* bottom nav */}
      <ul className="nav flex-column bottom-nav">
        {!isBeniplusUI
        &&
        <NavItem to={'https://gotodoctor.ca/'} label="GoToDoctor Website" goToAnotherWebsite={true}/>
        }
        <NavItem to={privacyURL} label="Privacy" />
        <NavItem to={termsOfServicesURL} label="Terms of services" />
      </ul>
    </div>
  );
};
