import React, {useEffect, useMemo} from 'react';
import {StyledBigTitle, StyledButtonSubmit, StyledContentBox, StyledMainContent} from "../../../shared/employee-styled";
import {VendorInfoComponent} from "../component/vendor-card";
import PictureLayout from "../component/picture-layout";
import styled, {css} from "styled-components";
import ProfileImage from "../../../../cores/config/component/images/profile.jpg";
import ReviewComponent from "../component/review-component";
import {
  createReviewsForAVendorByEmployee,
  getAllServices,
  getReviewDetailForAVendorByEmployee,
  getReviewsForAVendorByEmployee,
  getVendorDetailByEmployee,
  updateReviewsForAVendorByEmployee
} from "../../../../services/marketplace-service";
import {CreateReviewRequest, Review, ReviewDetail, VendorDetailType} from "../marketplace-type";
import {formatterUSD} from "../../../../cores/helpers/format-usd";
import {centToUsd} from "../../../../cores/helpers/cent-to-usd";
import {getFileUrl} from "../../../../services/file-service";
import {getProvinces} from "../../../../services/employee-registration-service";
import AddReviewModal from "../component/add-review-modal";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {message} from "../../../../cores/helpers/message/message";
import {getAvgRating, getInfoByToken} from "../../../../cores/utils/helpers";
import Rating from "../component/rating";
import AllReviewModal from "../component/all-review-modal";
import ImageModal from "../component/view-image-modal";
import {OpenInNew} from "@material-ui/icons";
import BackButton from "../../../../cores/helpers/router/back-button";
import {RouteChildrenProps} from "react-router";

const StyledLine = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledSideHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
`;

const StyledTitleWrapper = styled.div`
  min-width: 250px;
`;

const StyledBookThisVendorButtonWrapper = styled.div`
    
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 1rem;
    }
`;

const StyledButtonOutlined = styled.button`
    width: 100%;
    position: relative;
    background-color: transparent;
    border: 1px solid ${(props: any) => props.theme.primaryColor};
    border-radius: 0.25rem;
    display: inline-block;
    font-weight: bold;
    color: ${(props: any) => props.theme.primaryColor};
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 50px;
    margin-right: 10px;
`;

const StyledCopyButton = styled(StyledButtonOutlined)`
    border: 1px solid ${(props: any) => props.theme.textColor};
    font-weight: normal;
    color: ${(props: any) => props.theme.textColor};
    padding: none;
    height: fit-content;
    line-height: 1;
    max-width: 100px;
    margin-left: 10px;
`;

const StyledCell = styled.div`
    padding: 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    ${(props: { width: string }) =>
            props.width &&
            css`
      width: ${props.width};
    `};
    
    @media (max-width: 768px) {
        width: 100%;
    };
`;

const StyledOpenInNew = styled(OpenInNew)`
  margin-left: 5px;
  &.MuiSvgIcon-root {
      width: 16px;
  }
`;

const StyledFormattedText = styled.pre`
    font-family: ${props => props.theme.textFont};
    font-size: inherit;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    margin-bottom: 0;
`;

type Props = RouteChildrenProps;

function VendorDetail(props: Props) {
  const { location } = props;
  const previousPath = location.state && location.state.from || '/marketplace';
  const previousParam = location.state && location.state.searchParams || '';
  const urlParams = new URLSearchParams(window.location.search);
  const userInfo = getInfoByToken().user;
  const username = !!userInfo ? userInfo.name : 'User';
  const vendorId = Number(urlParams.get('id'));
  const openReview = urlParams.get('rv') === '1';
  const [serviceMap, setServiceMap] = React.useState<any>({}); // serviceId -> service
  const [provinceMap, setProvinceMap] = React.useState<any>({}); // provinceId -> province
  const [vendor, setVendor] = React.useState<VendorDetailType>({
    affiliateLink: '',
    costFrom: 0,
    costTo: 0,
    couponCode: '',
    description: '',
    email: '',
    id: 0,
    languageSpoken: [],
    location: {
      id: 0,
      address: '',
      cityName: '',
      latitude: 0,
      longitude: 0,
      phoneNumber: '',
      postcode: '',
      provinceId: 0,
    },
    name: '',
    photos: [],
    coverImageUrl: '',
    publishTo: '',
    serviceIds: [],
    status: '',
    website: '',
    workingHours: [],
  });
  const [photos, setPhotos] = React.useState<{[keys: string]: string}>({});
  const [reviews, setReviews] = React.useState<Review>({
    pageCount: 0,
    ratingPointTotal: 0,
    records: [],
    reviewTotal: 0,
    total: 0,
  });
  const [userReview, setUserReview] = React.useState<ReviewDetail | null>(null);
  const [openAddReviewModal, setOpenAddReviewModal] = React.useState<boolean>(false);
  const [openAllReviewModal, setOpenAllReviewModal] = React.useState<boolean>(false);
  const [allReviews, setAllReviews] = React.useState<Review>({
    pageCount: 0,
    ratingPointTotal: 0,
    records: [],
    reviewTotal: 0,
    total: 0,
  });
  const [allReviewsPage, setAllReviewsPage] = React.useState<number>(1);
  const [openImageModal, setOpenImageModal] = React.useState<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState<number>(0);

  const affiliateLinkRelativeUrl = !!vendor.affiliateLink ?
    (vendor.affiliateLink.startsWith('http') ? vendor.affiliateLink : `//${vendor.affiliateLink}`) :
    '';
  const websiteRelativeUrl = !!vendor.website ?
    (vendor.website.startsWith('http') ? vendor.website : `//${vendor.website}`) :
    '';

  useEffect(() => {
    addLoading();
    Promise.all([fetchStaticData(), fetchVendorDetail(), fetchReviews()])
      .then(() => removeLoading());

    if (openReview) {
      handleViewAllReview().then();
    }
  }, []);

  const fetchStaticData = async () => {
    const [servicesRes, provincesRes] = await Promise.all([
      getAllServices(),
      getProvinces(),
    ]);

    const serviceMap: any = {};
    for (const service of servicesRes.data) {
      serviceMap[service.id] = service.name;
    }
    setServiceMap(serviceMap);

    const provinceMap: any = {};
    for (const province of provincesRes.data) {
      provinceMap[province.id] = province.name;
    }
    setProvinceMap(provinceMap);
  }

  const fetchVendorDetail = async () => {
    const res = await getVendorDetailByEmployee(vendorId);
    setVendor(res.data);
    for (const photo of res.data.photos) {
      const res = await getFileUrl(photo);
      setPhotos((prevState: any) => ({...prevState, [photo]: res.data}));
    }
    setSelectedImageIndex(0);
  }

  const fetchReviews = async () => {
    const res = await getReviewsForAVendorByEmployee(vendorId);
    const reviews = {
      ...res.data,
      records: res.data.records || [],
      pageCount: res.data.pageCount || 0,
      total: res.data.total || 0,
    }
    setReviews(reviews);

    try {
      const userReviewRes = await getReviewDetailForAVendorByEmployee(vendorId);
      setUserReview(userReviewRes.data);
    } catch (e) {
      setUserReview(null);
    }
  }

  const handleFetchAllReviews = async (page: number) => {
    addLoading();
    const res = await getReviewsForAVendorByEmployee(vendorId, page, 5);
    setAllReviews(prevState => ({
      ...res.data,
      records: [...prevState.records, ...res.data.records],
    }))
    removeLoading();
  }

  const rating = useMemo(() => {
    return !!reviews.reviewTotal ? getAvgRating(reviews.ratingPointTotal, reviews.reviewTotal) : 0;
  }, [reviews.ratingPointTotal, reviews.reviewTotal])

  const handleWriteReview = () => {
    setOpenAddReviewModal(true);
  };

  const handleSubmitReview = async (model: CreateReviewRequest) => {
    addLoading();
    let res;
    if (!!userReview) {
      res = await updateReviewsForAVendorByEmployee(vendorId, userReview.reviewId, model)
      if (res.status === 200) {
        message('Your review has been updated successfully.', 'success');
      }
    } else {
      res = await createReviewsForAVendorByEmployee(vendorId, model);
      if (res.status === 201) {
        message('Your review has been submitted successfully.', 'success');
      }
    }
    setOpenAddReviewModal(false);
    await fetchReviews();
    removeLoading();
  }

  const handleViewAllReview = async () => {
    setOpenAllReviewModal(true);
    await handleFetchAllReviews(allReviewsPage);
  }

  const handleCloseAllReview = () => {
    setOpenAllReviewModal(false);
    setAllReviews({
      pageCount: 0,
      ratingPointTotal: 0,
      records: [],
      reviewTotal: 0,
      total: 0,

    });
    setAllReviewsPage(1);
  }

  const handleLoadMore = async () => {
    setAllReviewsPage(prevState => prevState + 1);
    await handleFetchAllReviews(allReviewsPage + 1);
  }

  const handleOpenImageModal = (image: string) => {
    const index = pictureLayoutImages.indexOf(image);
    setSelectedImageIndex(index);
    setOpenImageModal(true);
  }

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(vendor.couponCode)
      .then(() => {
        message('Coupon code has been copied to clipboard.', 'success');
      })
      .catch(() => {
        message('Failed to copy coupon code to clipboard.', 'error');
      })
  }

  const vendorInfo = useMemo(() => {
    const {address, cityName, provinceId, postcode} = vendor.location;
    const googleLink = `https://www.google.com/maps/search/?api=1&query=${vendor.location.latitude},${vendor.location.longitude}`;
    return [
      {title: 'Provider Name', value: vendor.name || '---'},
      {title: 'Language Spoken', value: vendor.languageSpoken.map((item) => item.name).join(', ')},
      {title: 'Description', value: !!vendor.description ?
          <StyledFormattedText>
            {vendor.description}
          </StyledFormattedText> :
          '---'},
      {title: 'Service Provide', value: vendor.serviceIds.map((id: number) => serviceMap[id]).join(', ')},
      {title: 'Working Hours', value: vendor.workingHours.map((item) =>
          <div className={'mb-1'} key={`vendor-working-hours-${item.dayOfWeek}-${item.from}-${item.to}`}>{item.dayOfWeek}: {item.from} - {item.to}</div>)},
      {title: 'Address', value: !!vendor.location.provinceId ?
          <div className={'d-flex align-items-center'}>
            <span>{address}, {cityName}, {provinceMap[provinceId]}, {postcode} <a rel="noopener noreferrer"
                                                                                  href={googleLink}
                                                                                  target="_blank"><StyledOpenInNew/> Show map</a></span>

          </div> :
          '---'
      },
      {
        title: 'Website', value: !!vendor.website ?
          <a rel="noopener noreferrer" href={websiteRelativeUrl} target="_blank">
            <span>{vendor.website}
              <StyledOpenInNew />
            </span>
          </a> :
          '---'
      },
      {title: 'Cost Range', value: `${formatterUSD("currency").format(centToUsd(vendor.costFrom))} - ${formatterUSD("currency").format(centToUsd(vendor.costTo))}`},
      {title: 'Coupon Code', value: !!vendor.couponCode ?
          <div className={'d-flex align-items-center'}>
            <span>{vendor.couponCode}</span>
            <StyledCopyButton onClick={handleCopyToClipboard}>Copy Code</StyledCopyButton>
        </div> : '---'
      },
      {title: 'Review',
        value: <ReviewItem review={reviews}
                           handleWriteReview={handleWriteReview}
                           handleViewAllReview={handleViewAllReview}
                           userReview={userReview}
        />
      },
    ]
  }, [vendor, serviceMap, provinceMap, reviews, userReview]);

  const pictureLayoutImages: string[] = useMemo(() => {
    let images: string[] = [];
    const mainImage = photos[vendor.coverImageUrl] || Object.values(photos)[0];
    images.push(mainImage);

    for (const photo of vendor.photos) {
      if (photo !== vendor.coverImageUrl) {
        images.push(photos[photo]);
      }
    }

    return images;
  },[photos, vendor.coverImageUrl]);

  return (
    <>
      <StyledMainContent>
        <div className="container-fluid p-2 mt-3">
          <StyledContentBox>
            <div className={"row"}>
              <div className={"col-12"}>
                <BackButton path={previousPath} params={{path: previousPath, searchParams: previousParam}} />
              </div>
              <StyledSideHeaderWrapper className={"col-12"}>
                <StyledTitleWrapper>
                  <StyledBigTitle className={"mb-2"}>{vendor.name}</StyledBigTitle>
                  <VendorInfoComponent
                    stars={rating} reviews={reviews.reviewTotal}
                    postcode={vendor.location.postcode || ''}
                    handleClickReview={handleViewAllReview}
                  />
                </StyledTitleWrapper>
                <StyledBookThisVendorButtonWrapper>
                  <StyledButtonSubmit
                    submit={!!affiliateLinkRelativeUrl || !!websiteRelativeUrl}
                    onClick={() => window.open(!!affiliateLinkRelativeUrl ? affiliateLinkRelativeUrl : websiteRelativeUrl, "_blank")}
                  >
                    Book This Vendor
                  </StyledButtonSubmit>
                </StyledBookThisVendorButtonWrapper>
              </StyledSideHeaderWrapper>

              <div className={"col-12 mt-3"}>
                <PictureLayout
                  images={pictureLayoutImages}
                  onClick={handleOpenImageModal}
                />
              </div>

              <div className="col-12 mt-3">
                {vendorInfo.map((item, index) => (
                  <StyledLine key={`vendor-info-${item.title}`}>
                    <StyledCell width={'20%'} className="fw-bold">
                      {item.title}
                    </StyledCell>
                    <StyledCell width={'80%'}>
                      {item.value || '---'}
                    </StyledCell>
                  </StyledLine>
                ))}
              </div>
            </div>
          </StyledContentBox>
        </div>
      </StyledMainContent>
      <AddReviewModal
        open={openAddReviewModal}
        title={vendor.name}
        handleClose={() => {
          setOpenAddReviewModal(false);
        }}
        handleSubmit={handleSubmitReview}
        isEdit={!!userReview}
        editInfo={{
          username,
          avatarUrl: ProfileImage,
          rating: !!userReview ? userReview.ratingPoint : 0,
          comment: !!userReview ? userReview.comment : '',
        }}
      />
      <AllReviewModal
        open={openAllReviewModal}
        handleClose={handleCloseAllReview}
        title={vendor.name}
        reviews={allReviews}
        handleLoadMore={handleLoadMore}
      />
      <ImageModal
        open={openImageModal}
        onClose={handleCloseImageModal}
        title={vendor.name}
        images={pictureLayoutImages}
        selected={selectedImageIndex}
        setSelected={setSelectedImageIndex}
      />
    </>
  );
}

export default VendorDetail;

type ReviewItemProps = {
  review: Review;
  userReview: ReviewDetail | null;
  handleWriteReview: () => void;
  handleViewAllReview: () => void;
}

const ReviewItem = React.memo(({review, handleWriteReview, handleViewAllReview, userReview}: ReviewItemProps) => {
  return (
    <div className="col-12" id={"reviews"}>
      <div className="row">
        <div className="col-12 d-flex align-items-center">
          <div style={{display: 'inline', marginRight: '8px'}}>
            <Rating rating={5} setRating={() => {}} disabled={true}/>
          </div>
          <div style={{display: 'inline'}}>{review.reviewTotal || 0} review</div>
        </div>
        <div className={"col-12"}>
          <hr/>
          <div className={"row"}>
            {review.records.map((item: ReviewDetail, index: number) => (
              <div className={"col-12"}
                   key={`featured-review-${index}`}>
                <ReviewComponent
                  key={`featured-review-${index}`}
                  index={`featured-review-${index}`}
                  avatarUrl={item.employeePhotoUrl || `${ProfileImage}`}
                  username={`${item.reviewByEmployeeFirstName} ${item.reviewByEmployeeLastName}`}
                  rating={item.ratingPoint}
                  content={item.comment}
                  date={new Date(item.createdDate)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={"col-xl-4 col-md-8"}>
          <div className={"d-flex justify-content-start"}>
            {!!review.reviewTotal && <StyledButtonOutlined
              onClick={handleViewAllReview}
            >
              View All Review
            </StyledButtonOutlined>}
            <StyledButtonSubmit
              submit={true}
              onClick={handleWriteReview}
            >
              {!!userReview ? "Update" : "Write"} Your Review
            </StyledButtonSubmit>
          </div>
        </div>

      </div>
    </div>
  )
});