import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  StyledButtonSubmit,
  StyledLargerTitle,
} from "../../shared/employee-styled";
import styled from "styled-components";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { putUpdatePassword } from "../../../services/auth-service";
import ShowPasswordView from "../../../cores/helpers/input/input";

const StyledTitle = styled.div`
  margin-top: 3rem;
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export const StyledContentBox = styled.div`
  background: #fff;
  padding: 25px 21px;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #ecedf0;
`;

const StyledVerifyPage = styled.div`
  height: calc(100vh - 75px);
`;
const StyledVerifiedUserIcon = styled(VerifiedUserIcon)`
  width: 150px !important;
  height: auto !important;
  color: ${(props) => props.theme.primaryColor};
`;

const StyledDivGroupVerify = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: center;

  @media (min-width: 320px) {
    top: 50px;
  }
  @media (min-width: 767px) {
    top: 25%;
  }
`;

const StyledRequestMessage = styled.div`
  margin-top: 1rem;
`;

const StyledTitleMessage = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;

  @media (min-width: 320px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (min-width: 1366px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const StyledDescriptionMessage = styled.div`
  color: #2d2d2d;
  margin-top: 18px;
  text-align: center;

  @media (min-width: 320px) {
    font-size: 13px;
    line-height: 17px;
  }
  @media (min-width: 1366px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

type State = {
  password: string;
  rePassword: string;
  token: string;
  page: number;
};

export default class UpdatePassword extends Component<any, State> {
  state: State = {
    password: "",
    rePassword: "",
    token: "",
    page: 0,
  };

  componentDidMount() {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });

    const urlParams = new URLSearchParams(window.location.search),
      token = urlParams.get("token");

    let state = Object.assign(this.state);
    state.token = token;

    this.setState(this.state);
  }

  handleChangeInput = (event: any) => {
    let state = Object.assign(this.state);
    const { name, value } = event.target;

    state[name] = value;

    this.setState(this.state);
  };

  onSubmit = () => {
    // publishMessage({
    //   variant: "success",
    //   message:
    //     "Please follow the instruction that we sent to your email to request a new password"
    // });
    let param = { password: this.state.password };

    putUpdatePassword(this.state.token, param).then(() => {
      this.setState({ page: 1 });
    });
  };

  render() {
    return (
      <div>
        <StyledTitle></StyledTitle>

        {this.state.page === 0 ? (
          <ValidatorForm className="w-100" onSubmit={this.onSubmit}>
            <div className="row pt-3">
              <div className="col-lg-2"></div>
              <div className="col-lg-8 mb-2">
                <StyledContentBox>
                  <div className="row">
                    <div className="col-12">
                      <StyledLargerTitle>Update password</StyledLargerTitle>
                    </div>

                    <div className="col-12 mt-3">
                      <ShowPasswordView
                        onChange={this.handleChangeInput}
                        name="password"
                        placeholder="New password"
                        value={this.state.password}
                        validators={["required"]}
                        errorMessages={["Please enter email."]}
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <ShowPasswordView
                        onChange={this.handleChangeInput}
                        name="rePassword"
                        placeholder="Confirm new password"
                        value={this.state.rePassword}
                        validators={["isPasswordMatch", "required"]}
                        errorMessages={[
                          "Password and confirm password does not match",
                          "Please enter confirm password",
                        ]}
                      />
                    </div>
                    <div className="col-lg-7"></div>
                    <div className="col-lg-5">
                      <StyledButtonSubmit
                        className="mt-3"
                        type="submit"
                        submit={true}
                      >
                        Update password
                      </StyledButtonSubmit>
                    </div>
                  </div>
                </StyledContentBox>
              </div>

              <div className="col-lg-2"></div>
            </div>
          </ValidatorForm>
        ) : (
          <StyledVerifyPage>
            <StyledDivGroupVerify>
              <StyledVerifiedUserIcon />
              <StyledRequestMessage>
                <StyledTitleMessage>Success</StyledTitleMessage>
                <StyledDescriptionMessage>
                  Your password has been updated successfully
                </StyledDescriptionMessage>
              </StyledRequestMessage>
            </StyledDivGroupVerify>
          </StyledVerifyPage>
        )}
      </div>
    );
  }
}
