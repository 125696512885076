import React, { useState } from "react";
import { ListSubheader, MenuItem, TextField } from "@material-ui/core";
import {
  StyledSelect,
  StyledDefaultOption,
  StyledOption,
} from "../../../components/shared/employee-styled";
import { SelectValidator } from "react-material-ui-form-validator";
import styled from "styled-components";

export const StyledSelectValidator = styled(SelectValidator)`
  font-family: ${(props) => props.theme.textFont} !important;
  .MuiSelect-select {
    border-radius: 5px;
    height: 35px;
    border: 1px solid #ced4da;
    padding: 6px 12px;
  }
`;

export const StyledDefaultOptionSelectValidator = styled.option`
  font-size: 13px;
  color: #2d2d2d;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledOptionSelectValidator = styled.option`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type SelectProps = {
  value: any;
  name?: string;
  onChange?: (event: any) => void;
  options: Option[];
  placeholder?: string;
  disabled?: boolean;
  tabIndex?: number;
};

type SelectValidatorProps = {
  value: string | number;
  name?: string;
  onChange?: (event: any) => void;
  options: Option[];
  validators?: string[];
  errorMessages?: string[];
  placeholder?: string;
};

export type Option = {
  id: number | string;
  name: number | string | JSX.Element;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const BNPSelect = (props: SelectProps) => (
  <StyledSelect
    value={props.value ? props.value : "-1"}
    onChange={props.onChange}
    name={props.name}
    disabled={props.disabled}
    tabIndex={props.tabIndex ? props.tabIndex : 0}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 300,
          marginTop: "8px" // Adjust as needed to control distance from input
        }
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left"
      },
      getContentAnchorEl: null,
      autoFocus: false
    }}
  >
    <MenuItem value="-1" key={-1}>
      <StyledDefaultOption className="p-2">
        {props.placeholder}
      </StyledDefaultOption>
    </MenuItem>

    {props.options.map((option) => (
      <MenuItem value={option.id} key={option.id}>
        <StyledOption className="p-2">{option.name}</StyledOption>
      </MenuItem>
    ))}
  </StyledSelect>
);

export const BNPSelectValidator = (props: SelectValidatorProps) => (
  <StyledSelectValidator
    value={props.value ? props.value : ""}
    onChange={props.onChange}
    name={props.name}
    SelectProps={{
      native: true,
    }}
    validators={props.validators}
    errorMessages={props.errorMessages}
  >
    <StyledDefaultOptionSelectValidator value="">
      {props.placeholder}
    </StyledDefaultOptionSelectValidator>

    {props.options.map((option) => (
      <StyledOptionSelectValidator value={option.id}>
        {option.name}
      </StyledOptionSelectValidator>
    ))}
  </StyledSelectValidator>
);
