import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {PinDrop} from "@material-ui/icons";
import {getFileUrl} from "../../../../services/file-service";
import {Link} from "react-router-dom";
import {marketplaceDetailURL} from "../../../../cores/constants/url-config";
import {StarIcon} from "./review-component";
import {getResizedImageUrl} from "../../../../cores/utils/helpers";
import {THUMBNAIL_SIZE} from "../marketplace-model";
import ImgStack from "../../../../cores/config/component/images/img-stack.svg";
import {RouteChildrenProps} from "react-router";

const StyledLink = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`

const Card = styled.div`
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
`;

const ImageWrapper = styled.div`
    width: 100%;
    padding-top: 66.67%; /* 3:2 aspect ratio (2/3 * 100) */
    position: relative;
    border-radius: 8px;
    overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledDiscount = styled.div`
    position: absolute;
    top: 0.5rem;
    right: 0;
    background-color: ${props => props.theme.primaryColor};
    opacity: 0.8;
    color: white;
    padding: 0.2rem 0.5rem;
`;

const VendorName = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0 5px 0;
  cursor: pointer;
  :hover {
      text-decoration: underline;
  }
`;

const VendorInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Reviews = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const NumReviews = styled.span`
  font-size: 14px;
  color: #555;
`;

const Location = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const StyledStar = styled(StarIcon)`
  margin-right: 8px;
`;

const StyledPinDrop = styled(PinDrop)`
  color: ${props => props.theme.grey[300]};
  margin-right: 8px;
`;

type Props = {
  id: number;
  image: string;
  name: string;
  stars: number;
  reviews: number;
  postcode: string;
  discount: string;
  searchParams: any;
} & RouteChildrenProps;

function VendorCard(props: Props) {
  const { image, name, stars, reviews, postcode } = props;
  const [imageSrc, setImageSrc] = React.useState<string | null>(null);

  useEffect(() => {
    if (image) {
      const resizedImage = getResizedImageUrl(image, THUMBNAIL_SIZE);
      getFileUrl(resizedImage).then((res) => {
        setImageSrc(res.data);
      })
    }
  }, [image]);

  const handleError = () => {
    // If the thumbnail image is not available, try to get the original image
    if (imageSrc && !imageSrc.startsWith(image))
    setImageSrc("");
    getFileUrl(image).then((res) => {
      setImageSrc(res.data);
    });
  }

  return (
    <StyledLink
      to={{
        pathname: `${marketplaceDetailURL}?id=${props.id}`,
        state: { searchParams: props.searchParams, from: props.location.pathname }
      }}
    >
      <Card>
        <ImageWrapper>
          <Image src={imageSrc || `${ImgStack}`} alt="Vendor Image" onError={handleError} />
          {props.discount && <StyledDiscount>{props.discount}</StyledDiscount>}
        </ImageWrapper>
        <VendorName>{name}</VendorName>
        <VendorInfoComponent
          stars={stars}
          reviews={reviews}
          postcode={postcode}
          handleClickReview={() => {
            props.history.push({
              pathname: `${marketplaceDetailURL}?id=${props.id}&rv=1`,
              state: { searchParams: props.searchParams, from: props.location.pathname }
          });
          }}
        />
      </Card>
    </StyledLink>

  );
}

export default VendorCard;

type VendorInfoProps = {
  stars: number | string;
  reviews: number;
  postcode: string;
  handleClickReview: () => void;
};

export const VendorInfoComponent =  (props: VendorInfoProps) => {
  const {stars, reviews, postcode} = props;
  const handleClickReview = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    props.handleClickReview();
  }

  return (
    <VendorInfo>
      <Reviews onClick={handleClickReview}>
        <StyledStar />
        <NumReviews>{stars} ({reviews} reviews)</NumReviews>
      </Reviews>
      <Location onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(`https://www.google.com/maps/search/?api=1&query=${postcode}`)
      }}>
        <StyledPinDrop />
        <NumReviews>{postcode}</NumReviews>
      </Location>
    </VendorInfo>)
}