import React from "react";
import styled from "styled-components";
import { StyledButtonCloseModal, StyledButtonSubmit, StyledHeaderModal, StyledHeaderTitleModal, StyledIconCloseModal } from "../../../shared/employee-styled";
import { QRCodeInfo } from "../../../shared/qr-code-info";

const StyledModalDialog = styled.div`
  text-align: center;
  margin: 2.5rem auto;
  max-width: 600px;
  width: 100%;
  pointer-events: none;
`;

const StyledDescriptionDiv = styled.div`
  margin: 0px 15px 0px 15px;
`

const StyledModelContent = styled.div`
  align-items: center;
  padding: 30px;
`

const StyledDoneButton = styled(StyledButtonSubmit)`
  width: 20% !important;
`

const StyledHeaderTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
`;


export function QRCodeInfoDialog(props: any) {
  return (
    <div className="modal" id="qrCodeInfoModal">
      <StyledModalDialog role="document">
        <StyledModelContent className="modal-content">
          <StyledHeaderModal>
            <StyledHeaderTitle>
              Your account is now ready!
            </StyledHeaderTitle>
          </StyledHeaderModal>
          <StyledDescriptionDiv>
            For an enhanced experience with our service, start using our mobile app by scanning the QR codes provided below.
          </StyledDescriptionDiv>

          <div className="modal-body text-center">
            <QRCodeInfo 
              logoHeight="40px" 
              qrWidth="150px" 
              isDisplaySeparateBar={true}
              logoAndQrBetweenMargin="20px"
            />
          </div>

          <StyledDoneButton
              id="closeQRCodeDialog"
              submit={true}
              onClick={() => closeQrCodeInfoModal()}
              >
            Done
          </StyledDoneButton>

        </StyledModelContent>
      </StyledModalDialog>
    </div>
  );
}

export function showQrCodeInfoModal() {
  ($("#qrCodeInfoModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static"
  });
}
export function closeQrCodeInfoModal() {
  ($("#qrCodeInfoModal") as any).modal("hide");
}
