import React, { Component } from "react";
import Profile from "../../cores/config/component/images/profile.jpg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { signOut, getInfoByToken, getToken } from "../../cores/utils/helpers";
import { signInURL } from "../../cores/constants/url-config";
import { getProfileEmployee } from "../../services/employee-registration-service";
import { ResponseEmployeeData } from "../pages/setup-wizard/employee-type";

const StyledHeaderRight = styled.span`
  position: absolute;
  color: #2d2d2d;
  right: 15px;
  top: 9px;
  font-family: ${(props) => props.theme.textFont} !important;
`;

const StyledText = styled.span`
  margin-right: 0.5em;
  font-size: 14px;
`;

const StyledImgProfile = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  position: relative;
  top: -2px;
`;

const StyledClickDiv = styled.div`
  font-weight: 500;
  font-size: 16px;
  height: 30px;
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  padding: 5px 0;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    opacity: 0.7;
  }
`;

const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  height: 30px;
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  padding: 5px 0;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    opacity: 0.7;
    text-decoration: none;
  }
  position: relative;
  top: 5px;
`;
// const StyledDivChangePassword = styled.div`
//   font-weight: 500;
//   font-size: 16px;
//   height: 30px;
//   color: ${(props) => props.theme.primaryColor};
//   cursor: pointer;
//   padding: 5px 0;
//   :hover {
//     color: ${(props) => props.theme.primaryColor};
//     opacity: 0.7;
//   }
// `;

const StyledUl = styled.ul`
  padding: 8px 20px !important;
`;
const StyledLi = styled.li`
  height: 30px;
`;

type State = {
  name: string;
  isShowSwitchToEmployeePortal: boolean;
};

export default class Header extends Component<any, State> {
  state: State = {
    name: "",
    isShowSwitchToEmployeePortal: false,
  };

  async componentDidMount() {
    
    if (this.isAdminOrOwnerUser()) {
      this.setState({isShowSwitchToEmployeePortal: true});
    }

    await this.getInfo();

  }

  isAdminOrOwnerUser() {
    const userInfo = getInfoByToken();
    return userInfo.authorities.includes("ROLE_EMP_OWNER") || userInfo.authorities.includes("ROLE_EMP_ADMIN");
  }

  switchToEmployerPortal = () => {

    if (!this.isAdminOrOwnerUser()) return;

    let href = "http://localhost:3000"; // employer localhost:port
    let token = getToken();

    // dev
    if (window.location.href.match("beniplus-employee-dev.futurify.io")) {
      href = "https://beniplus-employer-dev.futurify.io";
    }

    // staging
    if (window.location.href.match("employee-staging.beniplus.ca")) {
      href = "https://employer-staging.beniplus.ca";
    }

    // prod
    if (window.location.href.match("employee.beniplus.ca")) {
      href = "https://employer.beniplus.ca";
    }

    // GTD staging
    if (window.location.href.match("gotodoctor-employee.beniplus.ca")) {
      href = "https://gotodoctor-company.beniplus.ca";
    }

    // GTD prod
    if (window.location.href.match("employee.gotohealthwallet.ca")) {
      href = "https://company.gotohealthwallet.ca";
    }

    window.location.href = href + "?switchFromEmployee=true&token=" + token;

  }

  // Make a call to get info about employee name
  async getInfo() {
    await getProfileEmployee().then((result) => {
      let personalInfo: ResponseEmployeeData = result.data;
      this.setState({ name: `${personalInfo.firstName} ${personalInfo.lastName}` });
    });
  }

  render() {
    return (
      <div>
        <StyledHeaderRight>
          <div className="dropdown mt-2 cur">
            <span className="dropdown-toggle" data-toggle="dropdown">
              <StyledText>{this.state.name}</StyledText>
              <StyledImgProfile src={Profile} alt="profile" />
            </span>
            <StyledUl className="dropdown-menu">
              {/* <StyledLi className="dropdown">
                <StyledDivChangePassword>
                  Change password
                </StyledDivChangePassword>
              </StyledLi> */}
              {this.state.isShowSwitchToEmployeePortal &&
                <StyledLi className="dropdown">
                  <StyledClickDiv onClick={this.switchToEmployerPortal}>
                    Switch to employer portal
                  </StyledClickDiv>
                </StyledLi>
              }
              <StyledLi className="dropdown">
                <StyledLink
                  onClick={() => {
                    signOut();
                  }}
                  to={signInURL}
                >
                  Sign out
                </StyledLink>
              </StyledLi>
            </StyledUl>
          </div>
        </StyledHeaderRight>
      </div>
    );
  }
}
