import React from "react";
import {
  StyledLargerTitle,
  StyledHiddenForWeb,
  StyledHiddenForMobile,
  StyledButtonSubmit,
  StyledHideForMobile,
  StyledBtnLink,
} from "../../../shared/employee-styled";
import styled from "styled-components";
import { withStyles } from "@material-ui/core";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import {
  BenefitCoverage,
  EmployeeSignUpData,
  BenefitInfo,
  BankingInfo,
  Dependent,
  EmployeeBasicInformation,
} from "../employee-type";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import theme from "../../../../cores/helpers/theme";
import { Link } from "react-router-dom";
import {
  setupBenefitURL,
  setupBankingURL,
  setupDependentsURL,
  setupBasicInformationURL,
} from "../../../../cores/constants/url-config";
import { convertTimeZone } from "../../../../cores/utils/helpers";
import { FE_DETAIL_DATE_FORMAT } from "../../../../cores/utils/format/date-time-format";

const StyledReviewInformationTitle = styled.div`
  background-color: #e4e5eb;
  padding: 0.43em 15px;
  margin: 0 -20px;
  span {
    height: 26px;
    color: ${(props) => props.theme.secondaryColor};
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
  }
  a {
    height: 26px;
    color: ${(props) => props.theme.primaryColor};
    line-height: 26px;
    text-align: right;
    float: right;
  }
  a:hover {
    opacity: 0.8;
  }
`;

const StyledReviewInformationContentTitle = styled.div`
  height: 22px;
  font-weight: bold;
  line-height: 22px;
  a {
    color: #70b542 !important;
    font-size: 12px;
    margin-left: 1em;
  }

  @media (min-width: 992px) {
    color: #2d2d2d;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const StyledReviewInformationContentValue = styled.div`
  height: 22px;
  line-height: 22px;
  margin-top: 0.5rem;
`;

const StyledLinkEdit = styled(Link)`
  float: right;
  cursor: pointer;
  color: ${(props) => props.theme.primaryColor};
  :hover {
    color: ${(props) => props.theme.primaryColor};
  }
`;

const StyledDivBoxExpand = styled.div`
  background-color: #f2f2f5;
  width: 100%;
  padding: 17px 21px;
`;

type Props = {
  findBenefitsById: (id: number) => BenefitCoverage;
  employeeSignUp: EmployeeSignUpData;
  benefitInfo: BenefitInfo[];
  bankingInfo: BankingInfo;
  dependents: Dependent[];
  basicInfo: EmployeeBasicInformation;
  findRelationshipById: (id: string) => string;
  finishSetup: () => void;
  isProRated: boolean;
};

export default function ReviewInformationView(props: Props) {
  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(expanded ? panel : false);
  };

  const formatBankNumber = (bankNumber: string): string => {
    return bankNumber ? "xxxx-xxxx-x" + bankNumber.toString().slice(-3) : "";
  };

  return (
    <div className="row">
      <div className="col-12">
        <StyledLargerTitle>Review information</StyledLargerTitle>
        <hr />
      </div>
      <div className="col-12">
        <div>
          <div className="mt-3">
            <StyledReviewInformationTitle>
              <span>Personal Info</span>
            </StyledReviewInformationTitle>
            <div className="row">
              <div className="col-lg-6 mt-3">
                <StyledReviewInformationContentTitle>
                  Full name
                </StyledReviewInformationContentTitle>
                <StyledReviewInformationContentValue>
                  {props.employeeSignUp.firstName +
                    " " +
                    props.employeeSignUp.lastName}
                </StyledReviewInformationContentValue>
              </div>
              <div className="col-lg-6 mt-3">
                <StyledReviewInformationContentTitle>
                  Email
                </StyledReviewInformationContentTitle>
                <StyledReviewInformationContentValue>
                  {props.employeeSignUp.email}
                </StyledReviewInformationContentValue>
              </div>
            </div>
          </div>
          <div className="mt-4">
          <StyledReviewInformationTitle>
              <span>Basic Information</span>
              <StyledLinkEdit to={setupBasicInformationURL}>Edit</StyledLinkEdit>
            </StyledReviewInformationTitle>
            <div className="row">
              <div className="col-lg-6 mt-3">
                <StyledReviewInformationContentTitle>
                  Gender at birth
                </StyledReviewInformationContentTitle>
                <StyledReviewInformationContentValue>
                  {props.basicInfo.gender ? props.basicInfo.gender : '---'}
                </StyledReviewInformationContentValue>
              </div>
              <div className="col-lg-6 mt-3">
                <StyledReviewInformationContentTitle>
                  Date of birth
                </StyledReviewInformationContentTitle>
                <StyledReviewInformationContentValue>
                  {props.basicInfo.dateOfBirth
                  ? convertTimeZone("Canada/Eastern", props.basicInfo.dateOfBirth).format(FE_DETAIL_DATE_FORMAT)
                  : "---"}
                </StyledReviewInformationContentValue>
              </div>
              <div className="col-lg-6 mt-3">
                <StyledReviewInformationContentTitle>
                  Phone number
                </StyledReviewInformationContentTitle>
                <StyledReviewInformationContentValue>
                  {props.basicInfo.phoneNumber ? props.basicInfo.phoneNumber : '---'}
                </StyledReviewInformationContentValue>
              </div>
              <div className="col-lg-6 mt-3">
                <StyledReviewInformationContentTitle>
                  Address
                </StyledReviewInformationContentTitle>
                <StyledReviewInformationContentValue>
                  {props.basicInfo.homeAddress ? props.basicInfo.homeAddress : '---'}
                </StyledReviewInformationContentValue>
              </div>
              <div className="col-lg-6 mt-3">
                <StyledReviewInformationContentTitle>
                  Province
                </StyledReviewInformationContentTitle>
                <StyledReviewInformationContentValue>
                  {props.basicInfo.provinceName ? props.basicInfo.provinceName : '---'}
                </StyledReviewInformationContentValue>
              </div>
              <div className="col-lg-6 mt-3">
                <StyledReviewInformationContentTitle>
                  City
                </StyledReviewInformationContentTitle>
                <StyledReviewInformationContentValue>
                  {props.basicInfo.cityName ? props.basicInfo.cityName : '---'}
                </StyledReviewInformationContentValue>
              </div>
              <div className="col-lg-6 mt-3">
                <StyledReviewInformationContentTitle>
                  Postal code
                </StyledReviewInformationContentTitle>
                <StyledReviewInformationContentValue>
                  {props.basicInfo.postCode ? props.basicInfo.postCode : '---'}
                </StyledReviewInformationContentValue>
              </div>
              <div className="col-lg-6 mt-3">
                <StyledReviewInformationContentTitle>
                  Social Insurance Number
                </StyledReviewInformationContentTitle>
                <StyledReviewInformationContentValue>
                  {props.basicInfo.socialInsuranceNumber ? props.basicInfo.socialInsuranceNumber : '---'}
                </StyledReviewInformationContentValue>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <StyledReviewInformationTitle>
              <span>Dependents</span>
              <StyledLinkEdit to={setupDependentsURL}>Edit</StyledLinkEdit>
            </StyledReviewInformationTitle>
            {props.dependents.map((dependent, index) =>
              dependent.relationship ? (
                <div key={index}>
                  <StyledHiddenForWeb>
                    <ExpansionPanel
                      square
                      defaultExpanded={true}
                      onChange={handleChange("panel" + index)}
                    >
                      <ExpansionPanelSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ArrowDropUp />}
                      >
                        <div className="fw-bold">
                          {props.findRelationshipById(dependent.relationship)}
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <StyledDivBoxExpand>
                          <div className="fw-bold">Full name</div>
                          <div>
                            {dependent.firstName + " " + dependent.lastName}
                          </div>
                          <div className="fw-bold mt-3">Relationship</div>
                          <div>
                            {props.findRelationshipById(dependent.relationship)}
                          </div>
                        </StyledDivBoxExpand>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </StyledHiddenForWeb>
                  <StyledHiddenForMobile>
                    <div className="row">
                      <div className="col-lg-6 mt-3 fw-bold">
                        {dependent.firstName + " " + dependent.lastName}
                      </div>
                      <div className="col-lg-6 mt-3 text-right">
                        {props.findRelationshipById(dependent.relationship)}
                      </div>
                      {props.dependents.length > 1 &&
                        index < props.dependents.length - 1 && (
                          <div className="col-lg-12">
                            <hr />
                          </div>
                        )}
                    </div>
                  </StyledHiddenForMobile>
                </div>
              ) : (
                  ""
                )
            )}
          </div>
          <div className="mt-4">
            <StyledReviewInformationTitle>
              <span>Benefit Coverage</span>
              <StyledLinkEdit to={setupBenefitURL + "?back"}>
                Edit
              </StyledLinkEdit>
            </StyledReviewInformationTitle>
            <div className="row">
              {props.benefitInfo.map((benefit, index) => (
                <div className="col-lg-4 mt-3" key={index}>
                  <StyledReviewInformationContentTitle>
                    {props.findBenefitsById(benefit.enrollmentId).name}
                  </StyledReviewInformationContentTitle>
                  <StyledReviewInformationContentValue>
                    {
                      formatterUSD("currency", "USD").format(
                        props.isProRated ? benefit.proRatedAmount : benefit.amount
                      )
                    }
                  </StyledReviewInformationContentValue>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4">
            <StyledReviewInformationTitle>
              <span>Personal Banking Information</span>
              <StyledLinkEdit to={setupBankingURL + "?back"}>
                Edit
              </StyledLinkEdit>
            </StyledReviewInformationTitle>
            {props.bankingInfo.bankNumber && (
              <div className="row">
                <div className="col-lg-4 mt-3">
                  <StyledReviewInformationContentTitle>
                    Account number
                  </StyledReviewInformationContentTitle>
                  <StyledReviewInformationContentValue>
                    {formatBankNumber(props.bankingInfo.accountNumber)}
                  </StyledReviewInformationContentValue>
                </div>
                <div className="col-lg-4 mt-3">
                  <StyledReviewInformationContentTitle>
                    Transit number
                  </StyledReviewInformationContentTitle>
                  <StyledReviewInformationContentValue>
                    {props.bankingInfo.transitNumber}
                  </StyledReviewInformationContentValue>
                </div>
                <div className="col-lg-4 mt-3">
                  <StyledReviewInformationContentTitle>
                    Institution Number
                  </StyledReviewInformationContentTitle>
                  <StyledReviewInformationContentValue>
                    {props.bankingInfo.bankNumber}
                  </StyledReviewInformationContentValue>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row  mt-5">
          <StyledHideForMobile className="col-lg-2">
            <StyledBtnLink
              to={setupDependentsURL}
              background={theme.secondaryColor}
            >
              Back
            </StyledBtnLink>
          </StyledHideForMobile>
          <div className="col-lg-7"></div>
          <div className="col-lg-3">
            <StyledButtonSubmit
              id="finishSetup"
              submit={true}
              onClick={() => {
                $("#finishSetup").attr("disabled", "true");
                props.finishSetup();
              }}
            >
              Finish setup
            </StyledButtonSubmit>
          </div>
        </div>
      </div>
    </div>
  );
}

const ExpansionPanel = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: "none",
    marginBottom: -1,
    padding: 0,
    color: theme.primaryColor,
    minHeight: 50,
    "&$expanded": {
      minHeight: 50,
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiExpansionPanelDetails);
