import {Balance, PendingAmountByCategory} from "../view-list-claim/list-claim-type";
import {
  BenefitInfo,
  BenefitCoverage,
  Dependent,
  Relationship,
} from "../setup-wizard/employee-type";
import {
  getWalletEmployee,
} from "../../../services/wallet-service";
import { getAllClaimPolicy } from "../../../services/employee-claims-services";
import { ClaimPolicy, TypeOfExpense } from "./receipt/claim-type";
import {
  getAllInfoEmployee,
  getBenefits,
  getBenefitsType,
} from "../../../services/employee-registration-service";

export const getLimitBalance = (id: number, balances: Balance[]) => {
  switch (id) {
    case 1: {
      let hsaLimit = balances.find((m) => {
        return m.accountName === "hsa_limit";
      });

      return hsaLimit ? hsaLimit.balance : 0;
    }
    case 2: {
      let wellnessLimit = balances.find((m) => {
        return m.accountName === "wellness_limit";
      });
      return wellnessLimit ? wellnessLimit.balance : 0;
    }
    case 3: {
      let insuranceLimit = balances.find((m) => {
        return m.accountName === "insurance_limit";
      });
      return insuranceLimit ? insuranceLimit.balance : 0;
    }
    case 4: {
      let charityLimit = balances.find((m) => {
        return m.accountName === "charity_limit";
      });
      return charityLimit ? charityLimit.balance : 0;
    }
    case 5: {
      let rrspLimit = balances.find((m) => {
        return m.accountName === "rrsp_limit";
      });
      return rrspLimit ? rrspLimit.balance : 0;
    }
    case 6: {
      let craLimit = balances.find((m) => {
        return m.accountName === "cra_limit";
      });
      return craLimit ? craLimit.balance : 0;
    }
    default:
      return 0;
  }
};

export const setTypeOfPlan = (
  benefitInfos: BenefitInfo[],
  enrollments: BenefitCoverage[],
  balances: Balance[],
  pendingAmountByCategory: PendingAmountByCategory = {}
) => {
  let employeeBenefitCoverage: BenefitCoverage[] = [];

  enrollments.forEach((enrollment) => {
    benefitInfos.forEach((benefitInfo) => {
      if (enrollment.id === benefitInfo.enrollmentId) {
        let value: BenefitCoverage = {
          id: enrollment.id,
          name: enrollment.name,
          limit: getLimitBalance(enrollment.id, balances) -
              (pendingAmountByCategory[enrollment.id] ?
                  (pendingAmountByCategory[enrollment.id].pendingApproveClaimAmount +
                      pendingAmountByCategory[enrollment.id].pendingISSClaimAmount) :
                  0),
          total: benefitInfo.amount + benefitInfo.rolloverAmount,
          proRatedAmount: benefitInfo.proRatedAmount + benefitInfo.rolloverAmount
        };
        employeeBenefitCoverage.push(value);
      }
    });
  });

  return employeeBenefitCoverage;
};

export async function getWalletBalance() {
  let resultBalances = await getWalletEmployee();

  let balances: Balance[] = resultBalances.data
    ? resultBalances.data.balances
    : [];

  return balances;
}

export async function getDataClaim() {
  // get all info
  let infoOfEmployee = await getAllInfoEmployee();

  // benefits info employee
  let benefitsInfo: BenefitInfo[] = infoOfEmployee.data.benefitCoverage
    ? infoOfEmployee.data.benefitCoverage.benefitCoverages
    : [];

  // dependents
  let dependents = infoOfEmployee.data.dependents.length
    ? infoOfEmployee.data.dependents
    : [];

  // benefits data
  let resultBenefits = await getBenefits();
  let enrollments: BenefitCoverage[] = resultBenefits.data
    ? resultBenefits.data.enrollments
    : [];

  // benefits data
  let resultBenefitsType = await getBenefitsType();
  let enrollmentsType: BenefitCoverage[] = resultBenefitsType.data
    ? resultBenefitsType.data.enrollments
    : [];

  // set employee benefit coverage
  let employeeBenefitCoverage = setTypeOfPlan(benefitsInfo, enrollments, []);

  let resultClaimPolicies = await getAllClaimPolicy();
  let claimPolicies: ClaimPolicy[] = resultClaimPolicies.data
    ? resultClaimPolicies.data
    : [];

  let proRated = infoOfEmployee.data.proRated;
  let isAvailable = infoOfEmployee.data.available;
  return {
    dependents,
    employeeBenefitCoverage,
    claimPolicies,
    benefitsInfo,
    enrollments,
    enrollmentsType,
    proRated,
    isAvailable,
    reAllocationStatus: infoOfEmployee.data.reAllocationStatus,
    benefitCancellationDate: infoOfEmployee.data.benefitCancellationDate,
    enableMonthlyLimit: infoOfEmployee.data.enableMonthlyLimit,
  };
}

export const findClaimerById = (claimerId: number, dependents: Dependent[]) => {
  if (claimerId === null || claimerId === -1) {
    return { id: -1, firstName: "", lastName: "", relationship: "" };
  }

  let dependent = dependents.find((dependent) => {
    return dependent.id === claimerId;
  });

  return dependent
    ? dependent
    : { id: 0, firstName: "", lastName: "Me", relationship: "" };
};

export const findExpanseTypesById = (
  expenseTypeId: number,
  expanseTypes: TypeOfExpense[]
) => {
  let expanseType = expanseTypes.find((expanseType) => {
    return expanseType.id === expenseTypeId;
  });

  return expanseType ? expanseType.name : "";
};

export const findRelationshipById = (
  id: string,
  relationships: Relationship[]
) => {
  let relationship = relationships.find((relationship) => {
    return relationship.id === id;
  });

  return relationship ? relationship.name : "Other";
};

export const setAvatar = (name: string) => {
  let abbreviations = "";

  let splitName = name.split(" ");

  splitName.forEach((splitName) => {
    abbreviations += splitName.slice(0, 1);
  });

  return abbreviations;
};
