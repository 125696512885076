import React, { Component } from "react";
import {
  putBankingInfo,
  getAllInfoEmployee,
  getAgreementBankingInfo,
} from "../../../../services/employee-registration-service";

import { RouteChildrenProps } from "react-router";
import { BankingInfo, Agreement } from "../employee-type";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import OCRCheque from "../../../../cores/helpers/ocr-cheque/ocr-cheque";
import SetupBankingInfoView from "./setup-banking-information-view";
import SetUpWizardView from "../setup";
import { getInfoByToken, getIsSetupDone } from "../../../../cores/utils/helpers";
import { getFileUrl } from "../../../../services/file-service";
import { toUTCTime } from "../../../../cores/helpers/to-utc-time";
import {
  setupDependentsURL,
  reviewInformationURL,
  claimManagementURL,
} from "../../../../cores/constants/url-config";
import { Redirect } from "react-router-dom";

type State = {
  bankingInfo: BankingInfo;
  agreementData: Agreement[];
  fileUrl: string;
};

type Props = RouteChildrenProps;

export default class SetupBankingInfo extends Component<Props, State> {
  state: State = {
    bankingInfo: {
      employeeId: 0,
      voidCheque: "",
      transitNumber: "",
      bankNumber: "",
      accountNumber: "",
      agreements: [],
      eSignature: "",
      date: toUTCTime(new Date()),
    },
    agreementData: [],
    fileUrl: "",
  };

  async componentDidMount() {
    //add loading
    addLoading();
    if (getIsSetupDone() === "True")
      return;

    let result = await getAgreementBankingInfo();

    let agreementData: Agreement[] = result.data || [];

    this.setState({ agreementData: agreementData });

    await getAllInfoEmployee().then((result) => {
      this.setDataWhenReloadPage(result);
    });

    removeLoading();
  }

  getFileUrl = (url: string) => {
    getFileUrl(url).then((response) => {
      this.setState({ fileUrl: response.data });
    });
  };

  setDataWhenReloadPage = (result: any) => {
    let agreementIds = this.state.agreementData.map((agreement) => {
      return agreement.id;
    });

    if (result.data.employeeBanking) {
      let employeeBanking: BankingInfo = {
        accountNumber: result.data.employeeBanking.accountNumber,
        bankNumber: result.data.employeeBanking.bankNumber,
        transitNumber: result.data.employeeBanking.transitNumber,
        voidCheque: result.data.employeeBanking.voidChequeUrl,
        agreements: agreementIds,
        employeeId: 0,
        eSignature: result.data.employeeBanking.eSignature,
        date: result.data.employeeBanking.date || toUTCTime(new Date()),
      };

      if (result.data.employeeBanking.voidChequeUrl) {
        this.getFileUrl(result.data.employeeBanking.voidChequeUrl);
      }

      this.setState({
        bankingInfo: employeeBanking,
      });
    }
  };

  //#region banking information
  handleChangeInputBankingInformation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    let bankingInfo = Object.assign(this.state.bankingInfo);

    if (name !== "eSignature") {
      if (Number(value) >= 0) {
        bankingInfo[name] = value;
      }
    } else {
      bankingInfo[name] = value;
    }

    this.setState({ bankingInfo: bankingInfo });
  };
  handleChangeCheckboxBankingInformation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    let bankingInfo = Object.assign({}, this.state.bankingInfo);

    if (checked) {
      bankingInfo.agreements.push(Number(name));
    } else {
      let index = bankingInfo.agreements.findIndex((agreement: any) => {
        return agreement === Number(name);
      });

      bankingInfo.agreements.splice(index, 1);
    }

    this.setState({ bankingInfo });
  };

  handleDeleteImage = () => {
    let bankingInfo = Object.assign(this.state.bankingInfo);
    bankingInfo.voidCheque = "";

    this.setState({ bankingInfo: bankingInfo, fileUrl: "" });
  };
  handleChangeReplaceCheque = () => {
    $("#uploadImageCheque").click();
  };

  handleChangeVoidCheque = (urlImage: string) => {
    let bankingInfo = Object.assign(this.state.bankingInfo);
    bankingInfo.voidCheque = urlImage;

    this.getFileUrl(urlImage);

    this.setState({ bankingInfo: bankingInfo });
  };

  handleChangeOCR = (file: any) => {
    OCRCheque(file, "../tessdata", (res: any) => {
      if (!res.error) {
        const newbankingInfo: BankingInfo = { ...this.state.bankingInfo };
        newbankingInfo.transitNumber = res.numbers.transit;
        newbankingInfo.bankNumber = res.numbers.institution;
        newbankingInfo.accountNumber = res.numbers.account;

        this.setState({ bankingInfo: newbankingInfo });
      }
    });
  };

  validateBankingInfo = () => {
    let isValid = false;

    if (
      this.state.bankingInfo.agreements.length ===
      this.state.agreementData.length
    ) {
      isValid = true;
    }

    if (
      this.state.bankingInfo.accountNumber &&
      this.state.bankingInfo.bankNumber &&
      this.state.bankingInfo.transitNumber &&
      this.state.bankingInfo.voidCheque &&
      isValid
    ) {
      return true;
    } else {
      return false;
    }
  };
  handleChangeDate = (date: Date | null, name: string) => {
    let cheque = Object.assign(this.state.bankingInfo);

    cheque[name] = date;
    this.setState({ bankingInfo: cheque });
  };
  handleSubmitBankingInfo = () => {
    let bankingInfo: BankingInfo = Object.assign(this.state.bankingInfo);

    if (bankingInfo.date)
      bankingInfo.date = toUTCTime(new Date(bankingInfo.date));
    bankingInfo.employeeId = getInfoByToken().employeeId;

    putBankingInfo(bankingInfo)
      .then(() => {
        if (this.props.location.search === "?back") {
          this.props.history.push(reviewInformationURL);
        } else {
          this.props.history.push(setupDependentsURL);
        }
      })
      .catch(() => {
        $("#buttonSaveBanking").removeAttr("disabled");
      });
  };
  //#endRegion banking information

  render() {
    let isSubmit = this.validateBankingInfo();

    return getIsSetupDone() ? (
      <Redirect to={claimManagementURL} />
    ) : (
      <SetUpWizardView
        children={
          <SetupBankingInfoView
            bankingInfo={this.state.bankingInfo}
            handleChangeCheckbox={this.handleChangeCheckboxBankingInformation}
            handleChangeInput={this.handleChangeInputBankingInformation}
            handleChangeVoidCheque={this.handleChangeVoidCheque}
            handleDeleteImage={this.handleDeleteImage}
            handleSubmit={this.handleSubmitBankingInfo}
            handleChangeReplaceCheque={this.handleChangeReplaceCheque}
            isValid={isSubmit}
            agreementData={this.state.agreementData}
            handleChangeDate={this.handleChangeDate}
            fileUrl={this.state.fileUrl}
            handleOCRCheque={this.handleChangeOCR}
          />
        }
      />
    );
  }
}
