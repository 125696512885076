import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { StyledDivDatePicker } from "../../../components/shared/styled";

type Props = {
  disabled?: boolean;
  value?: string | number | Date | null;
  onChange: (date: Date | null, name: string) => void;
  name: string;
  placeholder?: string;
  disableFuture?: boolean;
  tabIndex?: number;
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
  views?: any[];
  format?: string;
};

export default function BNPDatePicker(props: Props) {
  return (
    <StyledDivDatePicker>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          views={props.views ? props.views : ["date"]}
          disableFuture={props.disableFuture}
          autoOk
          InputAdornmentProps={{ position: "start" }}
          disabled={props.disabled}
          variant="inline"
          format={props.format || "dd/MM/yyyy"}
          margin="normal"
          placeholder={props.placeholder ? props.placeholder : ""}
          value={props.value ? props.value : null}
          onChange={(date) => props.onChange(date, props.name)}
          KeyboardButtonProps={{
            tabIndex: -1,
            "aria-label": "change date",
          }}
          inputProps={{ tabIndex: props.tabIndex }}
          role="application"
          aria-hidden="true"
          minDate={props.minDate ? props.minDate : undefined}
          maxDate={props.maxDate ? props.maxDate : undefined}
        />
      </MuiPickersUtilsProvider>
    </StyledDivDatePicker>
  );
}
