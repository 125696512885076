import React, { Component } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  LandingStyledTextValidator,
  StyledButtonSubmit,
  StyledSpanTitle
} from "../../shared/employee-styled";
import styled from "styled-components";
import SuccessQuest from "../../../cores/helpers/success-quest/success-quest";
import { validateEmail } from "../../../cores/config/config";
import { postForgotPassword } from "../../../services/auth-service";

type State = {
  email: string;
  page: number;
};

const StyledTitle = styled.div`
  margin-top: 3rem;
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export const StyledContentBox = styled.div`
  background: #fff;
  padding: 25px 21px;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #ecedf0;
`;

export default class ForgotPassword extends Component<any, State> {
  state: State = {
    email: "",
    page: 0
  };

  handleChangeInput = (event: any) => {
    let state = Object.assign(this.state);
    state.email = event.target.value;

    this.setState(this.state);
  };

  onSubmit = () => {
    // publishMessage({
    //   variant: "success",
    //   message:
    //     "Please follow the instruction that we sent to your email to request a new password"
    // });
    let param = { email: this.state.email.toLowerCase().trim() };

    postForgotPassword(param).then(() => {
      this.setState({ page: 1 });
    });
  };

  render() {
    return (
      <div>
        <StyledTitle>
          {/* <StyledBigTitle>Reset Password</StyledBigTitle> */}
        </StyledTitle>

        {this.state.page === 0 ? (
          <ValidatorForm className="w-100" onSubmit={this.onSubmit}>
            <div className="row pt-3">
              <div className="col-lg-2"></div>
              <div className="col-lg-8 mb-2">
                <StyledContentBox>
                  <div className="row">
                    <div className="col-12">
                      <StyledSpanTitle>
                        Enter your recovery email
                      </StyledSpanTitle>

                      <LandingStyledTextValidator
                        className="mt-2"
                        onChange={this.handleChangeInput}
                        type="text"
                        name="email"
                        placeholder="Enter email"
                        value={this.state.email}
                        validators={[
                          "required",
                          `matchRegexp:${validateEmail}`
                        ]}
                        errorMessages={[
                          "Please enter email",
                          "Email is not valid"
                        ]}
                      />
                    </div>
                    <div className="col-lg-7"></div>
                    <div className="col-lg-5">
                      <StyledButtonSubmit
                        className="mt-3"
                        type="submit"
                        submit={true}
                      >
                        Reset password
                      </StyledButtonSubmit>
                    </div>
                  </div>
                </StyledContentBox>
              </div>

              <div className="col-lg-2"></div>
            </div>
          </ValidatorForm>
        ) : (
          <div>
            <SuccessQuest
              title="Success"
              description="Please follow the instruction that we sent to your email to request a new password."
            />
          </div>
        )}
      </div>
    );
  }
}
