import React from "react";
import {
  StyledHeaderTitleModal,
  StyledIconCloseModal,
  StyledButtonCloseModal,
  StyledButtonSubmit,
  StyledSpanTitle,
  StyledPError,
} from "../../../shared/employee-styled";
import { BNPInput } from "../../../../cores/helpers/input/input";
import { Debit } from "../benefit-wallet-type";
import styled from "styled-components";
import { formatterUSD } from "../../../../cores/helpers/format-usd";

const StyledHeaderModal = styled.div`
  height: 50px;
  display: block !important;
`;

const StyledButtonMaxBalance = styled.button`
  position: absolute;
  top: 67px;
  right: 14px;
  height: 36px;

  background-color: #f9fbfd;
  border: 1px solid #ecedf0;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

type Props = {
  debit: Debit;
  handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  handleChangeAmount: () => void;
  // wallet
  availableAmount: number;
};
export default function DebitModel(props: Props) {
  const checkNegative = () => {
    if (props.debit.withdrawAmount < 0) {
      return true;
    }
    return false;
  };

  const isSubmit = props.debit.withdrawAmount > 0 ? true : false;

  return (
    <div className="modal" id="debitModal" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <StyledButtonCloseModal name="btn-close" onClick={closeDebitModal}>
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
            <StyledHeaderModal>
              <StyledHeaderTitleModal className="text-left">
                Withdraw
              </StyledHeaderTitleModal>
            </StyledHeaderModal>
            <div className="row">
              <div className="col-12">
                <StyledSpanTitle>{`Your balance : ${formatterUSD(
                  "currency"
                ).format(props.availableAmount)}`}</StyledSpanTitle>

                <div className="w-100">
                  <StyledSpanTitle>Enter amount to withdraw</StyledSpanTitle>
                </div>
                <BNPInput
                  type="number"
                  className="col-12 mt-2"
                  onChangeInput={props.handleChangeInput}
                  name="withdrawAmount"
                  placeholder="Amount"
                  value={props.debit.withdrawAmount || ""}
                />

                <StyledButtonMaxBalance onClick={props.handleChangeAmount}>
                  {"Max"}
                </StyledButtonMaxBalance>
                {checkNegative() ? (
                  <StyledPError>
                    Please do not enter a negative number
                  </StyledPError>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row mt-3">
              <StyledButtonSubmit
                className="col-2 ml-auto"
                onClick={props.handleSubmit}
                submit={isSubmit}
                disabled={!isSubmit}
                type="submit"
              >
                Save
              </StyledButtonSubmit>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export function showDebitModal() {
  ($("#debitModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeDebitModal() {
  ($("#debitModal") as any).modal("hide");
}
