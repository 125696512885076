import { getAsync } from "../cores/utils/http-client";
import { AxiosResponse } from "axios";

const URL_Employee = "/manage-employee/api";

export const getBankingInfoEmployee = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/banking-info`;

  return getAsync(url);
};
