import { getAsync } from "../cores/utils/http-client";
import { AxiosResponse } from "axios";
const URL_EMPLOYER = "manage-employer/api/common";

export const subscribe = (email: string): Promise<AxiosResponse> => {
  let url = `${URL_EMPLOYER}/subscribe/${email}`;
  return getAsync(url);
};

export const unsubscribe = (email: string): Promise<AxiosResponse> => {
  let url = `${URL_EMPLOYER}/unsubscribe/${email}`;
  return getAsync(url);
};

export const checkSubscribe = (email: string): Promise<AxiosResponse> => {
  let url = `${URL_EMPLOYER}/employee/${email}`;
  return getAsync(url, {}, false);
};
