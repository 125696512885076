import React from "react";
import {
  StyledInput,
  LandingStyledTextValidator,
} from "../../../components/shared/employee-styled";
import Input from "@material-ui/core/Input";
import { IconButton, InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import styled from "styled-components";

export interface Input {
  className?: string;
  name?: string;
  value?: string | number;
  placeholder?: string;
  onChangeInput?: (e: any) => void;
  onClick?: (e: any) => void;
  maxLength?: number;
  minLength?: number;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  hidden?: boolean;
  tabIndex?: number;
}

export const StyledInputPassword = styled(Input)`
  display: block;
  width: 100%;
  height: 50px;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #2d2d2d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledInputAdornment = styled(InputAdornment)`
  position: absolute;
  top: 25px;
  right: 20px;

  button:hover,
  button:focus {
    outline: none;
  }
`;

export const BNPInput = (props: Input) => (
  <StyledInput
    hidden={props.hidden}
    disabled={props.disabled}
    className={props.className ? props.className : ""}
    type={props.type ? props.type : "text"}
    name={props.name}
    value={props.value}
    placeholder={props.placeholder}
    onChange={props.onChangeInput}
    onClick={props.onClick}
    maxLength={props.maxLength}
    minLength={props.minLength}
    required={props.required}
    tabIndex={props.tabIndex ? props.tabIndex : 0}
    role="application"
    aria-hidden="true"
  />
);

export type ShowPasswordViewType = {
  onChange?: (e: any) => void;
  name?: string;
  placeholder?: string;
  value?: string | number;
  validators?: any[];
  errorMessages?: any[];
  tabIndex?: number;
  validatorListener?: (event: boolean) => void;
};

export default function ShowPasswordView(props: ShowPasswordViewType) {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="position-relative">
      <LandingStyledTextValidator
        type={showPassword ? "text" : "password"}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        placeholder={props.placeholder}
        validators={props.validators}
        errorMessages={props.errorMessages}
        tabIndex={props.tabIndex ? props.tabIndex : 1}
        role="application"
        aria-hidden="true"
        validatorListener={props.validatorListener}
      />
      <StyledInputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          edge="end"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </StyledInputAdornment>
    </div>
  );
}

export function PasswordInput(props: Input) {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      <StyledInput
        hidden={props.hidden}
        disabled={props.disabled}
        className={props.className ? props.className : ""}
        type={props.type ? props.type : "text"}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChangeInput}
        maxLength={props.maxLength}
        minLength={props.minLength}
        required={props.required}
        tabIndex={props.tabIndex ? props.tabIndex : 0}
        role="application"
        aria-hidden="true"
      />
      <StyledInputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          edge="end"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </StyledInputAdornment>
    </div>
  );
}
