import { postAsync, getAsync, putAsync } from "../cores/utils/http-client";
import { AxiosResponse } from "axios";
const URL_USER = "auth/api/user";

export interface LoginReqModel {
  username: string;
  password: string;
}

export const login = (model: LoginReqModel): Promise<AxiosResponse> => {
  let url = `${URL_USER}/login?portal=employee`;
  return postAsync(url, model);
};

export const postFinishSetup = (): Promise<AxiosResponse> => {
  let url = `/manage-employee/api/employees/finish-setup`;
  return postAsync(url, {}, false);
};

export const postReAllocationComplete = (): Promise<AxiosResponse> => {
  let url = `/manage-employee/api/employees/re-allocation/complete`;
  return postAsync(url, {}, false);
};

export const getCheckFinishSetup = (): Promise<AxiosResponse> => {
  let url = `/manage-employee/api/employees/is-setup-done`;

  return getAsync(url);
};

export const postForgotPassword = (model: {
  email: string;
}): Promise<AxiosResponse> => {
  let url = `${URL_USER}/forgot-password?portal=employee`;

  return postAsync(url, model);
};

export const putUpdatePassword = (
  token: string,
  param: { password: string }
): Promise<AxiosResponse> => {
  let url = `${URL_USER}/forgot-password?token=${token}`;

  return putAsync(url, param);
};
