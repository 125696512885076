import React, {useEffect, useMemo} from 'react';
import {BNPSelect} from "../../../cores/helpers/select/select";
import {StyledBigTitle, StyledPTitle} from "../../shared/employee-styled";
import styled from "styled-components";
import {BNPMultiSelect} from "../../../cores/helpers/input/BNPMultiSelect";
import {distanceOptions, ratingOptions, workingHoursOptions} from "./marketplace-model";
import {addLoading, removeLoading} from "../../../cores/utils/loading";
import {Language, SearchVendorParams} from "./marketplace-type";
import {getAllLanguages, getSearchVendors} from "../../../services/marketplace-service";
import VendorCard from "./component/vendor-card";
import Pagination, {setPagingInfo} from "../../../cores/helpers/pagination/pagination";
import {getAvgRating} from "../../../cores/utils/helpers";
import SortSelect from "./component/sort-component";
import {RouteChildrenProps} from "react-router";

const StyledSearchArea = styled.div`
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 10% 20px;
    border-bottom: 1px solid #E5EAF3;
`;

type Props = {
  searchComponent: JSX.Element;
  searchParams: SearchVendorParams;
  setSearchParams: (params: SearchVendorParams) => void;
} & RouteChildrenProps;

function MarketplaceSearchPage(props: Props) {
  const [languages, setLanguages] = React.useState<Language[]>([]);
  const [vendors, setVendors] = React.useState<any[]>([]);
  const [totalVendors, setTotalVendors] = React.useState<number>(0);
  const [paging, setPaging] = React.useState<any>(
    setPagingInfo(props.searchParams.page, 0, props.searchParams.perPage));

  const displayDistanceFilter = useMemo(() => {
    const displayDistance = !!props.searchParams.latitude || !!props.searchParams.longitude;
    if (!displayDistance) {
      props.setSearchParams({
        ...props.searchParams,
        distanceOptionId: 'ALL',
        distanceFrom: 0,
        distanceTo: null
      });
    }
    return displayDistance;
  }, [props.searchParams.latitude, props.searchParams.longitude]);


  useEffect(() => {
    addLoading();
    loadStaticData()
      .then(() => removeLoading());
  }, []);

  useEffect(() => {
    addLoading();
    searchVendors()
      .then(() => removeLoading());
  }, [props.searchParams]);

  const loadStaticData = async () => {
    const res = await getAllLanguages();
    setLanguages(res.data.map((item: Language) => ({...item, id: item.code})));
  }

  const searchVendors = async () => {
    const res = await getSearchVendors(props.searchParams);
    const convertedVendors = res.data.records.map((vendor: any) => ({
      ...vendor,
      stars: !!vendor.ratingPointTotal ? getAvgRating(vendor.ratingPointTotal, vendor.reviewTotal) : 0,
    }));
    setVendors(convertedVendors);
    setTotalVendors(res.data.total);
    setPaging(setPagingInfo(props.searchParams.page, res.data.pageCount, props.searchParams.perPage))
  }

  const handleChangeLanguage = (event: any) => {
    if (event.target.value.includes("all")) {
      props.setSearchParams({...props.searchParams, languageCodes: []})
      return;
    }
    if (event.target.value.includes("clear")) {
      props.setSearchParams({...props.searchParams, languageCodes: []})
      return;
    }
    props.setSearchParams({...props.searchParams, languageCodes: event.target.value})
  }

  const handleChangeSelect = (event: any) => {
    const {name, value} = event.target;

    if (name === "workingHour") {
      const workingHour = value == -1 ? 'ALL' : value;
      props.setSearchParams({...props.searchParams, workingHour})
    } else if (name === "rating") {
      props.setSearchParams({...props.searchParams, ratingFrom: value})
    } else if (name === "distance") {
      const distanceOptionId = value == -1 ? 'ALL' : value;
      const distanceOption = distanceOptions.find(item => item.id === distanceOptionId);

      props.setSearchParams({
        ...props.searchParams,
        distanceOptionId,
        distanceFrom: (distanceOption && distanceOption.distanceFrom) || 0,
        distanceTo: (distanceOption && distanceOption.distanceTo) || null
      })
    }
  }

  const handleChangePage = (page: number) => {
    props.setSearchParams({...props.searchParams, page})
  }

  const handleChangePerPage = (event: any) => {
    props.setSearchParams({...props.searchParams, perPage: event.target.value, page: 1})
  }

  const handleChangeSortBy = (sortBy: string[]) => {
    props.setSearchParams({...props.searchParams, sortBy})
  }

  return (
    <div>
      <StyledSearchArea>
        {props.searchComponent}
      </StyledSearchArea>
      <div className={"row"}>
        <div className="col-xl-2 col-lg-3 col-md-4 mt-4">
          <StyledPTitle className="mb-2">Language Spoken</StyledPTitle>
          <BNPMultiSelect
            options={languages}
            value={props.searchParams.languageCodes}
            name="languageSpoken"
            placeholder="Language Spoken"
            onChange={handleChangeLanguage}
            tabIndex={1}
            allowAll={false}
            clearAll={true}
          />
        </div>
        <div className="col-xl-2 col-lg-3 col-md-4 mt-4">
          <StyledPTitle className="mb-2">Working Hours</StyledPTitle>
          <BNPSelect
            options={workingHoursOptions}
            value={props.searchParams.workingHour || ''}
            name="workingHour"
            placeholder="Working Hours"
            onChange={handleChangeSelect}
            tabIndex={2}
          />
        </div>
        <div className="col-xl-2 col-lg-3 col-md-4 mt-4">
          <StyledPTitle className="mb-2">Rating</StyledPTitle>
          <BNPSelect
            options={ratingOptions}
            value={props.searchParams.ratingFrom || -1}
            name="rating"
            placeholder="Rating"
            onChange={handleChangeSelect}
            tabIndex={3}
          />
        </div>
        <div className={`col-xl-2 col-lg-3 col-md-4 ${displayDistanceFilter ? 'mt-4' : ''} `}>
          {displayDistanceFilter &&(
            <>
              <StyledPTitle className="mb-2">Distance</StyledPTitle>
              <BNPSelect
                options={distanceOptions}
                value={props.searchParams.distanceOptionId || ''}
                name="distance"
                placeholder="Distance"
                onChange={handleChangeSelect}
                tabIndex={4}
              />
            </>
          )}
        </div>
        <div className="col-xl-2 col-lg-9 col-md-4"></div>
        <div className="col-xl-2 col-lg-3 col-md-4 mt-4">
          <StyledPTitle className="mb-2">Sort by</StyledPTitle>
          <SortSelect
            sortBy={props.searchParams.sortBy}
            setSortBy={handleChangeSortBy}
          />
        </div>
      </div>
      <div className={"mt-3"}>
        <StyledBigTitle>{totalVendors} {totalVendors > 1 ? 'Results' : "Result"}</StyledBigTitle>
        <div className={"row"}>
          {vendors.map((vendor: any) => (
            <div className="col-12 col-md-4 col-xl-3"
                 key={`vendor-${vendor.id}`}
            >
              <VendorCard
                id={vendor.id}
                image={vendor.coverImageUrl}
                name={vendor.name}
                stars={vendor.stars}
                reviews={vendor.reviewTotal || 0}
                postcode={vendor.postcode}
                discount={vendor.discount || ''}
                searchParams={props.searchParams}
                {...props}
              />
            </div>
          ))}
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center">
            <label>
              <Pagination
                pagerPagination={paging}
                getPage={handleChangePage}
                onChangeRowsPerPage={handleChangePerPage}
                perPageOptions={[8, 16, 24, 32]}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketplaceSearchPage;