import React from "react";
import styled, { css } from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonSubmit
} from "../../../shared/employee-styled";

export const StyledButtonAgreementSubmit = styled.button`
  width: 100%;
  position: relative;
  background-color: #f9fbfd;
  border: 1px solid #ecedf0;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  color: #96aac9;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 50px;

  ${(props: { submit: boolean }) =>
    props.submit &&
    css`
      background-color: #d32b28;
      color: #ffffff;

      :hover {
        opacity: 0.8;
      }
      a {
        color: #ffffff;
      }
    `}
`;

const StyledModalDialog = styled.div`
  text-align: left;
  max-width: 600px;
  margin: 1.75rem auto;
  width: 100%;
  pointer-events: none;
`;

const StyledWidthDiv = styled.div`
  width: 118px;
`;

const StyledOptionApproved = styled(StyledButtonSubmit)`
  background-color: ${props => props.theme.primaryColor};
`;

const StyledOptionRejected = styled(StyledButtonSubmit)`
  background-color: ${props => props.theme.secondaryColor};
`;

type Props = {
  submitClaim: (check: boolean) => void;
};

export default function DuplicateClaimModal(props: Props) {
  return (
    <div className="modal" id="duplicateClaimModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          {/* header */}
          <StyledHeaderModal>
            <StyledHeaderTitleModal>
              Notice
            </StyledHeaderTitleModal>
          </StyledHeaderModal>
          {/* end header */}
          {/* body */}
          <div className="col-12">
            <hr />
          </div>

          <div className="modal-body text-left">
            You have submitted a claim with the same amount before. Do you still want to submit this claim?
          </div>

          <div className="modal-footer">
            <div>
              <StyledOptionRejected
                type="button"
                submit={true}
                onClick={() => {
                  closeDuplicateClaimModal();
                  props.submitClaim(false);
                }}>
                No, remove the claim
              </StyledOptionRejected>
            </div>
            <StyledWidthDiv>
              <StyledOptionApproved
                type="button"
                submit={true}
                onClick={() => {
                  closeDuplicateClaimModal();
                  props.submitClaim(true);
                }}
                name="btn-ok">
                Yes
              </StyledOptionApproved>
            </StyledWidthDiv>
          </div>
          {/* end body */}
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showDuplicateClaimModal() {
  ($("#duplicateClaimModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeDuplicateClaimModal() {
  ($("#duplicateClaimModal") as any).modal("hide");
}
