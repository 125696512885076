import React from "react";
import {
  StyledBigTitle,
  StyledButtonSubmit,
  LandingStyledTextValidator,
  StyledPError,
} from "../../shared/employee-styled";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  login,
  LoginReqModel,
  getCheckFinishSetup,
} from "../../../services/auth-service";
import { publishMessage } from "../../../cores/utils/message";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getAllInfoEmployee } from "../../../services/employee-registration-service";
import { addLoading, removeLoading } from "../../../cores/utils/loading";
import {
  signOut,
  setToken,
  setIsSetupDone,
  getToken,
  getIsSetupDone,
  setReAllocationStatus,
  getReAllocationStatus
} from "../../../cores/utils/helpers";
import {
  forgotPasswordURL,
  claimManagementURL,
  reAllocationURL,
  reviewReAllocationURL,
  setupBasicInformationURL
} from "../../../cores/constants/url-config";
import ShowPasswordView from "../../../cores/helpers/input/input";

type State = {
  signIn: LoginReqModel;
  isValid: boolean;
  isIncorrectAccount: boolean;
  message: string;
};

const StyledDivForgotPassword = styled(Link)`
  color: ${(theme) => theme.theme.primaryColor};
  font-size: 14px;
  line-height: 22px;
  float: right;
`;

export default class SignIn extends React.Component<any, State> {
  state: State = {
    signIn: {
      username: "",
      password: "",
    },
    isValid: false,
    isIncorrectAccount: false,
    message: "",
  };

  UNSAFE_componentWillMount() {
    if (getToken()) {
      if (getIsSetupDone()) {
        let status = getReAllocationStatus();
        let url = status === "NONE" ? claimManagementURL : status === "REVIEW" ? reviewReAllocationURL : reAllocationURL;
        this.props.history.push(url);
      } else {
        this.props.history.push(setupBasicInformationURL);
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    let username = urlParams.get("username");
    if (username && username !== null)
      this.setState({
        signIn: {
          username,
          password: ""
        }
      });
  }

  signIn = async () => {
    let signIn: LoginReqModel = Object.assign(this.state.signIn);
    signIn.username = signIn.username.toLowerCase().trim();
    addLoading();
    await login(signIn).then(async (result: any) => {
      removeLoading();
      setToken(result.data.access_token, new Date(result.data.expired_at));

      await getAllInfoEmployee().then((result) => {
        if (result.data.personalInfo.isEmployerNotEditInfo) {
          signOut();
          publishMessage({
            message:
              "Please select your class in the manage employee screen before login",
            variant: "error",
          });
        } else {
          this.checkFinishSetup(result.data);
        }
      });
    }).catch((error) => {
      removeLoading();
      // if err
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (error.response.data.status === 500) {
          publishMessage({
            message: "We’ve run into a problem. Please try again later",
            variant: "error",
          });
        } else {
          this.setState({
            isIncorrectAccount: true,
            message: error.response.data.message,
          });
        }
      } else {
        publishMessage({
          message:
            "System error. We apologize for the inconvenience. Please try again.",
          variant: "error",
        });
      }
    });
  };

  async checkFinishSetup(data: any) {
    //get isAgree
    let resultIsSetupDone = await getCheckFinishSetup(); // get api
    let isSetupDone: boolean = resultIsSetupDone
      ? resultIsSetupDone.data.isSetupDone
      : false;

    if (isSetupDone) {
      setIsSetupDone("True");
      setReAllocationStatus(data.reAllocationStatus);
      this.props.history.push(data.reAllocationStatus !== "NONE" ? reAllocationURL : claimManagementURL);
    } else {
      setIsSetupDone("");
      setReAllocationStatus("NONE");
      this.props.history.push(setupBasicInformationURL);
    }
  }

  handleChange = (event: any) => {
    let signIn = Object.assign(this.state.signIn);

    signIn[event.target.name] = event.target.value;
    this.setState({ signIn: signIn, isIncorrectAccount: false });
  };

  validatorListener = (isValid: boolean) => {
    this.setState({ isValid: isValid && this.validate() });
  };
  validate = (): boolean => {
    if (this.state.signIn.username && this.state.signIn.password) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div>
        <StyledBigTitle className="mt-5">Sign In</StyledBigTitle>

        <ValidatorForm className="w-100" onSubmit={this.signIn}>
          <div className="row pt-3">
            <div className="col-lg-6 mb-2">
              <LandingStyledTextValidator
                onChange={this.handleChange}
                type="text"
                name="username"
                placeholder="Username/Email"
                value={this.state.signIn.username}
                validators={["required"]}
                errorMessages={["Please enter user name or email."]}
                validatorListener={this.validatorListener}
                tabIndex={1}
                role="application"
                aria-hidden="true"
              />
            </div>
            <div className="col-lg-6 mb-2">
              <ShowPasswordView
                onChange={this.handleChange}
                name="password"
                placeholder="Password"
                value={this.state.signIn.password}
                validators={["required"]}
                errorMessages={["Please enter password."]}
                tabIndex={2}
                validatorListener={this.validatorListener}
              />
            </div>
            {this.state.isIncorrectAccount && (
              <StyledPError className="col-lg-12 mb-0">
                {this.state.message}
              </StyledPError>
            )}

            <div className="col-lg-12 mb-2">
              <StyledDivForgotPassword to={forgotPasswordURL}>
                Forgot the password
              </StyledDivForgotPassword>
            </div>

            <div className="mt-3 col-lg-12">
              <div className="col-lg-4 p-0 float-right">
                <StyledButtonSubmit type="submit" submit={this.state.isValid}>
                  Sign in
                </StyledButtonSubmit>
              </div>
            </div>
          </div>
        </ValidatorForm>
      </div>
    );
  }
}
