import React from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import { publishMessage } from "../../utils/message";
import { removeLoading, addLoading } from "../../utils/loading";
import { postFileToS3 } from "../../../services/employee-registration-service";

const StyledDropZone = styled(Dropzone)`
  width: 100%;
  height: 150px;
  margin-top: 0;
  border-radius: 3px;
`;

type Props = {
  addFile: (urlImage: string) => void;
  folderName: string;
  hidden: boolean;
};

// for profile picture
export function UploadAvatar(props: Props) {
  const onDrop = (accepted: any, rejected: any) => {
    if (accepted.length !== 0) {
      if (accepted[0].size < 10485760) {
        handleChangeImage(accepted);
      } else {
        publishMessage({
          variant: "error",
          message:
            "This image cannot be uploaded, because it exceeds the maximum file size for uploading.",
        });
      }
    } else {
      publishMessage({
        variant: "error",
        message: "Please submit valid file type!",
      });
    }
  };

  const handleChangeImage = (files: any[]) => {
    if (files.length) {
      // FormData image to save
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append(
        "folderName",
        props.folderName ? props.folderName : "Images"
      );
      addLoading();

      postFileToS3(formData)
        .then((result) => {
          props.addFile(result.data);
        })
        .finally(() => {
          removeLoading();
        });
    }
  };

  return (
    <div hidden={props.hidden}>
      <StyledDropZone
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        id="uploadImageAvatar"
        multiple={false}
        tabIndex={-1}
        role="application"
        aria-hidden="true"
        accept="image/jpeg, image/png"
        onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
      ></StyledDropZone>
    </div>
  );
}
