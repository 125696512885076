import { SortTable } from "../../shared/table-styled";
import { ExpenseType, FilterCondition, StatusClaim } from "./list-claim-type";

export const columnsHeaderTable = (): SortTable[] => {
  return [
    {
      columnId: "employeeName",
      columnName: "Employee",
      sortType: null,
      width: 30
    },
    {
      columnId: "totalAmount",
      columnName: "Total amount",
      sortType: null,
      width: 19
    },
    {
      columnId: "submittedDate",
      columnName: "Submitted Date",
      sortType: null,
      width: 27
    },
    {
      columnId: "claimStatus",
      columnName: "Status",
      sortType: null,
      width: 24
    }
  ];
};

export const statusClaim: StatusClaim[] = [
  {
    id: "OPEN",
    name: "Open"
  },
  {
    id: "REQUESTED",
    name: "Requested"
  },
  {
    id: "APPROVED",
    name: "Approved"
  },
  {
    id: "REJECTED",
    name: "Declined"
  }
];

export const filterConditions: FilterCondition[] = [
  {
    id: "EXPENSE_TYPE",
    name: "Expense Type"
  },
  {
    id: "CLAIM_STATUS",
    name: "Claim Status"
  },
  {
    id: "PAYMENT_STATUS",
    name: "Payment Status"
  }
];

export const claimPaymentStatus: StatusClaim[] = [
  {
    id: "PARTIALLY_PAID",
    name: "Partially Paid"
  },
  {
    id: "PENDING_PAYMENT",
    name: "Pending Payment"
  },
  {
    id: "FULLY_PAID",
    name: "Fully Paid"
  },
];

export const expenseType: ExpenseType[] = [
  { id: "1", name: "Acupuncture" },
  { id: "2", name: "Chiropractor" },
  { id: "3", name: "Dental" },
  { id: "4", name: "Health/Dental Premiums" },
  { id: "5", name: "Massage Therapy" },
  { id: "6", name: "Medical Miscellaneous" },
  { id: "7", name: "Physical Therapy" },
  { id: "8", name: "Prescription Drugs" },
  { id: "9", name: "Unpaid Insurance Amount" },
  { id: "10", name: "Vision" },
  { id: "11", name: "Supporting Document" },
  { id: "12", name: "Medical Marijuana" },
  { id: "13", name: "Other" },
  { id: "19", name: "Life Insurance" },
  { id: "22", name: "Wellness Expense" },
  { id: "25", name: "RRSP" },
  { id: "26", name: "Health and Dental Insurance" },
  { id: "27", name: "Disability Insurance" },
  { id: "28", name: "Critical Illness Insurance" },
  { id: "29", name: "Other Insurance" },
  { id: "30", name: "Travel Insurance" },
  { id: "31", name: "Wellness Services" },
  { id: "32", name: "Vitamins and Supplements" },
  { id: "33", name: "Fitness and Sports Activities" },
  { id: "34", name: "Fitness and Sports Equipment" },
  { id: "35", name: "Professional Development" },
  { id: "36", name: "Computer and Mobile Digital Devices" },
  { id: "37", name: "Family Care" },
  { id: "38", name: "Personal Interest and Learning" },
  { id: "39", name: "Insurance Premiums" },
  { id: "40", name: "Financial Advice" },
  { id: "41", name: "Other" },
  { id: "42", name: "Major Medical - Single" },
  { id: "43", name: "Major Medical - Family" },
  { id: "44", name: "Travel - Single" },
  { id: "45", name: "Travel - Family" },
  { id: "46", name: "Life+" },
  { id: "47", name: "Long Term Disability" },
  { id: "48", name: "Critical Illness" }
];

export type RejectType = StatusClaim;

export const rejectOption: RejectType[] = [
  {
    id: "IMAGE_TOO_BLURRY",
    name: "Re-submit Image"
  },
  {
    id: "OVER_BUDGET",
    name: "HSA Limit Reached"
  },
  {
    id: "OTHER",
    name: "Other (Please include comments box)"
  }
];
