import React from "react";
import moment from "moment";
import {
  StyledContentBox,
  StyledBigTitle,
  StyledMainContent,
  StyledButtonSubmit,
  StyledHideForDesktop,
  StyledHideForMobile,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
} from "../../shared/employee-styled";
import { Transactions, WalletSearchParams } from "./benefit-wallet-type";
import { formatterUSD } from "../../../cores/helpers/format-usd";
import { centToUsd } from "../../../cores/helpers/cent-to-usd";
import {
  StyledTable,
  StyledHeaderTable,
  StyledCellTable,
  StyledBodyTable,
  SortTable,
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledImportExportIcon,
} from "../../shared/table-styled";
import Pagination, {
  PagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { statusTransaction } from "./benefit-wallet-model";
import { showDebitModal } from "./debit/debit-modal";
import { ExpansionPanel } from "@material-ui/core";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import {FE_DATE_FORMAT} from "../../../cores/utils/format/date-time-format";

type Props = {
  //availableAmount: number;
  //pendingAmount: number;
  transactionsFilter: Transactions[];
  changePage: (page: number) => void;
  pagingInfo: PagingInfo;
  setRowsPerPage: (event: any) => void;
  searchParam: WalletSearchParams;
  changeFilter: (event: any) => void;
  columns: SortTable[];
  sortTable: (columnId: string) => void;
  availableAmount: number;
};

export default function BenefitWalletsView(props: Props) {
  return (
    <StyledMainContent>
      <div className="row">
        <div className="col-lg-10 mt-2">
          <StyledBigTitle>
            Available Amount for Withdrawal:{" "}
            {formatterUSD("currency").format(props.availableAmount)}{" "}
          </StyledBigTitle>
        </div>
        <div className="col-lg-2 mt-2">
          {/*<StyledButtonSubmit submit={true} onClick={showDebitModal}>*/}
          {/*  Withdraw*/}
          {/*</StyledButtonSubmit>*/}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mt-4">
          <StyledContentBox>
            <div className="row">
              <div className="col-lg-10 mt-2"></div>
              <div className="col-lg-2 mt-2">
                <BNPSelect
                  options={statusTransaction}
                  placeholder="Filter by transaction type"
                  name="transactionType"
                  onChange={props.changeFilter}
                  value={props.searchParam.transactionType || -1}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-2">
                <StyledHideForMobile>
                  <StyledTable>
                    <StyledHeaderTable>
                      {props.columns.map((column, index) => (
                        <StyledCellTable
                          width={column.width}
                          key={index}
                          className="cur"
                          onClick={() => props.sortTable(column.columnId)}
                        >
                          {column.columnName}
                          <span>
                            {column.sortType ? (
                              column.sortType === "ASC" ? (
                                <StyledArrowDropDownIcon />
                              ) : (
                                <StyledArrowDropUpIcon />
                              )
                            ) : (
                              <StyledImportExportIcon />
                            )}
                          </span>
                        </StyledCellTable>
                      ))}
                    </StyledHeaderTable>
                    <div>
                      {props.transactionsFilter.map((transaction, index) => (
                        <StyledBodyTable key={index}>
                          <StyledCellTable
                            width={25}
                            title={transaction.txnTitle}
                          >
                            {transaction.txnTitle}
                          </StyledCellTable>
                          <StyledCellTable
                            width={25}
                            title={transaction.txnDescription}
                          >
                            {transaction.txnDescription}
                          </StyledCellTable>
                          <StyledCellTable
                            width={12}
                            title={formatterUSD("currency", "USD").format(
                              centToUsd(transaction.clearedAmount)
                            )}
                          >
                            {formatterUSD("currency", "USD").format(
                              centToUsd(transaction.clearedAmount)
                            )}
                          </StyledCellTable>
                          <StyledCellTable
                            width={12}
                            title={formatterUSD("currency", "USD").format(
                              centToUsd(transaction.pendingAmount)
                            )}
                          >
                            {formatterUSD("currency", "USD").format(
                              centToUsd(transaction.pendingAmount)
                            )}
                          </StyledCellTable>
                          <StyledCellTable
                            width={13}
                            title={transaction.transactionType}
                          >
                            {transaction.transactionType}
                          </StyledCellTable>
                          <StyledCellTable
                            width={13}
                            className="text-right"
                            title={
                              transaction.createdDate
                                ? moment(transaction.createdDate).format(FE_DATE_FORMAT)
                                : ""
                            }
                          >
                            {transaction.createdDate
                              ? moment(transaction.createdDate).format(FE_DATE_FORMAT)
                              : ""}
                          </StyledCellTable>
                        </StyledBodyTable>
                      ))}
                    </div>
                  </StyledTable>
                  <div className="text-center mt-4">
                    <label>
                      <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={props.changePage}
                        onChangeRowsPerPage={props.setRowsPerPage}
                      />
                    </label>
                  </div>
                </StyledHideForMobile>

                <StyledHideForDesktop>
                  {props.transactionsFilter.map((transaction, index) => (
                    <ExpansionPanel
                      square
                      defaultExpanded={false}
                      className="mt-2"
                      key={index}
                    >
                      <StyledExpansionPanelSummary
                        className="m-0"
                        aria-controls="panel1d-content"
                        expandIcon={<ArrowDropUp />}
                      >
                        <div className="w-100 ">
                          <StyledTitleExpansion>Title</StyledTitleExpansion>
                          <div className="mt-2">{transaction.txnTitle}</div>
                        </div>
                      </StyledExpansionPanelSummary>
                      <StyledExpansionPanelDetails>
                        <div className="w-100">
                          <StyledTitleExpansion>
                            Description
                          </StyledTitleExpansion>
                          <div className="mt-1">
                            {transaction.txnDescription}
                          </div>
                        </div>
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>
                            Cleared Amount
                          </StyledTitleExpansion>
                          <div className="mt-1">
                            {formatterUSD("currency", "USD").format(
                              centToUsd(transaction.clearedAmount)
                            )}
                          </div>
                        </div>
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>
                            Pending Amount
                          </StyledTitleExpansion>
                          <div className="mt-1">
                            {formatterUSD("currency", "USD").format(
                              centToUsd(transaction.pendingAmount)
                            )}
                          </div>
                        </div>
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>
                            Transaction Type
                          </StyledTitleExpansion>
                          <div className="mt-1">
                            {transaction.transactionType}
                          </div>
                        </div>
                        <div className="w-100 mt-3">
                          <StyledTitleExpansion>
                            Created Date
                          </StyledTitleExpansion>
                          <div className="mt-1">
                            {transaction.createdDate
                              ? moment(transaction.createdDate).format(FE_DATE_FORMAT)
                              : ""}
                          </div>
                        </div>
                      </StyledExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <label>
                        <Pagination
                          pagerPagination={props.pagingInfo}
                          getPage={props.changePage}
                          onChangeRowsPerPage={props.setRowsPerPage}
                        />
                      </label>
                    </div>
                  </div>
                </StyledHideForDesktop>
              </div>
            </div>
          </StyledContentBox>
        </div>
      </div>
    </StyledMainContent>
  );
}
