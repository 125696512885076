import React from "react";
import { claimPaymentStatus, expenseType, filterConditions, statusClaim } from "./list-claim-model";
import {
  ClaimData,
  StatusClaim,
  SearchParamClaim,
  Balance, PendingAmountByCategory,
  FilterCondition,
  Condition,
  ExpenseType,
} from "./list-claim-type";
import {
  PagingInfo,
  setPagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import {
  getAllClaimByUser,
  getClaimOpeningSummary,
  putSubmitClaim,
  getExpenseTypes,
  getRemoveDuplicateClaimAmount,
  getCheckDuplicateClaimAmount,
} from "../../../services/employee-claims-services";
import { RouteChildrenProps } from "react-router";
import ListClaimView from "./list-claim-view";
import moment, { Moment } from "moment";
import isNil from 'lodash/isNil';
import { addLoading, removeLoading } from "../../../cores/utils/loading";

import {
  BenefitInfo,
  BenefitCoverage,
  Dependent,
  Relationship,
  relationships,
} from "../setup-wizard/employee-type";
import {
  ClaimPolicy,
  ReceiptData,
  TypeOfExpense,
  ClaimModel,
  ReceiptUpdate,
  ReceiptImageInfo,
  Receipt,
  DataToSubmitMultipleClaims,
} from "../receipt/receipt/claim-type";
import {
  getAllReceiptById,
  putUpdateReceipt,
  getDataReceiptById,
  deleteReceipt,
} from "../../../services/employee-receipt-services";
import {
  ClaimModal,
  showClaimModal,
  closeClaimModal,
} from "./add-claim-modal/claim-modal";
import { publishMessage, MessageData } from "../../../cores/utils/message";
import { variantIcon } from "../../../cores/utils/snackbar-content-wrapper";
import {
  closeReceiptModal,
  ReceiptModal,
  showReceiptModal,
} from "./add-claim-modal/receipt-modal";
import {
  ConfirmDialog,
  closeConfirmModal,
  showConfirmModal,
} from "../../../cores/helpers/confirm-modal/confirm-modal";
import { getFileUrl } from "../../../services/file-service";
import { toUTCTime } from "../../../cores/helpers/to-utc-time";
import {
  getDataClaim as getClaimData,
  getWalletBalance,
  setTypeOfPlan,
} from "../receipt/claim-function";
import { centToUsd, usdToCent } from "../../../cores/helpers/cent-to-usd";
import {
  signOut,
  setIsSetupDone,
  setReAllocationStatus
} from "../../../cores/utils/helpers";
import { getAllInfoEmployee } from "../../../services/employee-registration-service";
import { getCheckFinishSetup } from "../../../services/auth-service";
import {
  claimManagementURL,
  reAllocationURL,
  setupBasicInformationURL
} from "../../../cores/constants/url-config";
import DuplicateClaimModal, { showDuplicateClaimModal } from "./add-claim-modal/claim-duplicate-modal";
import {getPendingAmountByCategory} from "../../../services/wallet-service";
import { QRCodeInfoDialog, showQrCodeInfoModal } from "../setup-wizard/review-information/qr-code-info-dialog";
import { isBeniplusUI } from "../../../cores/config/component/ui-config";
import { message } from "../../../cores/helpers/message/message";
import { BE_DATE_FORMAT } from "../../../cores/utils/format/date-time-format";

type Props = RouteChildrenProps;
type State = {
  openingClaimSummary?: ClaimData;
  claimsData: ClaimData[];
  statusClaim: StatusClaim[];
  filterConditions: FilterCondition[];
  filter: string | null;
  condition: string | null;
  conditions: Condition[];
  pagingInfo: PagingInfo;
  searchParam: SearchParamClaim;
  hasMore: boolean;
  openReceipt: boolean;
  enrollments: BenefitCoverage[];
  enrollmentsType: BenefitCoverage[];
  receipts: ReceiptData[];
  dependents: Dependent[];
  relationships: Relationship[];
  benefitsInfo: BenefitInfo[];
  claimPolicies: ClaimPolicy[];
  expanseTypes: TypeOfExpense[];
  employeeBenefitCoverage: BenefitCoverage[];
  employeeBenefitCoverageType: BenefitCoverage[];
  stepClaim: number;
  dataSubmitClaim: ClaimModel;
  isValidPolicy: boolean;
  receiptEdit: ReceiptUpdate;
  isOtherExpanse: boolean;
  isEdit: boolean;
  isValidAmount: boolean;
  isConfirmValid: boolean;
  // wallet
  balances: Balance[];
  pendingAmount: number;

  isPdfFile: boolean;
  proRated: boolean;
  isAvailable: boolean;
  benefitCancellationDate: undefined;
  isAddingMore: boolean;
  dataToSubmitMultipleClaim: DataToSubmitMultipleClaims[];
  pendingAmountByCategory: PendingAmountByCategory;
  enableMonthlyLimit: boolean;
};

export default class ListClaim extends React.Component<Props, State> {
  state: State = {
    claimsData: [],
    statusClaim: statusClaim,
    filterConditions: filterConditions,
    conditions: [],
    filter: null,
    condition: null,
    pagingInfo: {
      currentPage: 1,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    searchParam: {
      page: 1,
      columnName: null,
      sortType: null,
      status: null,
      claimPaymentStatus: null,
      expenseTypeId: null,
      from: null,
      to: null,
      rowsPerPage: 10,
    },
    hasMore: false,
    openReceipt: false,
    enrollments: [],
    receipts: [],
    benefitsInfo: [],
    claimPolicies: [],
    dependents: [],
    expanseTypes: [],
    relationships: relationships,
    employeeBenefitCoverage: [],
    employeeBenefitCoverageType: [],
    stepClaim: 0,
    dataSubmitClaim: {
      claimId: 0,
      claimPolicys: [],
    },
    isValidPolicy: false,
    receiptEdit: {
      receiptId: 0,
      amount: 0,
      attachmentUrl: "",
      claimerId: -1,
      claimerType: "",
      employeeBenefitCoverageId: -1,
      expenseDate: null,
      expenseTypeId: -1,
      expenseTypeOther: "",
      notes: "",
      fileUrl: "",
    },
    isOtherExpanse: false,
    isEdit: false,
    isValidAmount: false,
    isConfirmValid: false,
    //wallet
    balances: [],
    pendingAmount: 0,
    isPdfFile: false,
    enrollmentsType: [],
    proRated: false,
    isAvailable: false,
    benefitCancellationDate: undefined,
    isAddingMore: false,
    dataToSubmitMultipleClaim: [],
    pendingAmountByCategory: {},
    enableMonthlyLimit: false,
  };

  async getInfoEmployee() {
    await getAllInfoEmployee().then((result) => {
      if (result.data.personalInfo.isEmployerNotEditInfo) {
        signOut();
        publishMessage({
          message:
            "Please select your class in the manage employee screen before login",
          variant: "error",
        });
      } else {
        this.checkFinishSetup(result.data);
      }
    });
  }

  async checkFinishSetup(data: any) {
    //get isAgree
    let resultIsSetupDone = await getCheckFinishSetup(); // get api
    let isSetupDone: boolean = resultIsSetupDone
      ? resultIsSetupDone.data.isSetupDone
      : false;

    if (isSetupDone) {
      setIsSetupDone("True");
      setReAllocationStatus(data.reAllocationStatus);
      this.props.history.push(data.reAllocationStatus !== "NONE" ? reAllocationURL : claimManagementURL);
    } else {
      setIsSetupDone("");
      setReAllocationStatus("NONE");
      this.props.history.push(setupBasicInformationURL);
    }
  }

  async componentDidMount() {
    let searchParam = this.getSearchByUrl();

    //  guidance to download the App
    if(isBeniplusUI && window.location.search === "?default") {
      showQrCodeInfoModal();
    }

    addLoading();
    await this.getInfoEmployee();
    await this.getOpeningClaimSummary();
    await this.getSearchClaimDataWithoutOpen(searchParam.page, {...searchParam});

    let responseClaimData = await getClaimData();

    this.setState({
      searchParam: searchParam,
      dependents: responseClaimData.dependents,
      benefitsInfo: responseClaimData.benefitsInfo,
      claimPolicies: responseClaimData.claimPolicies,
      employeeBenefitCoverage: responseClaimData.employeeBenefitCoverage,
      enrollments: responseClaimData.enrollments,
      enrollmentsType: responseClaimData.enrollmentsType,
      proRated: responseClaimData.proRated,
      isAvailable: responseClaimData.isAvailable,
      benefitCancellationDate: responseClaimData.benefitCancellationDate,
      enableMonthlyLimit: responseClaimData.enableMonthlyLimit,
    });


    await this.getWalletBalance();

    removeLoading();
  }

  async getWalletBalance() {
    const [balances, responsePendingAmountByCategory] =
        await Promise.all([getWalletBalance(), getPendingAmountByCategory()])
    const pendingAmountByCategory: PendingAmountByCategory = responsePendingAmountByCategory.data;
    // set employee benefit coverage
    let employeeBenefitCoverage = setTypeOfPlan(
      this.state.benefitsInfo,
      this.state.enrollments,
      balances ? balances : [],
      pendingAmountByCategory
    );

    // set employee benefit coverage type
    let employeeBenefitCoverageType = setTypeOfPlan(
      this.state.benefitsInfo,
      this.state.enrollmentsType,
      balances ? balances : [],
      pendingAmountByCategory
    );

    const pendingAmount = Object.values(pendingAmountByCategory).reduce((previousValue, currentValue) => {
      return (previousValue + currentValue.pendingApproveClaimAmount + currentValue.pendingISSClaimAmount)
    }, 0);

    this.setState({
      balances,
      employeeBenefitCoverage,
      pendingAmount,
      employeeBenefitCoverageType,
      pendingAmountByCategory
    });
  }

  //#region get data
  async getSearchClaimDataWithoutOpen(page: number, searchParam: SearchParamClaim) {
    if (searchParam.from) {
      searchParam.from = moment(searchParam.from).format(BE_DATE_FORMAT);
    } 
    if (searchParam.to) {
      searchParam.to = moment(searchParam.to).format(BE_DATE_FORMAT);
    } 

    await getAllClaimByUser({...searchParam, excludeStatus: 'OPEN'}).then((result) => {
      let pagingInfo = setPagingInfo(
        page,
        result.data.pageCount,
        searchParam.rowsPerPage
      );
      let claimData: ClaimData[] = result.data ? result.data.records : [];

      let hasMore = true;

      if (
        result.data.pageCount === 0 ||
        result.data.pageCount === searchParam.page
      ) {
        hasMore = false;
      }

      this.setState({
        claimsData: claimData,
        pagingInfo: pagingInfo,
        hasMore: hasMore,
      });
    });
  }

  async getOpeningClaimSummary() {
    let openingClaimSummary = undefined;
    await getClaimOpeningSummary().then(result => {
      if(Object.keys(result.data).length !== 0){
        openingClaimSummary = {...result.data};
      }
    });
    this.setState({ ...this.state, openingClaimSummary: openingClaimSummary });
  }

  async getAllClaimByUser(searchParam: SearchParamClaim) {
    let resultClaimData = await getAllClaimByUser({...searchParam, perPage: searchParam.rowsPerPage, excludeStatus: 'OPEN'} as SearchParamClaim);
    let claimData: ClaimData[] = [...this.state.claimsData];
    let hasMore = true;

    if (resultClaimData.data.pageCount === searchParam.page) {
      hasMore = false;
    }

    resultClaimData.data.records.forEach((record: any) => {
      claimData.push(record);
    });

    this.setState({
      searchParam: searchParam,
      claimsData: claimData,
      hasMore: hasMore,
    });
  }

  // get all receipt by claim
  async getAllReceiptById(id: number) {
    let param = { claimId: id };
    await getAllReceiptById(param).then((result) => {
      let dataSubmitClaim: ClaimModel = Object.assign(
        this.state.dataSubmitClaim
      );
      // set claimId
      dataSubmitClaim.claimId = id;
      let receiptEdit: ReceiptUpdate = {} as ReceiptUpdate;

      let benefitInfo = this.state.benefitsInfo.find((benefitInfo) => {
        return benefitInfo.id === result.data[0].employeeBenefitCoverageId;
      });

      if (result.data.length)
        receiptEdit = {
          receiptId: result.data[0].id,
          amount: centToUsd(result.data[0].amount),
          attachmentUrl: result.data[0].attachmentUrl,
          claimerId: result.data[0].claimerId,
          claimerType: result.data[0].claimerType,
          employeeBenefitCoverageId: result.data[0].employeeBenefitCoverageId,
          expenseDate: result.data[0].expenseDate,
          expenseTypeId: result.data[0].expenseTypeId,
          expenseTypeOther: result.data[0].expenseTypeOther,
          notes: result.data[0].notes,
          fileUrl: "",
        };

      if (benefitInfo)
        this.getTypeOfExpense(benefitInfo.enrollmentId, receiptEdit, false);

      this.setState({
        receipts: result.data,
        receiptEdit: receiptEdit,
        dataSubmitClaim: dataSubmitClaim,
      });
    });
  }

  // get all receipt by claims
  async getAllReceiptByIds(ids: number[]) {
    addLoading();
    let receipts: any = [];
    for (const id of ids) {
      let param = { claimId: id };
      await getAllReceiptById(param).then(result => {
        if (result.data.length > 0 && receipts.findIndex((rc: { id: number; }) => rc.id === result.data[0].id) === -1) receipts.push(...result.data)
      })
    }
    let dataSubmitClaim: ClaimModel = Object.assign({},
      this.state.dataSubmitClaim
    );
    // set claimId
    dataSubmitClaim.claimIdList = ids;

    let receiptEdit: ReceiptUpdate = {} as ReceiptUpdate;

    let benefitInfo = this.state.benefitsInfo.find((benefitInfo) => {
      return benefitInfo.id === receipts[0] && receipts[0].employeeBenefitCoverageId;
    });

    if (receipts.length)
      receiptEdit = {
        receiptId: receipts[0].id,
        amount: centToUsd(receipts[0].amount),
        attachmentUrl: receipts[0].attachmentUrl,
        claimerId: receipts[0].claimerId,
        claimerType: receipts[0].claimerType,
        employeeBenefitCoverageId: receipts[0].employeeBenefitCoverageId,
        expenseDate: receipts[0].expenseDate,
        expenseTypeId: receipts[0].expenseTypeId,
        expenseTypeOther: receipts[0].expenseTypeOther,
        notes: receipts[0].notes,
        fileUrl: "",
      };

    if (benefitInfo)
      this.getTypeOfExpense(benefitInfo.enrollmentId, receiptEdit, false);

    this.setState({
      receipts: receipts,
      receiptEdit: receiptEdit,
      dataSubmitClaim: dataSubmitClaim,
    });
    removeLoading();
  }

  // async getPendingAmount() {
  //   let pendingAmount = await getPendingAmount();
  //
  //   this.setState({ pendingAmount });
  // }

  async getTypeOfExpense(
    enrollmentId: number,
    receipt: ReceiptUpdate,
    isChangeSelect: boolean
  ) {
    let resultExpanseTypes = await getExpenseTypes(enrollmentId);
    let expanseTypes: TypeOfExpense[] = resultExpanseTypes.data
      ? resultExpanseTypes.data
      : [];

    let isOtherExpanse = expanseTypes.some((expanseType) => {
      return (
        expanseType.id === receipt.expenseTypeId && expanseType.name === "Other"
      );
    });

    // if wellnest.
    if (enrollmentId === 2 && isChangeSelect) {
      let expanseType = expanseTypes.find((expanseType) => {
        return expanseType.name === "Other";
      });

      if (expanseType) {
        receipt.expenseTypeId = expanseType.id;
        receipt.expenseTypeOther = "";
        isOtherExpanse = true;
      }
    }

    this.setState({ expanseTypes, isOtherExpanse, receiptEdit: receipt });
  }
  //#endRegion get data

  claimDataProcess = (claimData: ClaimData[]): ClaimData[] => {
    const claimDataClone = [...claimData]

    const claimDataOpenList: ClaimData[] = claimDataClone.filter(claim => claim.claimStatus === "OPEN");
    const claimDataTotalAmount: number = claimDataOpenList.reduce((totalAmountOpen, claim) => {
      return totalAmountOpen + claim.totalAmount;
    }, 0)

    const claimIdList = claimDataOpenList.reduce((idList: number[], item): number[] => {
      if (item.openIdList) {
        return [...idList, ...item.openIdList]
      } else {
        return [...idList, item.id];
      }
    }, []);
    const claimDataOpen: ClaimData = { ...claimDataOpenList[0], openIdList: claimIdList, totalAmount: claimDataTotalAmount }
    const claimDataCloneFiltered = claimDataClone.filter(claim => claim.claimStatus !== "OPEN")
    if (claimDataOpenList.length > 0) {
      claimDataCloneFiltered.unshift(claimDataOpen)
    }
    return claimDataCloneFiltered;
  }

  findStatusNameById = (statusId: string) => {
    let status = this.state.statusClaim.find((statusClaim) => {
      return statusClaim.id === statusId;
    });
    if (status) {
      return status.name;
    } else {
      return "";
    }
  };

  findColorStatusById = (statusId: string) => {
    let color = "";

    this.state.statusClaim.forEach((status) => {
      if (status.id === statusId) {
        switch (status.id) {
          case "OPEN":
            color = "#003365";
            break;
          case "REQUESTED":
            color = "#FFA400";
            break;
          case "APPROVED":
            color = "#04A77A";
            break;
          default:
            color = "#CD2E2A";
            break;
        }
      }
    });

    return color;
  };


  handleFilterConditionChange = (event: any) => {
    const { value } = event.target;
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);
    if (this.state.filter === "CLAIM_STATUS") {
      if (Number(value) !== -1) searchParam.status = value;
      else searchParam.status = null;

      searchParam.claimPaymentStatus = null;
      searchParam.expenseTypeId = null;
    } else if (this.state.filter === "PAYMENT_STATUS") {
      if (Number(value) !== -1) searchParam.claimPaymentStatus = value;
      else searchParam.claimPaymentStatus = null;

      searchParam.status = null;
      searchParam.expenseTypeId = null;
    } else if (this.state.filter === "EXPENSE_TYPE") {
      if (Number(value) !== -1) searchParam.expenseTypeId = value;
      else searchParam.expenseTypeId = null;

      searchParam.claimPaymentStatus = null;
      searchParam.status = null;
    }
    searchParam.page = 1;

    // Visual representation for filter condition
    if (Number(value) !== -1) this.setState({condition: value});
    else this.setState({condition: value});

    this.setSearchByParam(searchParam);
  };

  setFilterConditions = (event: any) => {
    let { value } = event.target;
    let searchParam: SearchParamClaim = Object.assign({}, this.state.searchParam);
    let conditions: ExpenseType[];

    // Setting values for conditions depend on the type of filters
    if (value === "EXPENSE_TYPE") {
      conditions = expenseType
    } else if (value === "CLAIM_STATUS") {
      conditions = statusClaim
    } else if (value === "PAYMENT_STATUS") {
      conditions = claimPaymentStatus
    } else {
      // Clear the filter and reset to original list
      conditions = [];
      value = null;
      searchParam.status = null;
      searchParam.claimPaymentStatus = null;
      searchParam.expenseTypeId = null;
    }

    searchParam.page = 1;

    this.setState({ conditions, filter: value });
    this.setSearchByParam(searchParam);
  };

  handleClaimChangeDate = (date: Date | null, name: string) => {
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);

    if (name === "from") {
      let currentFrom = date ? new Date(date) : null;
      if (currentFrom && (!(currentFrom instanceof Date) || isNaN(currentFrom.getTime()))) {
        message("Invalid from date.", "error");
        return;
      }
      if (searchParam.to && currentFrom) {
        let to = new Date(searchParam.to);
        if (currentFrom > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.from = date;
    } else {
      let currentTo = date ? new Date(date) : null;
      if (currentTo && (!(currentTo instanceof Date) || isNaN(currentTo.getTime()))) {
        message("Invalid to date.", "error");
        return;
      }
      if (searchParam.from && currentTo) {
        let from = new Date(searchParam.from);
        if (from > currentTo) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.to = date;
    }
    searchParam.page = 1;

    this.setSearchByParam(searchParam);
  };

  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get("page");
    const status = urlParams.get("status");

    let searchParam = Object.assign({}, this.state.searchParam);

    if (page) {
      searchParam.page = Number(page);
    } else {
      searchParam.page = 1;
    }
    if (status) {
      searchParam.status = status;
    } else {
      searchParam.status = null;
    }

    return searchParam;
  };

  setSearchByParam = (searchParam: SearchParamClaim) => {
    let url = new URL(window.location.href);

    if (searchParam.page) {
      url.searchParams.set("page", searchParam.page.toString());
    } else {
      url.searchParams.delete("page");
    }

    if (searchParam.status) {
      url.searchParams.set("status", searchParam.status);
    } else {
      url.searchParams.delete("status");
    }

    if (searchParam.claimPaymentStatus) {
      url.searchParams.set("claimPaymentStatus", searchParam.claimPaymentStatus);
    } else {
      url.searchParams.delete("claimPaymentStatus");
    }

    if (searchParam.expenseTypeId) {
      url.searchParams.set("expenseTypeId", searchParam.expenseTypeId);
    } else {
      url.searchParams.delete("expenseTypeId");
    }

    if (searchParam.from) {
      url.searchParams.set("from", moment(searchParam.from).format(BE_DATE_FORMAT));
    } else {
      url.searchParams.delete("from");
    }
    if (searchParam.to) {
      url.searchParams.set("to", moment(searchParam.to).format(BE_DATE_FORMAT));
    } else {
      url.searchParams.delete("to");
    }
    
    this.props.history.push(url.search);
    this.refresh();
  };

  fetchMoreData = async () => {
    addLoading();
    let searchParam: SearchParamClaim = Object.assign({}, this.state.searchParam);
    searchParam.page = searchParam.page + 1;
    if (searchParam.from) {
      searchParam.from = moment(searchParam.from).format(BE_DATE_FORMAT);
    } 
    if (searchParam.to) {
      searchParam.to = moment(searchParam.to).format(BE_DATE_FORMAT);
    } 
    await this.getAllClaimByUser(searchParam);
    removeLoading();
  };

  // #region claim

  handleShowClaimModal = (id?: number | number[]) => {
    // if have claimId
    if (id) {
      //then get all receipt by claimId
      if (Array.isArray(id)) {
        this.getAllReceiptByIds(id);
      } else {
        this.getAllReceiptById(id);
      }
    } else if (this.state.openingClaimSummary) {
      if (this.state.openingClaimSummary.openIdList && this.state.openingClaimSummary.openIdList.length > 0) {
        this.getAllReceiptByIds(this.state.openingClaimSummary.openIdList);
      } else if (this.state.openingClaimSummary.id) {
        this.getAllReceiptById(this.state.openingClaimSummary.id)
      } else {
        this.setState({ receipts: [] })
      }
    }
    // show claim modal
    showClaimModal();
  };

  handleChangeStepClaim = (step: number) => {
    this.setState({ stepClaim: step });
  };

  handleChangCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    let dataSubmitClaim = Object.assign({}, this.state.dataSubmitClaim);

    // if checked = true then add value checked
    if (checked) {
      dataSubmitClaim.claimPolicys.push(Number(value));
    } else {
      // remove value checked
      let index = dataSubmitClaim.claimPolicys.indexOf(Number(value));
      if (index > -1) {
        dataSubmitClaim.claimPolicys.splice(index, 1);
      }
    }

    // validate data
    let isValid = this.validateSubmitClaim(dataSubmitClaim);
    this.setState({ dataSubmitClaim: dataSubmitClaim, isValidPolicy: isValid });
  };

  validateSubmitClaim = (dataSubmitClaim: ClaimModel) => {
    if (dataSubmitClaim.claimPolicys.length === this.state.claimPolicies.length)
      return true;
    return false;
  };

  showReceiptModalEdit = (receipt: ReceiptData) => {
    let benefitInfo = this.state.benefitsInfo.find((benefitInfo) => {
      return benefitInfo.id === receipt.employeeBenefitCoverageId;
    });

    let receiptEdit: ReceiptUpdate = {
      receiptId: receipt.id,
      amount: receipt.amount / 100,
      attachmentUrl: receipt.attachmentUrl,
      claimerId: receipt.claimerId,
      claimerType: receipt.claimerType,
      employeeBenefitCoverageId: benefitInfo ? benefitInfo.enrollmentId : -1,
      expenseDate: receipt.expenseDate,
      expenseTypeId: receipt.expenseTypeId ? receipt.expenseTypeId : -1,
      expenseTypeOther: receipt.expenseTypeOther,
      notes: receipt.notes,
      fileUrl: "",
    };

    this.getTypeOfExpense(receiptEdit.employeeBenefitCoverageId, receiptEdit, false);

    getFileUrl(receipt.attachmentUrl).then((response) => {
      receiptEdit.fileUrl = response.data;

      let isPdf = false;

      if (receipt.attachmentUrl.match("/*.pdf")) {
        isPdf = true;
      }

      this.setState({
        receiptEdit: receiptEdit,
        isEdit: false,
        isPdfFile: isPdf,
      });

      removeLoading();
    });

    closeClaimModal();
    showReceiptModal();
  };

  handleSubmitClaim = async () => {
    const benefitCancellationDate = this.state.benefitCancellationDate;
    if (!isNil(benefitCancellationDate)) {
      const expiredSubmitDate: Moment = moment(this.state.benefitCancellationDate).add(14, 'days')
      if (moment().isAfter(expiredSubmitDate, 'date')) {
        return publishMessage({
          message: `Sorry your benefit was cancelled, you can not submit the new claim after ${moment(expiredSubmitDate).format('MM/DD/YYYY')}`,
          variant: "error",
        });
      }
    }

    if (this.state.dataSubmitClaim.claimIdList) {
      // Reject the claims that don't have receipt (deleted before)
      const dataSubmitClaim = Object.assign({}, this.state.dataSubmitClaim);
      const claimIdListWithExistReceipt = this.state.receipts.map(item => (item.claimId));
      dataSubmitClaim.claimIdList = claimIdListWithExistReceipt;
      this.setState({ dataSubmitClaim: dataSubmitClaim })
      //loop to check duplicate claim amount
      for (const id of dataSubmitClaim.claimIdList) {
        const res = await getCheckDuplicateClaimAmount(id);
        if (res.status === 200) {
          const dataToSubmit = [...this.state.dataToSubmitMultipleClaim]
          const checkedDataSubmit: ClaimModel = {
            claimId: id,
            claimPolicys: this.state.dataSubmitClaim.claimPolicys
          }
          dataToSubmit.push({ dataSubmitClaim: checkedDataSubmit, check: res.data.accept })
          this.setState({ dataToSubmitMultipleClaim: dataToSubmit })
        }
      }
      if (this.state.dataToSubmitMultipleClaim.filter(item => !item.check).length === 0) {
        this.setState({ isValidPolicy: false });
        this.submitMultipleClaim(this.state.dataToSubmitMultipleClaim);
      } else {
        closeClaimModal();
        showDuplicateClaimModal();
      }
    } else {
      const res = await getCheckDuplicateClaimAmount(this.state.dataSubmitClaim.claimId);
      if (res.status === 200) {
        if (res.data.accept) {
          this.submitClaim(true)
        } else {
          closeClaimModal()
          showDuplicateClaimModal()
        }
      }
    }
  };

  userHandleDuplicateClaimAmount = (choose: boolean) => {
    const data = [...this.state.dataToSubmitMultipleClaim];
    this.setState({ isValidPolicy: false });
    const dataToSubmitMultipleClaim = data.map(item => {
      const _item = { ...item };
      if (!_item.check) _item.check = choose;
      return _item;
    })
    this.submitMultipleClaim(dataToSubmitMultipleClaim)
  }

  submitMultipleClaim = async (dataToSubmitMultipleClaim: DataToSubmitMultipleClaims[]) => {
    addLoading();
    for (const item of dataToSubmitMultipleClaim) {
      const { check, dataSubmitClaim } = item;
      if (check) {
        await putSubmitClaim(dataSubmitClaim);
      } else {
        const res = await getRemoveDuplicateClaimAmount(dataSubmitClaim.claimId);
        if (res.status === 204) {
          this.message(`Your claim with id ${dataSubmitClaim.claimId} has been disabled.`, "success");
        }
      }
    }
    this.message("Your claims has been submitted successfully.", "success");
    this.setState({ receipts: [] });
    this.handleCloseClaimModal()
    removeLoading();
  }

  submitClaim = async (check: boolean) => {
    if (check) {
      this.setState({ isValidPolicy: false });
      putSubmitClaim(this.state.dataSubmitClaim).then(() => {
        // close modal
        this.handleCloseClaimModal()
        //show message success
        this.message("Your claim has been submitted successfully.", "success");
      });
    } else {
      const res = await getRemoveDuplicateClaimAmount(this.state.dataSubmitClaim.claimId);
      if (res.status === 204) {
        this.handleCloseClaimModal()
        this.message("Your claim has been disabled.", "success");
      }
    }
  }

  refresh = async () => {
    addLoading();
    // get again list claim
    let searchParam = this.getSearchByUrl();
    await this.getOpeningClaimSummary();
    this.getSearchClaimDataWithoutOpen(searchParam.page, searchParam);
    // set default value receipt when edit
    this.setDefaultReceipt();
    // set default data submit claim
    this.setDefaultClaim();

    // get balance
    await this.getWalletBalance()

    this.setState({ searchParam: searchParam, stepClaim: 0, isEdit: false });
    removeLoading();
  }
  //#endRegion claim

  //#region receipt
  handleChangeFile = (data: ReceiptImageInfo) => {
    this.createClaim(data);
  };

  async createClaim(data: ReceiptImageInfo) {
    let dataSubmitClaim = Object.assign({}, this.state.dataSubmitClaim);
    this.setDefaultReceipt();
    let receiptEdit: ReceiptUpdate = Object.assign({}, this.state.receiptEdit);

    // get amount receipt
    let resultAmount = await getDataReceiptById(data.id);

    // set data
    if (resultAmount.data) {
      receiptEdit.amount = resultAmount.data.amount / 100;
      receiptEdit.attachmentUrl = resultAmount.data.attachmentUrl;
      receiptEdit.receiptId = data.id;
      receiptEdit.claimerId = -1;
      receiptEdit.fileUrl = "";
    }
    dataSubmitClaim.claimId = resultAmount.data.claimId;
    const newClaimIdList = dataSubmitClaim.claimIdList && [...dataSubmitClaim.claimIdList]
    newClaimIdList && newClaimIdList.push(resultAmount.data.claimId);
    dataSubmitClaim.claimIdList = newClaimIdList;

    let resultFileUrl = await getFileUrl(resultAmount.data.attachmentUrl);

    receiptEdit.fileUrl = resultFileUrl.data;

    let isPdf = false;

    if (receiptEdit.attachmentUrl.match("/*.pdf")) {
      isPdf = true;
    }

    this.setState({
      dataSubmitClaim,
      receiptEdit,
      isEdit: true,
      isPdfFile: isPdf,
      isAddingMore: false
    });

    // get again list claim
    let searchParam = this.getSearchByUrl();
    this.getSearchClaimDataWithoutOpen(searchParam.page, searchParam);

    removeLoading();

    showReceiptModal();
  }

  handleChangeClaimer = (event: any) => {
    const value = Number(event.target.value);
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);

    // check value != -1
    if (value !== -1) {
      //if value = 0 set claimerType = EMPLOYEE
      if (value === 0) {
        receipt.claimerType = "EMPLOYEE";
      } else {
        // set claimerType = DEPENDENTS
        receipt.claimerType = "DEPENDENTS";
      }
      // set claimerId = value
      receipt.claimerId = value;
    } else {
      receipt.claimerId = -1;
      receipt.claimerType = "";
    }

    this.setState({ receiptEdit: receipt });
  };

  handleChangeExpanseType = (event: any) => {
    const value = Number(event.target.value);
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);

    let isOtherExpanse = false;

    let expanseType = this.state.expanseTypes.find((f) => {
      return f.id === value;
    });

    // if value = (Other)
    if (expanseType) {
      if (expanseType.name === "Other") {
        isOtherExpanse = true;
      }
      receipt.expenseTypeOther = "";
    }

    // set expenseTypeId
    receipt.expenseTypeId = value;

    this.setState({
      receiptEdit: receipt,
      isOtherExpanse: isOtherExpanse,
    });
  };

  handleChangeEmployeeBenefitCoverage = (event: any) => {
    const value = Number(event.target.value);
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);

    receipt.employeeBenefitCoverageId = value;
    // get type of expense
    if (value > -1) {
      if (value === 2) this.getTypeOfExpense(value, receipt, true);
      else this.getTypeOfExpense(value, receipt, false);
    }

    this.setState({ receiptEdit: receipt });
  };

  handleChangInput = (event: any) => {
    const { name, value } = event.target;

    let receipt = Object.assign(this.state.receiptEdit);
    let isValidAmount = false;

    receipt[name] = value;

    // if amount then check value > 0
    if (name === "amount" && Number(value) < 0) {
      // show error message
      isValidAmount = true;
    }

    this.setState({
      receiptEdit: receipt,
      isValidAmount: isValidAmount,
    });
  };

  handleChangeDate = (date: Date | null) => {
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);

    receipt.expenseDate = date;

    this.setState({ receiptEdit: receipt });
  };

  validateEdit = () => {
    if (!this.state.isOtherExpanse) {
      if (
        this.state.receiptEdit.amount > 0 &&
        this.state.receiptEdit.claimerId > -1 &&
        this.state.receiptEdit.employeeBenefitCoverageId > 0 &&
        this.state.receiptEdit.expenseDate &&
        this.state.receiptEdit.expenseTypeId > 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.state.receiptEdit.amount > 0 &&
        this.state.receiptEdit.claimerId > -1 &&
        this.state.receiptEdit.employeeBenefitCoverageId > 0 &&
        this.state.receiptEdit.expenseDate &&
        this.state.receiptEdit.expenseTypeId > 0 &&
        this.state.receiptEdit.expenseTypeOther
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleEdit = (isEdit: boolean) => {
    this.setState({ isEdit: isEdit });
  };

  updateReceipt = () => {
    let currentBenefitCoverage = this.state.employeeBenefitCoverageType.find(
      (m) => m.id === this.state.receiptEdit.employeeBenefitCoverageId
    ) as BenefitCoverage;

    let limit = currentBenefitCoverage.limit;
    if (this.state.proRated) {
      let proRateAmount = currentBenefitCoverage.proRatedAmount ? currentBenefitCoverage.proRatedAmount : 0;
      limit = proRateAmount - (currentBenefitCoverage.total - currentBenefitCoverage.limit)
    }

    if (limit / 100 < this.state.receiptEdit.amount) {
      let isConfirmValid = true;
      this.setState({ isConfirmValid });
      showConfirmModal();
    } else {
      this.handleSubmitReceipt();
    }
  };

  handleSubmitReceipt = async () => {
    addLoading();
    let receipt: ReceiptUpdate = Object.assign({}, this.state.receiptEdit);
    // set amount save
    receipt.expenseDate = receipt.expenseDate ? toUTCTime(new Date(receipt.expenseDate)) : toUTCTime(new Date())
    receipt.amount = usdToCent(this.state.receiptEdit.amount);

    let benefitInfo = this.state.benefitsInfo.find((benefitInfo) => {
      return benefitInfo.enrollmentId === receipt.employeeBenefitCoverageId;
    });
    if (benefitInfo && benefitInfo.id) {
      receipt.employeeBenefitCoverageId = benefitInfo.id;
    }
    await putUpdateReceipt(receipt).then(async () => {
      this.message("Your receipt has been edited successfully.", "success");
      await this.refresh();

      // set default receipt edit
      this.setDefaultReceipt();

      this.setState({ isConfirmValid: false, isAddingMore: false });
      closeConfirmModal();
      closeReceiptModal();
      this.handleShowClaimModal();
    });
    removeLoading();
  };

  handleChangeEditImage = (data: any) => {
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);
    // set attachmentUrl
    receipt.attachmentUrl = data.attachmentUrl;

    getFileUrl(data.attachmentUrl).then((response) => {
      receipt.fileUrl = response.data;

      this.setState({ receiptEdit: receipt });
    });

    // get amount
    this.getAmount(data.id);
  };

  async getAmount(receiptId: number) {
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);
    // get amount receipt
    let resultAmount = await getDataReceiptById(receiptId).then();

    // set amount show UI
    receipt.amount = resultAmount.data.amount / 100;

    this.setState({ receiptEdit: receipt });
  }

  deleteReceipt = (receiptId: number) => {
    addLoading();
    deleteReceipt(receiptId).then(() => {
      let receipts: ReceiptData[] = Object.assign(this.state.receipts);

      // find index receipt have to remove
      let index = receipts.findIndex((receipt) => {
        return receipt.id === receiptId;
      });
      // remove out of list
      receipts.splice(index, 1);

      this.setState({ receipts: receipts });

      closeConfirmModal();
      this.handleShowClaimModal();
      this.message("You have successfully deleted the receipt.", "success");
    });
    removeLoading();
  };

  deleteAllReceipts = async () => {
    let receipts: ReceiptData[] = [...this.state.receipts];
    addLoading();
    await Promise.all(this.state.receipts.map(async (receipt) => {
      const res = await deleteReceipt(receipt.id);
      //TODO: refactor
      if (res.status = 200) {
        // find index receipt have to remove
        let index = receipts.findIndex((re) => receipt.id === re.id);
        // remove out of list
        receipts.splice(index, 1);
      }
    }));
    this.setState({ receipts: receipts });
    this.handleShowClaimModal();
    removeLoading();
    if (receipts.length === 0) this.message("You have successfully deleted all the receipts.", "success");
  }

  findClaimerById = (claimerId: number) => {
    if (claimerId === null || claimerId === -1) {
      return { id: -1, firstName: "", lastName: "", relationship: "" };
    }

    let dependent = this.state.dependents.find((dependent) => {
      return dependent.id === claimerId;
    });

    if (dependent) {
      return dependent;
    } else {
      return { id: 0, firstName: "", lastName: "Me", relationship: "" };
    }
  };
  findExpanseTypesById = (expenseTypeId: number) => {
    let expanseType = this.state.expanseTypes.find((expanseType) => {
      return expanseType.id === expenseTypeId;
    });

    if (expanseType) {
      return expanseType.name;
    } else {
      return "";
    }
  };

  findRelationshipById = (id: string) => {
    let relationship = this.state.relationships.find((relationship) => {
      return relationship.id === id;
    });

    return relationship ? relationship.name : "Other";
  };

  addMoreReceipt = async () => {
    this.setState({ isAddingMore: true })
    $("#uploadReceipt").click();
  };

  setDefaultReceipt = () => {
    let receiptEdit: ReceiptUpdate = {
      receiptId: 0,
      amount: 0,
      attachmentUrl: "",
      claimerId: -1,
      claimerType: "",
      employeeBenefitCoverageId: -1,
      expenseDate: null,
      expenseTypeId: -1,
      expenseTypeOther: "",
      notes: "",
      //todo
      fileUrl: "",
    };
    this.setState({ receiptEdit });
  };
  setDefaultClaim = () => {
    let dataSubmitClaim: ClaimModel = {
      claimId: 0,
      claimPolicys: [],
    };
    this.setState({ dataSubmitClaim });
  };

  handleCloseClaimModal = () => {
    this.setDefaultReceipt();
    this.setState({ isAddingMore: false });
    closeClaimModal();

    setTimeout(this.refresh, 1000);
  };

  handleCloseReceiptModal = () => {
    if (this.state.isEdit) {
      if (this.state.dataSubmitClaim.claimIdList) {
        this.getAllReceiptByIds(this.state.dataSubmitClaim.claimIdList)
      } else {
        this.getAllReceiptById(this.state.dataSubmitClaim.claimId)
      };
    }
    closeReceiptModal();
    showClaimModal();
  };

  //#endRegion receipt

  message = (message: string, type: keyof typeof variantIcon) => {
    let data = {} as MessageData;
    data.message = message;
    data.variant = type;
    publishMessage(data);
  };

  namePlanTypeOfEmployee = () => {
    let currentBebefit = this.state.employeeBenefitCoverage.find(
      (m) => m.id === this.state.receiptEdit.employeeBenefitCoverageId
    );
    return currentBebefit ? currentBebefit.name : "";
  };

  render() {
    return (
      <div className="col-12">
        {/* list all claim */}
        <ListClaimView
          openingClaimSummary={this.state.openingClaimSummary}
          claimsData={this.state.claimsData}
          searchParam={this.state.searchParam}
          filterConditions={this.state.filterConditions}
          conditions={this.state.conditions}
          employeeBenefitCoverage={this.state.employeeBenefitCoverage}
          findColorStatusById={this.findColorStatusById}
          findStatusNameById={this.findStatusNameById}
          setFilterConditions={this.setFilterConditions}
          handleFilterConditionChange={this.handleFilterConditionChange}
          filter={this.state.filter}
          condition={this.state.condition}
          fetchMoreData={this.fetchMoreData}
          hasMore={this.state.hasMore}
          openReceipt={this.state.openReceipt}
          handleShowClaimModal={this.handleShowClaimModal}
          handleClaimChangeDate={this.handleClaimChangeDate}
          // wallet
          pendingAmount={this.state.pendingAmount}
          balances={this.state.balances}
          proRated={this.state.proRated}
          isAvailable={this.state.isAvailable}
          pendingAmountByCategory={this.state.pendingAmountByCategory}
          enableMonthlyLimit={this.state.enableMonthlyLimit}
        />
        {/* claim modal add/edit claim */}
        <ClaimModal
          dependents={this.state.dependents}
          expanseTypes={this.state.expanseTypes}
          receipts={this.state.receipts}
          relationships={this.state.relationships}
          step={this.state.stepClaim}
          claimPolicies={this.state.claimPolicies}
          dataSubmitClaim={this.state.dataSubmitClaim}
          isValidPolicy={this.state.isValidPolicy}
          handleChangCheckbox={this.handleChangCheckbox}
          handleChangeFile={this.handleChangeFile}
          handleChangeStepClaim={this.handleChangeStepClaim}
          handleSubmitClaim={this.handleSubmitClaim}
          showReceiptModalEdit={this.showReceiptModalEdit}
          addMoreReceipt={this.addMoreReceipt}
          handleCloseClaimModal={this.handleCloseClaimModal}
          validateEdit={this.validateEdit}
          isAddingMore={this.state.isAddingMore}
          statusClaim="OPEN"
          deleteReceipt={this.deleteReceipt}
          deleteAllReceipts={this.deleteAllReceipts}
        />
        {/* receipt modal add/edit receipt */}
        <ReceiptModal
          claimId={this.state.dataSubmitClaim.claimId}
          folderName="Employee/Claim"
          deleteReceipt={this.deleteReceipt}
          dependents={this.state.dependents}
          employeeBenefitCoverage={this.state.employeeBenefitCoverageType}
          expanseTypes={this.state.expanseTypes}
          handleChangInput={this.handleChangInput}
          handleChangeClaimer={this.handleChangeClaimer}
          handleChangeDate={this.handleChangeDate}
          handleChangeEditImage={this.handleChangeEditImage}
          handleChangeEmployeeBenefitCoverage={
            this.handleChangeEmployeeBenefitCoverage
          }
          handleChangeExpanseType={this.handleChangeExpanseType}
          handleEdit={this.handleEdit}
          handleSubmit={this.updateReceipt}
          isEdit={this.state.isEdit}
          isOtherExpanse={this.state.isOtherExpanse}
          isValidAmount={this.state.isValidAmount}
          receiptEdit={this.state.receiptEdit}
          relationships={this.state.relationships}
          handleCloseReceiptModal={this.handleCloseReceiptModal}
          validateEdit={this.validateEdit}
          isPdfFile={this.state.isPdfFile}
          statusClaim="OPEN"
          proRated={this.state.proRated}
          isAvailable={this.state.isAvailable}
        />

        <DuplicateClaimModal
          submitClaim={this.state.dataSubmitClaim.claimIdList ? this.userHandleDuplicateClaimAmount : this.submitClaim}
        />
        {this.state.isConfirmValid ? (
          <ConfirmDialog
            title="Warning"
            content={
              "You are submitting the total claim more than the amount that you set to " +
              this.namePlanTypeOfEmployee() +
              ". Do you want to continue?"
            }
            handleOnOk={this.handleSubmitReceipt}
          />
        ) : (
          <ConfirmDialog
            title="Delete"
            content="Are you sure you want to delete this receipt?"
            handleOnOk={() =>
              this.deleteReceipt(this.state.receiptEdit.receiptId)
            }
          />
        )}
        <QRCodeInfoDialog />
      </div>
    );
  }
}
