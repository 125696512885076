import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  StyledButtonSubmit,
  StyledInputMask,
} from "../../../shared/employee-styled";
import GoogleLocationInput, {
  ResponseLocation,
} from "../../../../cores/helpers/google-map-services/google-location";
import { BNPSelect } from "../../../../cores/helpers/select/select";
import { BNPInput } from "../../../../cores/helpers/input/input";
import { EmployeeLocation, Provinces } from "../employee-type";
import { getProvinces } from "../../../../services/employee-registration-service";

const StyledDivMarginTop = styled.div`
  margin-top: 5%;
`;

const StyledSmallerSpan = styled.div`
  color: #d71f27;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-left: 4px;
`;
const StyledPTitle = styled.p`
  color: #2d2d2d;
  font-weight: bold;
  height: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

type Props = {
  employeeLocation: EmployeeLocation;
  handleSubmitLocationInfo: (employeeLocation: EmployeeLocation) => void;
  isValidLocationData: (employeeLocation: EmployeeLocation) => boolean;
};

export default function SetUpEmployeeLocationView(props: Props) {
  const [locationState, setLocationState] = useState<EmployeeLocation>({
    id: 0,
    street: "",
    cityName: "",
    provinceId: 0,
    provinceName: "",
    postCode: "",
  });
  const [provinces, setProvince] = useState<Provinces[]>([]);

  async function getProvincesData() {
    const result: any = await getProvinces();
    setProvince(result.data);
  }

  useEffect(() => {
    setLocationState(props.employeeLocation);
  }, [JSON.stringify(props.employeeLocation)]);

  useEffect(() => {
    getProvincesData();
  }, []);

  const setAddress = (address: string) => {
    let employeeLocation: EmployeeLocation = Object.assign({}, locationState);

    employeeLocation.street = address;

    setLocationState(employeeLocation);
  };

  const setLocation = (location: ResponseLocation) => {
    let employeeLocation: EmployeeLocation = Object.assign({}, locationState);

    employeeLocation.cityName = location.city;
    employeeLocation.postCode = location.postcode ? location.postcode : "";
    employeeLocation.provinceId = findProvinceIdByName(location.province);

    setLocationState(employeeLocation);
  };

  const findProvinceIdByName = (provinceName: string) => {
    let province = provinces.find((f) => {
      return f.name === provinceName;
    });
    return province ? province.id : -1;
  };

  const handleChangeInputStreet = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let employeeLocation: any = Object.assign({}, locationState);

    const { name, value } = event.target;

    employeeLocation[name] = value;

    setLocationState(employeeLocation);
  };

  const handleChangeSelected = (event: any) => {
    const { value } = event.target;

    let employeeLocation: EmployeeLocation = Object.assign({}, locationState);

    if (Number(value) > 0) {
      employeeLocation.provinceId = value;
    } else {
      employeeLocation.provinceId = 0;
    }
    employeeLocation.provinceName = "";

    setLocationState(employeeLocation);
  };

  const handleSubmit = () => {
    if (props.isValidLocationData(locationState)) {
        props.handleSubmitLocationInfo(locationState);
    }
  }

  return (
    <div>
      <StyledDivMarginTop>
        <div className="row">
          <StyledSmallerSpan>Please complete your address info setting before allocate the budget to RRSP or Personal Insurance</StyledSmallerSpan>
          <div className="col-sm-12">
            <StyledPTitle className="mb-2">Street *</StyledPTitle>
            <GoogleLocationInput
              id="location"
              address={locationState.street}
              setAddress={setAddress}
              setLocation={setLocation}
              placeholder="Street"
              tabIndex={6}
            />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-sm-4 mt-3">
            <StyledPTitle className="mb-2">Province *</StyledPTitle>
            <BNPSelect
              options={provinces}
              placeholder="Province"
              name="provinceId"
              value={locationState.provinceId || "-1"}
              onChange={handleChangeSelected}
              tabIndex={7}
            />
          </div>
          <div className="col-sm-4 mt-3">
            <StyledPTitle className="mb-2">City *</StyledPTitle>
            <BNPInput
              onChangeInput={handleChangeInputStreet}
              type="text"
              name="cityName"
              placeholder="City"
              value={locationState.cityName}
              tabIndex={8}
            />
          </div>
          <div className="col-sm-4 mt-3">
            <StyledPTitle className="mb-2">Postcode *</StyledPTitle>
            <StyledInputMask
              mask="*** ***"
              value={locationState.postCode || ""}
              onChange={handleChangeInputStreet}
              name="postCode"
              placeholder="Post code"
              tabIndex={9}
              role="application"
              aria-hidden="true"
            />
          </div>
        </div>
      </StyledDivMarginTop>
      <div className="row mt-3">
        <div className="col-lg-2 ml-auto">
          <StyledButtonSubmit
            id="buttonSaveProfile"
            type="submit"
            disabled={!props.isValidLocationData(locationState)}
            onClick={handleSubmit}
            submit={props.isValidLocationData(locationState)}
            tabIndex={10}
          >
            Save
          </StyledButtonSubmit>
        </div>
      </div>
    </div>
  );
}
