import React from "react";

import styled from "styled-components";
import { BNPInput, Input } from "../../../../cores/helpers/input/input";
import { BenefitInfo, BenefitData } from "../employee-type";
import {
  StyledLargerTitle,
  StyledDescriptionLargerTitle,
  StyledRow,
  StyledButtonSubmit,
  StyledHideForMobile,
  StyledBtnLink,
} from "../../../shared/employee-styled";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import {
  CheckBox,
  Checkbox,
} from "../../../../cores/helpers/checkbox/checkbox";
import { setupBasicInformationURL } from "../../../../cores/constants/url-config";
import theme from "../../../../cores/helpers/theme";

const StyledTotalAmount = styled.div`
  color: ${(props) => props.theme.secondaryColor};
  font-size: 19px !important;
  font-weight: bold;
  line-height: 26px;
  @media (min-width: 992px) {
    margin-top: 1px;
    font-size: 20px;
    line-height: 24px;
  }
`;

const StyledBNPInput = styled(BNPInput)`
  padding-left: 25px;
  @media (min-width: 992px) {
    margin-top: 10px;
    margin-left: 28px;
    margin-bottom: 12px;
    width: 85%;
  }
`;

const StyledSpanBalance = styled.span`
  height: 50px;

  background-color: #f9fbfd;
  border: 1px solid #ecedf0;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
const StyledSpan = styled.span`
  position: absolute;
  top: 57px;
  left: 50px;
  @media (max-width: 992px) {
    position: absolute;
    top: 47px;
    left: 21px;
  }
`;

type Props = {
  handleChangInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  findBenefitInfoById: (benefitId: number) => BenefitInfo;
  totalBenefitCoverageAmountLeft: (benefitInfo: BenefitInfo[]) => number;
  totalBenefitCoverageProRatedAmountLeft: (benefitInfo: BenefitInfo[]) => number
  benefitData: BenefitData;
  isValid: boolean;
  benefitInfo: BenefitInfo[];
  validateBenefitInfo: (totalAmountLeft: number) => boolean;
  isProRated: boolean;
  availableDate: Date;
  remainDays: number;
  isAvailable: boolean;
  allowEdit: boolean;
};

export default function SetupBenefitCoverageView(props: Props) {
  const findChecked = (enrollmentId: number): boolean => {
    if (enrollmentId) return true;
    else return false;
  };

  let totalAmountLeft = props.totalBenefitCoverageAmountLeft(props.benefitInfo);
  let totalProRatedAmountLeft = props.totalBenefitCoverageProRatedAmountLeft(props.benefitInfo);

  let total = 0;
  props.benefitInfo.forEach(t => total += t.amount);

  let isValid = props.validateBenefitInfo(totalAmountLeft) || !props.allowEdit;

  return (
    <div className="row">
      <div className="col-12">
        <StyledLargerTitle>Benefit Coverage</StyledLargerTitle>
        <div className="mt-3">
          <StyledDescriptionLargerTitle>
            Total benefit coverage Amount left
          </StyledDescriptionLargerTitle>
        </div>
        <hr />
        <StyledTotalAmount>
          {formatterUSD("currency", "USD").format(centToUsd(totalAmountLeft))}
          {` is the total benefit maximum for the whole year`}
        </StyledTotalAmount>
        {
          props.isProRated && (<>
            <StyledTotalAmount>
              {formatterUSD("currency", "USD").format(centToUsd(totalProRatedAmountLeft < 0 ? 0 : totalProRatedAmountLeft))}
              {` is the available benefit maximum (this may be pro-rated for the remainder of the year)`}
            </StyledTotalAmount>
          </>)
        }
      </div>

      <div className="col-12">
        <StyledRow>
          <div className="col-lg-12 mt-3 mb-md-4">
            <div>
              Please choose the benefit categories you would like to have available in your plan and allocate your available benefit maximum between those categories.
            </div>
          </div>

          {props.benefitData.enrollments.map((enrollment) => (
            <div className="col-lg-6 mt-3 mt-lg-2" key={enrollment.id}>
              <BenefitCoverage
                title={enrollment.name}
                checked={findChecked(
                  props.findBenefitInfoById(enrollment.id).enrollmentId
                )}
                name={enrollment.id.toString()}
                value={
                  props.isProRated ?
                    props.findBenefitInfoById(enrollment.id).proRatedAmount
                    :
                    props.findBenefitInfoById(enrollment.id).amount
                }
                amount={props.findBenefitInfoById(enrollment.id).amount}
                onChangeInput={props.handleChangInput}
                onChangeCheckbox={props.handleChangCheckbox}
                tabIndex={enrollment.id}
                isProRated={props.isProRated}
                allowEdit={props.allowEdit}
              />
            </div>
          ))}

          <div className="col-lg-12 order-lg-5 mt-5">
            <div className="row">
              <StyledHideForMobile className="col-lg-2">
                <StyledBtnLink
                  to={setupBasicInformationURL}
                  background={theme.secondaryColor}
                >
                  Back
                </StyledBtnLink>
              </StyledHideForMobile>
              <div className="col-lg-7"></div>
              <div className="col-lg-3">
                <StyledButtonSubmit
                  onClick={() => {
                    $("#buttonSaveBenefit").attr("disabled", "true");
                    props.handleSubmit();
                  }}
                  disabled={!isValid || props.benefitInfo.length === 0}
                  submit={isValid && props.benefitInfo.length !== 0}
                  id="buttonSaveBenefit"
                >
                  Continue
                </StyledButtonSubmit>
              </div>
            </div>
          </div>
        </StyledRow>
      </div>
    </div>
  );
}

const BenefitCoverage = (props: BenefitCoverage) => {

  return (
    <div>
      <CheckBox
        title={`${props.title}` || ""}
        classLabel={props.classLabel}
        classSpan={props.classSpan}
        onChangeCheckbox={props.onChangeCheckbox}
        checked={props.checked}
        name={props.name || ""}
        disabled={!props.allowEdit}
      />
      {props.checked ? (
        <div className="d-flex flex-row align-items-center">
          <StyledSpan>$</StyledSpan>
          {
            props.isProRated ? (<>
              <StyledBNPInput
                placeholder={props.placeholder || ""}
                onChangeInput={props.onChangeInput}
                value={props.value ? Number(props.value) : undefined}
                name={props.name || ""}
                type="number"
                disabled={!props.allowEdit}
                tabIndex={props.tabIndex} />
              <StyledSpanBalance className="d-flex align-items-center">
                {formatterUSD("currency", "USD").format(props.amount)}
              </StyledSpanBalance>
            </>) :
              (<>
                <StyledBNPInput
                  placeholder={props.placeholder || ""}
                  onChangeInput={props.onChangeInput}
                  value={props.value ? Number(props.value) : undefined}
                  name={props.name || ""}
                  type="number"
                  disabled={!props.allowEdit}
                  tabIndex={props.tabIndex} />
              </>)
          }
        </div>
      ) : (
          ""
        )}
    </div>
  );
}

type BenefitCoverage = Checkbox & Input & {
  isProRated: boolean;
  amount: number;
  allowEdit: boolean;
};
