export type BenefitCoverage = {
  id: number;
  name: string;
  limit: number;
  total: number;
  proRatedAmount: number;
};

export type BenefitData = {
  totalAmount: number;
  enrollments: BenefitCoverage[];
};

export type EmployeeSignUpData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  rePassword: string;
  token: string;
};
export type EmployeeLocation = {
  id: number;
  street: string;
  cityName: string;
  provinceId: number;
  provinceName: string;
  postCode: string;
};

export type EmployeeBasicInformation = {
  gender: string | null;
  dateOfBirth: string | null;
  phoneNumber: string;
  homeAddress: string;
  cityName: string;
  provinceId: number;
  provinceName: string;
  postCode: string | null;
  socialInsuranceNumber: number | null;
};

export type ResponseEmployeeData = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  phoneNumber: string;
  employeeLocation: EmployeeLocation;
  passwordObject: PasswordObject;
  profileUrl: string;
  companyName: string;
};
export type Provinces = {
  id: number;
  code: string;
  name: string;
};

export type PasswordObject = {
  oldPassword: string | null;
  newPassword: string | null;
  confirmPassword: string | null;
};

export type Cities = {
  id: number;
  name: string;
};

export type BenefitInfo = {
  id?: number;
  enrollmentId: number;
  name?: string;
  amount: number;
  proRatedAmount: number;
  // amountLastYear: number;
  // proRatedAmountLastYear: number;
  rolloverAmount: number;
};

export type BankingInfo = {
  employeeId: number;
  voidCheque: string;
  transitNumber: string;
  bankNumber: string;
  accountNumber: string;
  agreements: number[];
  eSignature: string;
  date: Date | string | null;
};

export type Dependent = {
  id?: number;
  firstName: string;
  lastName: string;
  dateOfBirth: Date | string | null;
  relationship: string;
};

export type Relationship = {
  id: string;
  name: string;
};

export type Agreement = {
  id: number;
  title: string;
  description: string;
  checked?: boolean;
};

export const relationships = [
  { id: "WIFE", name: "Wife" },
  { id: "HUSBAND", name: "Husband" },
  { id: "PARTNER", name: "Partner" },
  { id: "CHILDREN", name: "Child" },
  { id: "OTHER", name: "Other" },
];

export const defaultDependent = (): Dependent => {
  return { firstName: "", lastName: "", relationship: "", dateOfBirth: null };
};

export type RequestProfile = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  street: string;
  provinceId: number;
  cityName: string;
  postCode: string;
};

export type IncreaseLimit = {
  employeeId: number;
  targetWallets: TargetWallets[];
};
export type TargetWallets = {
  walletType:
    | "hsa_limit"
    | "wellness_limit"
    | "insurance_limit"
    | "charity_limit"
    | "rrsp_limit"
    | "cra_limit"
    | null;
  amount: number;
  desc: string;
};

export type PremiumInsuranceType = {
  id: number,
  productId: number,
  providerId: number,
  providerName: string,
  productName: string,
  status: PremiumPaymentStatus
}

export enum PremiumPaymentStatus {
  REQUESTED = "REQUESTED",
  QUOTED = "QUOTED",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED"
}
