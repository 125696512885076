import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { Message } from "./cores/utils/message";
import RouteLayout from "./components/shared/route-layout";
import SignIn from "./components/pages/sign-in/sign-in";
import ListClaim from "./components/pages/view-list-claim/list-claim";
import DetailClaim from "./components/pages/view-list-claim/claim-detail/claim-detail";
import { ThemeProvider } from "styled-components";
import Theme from "../src/cores/helpers/theme";
import RouteLayoutSignIn from "./components/shared/sign-in-layout/sign-in-route-layout";
import { StyledApp } from "./components/shared/employee-styled";
import { Loading } from "./cores/utils/loading";
import SignUpEmployee from "./components/pages/sign-up/sign-up";
import SetupBankingInfo from "./components/pages/setup-wizard/setup-banking-information/setup-banking-information";
import SetupBenefitCoverage from "./components/pages/setup-wizard/setup-benefit-coverage/setup-benefit-coverage";
import SetupDependents from "./components/pages/setup-wizard/setup-dependents/setup-dependents";
import ReviewInformation from "./components/pages/setup-wizard/review-information/review-information";
import PersonalInformation from "./components/pages/profile/components-profile/personal-information/personal-information";
import UpdateDependents from "./components/pages/profile/components-profile/dependents/dependents";
import Notifications from "./components/pages/profile/components-profile/notifications/notifications";
import BenefitWallets from "./components/pages/benefit-wallets/benefit-wallets";
import BankingInfo from "./components/pages/profile/components-profile/banking-info/banking-info";
import PrivacyPolicy from "./components/pages/privacy-policy/privacy-policy";
import TermService from "./components/pages/term-service/term-service";
import ForgotPassword from "./components/pages/forgot-password/forgot-password";
import UpdatePassword from "./components/pages/forgot-password/update-password";
import ChangePassword from "./components/pages/profile/components-profile/change-password/change-password";
import Unsubcribe from "./components/pages/unsubcribe/unsubcribe";
import Documents from "./components/pages/documents/document";
import Marketplace from "./components/pages/marketplace/marketplace";
import { googleAPIKey, hubSpotChatboxSrc, zendeskAPIKey } from "./cores/config/config";
import {
  getIsSetupDone,
  getReAllocationStatus
} from "./cores/utils/helpers";
import {
  homePage,
  signInURL,
  signUpURL,
  unsubcribeURL,
  forgotPasswordURL,
  updatePasswordURL,
  claimManagementURL,
  claimManagementDetailURL,
  setupBenefitURL,
  setupBankingURL,
  setupDependentsURL,
  reviewInformationURL,
  profileURL,
  walletsURL,
  bankingInfoURL,
  privacyURL,
  termsOfServicesURL,
  changePasswordURL,
  documentsURL,
  contactURL,
  reAllocationURL,
  reviewReAllocationURL,
  dependentURL,
  notificationUrl,
  setupBasicInformationURL,
  marketplaceURL,
  marketplaceDetailURL,
  marketplaceSearchURL
} from "./cores/constants/url-config";
import Contact from "./components/pages/contact/contact";
import ReAllocationBenefitCoverage from "./components/pages/re-allocation/re-allocation-benefit-coverage/re-allocation-benefit-coverage";
import ReviewReAllocation from "./components/pages/re-allocation/review-re-allocation/review-re-allocation";
import SetUpBasicInformation from "./components/pages/setup-wizard/basic-information/basic-information";
import VendorDetail from "./components/pages/marketplace/detail/vendor-detail";
import MarketplaceSearch from "./components/pages/marketplace/marketplace-search";

const App: React.FC = () => {
  useEffect(() => {
    // Start of  google api script
    const googleApi = document.createElement("script");

    googleApi.type = "text/javascript";
    googleApi.src = `https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places`;
    googleApi.async = true;
    document.body.appendChild(googleApi);
    // End of  google api script

    // Start of  Zendesk Widget script
    // const zenDesk = document.createElement("script");

    // zenDesk.type = "text/javascript";
    // zenDesk.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskAPIKey}`;
    // zenDesk.async = true;
    // zenDesk.id = "ze-snippet";
    // document.body.appendChild(zenDesk);
    // End of  Zendesk Widget script

    // Start of Hotjar script
    const hotJar = document.createElement("script");
    if (process.env.NODE_ENV === "production") {
      hotJar.append("(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3482614,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');");
      document.head.appendChild(hotJar);
    }
    // End of Hotjar script

    // <!-- Start of HubSpot Embed Code -->
    const hubSpot = document.createElement("script");

    hubSpot.type = "text/javascript";
    hubSpot.id = "hs-script-loader";
    hubSpot.src = `${hubSpotChatboxSrc}`;
    hubSpot.async = true;
    hubSpot.defer = true;
    document.body.appendChild(hubSpot);
    // <!-- End of HubSpot Embed Code -->

    return () => {
      document.body.removeChild(googleApi);
      // document.body.removeChild(zenDesk);
      document.body.removeChild(hotJar);
      document.body.removeChild(hubSpot);
    };
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <StyledApp className="App">
        <Message />
        <Loading />
        <BrowserRouter>
          <Switch>
            <RouteLayoutSignIn exact path={signInURL} component={SignIn} />
            <RouteLayoutSignIn
              exact
              path={signUpURL}
              component={SignUpEmployee}
            />
            <RouteLayoutSignIn path={unsubcribeURL} component={Unsubcribe} />
            <RouteLayoutSignIn
              path={forgotPasswordURL}
              component={ForgotPassword}
            />
            <RouteLayoutSignIn
              path={updatePasswordURL}
              component={UpdatePassword}
            />
            <RouteLayout exact path={homePage} component={ListClaim} />
            <RouteLayout
              exact
              path={claimManagementURL}
              component={ListClaim}
            />
            <RouteLayout
              exact
              path={claimManagementDetailURL}
              component={DetailClaim}
            />
            <RouteLayout path={contactURL} component={Contact} />

            {/*Setup  */}
            <RouteLayout
              exact
              path={setupBasicInformationURL}
              component={SetUpBasicInformation}
            />
            <RouteLayout
              exact
              path={setupBenefitURL}
              component={SetupBenefitCoverage}
            />
            <RouteLayout
              exact
              path={setupBankingURL}
              component={SetupBankingInfo}
            />
            <RouteLayout
              exact
              path={setupDependentsURL}
              component={SetupDependents}
            />
            <RouteLayout
              exact
              path={reviewInformationURL}
              component={ReviewInformation}
            />
            {/* end setup */}

            {/* Re Allocation  */}
            <RouteLayout
              exact
              path={reAllocationURL}
              component={ReAllocationBenefitCoverage}
            />

            <RouteLayout
              exact
              path={reviewReAllocationURL}
              component={ReviewReAllocation}
            />

            {/* End Re Allocation */}

            <RouteLayout
              exact
              path={profileURL}
              component={PersonalInformation}
            />

            <RouteLayout
              exact
              path={dependentURL}
              component={UpdateDependents}
            />

            <RouteLayout
              exact
              path={notificationUrl}
              component={Notifications}
            />

            <RouteLayout
              exact
              path={marketplaceURL}
              component={Marketplace}
            />

            <RouteLayout
              path={marketplaceSearchURL}
              component={MarketplaceSearch}
            />

            <RouteLayout
              path={marketplaceDetailURL}
              component={VendorDetail}
            />

            <RouteLayout path={walletsURL} component={BenefitWallets} />

            <RouteLayout path={bankingInfoURL} component={BankingInfo} />

            <RouteLayout path={privacyURL} component={PrivacyPolicy} />

            <RouteLayout path={termsOfServicesURL} component={TermService} />

            <RouteLayout path={changePasswordURL} component={ChangePassword} />
            <RouteLayout path={documentsURL} component={Documents} />

            <Redirect
              to={getIsSetupDone() ? (getReAllocationStatus() !== "NONE" ? reAllocationURL : claimManagementURL) : setupBasicInformationURL}
            />
          </Switch>
        </BrowserRouter>
      </StyledApp>
    </ThemeProvider>
  );
};

export default App;
