import React from "react";
import styled from "styled-components";
import Profile from "../../profile";
import {
  relationships,
  Dependent,
  Relationship,
  defaultDependent,
} from "../../../setup-wizard/employee-type";
import { addLoading, removeLoading } from "../../../../../cores/utils/loading";
import { getAllInfoEmployee } from "../../../../../services/employee-registration-service";
import {
  StyledButtonSubmit,
  StyledContent,
  StyledLargerTitle,
} from "../../../../shared/employee-styled";
import DependentModal, {
  showDependentModal,
  closeDependentModal,
} from "./dependents-modal";
import moment from "moment";

const StyledAddDependent = styled(StyledButtonSubmit)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.secondaryColor};
  color: ${(props) => props.theme.secondaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

type State = {
  dependents: Dependent[];
  relationships?: Relationship[];
};
export default class UpdateDependents extends React.Component<any, State> {
  state: State = {
    dependents: [
      {
        firstName: "",
        lastName: "",
        relationship: "",
        dateOfBirth: null,
      },
    ],
    relationships: relationships,
  };

  async componentDidMount() {
    //add loading
    addLoading();

    await getAllInfoEmployee().then((result) => {
      this.setDataWhenReloadPage(result);
    });

    removeLoading();
  }

  setDataWhenReloadPage = (result: any) => {
    let dependents = result.data.dependents.length
      ? result.data.dependents
      : [defaultDependent()];

    this.setState({
      dependents: dependents,
    });
  };

  findDependentNameById = (id: string): string => {
    let dependent = relationships.find((relationship) => {
      return relationship.id === id;
    });

    return dependent ? dependent.name : "";
  };

  handleCloseDependentModal = async (): Promise<void> => {
    closeDependentModal();
    await getAllInfoEmployee().then((result) => {
      this.setDataWhenReloadPage(result);
    });
  };

  render() {
    return (
      <>
        <Profile
          children={
            <div className="row">
              <div className="col-12 mt-3">
                <StyledLargerTitle>Dependents</StyledLargerTitle>

                {this.state.dependents
                  ? this.state.dependents.map((dependent, index) => (
                      <div className="row mt-3" key={`dependent-${index}`}>
                        <StyledContent className="col-6">
                          {dependent.firstName + " " + dependent.lastName}
                        </StyledContent>
                        <StyledContent className="col-3">
                        {dependent.dateOfBirth
                              ? `${moment().diff(dependent.dateOfBirth, 'years')} YO`
                              : ""}
                        </StyledContent>
                        <StyledContent className="col-3 text-right">
                          {this.findDependentNameById(dependent.relationship)}
                        </StyledContent>
                        <hr />
                      </div>
                    ))
                  : null}
                <div className="row mt-2 justify-content-end">
                  <div className="col-4">
                    <StyledAddDependent
                      submit={true}
                      onClick={showDependentModal}
                    >
                      Manage Dependent
                    </StyledAddDependent>
                  </div>
                </div>
              </div>
            </div>
          }
        />
        <DependentModal
          handleCloseDependentModal={this.handleCloseDependentModal}
        />
      </>
    );
  }
}
