import React, { Component } from "react";
import { RouteChildrenProps } from "react-router";
import {
  EmployeeBasicInformation,
  Provinces
} from "../employee-type";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import {
  getAllInfoEmployee,
  getProvinces,
  putEmployeeBasicInformation
} from "../../../../services/employee-registration-service";
import SetUpWizardView from "../setup";
import {
  claimManagementURL,
  setupBenefitURL
} from "../../../../cores/constants/url-config";
import { getIsSetupDone } from "../../../../cores/utils/helpers";
import { Redirect } from "react-router-dom";
import SetupBasicInformationView from "./basic-information-view";
import { toUTCTime } from "../../../../cores/helpers/to-utc-time";
import { formatValueInputMask } from "../../../../cores/helpers/format-phone-number";
import { ResponseLocation } from "../../../../cores/helpers/google-map-services/google-location";

type State = {
    isValid: boolean,
    employeeBasicInformation: EmployeeBasicInformation,
    provinces: Provinces[]
};

type Props = RouteChildrenProps;

export default class SetUpBasicInformation extends Component<Props, State> {
  state: State = {
    isValid: false,
    employeeBasicInformation: {
        gender: null,
        dateOfBirth: "",
        phoneNumber: "",
        homeAddress: "",
        cityName: "",
        provinceId: 0,
        provinceName: "",
        postCode: null,
        socialInsuranceNumber:  null,
    },
    provinces: [],
  };

  async componentDidMount() {
    addLoading();

    if (getIsSetupDone() === "True")
      return;

    await this.getData();

    await getAllInfoEmployee().then((result) => {
      this.setDataWhenReloadPage(result);
    });

    removeLoading();
  }

  async getData() {
    const [resultProvinces] = await Promise.all([
      getProvinces(),
    ])

    let provinces: Provinces[] = resultProvinces.data || [];

    this.setState({
      provinces: provinces,
    });
  }

  setDataWhenReloadPage = (result: any) => {
    let basicInformation = [];
    if (result.data.basicInformation) {
      basicInformation = result.data.basicInformation;
    }

    this.setState({
        employeeBasicInformation: basicInformation
    });

    let isValid = this.validateForm();
    this.setState({ isValid: isValid });

  };


  handleChangeSubmit = async () => {
    addLoading();

    let employeeInfo: EmployeeBasicInformation = Object.assign(this.state.employeeBasicInformation);
    if (employeeInfo.dateOfBirth) {
        employeeInfo.dateOfBirth = toUTCTime(new Date(employeeInfo.dateOfBirth))
    }

    putEmployeeBasicInformation(employeeInfo)
      .then(() => {
        this.props.history.push(setupBenefitURL);
      })
      .catch(() => {
        $("#buttonSaveBasicInformation").removeAttr("disabled");
      });

    removeLoading();

  };

  validateBasicInformation = (): boolean => {
    return true;
  }

  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let employeeInfo = Object.assign(this.state.employeeBasicInformation);
    if (event.target.name === "phoneNumber") {
        employeeInfo.phoneNumber = formatValueInputMask(event.target.value);
    } else {
        employeeInfo[event.target.name] = event.target.value;
    }

    let isValid = this.validateForm();
    this.setState({ employeeBasicInformation: employeeInfo, isValid: isValid });
  };

  //#region
  handleChangeDate = (date: Date | null, name: string) => {
    let employeeInfo = Object.assign(this.state.employeeBasicInformation);

    employeeInfo[name] = date && toUTCTime(new Date(date));

    let isValid = this.validateForm();
    this.setState({ employeeBasicInformation: employeeInfo, isValid: isValid });

  };

  handleChangeSelect = (event: any) => {
    let { name, value } = event.target,
    employeeInfo = Object.assign(this.state.employeeBasicInformation);
    
    if (value === -1) value = null;

    employeeInfo[name] = value;

    let isValid = this.validateForm();
    this.setState({ employeeBasicInformation: employeeInfo, isValid: isValid });
  };

  validateForm = (): boolean => {
    const { gender, dateOfBirth, provinceId } = this.state.employeeBasicInformation;
    return !!(gender &&
        dateOfBirth && 
        provinceId && 
        provinceId > 0
    );
  };

  setAddress = (address: string) => {
    let employeeInfo: EmployeeBasicInformation = Object.assign(this.state.employeeBasicInformation);
    employeeInfo.homeAddress = address;

    let isValid = this.validateForm();
    this.setState({ employeeBasicInformation: employeeInfo, isValid: isValid });
  };

  setLocation = (location: ResponseLocation) => {
    let employeeInfo: EmployeeBasicInformation = Object.assign(this.state.employeeBasicInformation);

    employeeInfo.cityName = location.city;
    employeeInfo.postCode = location.postcode ? location.postcode : "";
    employeeInfo.provinceId = this.findProvinceIdByName(
      location.province,
      this.state.provinces
    );

    let isValid = this.validateForm();
    this.setState({ employeeBasicInformation: employeeInfo, isValid: isValid });
  };

  findProvinceIdByName = (provinceName: string, provinces: Provinces[]) => {
    let province = provinces.find((f) => {
      return f.name === provinceName;
    });
  
    return province ? province.id : -1;
  }
  


  render() {
    return getIsSetupDone() === "True" ? (
      <Redirect to={claimManagementURL} />
    ) : (
      <SetUpWizardView
        children={
          <SetupBasicInformationView
            employeeBasicInformation={this.state.employeeBasicInformation}
            handleChangeInput={this.handleChangeInput}
            handleChangeDate={this.handleChangeDate}
            handleChangeSelect={this.handleChangeSelect}
            handleChangeSubmit={this.handleChangeSubmit}
            validateBasicInformation={this.validateBasicInformation}
            provinces={this.state.provinces}
            setAddress={this.setAddress}
            setLocation={this.setLocation}
            isValid={this.state.isValid}
          />        
        }
      />
    );
  }
}
