import React from "react";
import styled from "styled-components";
import { Select, MenuItem } from "@material-ui/core";
import {
  ReceiptUpdate,
  TypeOfExpense,
  ReceiptImageInfo,
} from "../../receipt/receipt/claim-type";
import {
  Dependent,
  BenefitCoverage,
  Relationship,
} from "../../setup-wizard/employee-type";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledPError,
  StyledButtonSubmit,
  StyledTextarea,
} from "../../../shared/employee-styled";
import UploadImage from "../../../../cores/helpers/file-upload-receipt/upload-image";
import { BNPSelect } from "../../../../cores/helpers/select/select";
import { BNPInput } from "../../../../cores/helpers/input/input";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import moment from "moment";
import { showConfirmModal } from "../../../../cores/helpers/confirm-modal/confirm-modal";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import {
  findClaimerById,
  findRelationshipById,
  findExpanseTypesById,
  setAvatar,
} from "../../receipt/claim-function";
import ImageIcon from "@material-ui/icons/Image";
import { convertTimeZone } from "../../../../cores/utils/helpers";
import { PdfViewer } from "../../../shared/pdf-viewer";
import { getEligibilityDate } from "../../../../services/employee-registration-service";
import {FE_DATE_FORMAT, FE_DETAIL_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";

const StyledImgReceipt = styled.img`
  height: 90px;
  width: 90px;
  border-radius: 4px;
`;

const StyledDefaultImgReceipt = styled(ImageIcon)`
  height: 90px !important;
  width: 90px !important;
  border-radius: 4px !important;
`;

const StyledDivImgClaimer = styled.div``;
const StyledDivInfoClaimer = styled.div`
  width: 85%;
  padding-left: 8px;
`;

const StyledDivNameClaimer = styled.div`
  font-size: 14px;
  line-height: 16px;
`;
const StyledDivRelationship = styled.div`
  color: #b0b3c2;
  font-size: 12px;
  line-height: 14px;
`;
const StyledSpanDelete = styled.span`
  color: ${(props) => props.theme.secondaryColor};
`;

const StyledDivTitleSelect = styled.div`
  color: #a0b2c6;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
  text-transform: uppercase;
`;
const StyledDivDatePicker = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 14px;
    height: 19px;
  }
`;

const StyledSelect = styled(Select)`
  font-family: ${(props) => props.theme.textFont} !important;
  .MuiSelect-select {
    border-radius: 5px;
    padding: 7px 0 7px;
    min-height: 34px;
  }
  svg {
    width: 1em !important;
    height: 1em !important;
  }
`;
const StyledDefaultOption = styled.div`
  font-size: 13px;
  color: #2d2d2d;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledOption = styled.div`
  font-size: 14px;
  display: flex;
  width: 100%;
  padding: 0 0.5rem;
`;

const StyledDivImg = styled.div`
  width: max-content;
`;
const StyledImg = styled.div`
  width: 32px;
  border-radius: 50%;
  background-color: #4e69be;
  color: #fff;
  text-align: center;
  padding: 0.4rem 0;
`;
const StyledDivInfoClaimerMe = styled.div`
  width: 85%;
  padding: 0.4rem 0.75rem;
`;

const StyledModalDialog = styled.div`
  text-align: left;
  max-width: 600px;
  margin: 1.75rem auto;
  width: 100%;
  pointer-events: none;

  @media(max-width: 599px) {
    width: 100vw;
    height: fit-content;
    margin: 0;
    .modal-content {
      height: 100%;
    }
  }
`;

const StyledDivRelationshipAmount = styled.div`
  color: #000;
  font-size: 14px;
  line-height: 14px;
  margin-top: 2px;
`;

export const StyledPWithColorError = styled.p`
  color: ${(props) => props.theme.errorColor};
  font-size: 0.75rem;
  margin-top: 8px;
  min-height: 1em;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03333em;
`;


type Props = {
  receiptEdit: ReceiptUpdate;
  deleteReceipt: (receiptId: number) => void;
  expanseTypes: TypeOfExpense[];
  employeeBenefitCoverage: BenefitCoverage[];
  dependents: Dependent[];
  relationships: Relationship[];
  isOtherExpanse: boolean;
  isValidAmount: boolean;
  isEdit: boolean;
  handleChangeClaimer: (event: any) => void;
  handleChangeExpanseType: (event: any) => void;
  handleChangeEmployeeBenefitCoverage: (event: any) => void;
  handleChangInput: (event: any) => void;
  handleChangeDate: (date: Date | null) => void;
  handleSubmit: () => void;
  handleEdit: (isEdit: boolean) => void;
  handleChangeEditImage: (data: ReceiptImageInfo) => void;
  folderName: string;
  claimId: number;
  handleCloseReceiptModal: () => void;
  validateEdit: () => boolean;
  isPdfFile: boolean;
  statusClaim: string;
  proRated?: boolean;
  isAvailable?: boolean;
};

var handleEdit = (isEdit: boolean) => { };

export function ReceiptModal(props: Props) {
  handleEdit = props.handleEdit;
  let claimer = findClaimerById(props.receiptEdit.claimerId, props.dependents);
  const [eligibilityDate, setEligibilityDate] = React.useState<Date | null>(null);

  const today = new Date();
  const todayYear = today.getFullYear();
  const receiptDate = props.receiptEdit.expenseDate && new Date(props.receiptEdit.expenseDate);
  const notValidExpenseDate = !!receiptDate ?
      (todayYear === receiptDate.getFullYear() && today.getMonth() === 0 && today.getDate() < 16) :
      false;


  React.useEffect(() => {
    getEligibilityDate().then((res: any) => setEligibilityDate(res.data.eligibilityDate))
  }, [])

  let isValid = props.validateEdit();

  return (
    <div className="modal" id="editReceiptModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          {/* header */}
          <StyledHeaderModal>
            <StyledHeaderTitleModal>
              {props.isEdit
                ? "Receipt"
                : findExpanseTypesById(
                  props.receiptEdit.expenseTypeId,
                  props.expanseTypes
                )}
            </StyledHeaderTitleModal>

            <StyledButtonCloseModal
              name="btn-close"
              onClick={props.handleCloseReceiptModal}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          {/* end header */}
          {/* body */}
          <div className="modal-body">
            {props.isEdit ? (
              <div className="row">
                <div className="col-12 mt-3">
                  {props.receiptEdit.attachmentUrl ? (
                    <UploadImage
                      urlImage={props.receiptEdit.fileUrl}
                      addFile={props.handleChangeEditImage}
                      folderName={props.folderName}
                      receiptId={props.receiptEdit.receiptId}
                      claimId={props.claimId}
                      isAddNew={false}
                    />
                  ) : (
                      ""
                    )}
                </div>

                <div className="col-lg-6 mt-3">
                  <StyledDivTitleSelect>Claimant</StyledDivTitleSelect>
                  <StyledSelect
                    value={
                      findClaimerById(
                        props.receiptEdit.claimerId,
                        props.dependents
                      ).id
                    }
                    onChange={props.handleChangeClaimer}
                    name="claimer"
                  >
                    {/* default */}
                    <MenuItem value="-1" key={-1}>
                      <StyledDefaultOption className="p-2">
                        Claimant
                      </StyledDefaultOption>
                    </MenuItem>
                    {/* options */}
                    <MenuItem value={0}>
                      <StyledOption>
                        <StyledDivImgClaimer>
                          <StyledDivImg>
                            <StyledImg>M</StyledImg>
                          </StyledDivImg>
                        </StyledDivImgClaimer>
                        <StyledDivInfoClaimerMe>Me</StyledDivInfoClaimerMe>
                      </StyledOption>
                    </MenuItem>
                    {props.dependents.map((dependent, index) => (
                      <MenuItem value={dependent.id} key={index}>
                        <StyledOption>
                          <StyledDivImgClaimer>
                            <StyledDivImg>
                              <StyledImg>
                                {setAvatar(
                                  dependent.firstName + " " + dependent.lastName
                                )}
                              </StyledImg>
                            </StyledDivImg>
                          </StyledDivImgClaimer>
                          <StyledDivInfoClaimer>
                            <StyledDivNameClaimer>
                              {dependent.firstName + " " + dependent.lastName}
                            </StyledDivNameClaimer>
                            <StyledDivRelationship>
                              {findRelationshipById(
                                dependent.relationship,
                                props.relationships
                              )}
                            </StyledDivRelationship>
                          </StyledDivInfoClaimer>
                        </StyledOption>
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </div>
                <div className="col-lg-6 mt-3">
                  <StyledDivTitleSelect>Benefit Type</StyledDivTitleSelect>
                  <StyledSelect
                    value={props.receiptEdit.employeeBenefitCoverageId || -1}
                    onChange={props.handleChangeEmployeeBenefitCoverage}
                    name="claimer"
                  >
                    {/* default */}
                    <MenuItem value={-1} key={-1}>
                      <StyledDefaultOption className="p-2">
                        Benefit Type
                      </StyledDefaultOption>
                    </MenuItem>
                    {props.employeeBenefitCoverage.map((dependent, index) => {
                      let calculateProRatedAmountRemain = dependent.total - dependent.limit - (dependent.proRatedAmount === undefined ? 0 : dependent.proRatedAmount);
                      return (
                        <MenuItem value={dependent.id} key={index}>
                          <StyledOption>
                            <div>
                              <StyledDivNameClaimer>
                                {dependent.name}
                              </StyledDivNameClaimer>
                              <StyledDivRelationshipAmount>
                                {
                                  `Available ` +
                                  formatterUSD("currency", "USD").format(centToUsd(
                                    props.proRated && dependent.proRatedAmount !== undefined ?
                                      (calculateProRatedAmountRemain >= 0 ? 0 : props.isAvailable ? Math.abs(calculateProRatedAmountRemain) : 0)
                                      :
                                      (dependent.limit < 0 ? 0 : dependent.limit)
                                  ))
                                }
                              </StyledDivRelationshipAmount>
                            </div>
                          </StyledOption>
                        </MenuItem>
                      )
                    })}
                  </StyledSelect>
                </div>
                <div className="col-lg-6 mt-3">
                  <StyledDivTitleSelect>Type of Expense</StyledDivTitleSelect>
                  <BNPSelect
                    options={props.expanseTypes}
                    value={props.receiptEdit.expenseTypeId}
                    placeholder="Type of Expense"
                    name="expanseTypes"
                    onChange={props.handleChangeExpanseType}
                  />
                  <BNPInput
                    className="mt-3"
                    placeholder="Type of expanse"
                    value={props.receiptEdit.expenseTypeOther || ""}
                    hidden={!props.isOtherExpanse}
                    name="expenseTypeOther"
                    onChangeInput={props.handleChangInput}
                    maxLength={255}
                  />
                </div>

                <div className="col-lg-6 mt-3">
                  <StyledDivTitleSelect>Amount of Claim</StyledDivTitleSelect>
                  <BNPInput
                    placeholder="Amount of Claim"
                    type="number"
                    maxLength={9}
                    value={props.receiptEdit.amount !== 0 ? props.receiptEdit.amount : undefined}
                    name="amount"
                    onChangeInput={props.handleChangInput}
                  />
                  {props.isValidAmount && (
                    <StyledPError>
                      Please do not enter a negative number
                    </StyledPError>
                  )}
                </div>
                <div className="col-lg-6 mt-3">
                  <StyledDivTitleSelect>Date of Expense</StyledDivTitleSelect>
                  <StyledDivDatePicker>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        disableFuture
                        minDate={eligibilityDate && convertTimeZone("Canada/Eastern", eligibilityDate)}
                        InputAdornmentProps={{ position: "start" }}
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        placeholder={FE_DATE_FORMAT}
                        value={props.receiptEdit.expenseDate ?
                          convertTimeZone("Canada/Eastern", props.receiptEdit.expenseDate)
                          :
                          null}
                        onChange={props.handleChangeDate}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </StyledDivDatePicker>
                </div>
                <div className="col-lg-12 mt-3">
                  <StyledDivTitleSelect>Note</StyledDivTitleSelect>
                  <StyledTextarea
                    value={props.receiptEdit.notes || ""}
                    cols={100}
                    rows={4}
                    name="notes"
                    onChange={props.handleChangInput}
                    placeholder="Note"
                  />
                </div>

                <div className="col-12 mt-3">
                  {notValidExpenseDate && (
                      <StyledPWithColorError>{`Be advised that we are transitioning from the ${todayYear -1} to ${todayYear} benefit years and you will be unable to make any claims for the ${todayYear} benefit year until January 16. You will still be able to claim eligible ${todayYear} expenses incurred before then, but you will simply not be able to submit the claim until you have done your ${todayYear} allocations starting on January 16.`}</StyledPWithColorError>
                  )}
                  <StyledButtonSubmit
                    onClick={props.handleSubmit}
                    submit={isValid && !notValidExpenseDate}
                    disabled={!isValid || notValidExpenseDate}
                    tabIndex={8}
                  >
                    Save
                  </StyledButtonSubmit>
                </div>
              </div>
            ) : (
                <div className="row">
                  <div className="col-12">
                    {props.isPdfFile ? (
                      props.receiptEdit.fileUrl ? (
                        <PdfViewer fileUrl={props.receiptEdit.fileUrl} height='90px' />
                      ) : (
                          <StyledDefaultImgReceipt />
                        )
                    ) : props.receiptEdit.fileUrl ? (
                      <StyledImgReceipt
                        src={props.receiptEdit.fileUrl}
                        alt="receipt"
                      />
                    ) : (
                          <StyledDefaultImgReceipt />
                        )}
                  </div>
                  <div className="col-12">
                    <div className="mt-3">
                      <div className="fw-bold">Claimer</div>
                      <div className="d-flex mt-2">
                        <StyledDivImgClaimer>
                          {findClaimerById(
                            props.receiptEdit.claimerId,
                            props.dependents
                          ).id !== -1 ? (
                              <StyledDivImg>
                                <StyledImg>
                                  {setAvatar(
                                    claimer.firstName + " " + claimer.lastName
                                  )}
                                </StyledImg>
                              </StyledDivImg>
                            ) : (
                              ""
                            )}
                        </StyledDivImgClaimer>

                        {claimer.lastName === "Me" ? (
                          <StyledDivInfoClaimerMe>Me</StyledDivInfoClaimerMe>
                        ) : (
                            <StyledDivInfoClaimer>
                              <StyledDivNameClaimer>
                                {claimer.firstName + " " + claimer.lastName}
                              </StyledDivNameClaimer>
                              <StyledDivRelationship>
                                {findRelationshipById(
                                  claimer.relationship,
                                  props.relationships
                                ) || ""}
                              </StyledDivRelationship>
                            </StyledDivInfoClaimer>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mt-3">
                      <div className="fw-bold">Type of Expense</div>
                      <div>
                        {findExpanseTypesById(
                          props.receiptEdit.expenseTypeId,
                          props.expanseTypes
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mt-3">
                      <div className="fw-bold">Amount of Expense</div>
                      <div>
                        {formatterUSD("currency", "USD").format(
                          props.receiptEdit.amount
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mt-3">
                      <div className="fw-bold">Date of Expense</div>
                      <div>
                        {props.receiptEdit.expenseDate
                          ? moment(props.receiptEdit.expenseDate).format(FE_DETAIL_DATE_FORMAT)
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <StyledButtonSubmit
                      submit={true}
                      onClick={() => props.handleEdit(true)}
                    >
                      Edit This Receipt
                  </StyledButtonSubmit>
                    {props.statusClaim !== "REQUESTED" && (
                      <div className="text-center mt-3">
                        <label>
                          <StyledSpanDelete
                            onClick={() => {
                              closeReceiptModal();
                              showConfirmModal();
                            }}
                          >
                            Delete
                        </StyledSpanDelete>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )}
          </div>
          {/* end body */}
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showReceiptModal() {
  ($("#editReceiptModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeReceiptModal() {
  handleEdit(false);
  ($("#editReceiptModal") as any).modal("hide");
}
