import {getAsync, getWithoutHeaderAsync, postAsync, putAsync} from "../cores/utils/http-client";
import {AxiosResponse} from "axios";
import {googleAPIKey} from "../cores/config/config";
import {CreateReviewRequest} from "../components/pages/marketplace/marketplace-type";

const URL_Employer = "/manage-employer/api";

export const getAllServices = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/services`;

  return getAsync(url);
};

export const getAllLanguages = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/languages/available`;

  return getAsync(url);
};

export const getSearchVendors = (searchParam: any): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/employee`;

  const request = {...searchParam}

  if (!!request.expenseTypeIds && request.expenseTypeIds.length > 0 && request.expenseTypeIds[0] !== -1) {
    request.expenseTypeIds = request.expenseTypeIds.join(',');
  } else {
    delete request.expenseTypeIds;
  }

  if (!!request.languageCodes && request.languageCodes.length > 0) {
    request.languageCodes = request.languageCodes.join(',');
  } else {
    delete request.languageCodes;
  }

  if (!!request.sortBy && request.sortBy.length > 0) {
    request.sortBy = request.sortBy.join(',');
  } else {
    delete request.sortBy;
  }

  return getAsync(url, request);
};

export const getLocationFromLatLong = (lat: number, long: number): Promise<AxiosResponse> => {
  let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleAPIKey}`;

  return getWithoutHeaderAsync(url);
}

export const getVendorDetailByEmployee = (vendorId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/${vendorId}/by-employee`;

  return getAsync(url);
}

//Review APIs
export const getReviewsForAVendorByEmployee = (vendorId: number, page: number = 1, perPage: number = 4): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/${vendorId}/reviews/by-employee?page=${page}&perPage=${perPage}&columnName=createdDate&sortType=DESC`;

  return getAsync(url);
}

export const createReviewsForAVendorByEmployee = (vendorId: number, request: CreateReviewRequest): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/${vendorId}/reviews/by-employee`;

  return postAsync(url, request);
}

export const updateReviewsForAVendorByEmployee = (vendorId: number, reviewId: number, request: CreateReviewRequest): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/${vendorId}/reviews/${reviewId}/by-employee`;

  return putAsync(url, request);
}

export const getReviewDetailForAVendorByEmployee = (vendorId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/${vendorId}/reviews/by-employee/detail`;

  return getAsync(url, {}, false);
}