import jwt from "jsonwebtoken";
import { UserInfo } from "../models/login/login-req-model";
import {signInURL} from "../../cores/constants/url-config";
import moment from "moment";
import { getCookie, removeCookie, setCookie } from "./cookie";

export const authorizationHeader = (): { [index: string]: string } => {
  const token = getToken();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }
  return {};
};

export const COOKIE_NAME = {
  TOKEN: 'token',
  RE_ALLOCAION_STATUS: 'reAllocationStatus',
  IS_SETUP_DONE: 'isSetupDone'
}

export const getToken = () => getCookie(COOKIE_NAME.TOKEN);
export const setToken = (token: string, expriedAt?: Date): void => {
  if (token) {
    setCookie(COOKIE_NAME.TOKEN, token, expriedAt);
  } else {
    removeCookie(COOKIE_NAME.TOKEN);
  }
};

/**
 *
 * @param isSetupDone "true" or ""
 */
export const setIsSetupDone = (isSetupDone: "True" | ""): void => setCookie(COOKIE_NAME.IS_SETUP_DONE, isSetupDone);

export const getIsSetupDone = () => getCookie(COOKIE_NAME.IS_SETUP_DONE);

export const setReAllocationStatus = (reAllocationStatus: "NONE" | "REQUIRED" | "REVIEW"): void => setCookie(COOKIE_NAME.RE_ALLOCAION_STATUS, reAllocationStatus);

export const getReAllocationStatus = () => getCookie(COOKIE_NAME.RE_ALLOCAION_STATUS);

export const getInfoByToken = (): UserInfo => {
  let token = getToken() as string;

  let decoded = jwt.decode(token) as UserInfo;

  return decoded;
};

export const signOut = (): void => {
  Object.values(COOKIE_NAME).forEach(val => removeCookie(val))
  window.location.href = signInURL;
};

export const download = (url: string) => {
  window.open(url, "_blank");
};

export const convertTimeZone = (timeZone: string, date: Date | string) => {
  const tz = date.toLocaleString("en", { timeZone }).split(" ").slice(-1)[0];
  const dateString = date.toString();
  const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);

  // return UTC offset in millis
  return moment(date).add(-offset, "ms");
}

export const getAvgRating = (ratingTotal: number, reviewsTotal: number) => {
  return Intl.NumberFormat('en-US', { maximumFractionDigits: 1 }).format(ratingTotal / reviewsTotal);
}

export const getResizedImageUrl = (url: string, size: number) => {
  const urlParts = url.split('/');

  if (urlParts.length < 4) {
    return url;
  }

  // Insert the path (e.g., "300") before the last part (the key)
  urlParts.splice(3, 0, size.toString());

  return urlParts.join('/');
}