import moment from "moment";

export const FE_DATE_FORMAT = "DD/MM/YYYY";
export const FE_DATETIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const FE_DETAIL_DATE_FORMAT = "DD MMM YYYY";
export const BE_DATE_FORMAT = "YYYY-MM-DD";

export const formatInputDateTime = (date: string): string => {
    return date.split("/").map(item => item.length < 2 ? `0${item}` : item).join("/");
}

export const dateStringToDate = (date: Date | string): Date => {
    if (date instanceof Date) return date;
    return moment(date, FE_DATE_FORMAT).toDate()
}

export const offsetToFrontEndTimezone = (date: Date | string): Date => {
    return moment(date).zone(new Date().getTimezoneOffset()).toDate()
}