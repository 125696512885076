import React from "react";
import {
  StyledCell,
  StyledSpanLargerTitle,
  StyledButtonSubmit,
  StyledHiddenForMobile,
  StyledHiddenForWeb,
} from "../../../shared/employee-styled";
import styled from "styled-components";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Breadcrumbs,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { ClaimInfo, ClaimSeparation } from "../list-claim-type";
import { Link } from "react-router-dom";
import { setAvatar } from "../../receipt/claim-function";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import { claimManagementURL } from "../../../../cores/constants/url-config";
import { PdfViewer } from "../../../shared/pdf-viewer";
import {FE_DETAIL_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";
import { convertTimeZone } from "../../../../cores/utils/helpers";

export const StyledDiv = styled.div``;
const StyledDivBoxExpand = styled.div`
  background-color: #f2f2f5;
  width: 100%;
  padding: 17px 21px;
  text-align: left;
`;

const StyledDivImgDependent = styled.div`
  width: max-content;
`;
const StyledImgDependent = styled.div`
  width: 45px;
  border-radius: 50%;
  background-color: #4e69be;
  color: #fff;
  text-align: center;
  padding: 0.8rem 0;
`;
const StyledDivDependent = styled.div`
  width: max-content;
  text-align: left;
  padding-left: 15px;
`;
const StyledDivName = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`;

const StyledImgReceipt = styled.img`
  max-width: 100%;
  max-height: 200px;
`;

const StyledLink = styled(Link)`
  color: #003365;
`;

type Props = {
  claimInfo: ClaimInfo;
  findNameStatusById: (statusId: string) => string;
  findRejectById: (rejectId: string) => string;
  findRelationshipById: (relationshipId: string) => string;
  handleShowClaimModal: (id?: number) => void;
  findLimitAmount: (typeOfClaim: string) => number;
  isProRated: boolean;
  findProRatedAmount: (typeOfClaim: string) => number;
  findTotalAmountOfEnrollment: (typeOfClaim: string) => number;
  isAvailable: boolean;
};

const DO_NOT_ALLOW_EDIT_CLAIM_STATUS = ['APPROVED', 'REJECTED'];

export default function DetailClaimView(props: Props) {
  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(expanded ? panel : false);
  };

  const widthTitle = "40%";
  const widthParam = "60%";

  const ClaimSeparationDetail = (props: {data: ClaimSeparation}) =>{
    const {data} = props;
    const getStr = () => {
      let strArray = [`${formatterUSD("currency", "USD").format(centToUsd(data.amount))}`];
      if (data.paidStatus) {
        if (data.paidStatus === 'CMP') {
          strArray.push('Paid');
        } else if (data.paidStatus === 'ISS') {
          strArray.push('Waiting payment from Employer');
        }
      } else if (data.status && data.status === 'PENDING_APPROVE') {
        strArray.push(`Pending payment until`);
        if (data.forDate) { strArray.push(convertTimeZone("Canada/Eastern", data.forDate).format(FE_DETAIL_DATE_FORMAT)) };
      } else if (data.status && data.status === 'APPROVED') {
        strArray.push(`Will be paid on approval`);
      }
      return strArray.join(" ");
    }
    return (
      <StyledCell width="100%" style={{paddingLeft:"10px"}}>
        &bull; {getStr()}
      </StyledCell>
    )
  }

  return (
    <div>
      <div className="row">
        <div className="col-10">
          <StyledSpanLargerTitle>Claim Information</StyledSpanLargerTitle>
        </div>
        {!DO_NOT_ALLOW_EDIT_CLAIM_STATUS.includes(props.claimInfo.status) && (
          <StyledHiddenForMobile className="col-2">
            <StyledButtonSubmit
              submit={true}
              onClick={() => props.handleShowClaimModal(props.claimInfo.id)}
            >
              Edit
            </StyledButtonSubmit>
          </StyledHiddenForMobile>
        )}
        {!DO_NOT_ALLOW_EDIT_CLAIM_STATUS.includes(props.claimInfo.status) && (
          <StyledHiddenForWeb className="col-2 p-0">
            <StyledButtonSubmit submit={true} onClick={() => props.handleShowClaimModal(props.claimInfo.id)}>
              Edit
            </StyledButtonSubmit>
          </StyledHiddenForWeb>
        )}
      </div>

      <Breadcrumbs aria-label="breadcrumb" className="mt-3">
        <StyledLink to={claimManagementURL}>Claim Management</StyledLink>

        <Typography color="textPrimary">Claim Information</Typography>
      </Breadcrumbs>
      <div className="col-12 mt-3 p-0">
        <div className="d-flex">
          <StyledCell width={widthTitle} className="fw-bold">
            Claim Id
          </StyledCell>
          <StyledCell width={widthParam}>{props.claimInfo.id}</StyledCell>
        </div>
        <div className="d-flex">
          <StyledCell width={widthTitle} className="fw-bold">
            Employee name
          </StyledCell>
          <StyledCell width={widthParam}>
            {props.claimInfo.employeeName}
          </StyledCell>
        </div>
        <div className="d-flex">
          <StyledCell width={widthTitle} className="fw-bold">
            Employee email
          </StyledCell>
          <StyledCell width={widthParam}>
            {props.claimInfo.employeeEmail}
          </StyledCell>
        </div>
        <div className="d-flex">
          <StyledCell width={widthTitle} className="fw-bold">
            Company name
          </StyledCell>
          <StyledCell width={widthParam}>
            {props.claimInfo.companyName}
          </StyledCell>
        </div>
        <div className="d-flex">
          <StyledCell width={widthTitle} className="fw-bold">
            Total amount
          </StyledCell>
          <StyledCell width={widthParam}>
            {formatterUSD("currency", "USD").format(
              props.claimInfo.totalAmount / 100
            )}
          </StyledCell>
        </div>
        {props.claimInfo.status === "APPROVED" && (
          <div className="d-flex">
            <StyledCell width={widthTitle} className="fw-bold">
              Total paid
            </StyledCell>
            {
              props.claimInfo.enableClaimSeparation && props.claimInfo.claimSeparations && props.claimInfo.claimSeparations.length > 0 ? (
                <StyledCell width={widthParam} className="d-flex flex-column" style={{ padding: 0 }}>
                  <StyledCell width="100%">
                    {formatterUSD("currency", "USD").format(centToUsd(props.claimInfo.claimSeparations.reduce((total, currentVal) => total + currentVal.amount, 0)))}
                  </StyledCell>
                  {props.claimInfo.claimSeparations.map(p => <ClaimSeparationDetail data={p} />)}
                </StyledCell>
              ) :
                <StyledCell width={widthParam}>{formatterUSD("currency", "USD").format(centToUsd(props.claimInfo.maxPaid))}</StyledCell>
            }
          </div>
        )}
        {props.claimInfo.status === "REQUESTED" && props.claimInfo.enableClaimSeparation && (
          <div className="d-flex">
            <StyledCell width={widthTitle} className="fw-bold">
              Estimate paid
            </StyledCell>
            {
              props.claimInfo.claimSeparations ? (
                <StyledCell width={widthParam} className="d-flex flex-column" style={{ padding: 0 }}>
                  <StyledCell width="100%">
                    {formatterUSD("currency", "USD").format(centToUsd(props.claimInfo.claimSeparations.reduce((total, currentVal) => total + currentVal.amount, 0)))}
                  </StyledCell>
                  {props.claimInfo.claimSeparations.map(p => <ClaimSeparationDetail data={p} />)}
                </StyledCell>
              ) :
                <StyledCell width={widthParam}>{formatterUSD("currency", "USD").format(centToUsd(props.claimInfo.maxPaid))}</StyledCell>
            }
          </div>
        )}
        <div className="d-flex">
          <StyledCell width={widthTitle} className="fw-bold">
            Status
          </StyledCell>
          <StyledCell width={widthParam}>
            {props.findNameStatusById(props.claimInfo.status)}
          </StyledCell>
        </div>
        <div className="d-flex">
          <StyledCell width={widthTitle} className="fw-bold">
            Submitted date
          </StyledCell>
          <StyledCell width={widthParam}>
            {moment(props.claimInfo.submittedDate).format(FE_DETAIL_DATE_FORMAT)}
          </StyledCell>
        </div>
        {props.claimInfo.reasonType ? (
          <div className="d-flex">
            <StyledCell width={widthTitle} className="fw-bold">
              Rejection reason
            </StyledCell>
            <StyledCell width={widthParam}>
              {props.findRejectById(props.claimInfo.reasonType)}
            </StyledCell>
          </div>
        ) : (
            ""
          )}
        {props.claimInfo.reason ? (
          <div className="d-flex">
            <StyledCell width={widthTitle} className="fw-bold">
              Rejection description
            </StyledCell>
            <StyledCell width={widthParam}>{props.claimInfo.reason}</StyledCell>
          </div>
        ) : (
            ""
          )}
      </div>
      <div className="col-12 mt-3 p-0">
        {props.claimInfo.receipts
          ? props.claimInfo.receipts.map((receipt, index) => {
            let calculateProRatedAmountRemain =
              props.findTotalAmountOfEnrollment(receipt.typeOfClaim.trim()) -
              props.findLimitAmount(receipt.typeOfClaim.trim()) -
              props.findProRatedAmount(receipt.typeOfClaim.trim());
            return (
              <ExpansionPanel
                square
                defaultExpanded={true}
                onChange={handleChange("panel" + index)}
                key={index}>
                <ExpansionPanelSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={<ArrowDropUp />}
                >
                  <div className="d-flex w-100">
                    <StyledDivImgDependent>
                      <StyledImgDependent>
                        {setAvatar(receipt.claimerName)}
                      </StyledImgDependent>
                    </StyledDivImgDependent>
                    <StyledDivDependent>
                      <StyledDivName>{receipt.claimerName}</StyledDivName>
                      <div>
                        {props.findRelationshipById(receipt.relationship)}
                      </div>
                    </StyledDivDependent>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="p-0">
                  <StyledDivBoxExpand>
                    {String(receipt.attachmentUrl).match("/*.pdf") ? (
                      <PdfViewer fileUrl={receipt.fileUrl} height='100px' />
                    ) : (
                        <StyledImgReceipt
                          src={receipt.fileUrl}
                          alt="receipt-image"
                        />
                      )}
                    <div className="d-flex mt-3">
                      <StyledCell width={widthTitle} className="fw-bold">
                        Receipt Id:
                      </StyledCell>
                      <StyledCell width={widthParam} title={receipt.id + ""}>
                        {receipt.id}
                      </StyledCell>
                    </div>

                    <div className="d-flex">
                      <StyledCell width={widthTitle} className="fw-bold">
                        Type of claim
                      </StyledCell>
                      <StyledCell width={widthParam}>
                        {receipt.typeOfClaim}
                        {
                          props.claimInfo.status === "REQUESTED"
                            ? " - Available " +
                            formatterUSD("currency").format(
                              centToUsd(
                                props.isProRated ?
                                  (calculateProRatedAmountRemain >= 0 ? 0 : props.isAvailable ? Math.abs(calculateProRatedAmountRemain) : 0)
                                  :
                                  (props.findLimitAmount(receipt.typeOfClaim.trim()) < 0 ? 0 : props.findLimitAmount(receipt.typeOfClaim.trim()))
                              )
                            ) : ""
                        }
                      </StyledCell>
                    </div>
                    <div className="d-flex">
                      <StyledCell width={widthTitle} className="fw-bold">
                        Type of expense
                      </StyledCell>
                      <StyledCell
                        width={widthParam}
                        title={receipt.expenseType}
                      >
                        {receipt.expenseType}
                      </StyledCell>
                    </div>
                    <div className="d-flex">
                      <StyledCell width={widthTitle} className="fw-bold">
                        Amount
                      </StyledCell>
                      <StyledCell
                        width={widthParam}
                        title={formatterUSD("currency", "USD").format(
                          receipt.amount / 100
                        )}
                      >
                        {formatterUSD("currency", "USD").format(
                          receipt.amount / 100
                        )}
                      </StyledCell>
                    </div>
                    <div className="d-flex">
                      <StyledCell width={widthTitle} className="fw-bold">
                        Date of expense
                      </StyledCell>
                      <StyledCell
                        width={widthParam}
                        title={moment(receipt.expenseDate).format(FE_DETAIL_DATE_FORMAT)}
                      >
                        {moment(receipt.expenseDate).format(FE_DETAIL_DATE_FORMAT)}
                      </StyledCell>
                    </div>
                    <div className="d-flex">
                      <StyledCell width={widthTitle} className="fw-bold">
                        Note
                      </StyledCell>
                      <StyledCell width={widthParam} title={receipt.notes}>
                        {receipt.notes}
                      </StyledCell>
                    </div>
                  </StyledDivBoxExpand>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          }
          )
          : ""}
      </div>
    </div>
  );
}
