import React from "react";
import Dropzone from "react-dropzone";
import styled, { css } from "styled-components";
import { publishMessage } from "../../utils/message";
import { postUploadImageReceipts } from "../../../services/employee-receipt-services";
import { addLoading, removeLoading } from "../../utils/loading";
import { ReceiptImageInfo } from "../../../components/pages/receipt/receipt/claim-type";
import { PdfViewer } from "../../../components/shared/pdf-viewer";

type StyledButtonProps = {
  border: string;
};

const StyledDropZone = styled(Dropzone)`
  width: 100%;
  height: fit-content;
  margin-top: -7px;
  border-radius: 3px;
  //margin-bottom: 3rem;
  cursor: pointer;

  ${(props: StyledButtonProps) =>
    !props.border &&
    css`
      border: 1px solid rgb(236, 237, 240);
    `}
`;

const StyledDivDefaultUpload = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(242, 242, 245, 0.4);
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const StyledDiv = styled.div`
  text-align: center;
  color: rgb(0, 48, 73);
  font-weight: 700;
`;

const StyledImgReview = styled.img`
  max-height: 150px;
  max-width: 100%;
`;

const StyledAside = styled.aside`
  text-align: center;
`;

const StyledText = styled.div`
  font-size: 10px;
  color: ${props => props.theme.errorColor};
  opacity: 0.5;
  padding-top: 8px;
  padding-bottom: 8px;
`

type Props = {
  addFile: (data: ReceiptImageInfo) => void;
  urlImage: string;
  folderName: string;
  receiptId: number;
  claimId: number;
  isAddNew: boolean;
};

// for profile picture
export default function UploadImage(props: Props) {
  const [isPdfFile, setIsPdfFile] = React.useState<boolean>(false);

  const onDrop = (accepted: any, rejected: any) => {
    if (accepted.length !== 0) {
      if (accepted[0].size < 10485760) {
        handleChangeImage(accepted);
      } else {
        publishMessage({
          variant: "error",
          message:
            "This image cannot be uploaded, because it exceeds the maximum file size for uploading.",
        });
      }
    } else {
      publishMessage({
        variant: "error",
        message: "File type does not support.",
      });
    }
  };

  React.useEffect(() => {
    if (props.urlImage.match("/*.pdf")) {
      setIsPdfFile(true);
    } else {
      setIsPdfFile(false);
    }
  }, [props.urlImage]);

  const handleChangeImage = (files: File[]) => {
    if (files.length) {
      // FormData image to save
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append(
        "folderName",
        props.folderName ? props.folderName : "Images"
      );
      formData.append("receiptId", props.receiptId.toString());
      formData.append("claimId", props.claimId.toString());
      addLoading();

      postUploadImageReceipts(formData)
        .then((result) => {
          props.addFile(result.data);

          removeLoading();
        })
        .catch(() => {
          removeLoading();
        });
    }
  };

  const { urlImage: voidCheque } = props;

  const thumbs = <StyledImgReview src={voidCheque} alt="profile" />;

  const render = voidCheque ? (
    isPdfFile ? (
      <div className="text-center">
        <PdfViewer fileUrl={voidCheque} height='100px' />
      </div>
    ) : (
      <StyledAside>{thumbs}</StyledAside>
    )
  ) : (
    <StyledDivDefaultUpload>
      <StyledDiv>
        {props.isAddNew ? "Add your receipt" : "Drag & drop your receipt."}
        <StyledText>
          Please submit official receipts from suppliers that include details of the goods or services provide, the provide, the patient/purchaser, cost, date etc. In the case of prescriptions, the pharmacist should provide an official receipt for each medication. Payment slips will generally not be accepted as receipts.
        </StyledText>
      </StyledDiv>
    </StyledDivDefaultUpload>
  );

  return (
    <div>
      <StyledDropZone
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        border={voidCheque}
        multiple={false}
        accept="image/*,application/pdf"
        tabIndex={0}
        role="application"
        aria-hidden="true"
        onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
      >
        {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
          // for drag and drop warning statement
          if (isDragReject) return "File type does not support.";
          return render;
        }}
      </StyledDropZone>
    </div>
  );
}
