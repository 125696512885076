import React from "react";
import Profile from "../../profile";
import BankingInfoView from "./banking-info-view";
import { Agreement, BankingInfo } from "../../../setup-wizard/employee-type";
import { addLoading, removeLoading } from "../../../../../cores/utils/loading";
import {
  getAgreementBankingInfo,
  putBankingInfo,
  putBenefitInfo,
} from "../../../../../services/employee-registration-service";
import OCRCheque from "../../../../../cores/helpers/ocr-cheque/ocr-cheque";
import { getInfoByToken } from "../../../../../cores/utils/helpers";
import BankingInfoModal, { closeBankingModal } from "./banking-info-modal";
import { publishMessage } from "../../../../../cores/utils/message";
import { getBankingInfoEmployee } from "../../../../../services/banking-info-service";
import { getFileUrl } from "../../../../../services/file-service";
import { toUTCTime } from "../../../../../cores/helpers/to-utc-time";

type State = {
  bankingInfo: BankingInfo;
  bankingInfoEdit: BankingInfo;
  agreementData: Agreement[];
  isOCRCheckingCompleted: boolean;
  fileUrl: string;
  fileUrlEdit: string;
};

type Props = {};

export default class Banking extends React.Component<Props, State> {
  state: State = {
    agreementData: [],
    bankingInfo: {} as BankingInfo,
    bankingInfoEdit: {
      employeeId: 0,
      voidCheque: "",
      eSignature: "",
      transitNumber: "",
      bankNumber: "",
      accountNumber: "",
      agreements: [],
      date: toUTCTime(new Date()),
    },
    isOCRCheckingCompleted: false,
    fileUrl: "",
    fileUrlEdit: "",
  };

  async componentDidMount() {
    //add loading
    addLoading();

    let resultBanking = await getBankingInfoEmployee();

    let result = await getAgreementBankingInfo();

    let agreementData = result.data || [];
    let bankingInfo: BankingInfo = resultBanking.data;

    if (bankingInfo.voidCheque) {
      this.getFileUrl(bankingInfo.voidCheque);
    }

    this.setState({ agreementData: agreementData, bankingInfo });

    removeLoading();
  }

  getFileUrl = (url: string) => {
    getFileUrl(url).then((response) => {
      this.setState({ fileUrl: response.data });
    });
  };

  //#region banking information
  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let bankingInfo = Object.assign(this.state.bankingInfoEdit);

    if (name !== "eSignature") {
      if (Number(value) >= 0) {
        bankingInfo[name] = value;
      }
    } else {
      bankingInfo[name] = value;
    }
    this.setState({ bankingInfoEdit: bankingInfo });
  };
  handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    let bankingInfoEdit = Object.assign({}, this.state.bankingInfoEdit);

    if (checked) {
      bankingInfoEdit.agreements.push(Number(name));
    } else {
      let index = bankingInfoEdit.agreements.findIndex((agreement: any) => {
        return agreement === Number(name);
      });

      bankingInfoEdit.agreements.splice(index, 1);
    }

    this.setState({ bankingInfoEdit });
  };

  handleChangeVoidCheque = (urlImage: string) => {
    let bankingInfoEdit: BankingInfo = Object.assign(
      this.state.bankingInfoEdit
    );

    if (urlImage) {
      bankingInfoEdit.voidCheque = urlImage;

      getFileUrl(urlImage).then((response) => {
        this.setState({ fileUrlEdit: response.data });
      });

      this.setState({ bankingInfoEdit });
    }
  };

  handleOCRCheque = (urlBlob: any) => {
    this.setState({ isOCRCheckingCompleted: false });
    OCRCheque(urlBlob, "../../tessdata", (res: any) => {
      if (!res.error) {
        const newBankingInfo: BankingInfo = { ...this.state.bankingInfoEdit };
        newBankingInfo.transitNumber = res.numbers.transit;
        newBankingInfo.bankNumber = res.numbers.institution;
        newBankingInfo.accountNumber = res.numbers.account;

        this.setState({ bankingInfoEdit: newBankingInfo });
      }
      this.setState({ isOCRCheckingCompleted: true });
    });
  };

  validateBankingInfo = () => {
    let isValid = false;

    if (
      this.state.bankingInfoEdit.agreements.length ===
      this.state.agreementData.length
    ) {
      isValid = true;
    }

    if (
      this.state.bankingInfoEdit.accountNumber &&
      this.state.bankingInfoEdit.bankNumber &&
      this.state.bankingInfoEdit.transitNumber &&
      this.state.bankingInfoEdit.voidCheque &&
      this.state.bankingInfoEdit.eSignature &&
      this.state.bankingInfoEdit.date &&
      isValid
    ) {
      return true;
    } else {
      return false;
    }
  };
  handleChangeDate = (date: Date | null, name: string) => {
    let bankingInfo = Object.assign(this.state.bankingInfoEdit);

    bankingInfo[name] = date;

    this.setState({ bankingInfoEdit: bankingInfo });
  };

  handleSubmitBankingInfo = () => {
    let bankingInfo: BankingInfo = Object.assign(this.state.bankingInfoEdit);

    if (bankingInfo.date)
      bankingInfo.date = toUTCTime(new Date(bankingInfo.date))
    bankingInfo.employeeId = getInfoByToken().employeeId;

    putBankingInfo(bankingInfo).then(() => {
      closeBankingModal();

      let bankingInfoEdit = {
        employeeId: 0,
        voidCheque: "",
        eSignature: "",
        transitNumber: "",
        bankNumber: "",
        accountNumber: "",
        agreements: [],
        date: new Date()
      };

      /// get image
      this.getFileUrl(bankingInfo.voidCheque);

      this.setState({
        bankingInfoEdit,
        fileUrlEdit: "",
        bankingInfo: bankingInfo,
      });
      publishMessage({
        message: "Your information has been update successfully.",
        variant: "success",
      });
    });
  };

  render() {
    const isValid = this.validateBankingInfo();
    return (
      <Profile
        children={
          <div>
            <BankingInfoView
              bankingInfo={this.state.bankingInfo}
              fileUrl={this.state.fileUrl}
            />
            <BankingInfoModal
              handleSubmitBankingInfo={this.handleSubmitBankingInfo}
              handleChangeInput={this.handleChangeInput}
              handleChangeCheckbox={this.handleChangeCheckbox}
              handleChangeVoidCheque={this.handleChangeVoidCheque}
              handleOCRCheque={this.handleOCRCheque}
              isOCRCheckingCompleted={this.state.isOCRCheckingCompleted}
              bankingInfo={this.state.bankingInfoEdit}
              isValid={isValid}
              folderName="Employee/Cheque"
              agreementData={this.state.agreementData}
              handleChangeDate={this.handleChangeDate}
              fileUrlEdit={this.state.fileUrlEdit}
            />
          </div>
        }
      />
    );
  }
}
