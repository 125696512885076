import { getAsync, putAsync } from "../cores/utils/http-client";
import { AxiosResponse } from "axios";
import { NotificationType } from "../components/pages/profile/components-profile/notifications/notifications-type";

const URL_Employee = "/manage-employee/api";

export const getEmailNotifications = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/email-notifications`;

  return getAsync(url);
};

export const updateEmailNotifications = (notifications: NotificationType[]): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/email-notifications`;

  const body = {
    items: notifications,
  }
  return putAsync(url, body);
};