import React from "react";
import styled from "styled-components";
import { CheckBox } from "../../../../../cores/helpers/checkbox/checkbox";
import { StyledBigTitle, StyledButtonSubmit } from "../../../../shared/employee-styled";
import {
  NotificationData,
  NotificationDescription,
} from "./notifications-type";

type Props = {
  notificationData: NotificationData;
  isOwner: boolean;
  handleChangeCheckbox: (e: any) => void;
  handleSubmit: () => void;
  isValidButton: boolean;
};

const StyledWarningTitle = styled.div`
  color: ${(props) => props.theme.secondaryColor};
  font-family: ${(props) => props.theme.subheadFont};
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
`;

const StyledLargeTitle = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.subheadFont};
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  padding-top: 16px;
  margin-bottom: 16px;
`;

export default function NotificationsView(props: Props) {
  const { notificationData, isOwner } = props;
  return (
    <div className="w-100">
      <StyledBigTitle>Your Notifications</StyledBigTitle>
      <StyledWarningTitle>
        You can turn off notification by uncheck it
      </StyledWarningTitle>
      {notificationData.ownerNotifications.length > 0 && (
        <div>
          <StyledLargeTitle>Owner's Notifications</StyledLargeTitle>
          {notificationData.ownerNotifications.map((noti) => (
            <div key={`notification_${noti.emailNotificationType}`}>
              <CheckBox
                title={NotificationDescription[noti.emailNotificationType]}
                checked={noti.enabled}
                value={noti.emailNotificationType}
                onChangeCheckbox={e => props.handleChangeCheckbox(e)}
                classSpan="top-0px"
                disabled={isOwner}
              />
            </div>
          ))}
        </div>
      )}
      {notificationData.adminNotifications.length > 0 && (
        <div>
          <StyledLargeTitle>Admin's Notifications</StyledLargeTitle>
          {notificationData.adminNotifications.map((noti) => (
            <div key={`notification_${noti.emailNotificationType}`}>
              <CheckBox
                title={NotificationDescription[noti.emailNotificationType]}
                checked={noti.enabled}
                value={noti.emailNotificationType}
                onChangeCheckbox={e => props.handleChangeCheckbox(e)}
                classSpan="top-0px"
              />
            </div>
          ))}
        </div>
      )}
      {notificationData.employeeNotifications.length > 0 && (
        <div>
          <StyledLargeTitle>Employee's Notifications</StyledLargeTitle>
          {notificationData.employeeNotifications.map((noti) => (
            <div key={`notification_${noti.emailNotificationType}`}>
              <CheckBox
                title={NotificationDescription[noti.emailNotificationType]}
                checked={noti.enabled}
                value={noti.emailNotificationType}
                onChangeCheckbox={e => props.handleChangeCheckbox(e)}
                classSpan="top-0px"
              />
            </div>
          ))}
        </div>
      )}
      <div className="row mt-3">
          <div className="col-lg-2 ml-auto">
            <StyledButtonSubmit
              id="buttonUpdateNotification"
              type="button"
              disabled={!props.isValidButton}
              submit={props.isValidButton}
              onClick={props.handleSubmit}
            >
              Update
            </StyledButtonSubmit>
          </div>
        </div>
    </div>
  );
}
