import React from "react";

import styled from "styled-components";
import { BNPInput, Input } from "../../../../cores/helpers/input/input";
import { BenefitInfo, BenefitData } from "../employee-type";
import {
  StyledLargerTitle,
  StyledDescriptionLargerTitle,
  StyledRow,
  StyledButtonSubmit,
  StyledContentBox,
  StyledMainContent,
} from "../../../shared/employee-styled";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import {
  CheckBox,
  Checkbox,
} from "../../../../cores/helpers/checkbox/checkbox";
import {Balance, PendingAmountByCategory} from "../../view-list-claim/list-claim-type";
import moment from "moment";

const StyledTotalAmount = styled.div`
  color: ${(props) => props.theme.secondaryColor};
  font-size: 19px !important;
  font-weight: bold;
  line-height: 26px;
  @media (min-width: 992px) {
    margin-top: 1px;
    font-size: 20px;
    line-height: 24px;
  }
`;

const StyledBNPInput = styled(BNPInput)`
  padding-left: 25px;
  @media (min-width: 992px) {
    margin-top: 10px;
    margin-left: 28px;
    margin-bottom: 12px;
    width: 85%;
  }
`;

const StyledSpanBalance = styled.span`
  height: 50px;

  background-color: #f9fbfd;
  border: 1px solid #ecedf0;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
const StyledSpan = styled.span`
  position: absolute;
  top: 57px;
  left: 50px;
  @media (max-width: 992px) {
    position: absolute;
    top: 47px;
    left: 21px;
  }
`;
const StyledPage = styled.div`
  max-width: 900px;
  margin: 3rem auto;
`;

type Props = {
  handleChangInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  findBenefitInfoById: (benefitId: number) => BenefitInfo;
  totalBenefitCoverageAmountLeft: (benefitInfo: BenefitInfo[]) => number;
  totalBenefitCoverageProRatedAmountLeft: (benefitInfo: BenefitInfo[]) => number
  benefitData: BenefitData;
  isValid: boolean;
  benefitInfo: BenefitInfo[];
  validateBenefitInfo: (totalAmountLeft: number) => boolean;
  isProRated: boolean;
  availableDate: Date;
  remainDays: number;
  isAvailable: boolean;
  balances: Balance[];
  fullProRatedAmount: number;
  fullAmount: number;
  pendingAmountByCategory: PendingAmountByCategory;
};

export default function ReAllocationBenefitCoverageView(props: Props) {
  const findChecked = (enrollmentId: number): boolean => {
    if (enrollmentId) return true;
    else return false;
  };

  let totalAmountLeft = props.totalBenefitCoverageAmountLeft(props.benefitInfo);
  let totalProRatedAmountLeft = props.totalBenefitCoverageProRatedAmountLeft(props.benefitInfo);

  let total = 0;
  props.benefitInfo.forEach(t => total += t.amount);

  let isValid = props.validateBenefitInfo(totalAmountLeft);

  const findAvailable = (enrollmentName: string): number => {
    let walletName = '';
    switch (enrollmentName) {
      case "1":
        walletName = 'hsa_limit';
        break;
      case "2":
        walletName = 'wellness_limit';
        break;
      default:
      // Do nothing - charitable | insurance | rrsp is not allow to re-allocate
    }

    if (!walletName) return 0;
    const index = props.balances.findIndex(i => i.accountName === walletName);
    if (index === -1) return 0;

    const pendingISSClaimAmount = props.pendingAmountByCategory[Number(enrollmentName)] ?
        props.pendingAmountByCategory[Number(enrollmentName)].pendingISSClaimAmount : 0;
    const pendingISSClaimAmountThisYear = props.pendingAmountByCategory[Number(enrollmentName)] ?
        props.pendingAmountByCategory[Number(enrollmentName)].pendingISSClaimAmountThisYear : 0;
    const pendingISSClaimAmountLastYear = pendingISSClaimAmount - pendingISSClaimAmountThisYear;

    return (props.balances[index].balance - pendingISSClaimAmountLastYear) / 100;
  }

  return (
    <StyledMainContent>
      <StyledContentBox>
        <StyledPage>
          <div className="row">
            <div className="col-12">
              <StyledLargerTitle>Benefit Coverage</StyledLargerTitle>
              <div className="mt-3">
                <StyledDescriptionLargerTitle>
                  Total benefit coverage Amount left
                </StyledDescriptionLargerTitle>
              </div>
              <hr />
              <StyledTotalAmount>
                {
                  props.isProRated ?
                    <>
                      {
                        props.fullAmount !== props.fullProRatedAmount ?
                          <>
                            {formatterUSD("currency", "USD").format(centToUsd(totalAmountLeft))}
                            {` is the total benefit maximum for the whole year`}
                          </> : <>
                            {`You have a maximum of `}
                            {formatterUSD("currency", "USD").format(centToUsd(totalAmountLeft))}
                            {` available to allocate for your ${moment().format("YYYY")} benefits.`}
                          </>
                      }
                    </> : <>
                      {`You have a maximum of `}
                      {formatterUSD("currency", "USD").format(centToUsd(totalAmountLeft))}
                      {` available to allocate for your ${moment().format("YYYY")} benefits.`}
                    </>
                }
              </StyledTotalAmount>
              {
                props.isProRated && props.fullAmount !== props.fullProRatedAmount && (<>
                  <StyledTotalAmount>
                    {formatterUSD("currency", "USD").format(centToUsd(totalProRatedAmountLeft))}
                    {` is the available benefit maximum (this may be pro-rated for the remainder of the year)`}
                  </StyledTotalAmount>
                </>)
              }
            </div>

            <div className="col-12">
              <StyledRow>
                <div className="col-lg-12 mt-3 mb-md-4">
                  <div>
                    Please choose the benefit categories you would like to have available in your plan and allocate your available benefit maximum between those categories.
                    <br/>
                    <b>IMPORTANT</b>:
                    <br/>
                    - Once these allocations are submitted, they will be set and you will not be able to reallocate them again until {Number(moment().format('Y')) + 1}.
                    <br/>
                    - Please be advised that RRSP contributions must go to a plan sponsored RRSP. We are unable to direct funds to private RRSPs at this time.
                  </div>
                </div>

                {props.benefitData.enrollments.map((enrollment) => (
                  <div className="col-lg-12 mt-3 mt-lg-2" key={enrollment.id}>
                    <BenefitCoverage
                      title={enrollment.name}
                      checked={findChecked(
                        props.findBenefitInfoById(enrollment.id).enrollmentId
                      )}
                      name={enrollment.id.toString()}
                      value={
                        props.isProRated ?
                          props.findBenefitInfoById(enrollment.id).proRatedAmount
                          :
                          props.findBenefitInfoById(enrollment.id).amount
                      }
                      amount={props.findBenefitInfoById(enrollment.id).amount}
                      onChangeInput={props.handleChangInput}
                      onChangeCheckbox={props.handleChangCheckbox}
                      tabIndex={enrollment.id}
                      isProRated={props.isProRated}
                      findAvailbale={findAvailable}
                      rolloverAmount={props.findBenefitInfoById(enrollment.id).rolloverAmount}
                      // amountLastYear={props.findBenefitInfoById(enrollment.id).amountLastYear}
                      // proRatedAmountLastYear={props.findBenefitInfoById(enrollment.id).proRatedAmountLastYear}
                    />
                  </div>
                ))}

                <div className="col-lg-12 mt-3">
                  <div className="col-lg-3 p-0 float-right">
                    <StyledButtonSubmit
                      onClick={() => {
                        $("#buttonSaveBenefit").attr("disabled", "true");
                        props.handleSubmit();
                      }}
                      disabled={!isValid || props.benefitInfo.length === 0}
                      submit={isValid && props.benefitInfo.length !== 0}
                      id="buttonSaveBenefit">
                      Continue
                    </StyledButtonSubmit>
                  </div>
                </div>
              </StyledRow>
            </div>
          </div>
        </StyledPage>
      </StyledContentBox>
    </StyledMainContent>
  );
}

const BenefitCoverage = (props: BenefitCoverage) => {

  // let rolledOverAmount = !props.isProRated ?
  //   props.findAvailbale(props.name ? props.name : "") :
  //   props.proRatedAmountLastYear;
  const rolledOverAmount = props.rolloverAmount;
  return (
    <div>
      <CheckBox
        title={`${props.title}` || ""}
        classLabel={props.classLabel}
        classSpan={props.classSpan}
        onChangeCheckbox={props.onChangeCheckbox}
        checked={props.checked}
        name={props.name || ""}
      />
      {props.checked ? (
        <div className="d-flex flex-row align-items-center">
          <StyledSpan>$</StyledSpan>
          {
            props.isProRated ? (<>
              <StyledBNPInput
                placeholder={props.placeholder || ""}
                onChangeInput={props.onChangeInput}
                value={(props.value ? props.value : 0).toString()}
                name={props.name || ""}
                className={"w-100"}
                type="number"
                tabIndex={props.tabIndex} />
              {
                rolledOverAmount === 0 ?
                  <></> :
                  <StyledSpanBalance className="d-flex align-items-center w-50  justify-content-center">
                    {`+${formatterUSD("currency", "USD").format(rolledOverAmount)} rollover from ${new Date().getFullYear() - 1}`}
                  </StyledSpanBalance>
              }
            </>) :
              (<>
                <StyledBNPInput
                  placeholder={props.placeholder || ""}
                  onChangeInput={props.onChangeInput}
                  value={(props.value ? props.value : 0).toString()}
                  name={props.name || ""}
                  className={"w-100"}
                  type="number"
                  tabIndex={props.tabIndex} />
                {
                  rolledOverAmount === 0 ?
                    <></> :
                    <StyledSpanBalance className="d-flex align-items-center w-50 justify-content-center">
                      {`+${formatterUSD("currency", "USD").format(rolledOverAmount)} rollover from ${new Date().getFullYear() - 1}`}
                    </StyledSpanBalance>
                }
              </>)
          }
        </div>
      ) : (
          ""
        )}
    </div>
  );
}

type BenefitCoverage = Checkbox & Input & {
  isProRated: boolean;
  amount: number;
  findAvailbale: (enrollmentName: string) => number;
  // proRatedAmountLastYear: number;
  // amountLastYear: number;
  rolloverAmount: number;
};
