import React from "react";
import styled from "styled-components";
import {
  StyledButtonSubmit,
  StyledLargerTitle,
  StyledLabel,
  StyledHideForMobile,
  StyledBtnLink,
} from "../../../shared/employee-styled";
import { BankingInfo, Agreement } from "../employee-type";
import { BNPInput } from "../../../../cores/helpers/input/input";
import { CheckBox } from "../../../../cores/helpers/checkbox/checkbox";
import theme from "../../../../cores/helpers/theme";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import UploadImageCheque from "../../../../cores/helpers/upload-cheque-banking/image-upload";
import Cheque from "../../../../cores/config/component/images/cheque.jpg";
import {
  TermsAndConditionsModal,
  showTermsAndConditionsModal,
} from "../../../../cores/helpers/terms-and-conditions/terms-and-conditions-modal";
import { setupBenefitURL } from "../../../../cores/constants/url-config";
import { convertTimeZone } from "../../../../cores/utils/helpers";

const StyledCard = styled.div`
  box-sizing: border-box;
  border: 1px solid #f5eed6 !important;
  border-radius: 5px;
  background-color: #fffbed !important;

  .card-body ul {
    margin-bottom: 0;
    padding-left: 1em;
  }

  @media (min-width: 992px) {
    font-size: 14px;
    line-height: 22px;
    .card-body li {
      margin-bottom: 10px;
    }
  }
`;

const StyledPersonalBankingLeftContainer = styled.div`
  @media (min-width: 992px) {
    margin-top: -32px;
  }
`;

const StyledPersonalBankingRightContainer = styled.div`
  @media (min-width: 992px) {
    margin-top: -32px;

    span {
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      text-align: center;
    }
  }
`;

const StyledDivDatePicker = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 14px;
    height: 19px;
  }
`;

const StyledImgCheque = styled.img`
  max-height: 100%;
  max-width: 100%;
`;
const StyledSpan = styled.span`
  font-size: 12px !important;
`;

type Props = {
  handleChangeVoidCheque: (event: any) => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  isValid: boolean;
  bankingInfo: BankingInfo;
  handleDeleteImage: () => void;
  handleChangeReplaceCheque: () => void;
  agreementData: Agreement[];
  handleChangeDate: (date: Date | null, name: string) => void;
  fileUrl: string;
  handleOCRCheque: (file: any) => void;
};

export default function SetupBankingInfoView(props: Props) {
  const findChecked = (agreementId: number) => {
    let isAgree = props.bankingInfo.agreements.some((agree) => {
      return agree === agreementId;
    });

    return isAgree;
  };

  React.useEffect(() => {
    let $termsAndConditions = document.getElementById("termsAndConditions");

    if ($termsAndConditions) {
      $termsAndConditions.removeEventListener(
        "click",
        showTermsAndConditionsModal
      );
      $termsAndConditions.addEventListener(
        "click",
        showTermsAndConditionsModal
      );
    }
  });

  return (
    <div className="row">
      <div className="col-lg-12 order-lg-1">
        <StyledLargerTitle>Personal Banking Information</StyledLargerTitle>
        <StyledCard className="card mt-3">
          <div className="card-body">
            <ul>
              <li>
                Please keep in mind that you need to use your{" "}
                <strong>Personal Bank Account.</strong>
              </li>
              <li>
                Once this step is completed, you'll receive a confirmation email
                advising you that your Online Claim account is active and ready
                to use.
              </li>
            </ul>
          </div>
        </StyledCard>
        <hr />
      </div>
      <div className="col-12 col-lg-5 order-lg-2 mt-3">
        <StyledPersonalBankingLeftContainer>
          <div className="row">
            <div className="col-12 mt-3">
              <label>Void Cheque Image</label>
            </div>
            <div className="col-12 mt-3">
              <UploadImageCheque
                addFile={props.handleChangeVoidCheque}
                addBlob={props.handleOCRCheque}
                urlImage={props.fileUrl}
                folderName="Employee/Cheque"
              />
            </div>
          </div>
        </StyledPersonalBankingLeftContainer>
      </div>
      <div className="col-12 col-lg-7 order-lg-3 mt-3">
        <StyledPersonalBankingRightContainer>
          <div className="row">
            <div className="col-12 mt-3">
              <StyledLabel>Information</StyledLabel>
            </div>
            <div className="col-6 mt-3">
              <StyledLabel>Account Number</StyledLabel>
              <BNPInput
                maxLength={12}
                value={props.bankingInfo.accountNumber}
                onChangeInput={props.handleChangeInput}
                placeholder="Account number"
                name="accountNumber"
              />
            </div>

            <div className="col-6 mt-3">
              <StyledLabel>Transit Number</StyledLabel>
              <BNPInput
                maxLength={5}
                value={props.bankingInfo.transitNumber}
                onChangeInput={props.handleChangeInput}
                placeholder="Branch or Transit number"
                name="transitNumber"
              />
            </div>
            <div className="col-6 mt-3">
              <StyledLabel>Institution Number</StyledLabel>
              <BNPInput
                maxLength={3}
                value={props.bankingInfo.bankNumber}
                onChangeInput={props.handleChangeInput}
                placeholder="Institution or Bank Number"
                name="bankNumber"
              />
            </div>
            <div className="col-6 mt-3">
              <StyledLabel>
                E-signature{" "}
                <StyledSpan> (Please type name to accept)</StyledSpan>
              </StyledLabel>
              <BNPInput
                value={props.bankingInfo.eSignature || ""}
                onChangeInput={props.handleChangeInput}
                placeholder="eSignature"
                name="eSignature"
              />
            </div>
            <div className="col-12 mt-3">
              <StyledLabel>Date</StyledLabel>
              <StyledDivDatePicker>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableFuture
                    InputAdornmentProps={{ position: "start" }}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline1"
                    placeholder="Date"
                    name="date"
                    value={props.bankingInfo.date ?
                      convertTimeZone("Canada/Eastern", props.bankingInfo.date)
                      :
                      null}
                    onChange={(date) => props.handleChangeDate(date, "date")}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    tabIndex={-1}
                    role="application"
                    aria-hidden="true"
                  />
                </MuiPickersUtilsProvider>
              </StyledDivDatePicker>
            </div>

            <div className="col-12 mt-3 text-center">
              <label>
                <StyledImgCheque src={Cheque} alt="cheque" />
              </label>
            </div>
          </div>
        </StyledPersonalBankingRightContainer>
      </div>

      <div className="order-lg-4">
        {props.agreementData.map((agreement, index) => (
          <div className="col-lg-12 mt-3">
            <CheckBox
              key={index}
              checked={findChecked(agreement.id)}
              onChangeCheckbox={props.handleChangeCheckbox}
              name={agreement.id.toString()}
              title={
                <div
                  dangerouslySetInnerHTML={{
                    __html: agreement.title,
                  }}
                />
              }
            />
          </div>
        ))}
      </div>

      <div className="col-lg-12 order-lg-5 mt-5">
        <div className="row">
          <StyledHideForMobile className="col-lg-2">
            <StyledBtnLink
              to={setupBenefitURL}
              background={theme.secondaryColor}
            >
              Back
            </StyledBtnLink>
          </StyledHideForMobile>
          <div className="col-lg-7"></div>
          <div className="col-lg-3">
            <StyledButtonSubmit
              onClick={() => {
                $("#buttonSaveBanking").attr("disabled", "true");
                props.handleSubmit();
              }}
              submit={props.isValid}
              disabled={!props.isValid}
              id="buttonSaveBanking"
            >
              Continue
            </StyledButtonSubmit>
          </div>
        </div>
      </div>
      <TermsAndConditionsModal />
    </div>
  );
}
