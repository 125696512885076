import React from "react";
import {
  StyledLargerTitle,
  StyledButtonSubmit,
  StyledHideForMobile,
  StyledBtnLink,
  StyledContentBox,
  StyledMainContent,
} from "../../../shared/employee-styled";
import styled from "styled-components";
import {
  BenefitCoverage,
  BenefitInfo
} from "../employee-type";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import theme from "../../../../cores/helpers/theme";
import { Link } from "react-router-dom";
import {
  reAllocationURL
} from "../../../../cores/constants/url-config";
import {
  getReAllocationStatus
} from "../../../../cores/utils/helpers";
import {Balance, PendingAmountByCategory} from "../../view-list-claim/list-claim-type";

const StyledReviewInformationTitle = styled.div`
  background-color: #e4e5eb;
  padding: 0.43em 15px;
  margin: 0 -20px;
  span {
    height: 26px;
    color: ${(props) => props.theme.secondaryColor};
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
  }
  a {
    height: 26px;
    color: ${(props) => props.theme.primaryColor};
    line-height: 26px;
    text-align: right;
    float: right;
  }
  a:hover {
    opacity: 0.8;
  }
`;

const StyledReviewInformationContentTitle = styled.div`
  height: 22px;
  font-weight: bold;
  line-height: 22px;
  a {
    color: #70b542 !important;
    font-size: 12px;
    margin-left: 1em;
  }

  @media (min-width: 992px) {
    color: #2d2d2d;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledReviewInformationContentValue = styled.div`
  height: 22px;
  line-height: 22px;
  margin-top: 0.5rem;
`;

const StyledLinkEdit = styled(Link)`
  float: right;
  cursor: pointer;
  color: ${(props) => props.theme.primaryColor};
  :hover {
    color: ${(props) => props.theme.primaryColor};
  }
`;

const StyledPage = styled.div`
  max-width: 900px;
  margin: 3rem auto;
`;

type Props = {
  findBenefitsById: (id: number) => BenefitCoverage;
  benefitInfo: BenefitInfo[];
  finishSetup: () => void;
  isProRated: boolean;
  balances: Balance[];
  pendingAmountByCategory: PendingAmountByCategory;
};

export default function ReviewReAllocationView(props: Props) {

  const findAvailbale = (enrollmentId: number): number => {
    let walletName = '';
    switch (enrollmentId) {
      case 1:
        walletName = 'hsa_limit';
        break;
      case 2:
        walletName = 'wellness_limit';
        break;
      default:
      // Do nothing - charitable | insurance | rrsp is not allow to re-allocate
    }

    if (!walletName) return 0;
    const index = props.balances.findIndex(i => i.accountName === walletName);
    if (index === -1) return 0;

    const pendingISSClaimAmount = props.pendingAmountByCategory[Number(enrollmentId)] ?
        props.pendingAmountByCategory[Number(enrollmentId)].pendingISSClaimAmount : 0;
    const pendingISSClaimAmountThisYear = props.pendingAmountByCategory[Number(enrollmentId)] ?
        props.pendingAmountByCategory[Number(enrollmentId)].pendingISSClaimAmountThisYear : 0;

    return (props.balances[index].balance - (pendingISSClaimAmount - pendingISSClaimAmountThisYear)) / 100;
  }

  return (
    <StyledMainContent>
      <StyledContentBox>
        <StyledPage>
          <div className="row">
            <div className="col-12">
              <StyledLargerTitle>Review information</StyledLargerTitle>
              <hr />
            </div>
            <div className="col-12">
              <div>
                <div className="mt-4">
                  <StyledReviewInformationTitle>
                    <span>Benefit Coverage</span>
                    {
                      getReAllocationStatus() === "REQUIRED" && <StyledLinkEdit to={reAllocationURL}>Edit</StyledLinkEdit>
                    }
                  </StyledReviewInformationTitle>
                  <div className="row">
                    {props.benefitInfo.map((benefit, index) => (
                      <div className="col-lg-4 mt-3" key={index}>
                        <StyledReviewInformationContentTitle>
                          {props.findBenefitsById(benefit.enrollmentId).name}
                        </StyledReviewInformationContentTitle>
                        <StyledReviewInformationContentValue>
                          {
                            formatterUSD("currency", "USD").format(
                                findAvailbale(benefit.enrollmentId) +
                                (props.isProRated ? benefit.proRatedAmount : benefit.amount)
                            )
                          }
                        </StyledReviewInformationContentValue>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mt-5">
                {
                  getReAllocationStatus() === "REVIEW" ?
                    <StyledHideForMobile className="col-lg-2"></StyledHideForMobile>
                    :
                    <StyledHideForMobile className="col-lg-2">
                      <StyledBtnLink
                        to={reAllocationURL}
                        background={theme.secondaryColor}>
                        Back
                    </StyledBtnLink>
                    </StyledHideForMobile>
                }
                <div className="col-lg-7"></div>
                <div className="col-lg-3">
                  <StyledButtonSubmit
                    id="finishSetup"
                    submit={true}
                    onClick={() => {
                      $("#finishSetup").attr("disabled", "true");
                      props.finishSetup();
                    }} >
                    Finish
                  </StyledButtonSubmit>
                </div>
              </div>
            </div>
          </div>
        </StyledPage>
      </StyledContentBox>
    </StyledMainContent>
  );
}
