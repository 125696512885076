import React from "react";
import {
  getProfileEmployee,
  getProvinces,
  putProfile,
  putChangeAvatar,
} from "../../../../../services/employee-registration-service";
import { getFileUrl } from "../../../../../services/file-service";
import { addLoading, removeLoading } from "../../../../../cores/utils/loading";
import {
  ResponseEmployeeData,
  Provinces,
  RequestProfile,
} from "../../../setup-wizard/employee-type";

import { publishMessage } from "../../../../../cores/utils/message";
import { ValidatorForm } from "react-material-ui-form-validator";
import Profile from "../../profile";
import PersonalInformationView from "./personal-information-view";
import { toUTCTime } from "../../../../../cores/helpers/to-utc-time";
import { ResponseLocation } from "../../../../../cores/helpers/google-map-services/google-location";
import {getToken, signOut} from "../../../../../cores/utils/helpers";
import {validFullNameLength} from "../../../../../cores/utils/validation/validate-employee";

type State = {
  employee: ResponseEmployeeData;
  provinces: Provinces[];
};

type Props = {};

export default class PersonalInformation extends React.Component<Props, State> {
  state: State = {
    employee: {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      dateOfBirth: "",
      phoneNumber: "",
      employeeLocation: {
        id: -1,
        street: "",
        cityName: "",
        provinceId: 0,
        provinceName: "",
        postCode: "",
      },
      passwordObject: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      profileUrl: "",
    } as ResponseEmployeeData,
    provinces: [
      {
        id: -1,
        code: "",
        name: "",
      },
    ] as Provinces[],
  };

  async componentDidMount() {
    //Not filled billing address
    ValidatorForm.addValidationRule("isNotFilled", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });

    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.employee.passwordObject.newPassword) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("isFillPassword", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule(
      "isOldPasswordMatchNewPassword",
      (value) => {
        if (
          this.state.employee.passwordObject.oldPassword &&
          value === this.state.employee.passwordObject.oldPassword
        ) {
          return false;
        }
        return true;
      }
    );

    if (getToken()) {
      addLoading();
      this.getInfo();
      this.provinces();
      removeLoading();
    }
  }

  validateForm = (): boolean => {
    const { email, firstName, lastName, dateOfBirth } = this.state.employee;
    return !!(email &&
        firstName &&
        lastName &&
        dateOfBirth &&
        validFullNameLength(firstName, lastName)
    );
  };

  async getInfo() {
    await getProfileEmployee().then((result) => {
      let personalInfo: ResponseEmployeeData = result.data;
      personalInfo.passwordObject = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      };
      this.setState({ employee: personalInfo });
    });
    let employee = this.state.employee;
    if (employee.profileUrl) {
      await getFileUrl(employee.profileUrl).then((response) => {
        employee.profileUrl = response.data;
      });
      this.setState({ employee: employee });
    }
  }

  async provinces() {
    await getProvinces().then((result) => {
      let provinces: Provinces[] = result.data;

      this.setState({
        provinces: provinces,
      });
    });
  }
  setAvatar = (name: string) => {
    let abbreviations = "";

    let splitName = name.split(" ");

    splitName.forEach((splitName) => {
      abbreviations += splitName.slice(0, 1);
    });

    return abbreviations;
  };
  handleChangeDate = (date: Date | null, name: string) => {
    let employee = Object.assign(this.state.employee);

    employee.dateOfBirth = date;

    this.setState({
      employee: employee,
    });
  };
  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let employee = Object.assign(this.state.employee);
    const { name, value } = event.target;

    employee[name] = value;

    this.setState({
      employee: employee,
    });
  };

  setAddress = (address: string) => {
    let employee: ResponseEmployeeData = Object.assign(this.state.employee);

    employee.employeeLocation.street = address;

    this.setState({
      employee: employee,
    });
  };

  setLocation = (location: ResponseLocation) => {
    let employee: ResponseEmployeeData = Object.assign(this.state.employee);

    employee.employeeLocation.cityName = location.city;
    employee.employeeLocation.postCode = location.postcode
      ? location.postcode
      : "";
    employee.employeeLocation.provinceId = this.findProvinceIdByName(
      location.province
    );

    this.setState({
      employee: employee,
    });
  };

  findProvinceIdByName = (provinceName: string) => {
    let province = this.state.provinces.find((f) => {
      return f.name === provinceName;
    });
    return province ? province.id : -1;
  };

  handleChangeInputStreet = (event: React.ChangeEvent<HTMLInputElement>) => {
    let employee = Object.assign(this.state.employee);
    const { name, value } = event.target;

    employee.employeeLocation[name] = value;

    this.setState({
      employee: employee,
    });
  };

  handleChangeSelected = (event: any) => {
    const { value } = event.target;

    let employee = Object.assign({}, this.state.employee);

    if (Number(value) > 0) {
      employee.employeeLocation.provinceId = value;
    } else {
      employee.employeeLocation.provinceId = 0;
    }

    this.setState({ employee });
  };

  handleOnSubmit = () => {
    let reqProfile = {
      firstName: this.state.employee.firstName,
      lastName: this.state.employee.lastName,
      dateOfBirth: toUTCTime(new Date(this.state.employee.dateOfBirth)),
      email: this.state.employee.email,
      phoneNumber: this.state.employee.phoneNumber,
      street: this.state.employee.employeeLocation.street,
      provinceId: this.state.employee.employeeLocation.provinceId,
      cityName: this.state.employee.employeeLocation.cityName,
      postCode: this.state.employee.employeeLocation.postCode,
    } as RequestProfile;

    putProfile(reqProfile).then((res) => {
      if (res.status === 200 && res.data && ("object" === typeof res.data)) {
        publishMessage({
          message: "Save profile with new email success. Please sign in again to continue",
          variant: "success",
        });
        setTimeout(() => {signOut()}, 1000);
      }

      publishMessage({
        variant: "success",
        message: "Save profile success",
      });
    });
  };

  handleChangeAvatar = (urlImage: string) => {
    let employee = Object.assign(this.state.employee);
    employee.profileUrl = urlImage;

    this.changeAvatar(employee);
    getFileUrl(employee.profileUrl).then((response) => {
      employee.profileUrl = response.data;
      this.setState({ employee: employee });
    });
  };

  changeAvatar = (employee: ResponseEmployeeData) => {
    let model = { profileUrl: employee.profileUrl };

    putChangeAvatar(model).then(() => {
      publishMessage({
        message: "Change avatar success",
        variant: "success",
      });
    });
  };

  render() {
    return (
      <Profile
        children={
          <PersonalInformationView
            employee={this.state.employee}
            handleChangeDate={this.handleChangeDate}
            handleChangeInput={this.handleChangeInput}
            onSubmit={this.handleOnSubmit}
            setAvatar={this.setAvatar}
            provinces={this.state.provinces}
            handleChangeSelected={this.handleChangeSelected}
            handleChangeInputStreet={this.handleChangeInputStreet}
            handleChangeAvatar={this.handleChangeAvatar}
            validateForm={this.validateForm}
            setAddress={this.setAddress}
            setLocation={this.setLocation}
          />
        }
      />
    );
  }
}
