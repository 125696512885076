// SortSelect.js
import React, {useEffect, useState} from 'react';
import {MenuItem, FormControlLabel, Radio, FormControl, Select} from '@material-ui/core';
import styled from 'styled-components';
import {StyledOption} from "../../../shared/styled";

const StyledFilterSelect = styled(Select)`
    font-family: ${(props) => props.theme.textFont} !important;
    .MuiSelect-select {
        border-radius: 5px;
    }
    .MuiInputBase-input {
        padding: 5px 0 6px;
    }
    .MuiSelect-nativeInput {
        padding: 5px 0 6px;
    }
    .MuiSelect-select.Mui-disabled {
        background: #f3f6f8;
    }
    & .MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover {
        background-color: transparent;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    background-color: transparent;
    &.Mui-selected {
        background-color: transparent;
    }
`;

const StyledFilterOptionTitle = styled.div`
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
    width: 80px;
    
`;

const MenuContainer = styled.div`
  & .MuiFormControlLabel-root {
    margin-bottom: 0;
  }
  & .MuiTypography-body1 {
    font-size: 14px;
  }
`;

const SortRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 115px;
  font: inherit;
`;

const StyledRadio = styled(Radio)`
  padding: 0;
    
`;

type SelectType = {
  [key: string]: string;
  costFrom: string;
  rating: string;
  distance: string;
};

const SortByOptions: {[key: string]: string}[] = [
  { key: 'costFrom', label: 'Price', asc: 'Low To High', desc: 'High To Low' },
  { key: 'rating', label: 'Rating', asc: 'Low To High', desc: 'High To Low' },
  { key: 'distance', label: 'Distance', asc: 'Near To Far', desc: 'Far To Near' },
];

type SortSelectProps = {
  sortBy: string[];
  setSortBy: (sortBy: string[]) => void;
}

const SortSelect = (props: SortSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<SelectType>({
    costFrom: '',
    rating: '',
    distance: '',
  });

  useEffect(() => {
    if (props.sortBy.length > 0) {
      const selectedOptionClone = { ...selectedOption };
      props.sortBy.forEach((sort) => {
        const [sortKey, order] = sort.split(';');
        selectedOptionClone[sortKey.toLowerCase()] = order;
      });

      setSelectedOption(selectedOptionClone);
    }
  }, [props.sortBy]);

  const handleRadioChange = (event: any) => {
    event.stopPropagation();
    const { name, value } = event.target;
    setSelectedOption((prev) => ({ ...prev, [name]: value }));
  };

  const handleRadioClick = (event: any) => {
    event.stopPropagation();
    const { name, value } = event.target;
    if (selectedOption[name] === value) {
      setSelectedOption((prev) => ({ ...prev, [name]: '' }));
    }
  }

  const handleClose = () => {
    const sortBy: string[] = [];
    Object.keys(selectedOption).forEach((key) => {
      if (selectedOption[key]) {
        sortBy.push(`${key};${selectedOption[key]}`);
      }
    });
    props.setSortBy(sortBy);
  }

  const renderValue = () => {
    const selected = Object.entries(selectedOption)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => {
        const option = SortByOptions.find((option) => option.key === key);
        if (!option) {
          return '';
        }
        return `${option.label}: ${option[value]}`;
      })
      .filter((value) => !!value);

    let selectedString = '';
    if (selected.length > 1) {
      selectedString = `${selected[0]} & more`;
    } else if (selected.length === 1) {
      selectedString = selected[0];
    } else {
      selectedString = 'Sort By';
    }

    return <StyledOption>
      {selectedString}
    </StyledOption>;
  }

  return (
    <FormControl>
      <StyledFilterSelect
        labelId={`filter-select-label`}
        name={"Filter"}
        value={Object.keys(selectedOption)}
        multiple={true}
        onClose={handleClose}
        renderValue={renderValue}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              width: "350px",
              marginTop: "8px"
            }
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          getContentAnchorEl: null,
          autoFocus: false
        }}
      >
        {SortByOptions.map((option) => (
          <StyledMenuItem
            value={option.key}
            key={`${option.key}-filter-select`}
            selected={false}
            onKeyDown={(e) => e.stopPropagation()}
            style={{ backgroundColor: 'transparent' }}
          >
            <MenuContainer onClick={e => e.stopPropagation()}>
              <SortRow key={option.key}>
                <StyledFilterOptionTitle>{option.label}</StyledFilterOptionTitle>
                <div>
                  <StyledFormControlLabel
                    control={
                      <StyledRadio
                        checked={selectedOption[option.key] === 'asc'}
                        onChange={handleRadioChange}
                        value="asc"
                        name={option.key}
                        onClick={handleRadioClick}
                        color={"primary"}
                      />
                    }
                    label={option.asc}
                  />
                  <StyledFormControlLabel
                    control={
                      <StyledRadio
                        checked={selectedOption[option.key] === 'desc'}
                        onChange={handleRadioChange}
                        value="desc"
                        name={option.key}
                        onClick={handleRadioClick}
                        color={"primary"}
                      />
                    }
                    label={option.desc}
                  />
                </div>
              </SortRow>
            </MenuContainer>
          </StyledMenuItem>
        ))}
      </StyledFilterSelect>
    </FormControl>
  );
};

export default SortSelect;
