import React from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledSpanTitle,
  StyledTextarea,
  StyledButtonSubmit,
  LandingStyledTextValidator,
  StyledInputMask,
} from "../../shared/employee-styled";
import styled from "styled-components";
import { ValidatorForm } from "react-material-ui-form-validator";
import { BNPInput } from "../../../cores/helpers/input/input";
import { getInfoByToken } from "../../../cores/utils/helpers";
import { validateEmail } from "../../../cores/config/config";
import { ContactType } from "./contact-type";
import { RouteChildrenProps } from "react-router";
import { publishMessage } from "../../../cores/utils/message";
import { getProfileEmployee } from "../../../services/employee-registration-service";
import { ResponseEmployeeData } from "../setup-wizard/employee-type";

const StyledDiv = styled.div`
  font-size: 16px;
`;

const defaultContact: ContactType = {
  companyName: "",
  email: getInfoByToken() ? getInfoByToken().sub : "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  notes: "",
};

export default function Contact(props: RouteChildrenProps) {
  const [contact, setContact] = React.useState<ContactType>({
    ...defaultContact,
  });

  const formId: string = "3c0657c8-c9e3-418e-8573-0481ca1e97a7";

  React.useEffect(() => {
    document.body.classList.add("contact");
    getCompany();
    return () => {
      document.body.classList.remove("contact");
    };
  }, []);

  React.useEffect(() => {});

  const getCompany = async () => {
    let resultCompanyInfo = await getProfileEmployee();
    let company: ResponseEmployeeData = resultCompanyInfo.data || [];

    let contactChange = { ...contact };

    contactChange.companyName = company.companyName;
    setContact(contactChange);
  };

  const onChangInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    const name = event.target.name as keyof typeof defaultContact;

    let contactChange = { ...contact };

    contactChange[name] = value;

    setContact(contactChange);
  };

  const validate = () => {
    if (
      contact.companyName &&
      contact.email.match(validateEmail) &&
      contact.firstName &&
      contact.lastName
    ) {
      return true;
    } else return false;
  };

  const submitForm = () => {
    let iframe = $("iframe").contents();
    $("#email-" + formId, iframe).val(contact.email);
    $("#firstname-" + formId, iframe).val(contact.firstName);
    $("#lastname-" + formId, iframe).val(contact.lastName);
    $("#message-" + formId, iframe).val(contact.notes);
    $("#hsForm_" + formId, iframe).submit();

    setTimeout(() => {
      if (iframe.find("form").length === 0) {
        publishMessage({
          variant: "success",
          message: "Successfully submitted",
        });
      } else {
        publishMessage({
          variant: "error",
          message: "Please enter your company email",
        });
      }
    }, 1000);
  };

  return (
    <StyledMainContent>
      <StyledBigTitle>Contact</StyledBigTitle>
      <StyledContentBox className="mt-4">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-4">
            <StyledDiv className="mt-2">
              Are you having issues with your{" "}
              <strong>BeniPlus Benefit Wallet</strong>? Would you like to speak
              with one of our experts to get assistance?
            </StyledDiv>
            <StyledDiv className="mt-2">
              We have a number of ways you can contact us. You can submit your
              details in our online form, you can use our online chat, you can
              email us at <strong>support@beniplus.ca</strong> or you can phone
              us at <strong>1-888-859-3579</strong> during regular office hours.{" "}
            </StyledDiv>
          </div>
          <div className="col-lg-1 mt-2"></div>
          <div className="col-lg-5">
            <ValidatorForm className="w-100" onSubmit={submitForm}>
              <div className="row">
                <div className="col-12">
                  <StyledSpanTitle>Company name *</StyledSpanTitle>
                  <BNPInput
                    name="companyName"
                    value={contact.companyName}
                    onChangeInput={onChangInput}
                    placeholder="Company name"
                    tabIndex={1}
                  />
                </div>
                <div className="col-12 mt-2">
                  <StyledSpanTitle>First name *</StyledSpanTitle>
                  <BNPInput
                    name="firstName"
                    value={contact.firstName}
                    onChangeInput={onChangInput}
                    placeholder="First name"
                    tabIndex={2}
                  />
                </div>
                <div className="col-12 mt-2">
                  <StyledSpanTitle>Last name *</StyledSpanTitle>
                  <BNPInput
                    name="lastName"
                    value={contact.lastName}
                    onChangeInput={onChangInput}
                    placeholder="Last name"
                    tabIndex={3}
                  />
                </div>
                <div className="col-12 mt-2">
                  <StyledSpanTitle>Phone number</StyledSpanTitle>
                  <StyledInputMask
                    mask="999-999-9999"
                    value={contact.phoneNumber}
                    onChange={onChangInput}
                    name="phoneNumber"
                    placeholder="Phone number"
                    tabIndex={4}
                  />
                </div>
                <div className="col-12 mt-2">
                  <StyledSpanTitle>Email address *</StyledSpanTitle>
                  <LandingStyledTextValidator
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={contact.email}
                    onChange={onChangInput}
                    validators={["required", `matchRegexp:${validateEmail}`]}
                    errorMessages={[
                      "Please enter user name or email.",
                      "Email is not valid",
                    ]}
                    tabIndex={5}
                    role="application"
                    aria-hidden="true"
                  />
                </div>
                <div className="col-12 mt-2">
                  <StyledSpanTitle>Note</StyledSpanTitle>
                  <StyledTextarea
                    name="notes"
                    rows={4}
                    onChange={onChangInput}
                    placeholder="Note"
                    value={contact.notes}
                    tabIndex={6}
                  />
                </div>
                <div className="col-12 mt-4">
                  <StyledButtonSubmit
                    submit={validate()}
                    disabled={!validate()}
                    type="submit"
                  >
                    Submit
                  </StyledButtonSubmit>
                </div>
              </div>
            </ValidatorForm>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </StyledContentBox>
    </StyledMainContent>
  );
}
