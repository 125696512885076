import React from "react";
import { StyledBigTitle } from "../../../../shared/employee-styled";
import styled from "styled-components";
import EditIcon from "@material-ui/icons/Edit";
import { BankingInfo } from "../../../setup-wizard/employee-type";
import { showBankingModal } from "./banking-info-modal";
import { TermsAndConditionsModal } from "../../../../../cores/helpers/terms-and-conditions/terms-and-conditions-modal";
import {PdfViewer} from "../../../../shared/pdf-viewer"

const ContentBoxCustomStyled = styled.div`
  padding: 20px 15px;
  border-radius: 5px;
  background-color: #f4f5f9;
`;

const StyledImgReview = styled.img`
  max-height: 200px;
  max-width: 100%;
`;

const CellStyled = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type Props = {
  bankingInfo: BankingInfo;
  fileUrl: string;
};
export default function BankingInfoView(props: Props) {
  const [isPdfFile, setIsPdfFile] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.fileUrl.match("/*.pdf")) {
      setIsPdfFile(true);
    } else {
      setIsPdfFile(false);
    }
  }, [props.fileUrl]);

  const formatBankNumber = (bankNumber: string): string => {
    return bankNumber ? "xxxx-xxxx-x" + bankNumber.toString().slice(-3) : "";
  };

  return (
    <div>
      <StyledBigTitle>Banking Info</StyledBigTitle>

      <div className="row mt-3">
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
          <div>
            <ContentBoxCustomStyled>
              <div className="d-flex">
                <CellStyled className="w-40 p-2">Account Number</CellStyled>
                <CellStyled className="w-60 text-right p-2 fw-bold">
                  {formatBankNumber(props.bankingInfo.accountNumber)}
                </CellStyled>
              </div>
              <div className="d-flex">
                <CellStyled className="w-40 p-2">Transit Number</CellStyled>
                <CellStyled className="w-60 text-right p-2 fw-bold">
                  {props.bankingInfo.transitNumber}
                </CellStyled>
              </div>
              <div className="d-flex">
                <CellStyled className="w-40 p-2">Institution Number</CellStyled>
                <CellStyled className="w-60 text-right p-2 fw-bold">
                  {props.bankingInfo.bankNumber}
                </CellStyled>
              </div>

              <div className="d-flex">
                <CellStyled className="w-40 p-2">Cheque</CellStyled>
                <CellStyled className="w-60 text-right p-2 fw-bold">
                  {props.fileUrl &&
                    (isPdfFile ? (
                      <PdfViewer fileUrl={props.fileUrl} height="200px" />
                    ) : (
                      <StyledImgReview src={props.fileUrl} alt="cheque" />
                    ))}
                </CellStyled>
              </div>
            </ContentBoxCustomStyled>

            <span
              className="float-right primary-color cur mt-3 hover fw-bold"
              onClick={showBankingModal}
            >
              <EditIcon className="icon mr-1 mb-1" />
              Edit & Update
            </span>
          </div>
        </div>
        <div className="col-lg-2"></div>
      </div>
      <TermsAndConditionsModal />
    </div>
  );
}
