import React, { Component } from "react";

import { ValidatorForm } from "react-material-ui-form-validator";
import { EmployeeSignUpData } from "../setup-wizard/employee-type";
import {
  StyledBigTitle,
  LandingStyledTextValidator,
  StyledPError,
  StyledButtonSubmit,
} from "../../shared/employee-styled";
import ShowPasswordView, { BNPInput } from "../../../cores/helpers/input/input";
import {
  getEmployeeInfo,
  postEmployeeSignUp,
} from "../../../services/employee-registration-service";
import { LoginReqModel } from "../../../cores/models/login/login-req-model";
import { RouteChildrenProps } from "react-router";
import { login } from "../../../services/auth-service";
import { publishMessage } from "../../../cores/utils/message";
import {
  setToken,
  setIsSetupDone,
  getToken,
  getIsSetupDone,
  setReAllocationStatus
} from "../../../cores/utils/helpers";
import {
  claimManagementURL,
  setupBasicInformationURL,
} from "../../../cores/constants/url-config";

type State = {
  signUp: EmployeeSignUpData;
  isEmailRegistered: boolean;
  isValid: boolean;
};
type Props = RouteChildrenProps;

export default class SignUpEmployee extends Component<Props, State> {
  state: State = {
    signUp: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      rePassword: "",
      token: "",
    },
    isEmailRegistered: false,
    isValid: false,
  };
  constructor(props: any) {
    super(props);
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.signUp.password) {
        return false;
      }
      return true;
    });

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      this.getAccount(token);
    }
  }

  UNSAFE_componentWillMount() {
    if (getToken()) {
      if (getIsSetupDone()) {
        this.props.history.push(claimManagementURL);
      } else {
        this.props.history.push(setupBasicInformationURL);
      }
    }
  }

  async getAccount(token: string) {
    //get account
    let param = {
      token: token,
    };
    await getEmployeeInfo(param).then((result) => {
      //set account on state
      let signUp: EmployeeSignUpData = Object.assign(this.state.signUp);
      signUp.firstName = result.data.firstName;
      signUp.lastName = result.data.lastName;
      signUp.email = result.data.email;
      signUp.token = token;
      this.setState({ signUp: signUp });
    });
  }

  handleChangeInput = (event: any) => {
    let signUp = Object.assign(this.state.signUp);

    signUp[event.target.name] = event.target.value;
    this.setState({ signUp: signUp });
  };

  onSubmit = () => {
    let signUpData: EmployeeSignUpData = Object.assign(this.state.signUp);
    signUpData.email = signUpData.email.toLowerCase().trim();

    postEmployeeSignUp(signUpData).then((response) => {
      publishMessage({
        variant: "success",
        message: "Thank you for signing up.",
      });
      if (response.status === 200 && response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl;
      } else {
        this.login();
      }
    });
  };

  async login() {
    let loginReqModel: LoginReqModel = {
      username: this.state.signUp.email.toLowerCase().trim(),
      password: this.state.signUp.password,
    };

    await login(loginReqModel)
      .then((value: any) => {
        setToken(value.data.access_token, new Date(value.data.expired_at));

        setIsSetupDone("");
        setReAllocationStatus("NONE");
        this.props.history.push(setupBasicInformationURL);
      })
      .catch((error) => {
        // if err
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.status === 500) {
            publishMessage({
              message: "We’ve run into a problem. Please try again later",
              variant: "error",
            });
          }
        } else {
          publishMessage({
            message:
              "System error. We apologize for the inconvenience. Please try again.",
            variant: "error",
          });
        }
      });
  }

  validatorListener = (event: boolean) => {
    if (event) {
      let isValid = this.validate();
      this.setState({ isValid: isValid });
    } else {
      this.setState({ isValid: event });
    }
  };

  validate = (): boolean => {
    if (
      this.state.signUp.firstName &&
      this.state.signUp.lastName &&
      this.state.signUp.password &&
      this.state.signUp.rePassword &&
      this.state.signUp.email
    ) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div>
        <StyledBigTitle className="mt-5">Create an new account</StyledBigTitle>
        <p className="mt-3">
          Please enter the following required information to create a new
          account.
        </p>
        <ValidatorForm className="col-lg-12 row" onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <LandingStyledTextValidator
                onChange={this.handleChangeInput}
                type="text"
                name="firstName"
                placeholder="First name"
                value={this.state.signUp.firstName}
                validators={["required"]}
                errorMessages={["Please enter first name."]}
                validatorListener={this.validatorListener}
                tabIndex={1}
                role="application"
                aria-hidden="true"
              />
            </div>
            <div className="col-lg-4 mb-3">
              <LandingStyledTextValidator
                onChange={this.handleChangeInput}
                type="text"
                name="lastName"
                placeholder="Last name"
                value={this.state.signUp.lastName}
                validators={["required"]}
                errorMessages={["Please enter last name."]}
                validatorListener={this.validatorListener}
                tabIndex={2}
                role="application"
                aria-hidden="true"
              />
            </div>
            <div className="col-lg-4 mb-3">
              <BNPInput
                type="text"
                name="email"
                className="disabled"
                disabled={true}
                placeholder="Email"
                value={this.state.signUp.email}
              />
              {this.state.isEmailRegistered && (
                <StyledPError className="mb-0">
                  Email already registered.
                </StyledPError>
              )}
            </div>
            <div className="col-lg-6 mb-3">
              <ShowPasswordView
                onChange={this.handleChangeInput}
                name="password"
                placeholder="Password"
                value={this.state.signUp.password}
                validators={["required"]}
                errorMessages={["Please enter password"]}
                tabIndex={3}
                validatorListener={this.validatorListener}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <ShowPasswordView
                onChange={this.handleChangeInput}
                name="rePassword"
                placeholder="Confirm the password"
                value={this.state.signUp.rePassword}
                validators={["isPasswordMatch", "required"]}
                errorMessages={[
                  "Password and confirm password does not match",
                  "Please enter confirm password",
                ]}
                tabIndex={4}
                validatorListener={this.validatorListener}
              />
            </div>
            <div className="col-lg-12 mb-3">
              <div className="col-lg-5 float-right p-0">
                <StyledButtonSubmit
                  type="submit"
                  submit={this.state.isValid}
                  disabled={!this.state.isValid}
                >
                  Create a new account
                </StyledButtonSubmit>
              </div>
            </div>
          </div>
        </ValidatorForm>
      </div>
    );
  }
}
