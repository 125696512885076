import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { ResponseEmployeeData } from "../../../setup-wizard/employee-type";
import ShowPasswordView from "../../../../../cores/helpers/input/input";
import {
  StyledButtonSubmit,
  StyledBigTitle,
} from "../../../../shared/employee-styled";

type Props = {
  employee: ResponseEmployeeData;
  handleChangeInputPassword: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleSavePassword: () => void;
  isValidButton: boolean;
};
export default function ChangePasswordView(props: Props) {
  return (
    <div>
      <StyledBigTitle>Change password</StyledBigTitle>
      <ValidatorForm className="w-100" onSubmit={props.handleSavePassword}>
        <div>
          <div className="row">
            <div className="col-sm-4 mt-3">
              <ShowPasswordView
                placeholder="Old password"
                name="oldPassword"
                onChange={props.handleChangeInputPassword}
                value={props.employee.passwordObject.oldPassword || ""}
                validators={["isFillPassword"]}
                errorMessages={["Can fill old password."]}
              />
            </div>
            <div className="col-sm-4  mt-3">
              <ShowPasswordView
                name="newPassword"
                placeholder="New password"
                onChange={props.handleChangeInputPassword}
                value={props.employee.passwordObject.newPassword || ""}
                validators={["isOldPasswordMatchNewPassword", "isFillPassword"]}
                errorMessages={[
                  "New password shouldn't match old password.",
                  "Can fill new password.",
                ]}
              />
            </div>
            <div className="col-sm-4  mt-3">
              <ShowPasswordView
                placeholder="Confirm password"
                name="confirmPassword"
                onChange={props.handleChangeInputPassword}
                value={props.employee.passwordObject.confirmPassword || ""}
                validators={["isPasswordMatch", "isFillPassword"]}
                errorMessages={[
                  "New password and confirm not match.",
                  "Can fill confirm password.",
                ]}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-2 ml-auto">
            <StyledButtonSubmit
              id="buttonSavePassword"
              type="submit"
              disabled={props.isValidButton}
              submit={!props.isValidButton}
            >
              Update
            </StyledButtonSubmit>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
}
