import styled from "styled-components";
import { Select } from "@material-ui/core";

export const StyledDivDatePicker = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-family: ${(props) => props.theme.textFont} !important;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 14px;
    line-height: 21px;
    height: 19px;
  }
`;

export const StyledSelect = styled(Select)`
  font-family: ${(props) => props.theme.textFont} !important;
  .MuiSelect-select {
    border-radius: 5px;
  }
  .MuiInputBase-input {
    padding: 5px 0 6px;
  }
  .MuiSelect-select.Mui-disabled {
    background: #f3f6f8;
  }
`;
export const StyledDefaultOption = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #2d2d2d;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem;
`;
export const StyledOption = styled.div`
  font-size: 14px;
  line-height: 21px;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;