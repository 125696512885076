import React from "react";
import styled from "styled-components";
import { Page, Document, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledErrorMessage = styled.div`
  font-size: 12px;
  margin-top: 8px;
  color: ${props => props.theme.errorColor};
`;

type PdfViewerProps = {
  fileUrl: string;
  height: string;
};

export const PdfViewer = React.memo((props: PdfViewerProps) => {
  const StyledPdfView = styled.div`
    // max-width: 100%;
    width: 100%;
    height: ${props.height};
    margin-bottom: 8px;
    overflow-y: scroll;
    overflow-x: hidden;
    .custom-pdf canvas {
      width: 100%!important;
      height: auto!important;
    }
  `;

  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [isPasswordProtectedPdf, setIsPasswordProtectedPdf] = React.useState<boolean>(false);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  return (
    <div className="text-center">
      {!isPasswordProtectedPdf ? (
        <StyledPdfView>
          <Document
            file={{
              url: props.fileUrl,
            }}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
            onPassword={() => setIsPasswordProtectedPdf(true)}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className="custom-pdf"
              />
            ))}
          </Document>
        </StyledPdfView>
      ) : (
        <div className="text-center">
          <StyledErrorMessage>
            This file is protected by a password, please ask the file's owner
            for the password to open it
          </StyledErrorMessage>
        </div>
      )}
      <a
        href={props.fileUrl}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
      >
        View full PDF in new tab
      </a>
    </div>
  );
});
