import React, { Fragment } from "react";

import styled from "styled-components";
import { BNPInput, Input } from "../../../../cores/helpers/input/input";
import { EmployeeBasicInformation, Provinces } from "../employee-type";
import {
  StyledLargerTitle,
  StyledPTitle,
  StyledSelect,
  StyledDefaultOption,
  StyledOption,
  StyledInputMask,
  StyledButtonSubmit,
} from "../../../shared/employee-styled";
import { MenuItem } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { convertTimeZone } from "../../../../cores/utils/helpers";
import GoogleLocationInput, { ResponseLocation } from "../../../../cores/helpers/google-map-services/google-location";
import { BNPSelect } from "../../../../cores/helpers/select/select";

const StyledDivDatePicker = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 14px;
    height: 19px;
  }
`;


type Props = {
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDate: (date: Date | null, name: string) => void;
  handleChangeSelect: (event: any) => void;
  handleChangeSubmit: () => void;
  employeeBasicInformation: EmployeeBasicInformation;
  validateBasicInformation: () => boolean;
  provinces: Provinces[];
  setAddress: (address: string) => void;
  setLocation: (location: ResponseLocation) => void;
  isValid: boolean;
};

export default function SetupBasicInformationView(props: Props) {
 

  return (
    <div className="row">
      <div className="col-12">
        <StyledLargerTitle>Basic Information</StyledLargerTitle>
      </div>

      <Fragment>
        <div className="col-lg-6 mt-3">
              <StyledPTitle className="mb-1">Gender at Birth *</StyledPTitle>
              <StyledSelect
                value={props.employeeBasicInformation.gender || -1}
                onChange={props.handleChangeSelect}
                name="gender"
                inputProps={{ tabIndex: 3 }}
              >
                <MenuItem value={-1}>
                  <StyledDefaultOption className="p-2">
                    Gender at Birth
                  </StyledDefaultOption>
                </MenuItem>
                <MenuItem value="MALE">
                  <StyledOption className="p-2">Male</StyledOption>
                </MenuItem>
                <MenuItem value="FEMALE">
                  <StyledOption className="p-2">Female</StyledOption>
                </MenuItem>
              </StyledSelect>
            </div>
            <div className="col-lg-6 mt-3">
              <StyledPTitle className="mb-1">Date of birth *</StyledPTitle>
              <StyledDivDatePicker>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableFuture
                    InputAdornmentProps={{ position: "start" }}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline1"
                    placeholder="Date of birth"
                    value={
                      props.employeeBasicInformation.dateOfBirth
                        ? convertTimeZone(
                            "Canada/Eastern",
                            props.employeeBasicInformation.dateOfBirth
                          )
                        : null
                    }
                    onChange={(date) =>
                      props.handleChangeDate(date, "dateOfBirth")
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    tabIndex={4}
                  />
                </MuiPickersUtilsProvider>
              </StyledDivDatePicker>
            </div>
            <div className="col-lg-6 mt-3">
              <StyledPTitle className="mb-1">Phone number</StyledPTitle>
              <StyledInputMask
                mask="999-999-9999"
                value={props.employeeBasicInformation.phoneNumber || ""}
                onChange={props.handleChangeInput}
                name="phoneNumber"
                placeholder="Phone number"
                tabIndex={6}
              />
            </div>

            <div className="col-lg-12 mt-3">
              <StyledPTitle className="mb-2">Street</StyledPTitle>
              <GoogleLocationInput
                id="street"
                address={props.employeeBasicInformation.homeAddress || ""}
                setAddress={props.setAddress}
                setLocation={props.setLocation}
                placeholder="Street address"
                tabIndex={7}
              />
            </div>

            <div className="col-lg-4 mt-3">
              <StyledPTitle className="mb-2">Province *</StyledPTitle>
              <BNPSelect
                options={props.provinces}
                placeholder="Province"
                name="provinceId"
                value={props.employeeBasicInformation.provinceId || "-1"}
                onChange={props.handleChangeSelect}
                tabIndex={8}
              />
            </div>
            <div className="col-lg-4 mt-3">
              <StyledPTitle className="mb-2">City</StyledPTitle>
              <BNPInput
                value={props.employeeBasicInformation.cityName || ""}
                onChangeInput={props.handleChangeInput}
                name="cityName"
                placeholder="City"
                tabIndex={9}
              />
            </div>
            <div className="col-lg-4 mt-3">
              <StyledPTitle className="mb-2">Post code</StyledPTitle>
              <StyledInputMask
                mask="*** ***"
                value={props.employeeBasicInformation.postCode || ""}
                onChange={props.handleChangeInput}
                name="postCode"
                placeholder="Post code"
                tabIndex={10}
              />
            </div>
            <div className="col-lg-4 mt-3">
                <StyledPTitle className="mb-1">
                  Social insurance number
                </StyledPTitle>
                <BNPInput
                  placeholder="Social insurance number (Optional)"
                  name="socialInsuranceNumber"
                  type="number"
                  value={props.employeeBasicInformation.socialInsuranceNumber || ""}
                  onChangeInput={props.handleChangeInput}
                  tabIndex={16}
                />
            </div>
        </Fragment>

      <div className="col-lg-12 order-lg-5 mt-5">
        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-7"></div>
            <div className="col-lg-3">
                <StyledButtonSubmit
                  onClick={() => {
                    $("#buttonSaveBasicInformation").attr("disabled", "true");
                    props.handleChangeSubmit();
                  }}
                  disabled={!props.isValid}
                  submit={props.isValid}
                  id="buttonSaveBasicInformation"
                >
                  Continue
                </StyledButtonSubmit>
              </div>
            </div>
          </div>
    </div>
  );

}

