import { AxiosResponse } from "axios";
import { getAsync, postAsync } from "../cores/utils/http-client";
import { getInfoByToken } from "../cores/utils/helpers";
import { IncreaseLimit } from "../components/pages/setup-wizard/employee-type";
import { Debit } from "../components/pages/benefit-wallets/benefit-wallet-type";

const URL_Employee = "/manage-wallet-partner/employee/wallet";

export const getWalletEmployee = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/balances/${getInfoByToken().employeeId}`;

  return getAsync(url, {}, false);
};

/**
 * list all transactions
 * api get all transactions
 * @param employeeId
 */
export const getAllTransactions = (
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/transactions/all/${employeeId}`;

  return getAsync(url);
};

/**
 * get total amount claims has status == Requested
 * @param employeeId
 */

export const getPendingAmountByCategory = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/balances/pending-claim-by-category/${
      getInfoByToken().employeeId
  }`;

  return getAsync(url);
};

export const postIncreaseLimitEmployee = (
  model: IncreaseLimit
): Promise<AxiosResponse> => {
  let url =
    "manage-wallet-partner/partner/wallet/manual/partner/increase/limit/employee";

  return postAsync(url, model);
};

export const postCashDebitPartner = (model: Debit): Promise<AxiosResponse> => {
  let url = "manage-wallet-partner/employee/bank/eft/withdraw/";

  return postAsync(url, model);
};
