import React from 'react';
import {useHistory} from "react-router";
import styled from "styled-components";
import { ArrowBack } from "@material-ui/icons"

const StyledBackButton = styled.button`
    background: none;
    border: none;
    color: ${({theme}) => theme.primaryColor};
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 4px;
    
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;

type Props = {
  path: string,
  params: any
}

const BackButton = ({path, params}: Props) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(path, params);
  };

  return (
    <StyledBackButton onClick={handleClick}>
      <ArrowBack />
      Back
    </StyledBackButton>
  );
};

export default BackButton;
