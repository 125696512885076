import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
  Provinces,
  ResponseEmployeeData,
} from "../../../setup-wizard/employee-type";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import {
  StyledInputMask,
  StyledBigTitle,
} from "../../../../shared/employee-styled";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { StyledButtonSubmit } from "../../../../shared/employee-styled";
import { BNPSelect } from "../../../../../cores/helpers/select/select";
import GoogleLocationInput, {
  ResponseLocation,
} from "../../../../../cores/helpers/google-map-services/google-location";
import { BNPInput } from "../../../../../cores/helpers/input/input";
import { UploadAvatar } from "../../../../../cores/helpers/upload-avatar/upload-avatar";
import { convertTimeZone } from "../../../../../cores/utils/helpers";
import {validFullNameLength} from "../../../../../cores/utils/validation/validate-employee";

const StyledDivImgDependent = styled.div`
  width: max-content;
`;

const StyledImgDependent = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: #4e69be;
  color: #fff;
  text-align: center;
  padding: 1.8rem 0;
`;

const StyledSmallPlusBackgroundImage = styled.div`
  height: 26px;
  width: 26px;
  background-color: #df0b1b;
  border-radius: 50%;
  align-items: center;
  margin-left: 75%;
`;
const StyledSmallPlusImage = styled.div`
  color: #fff;
  font-size: 14px;
`;
const StyledCurrentName = styled.div`
  font-size: 32px;
`;
const StyledDivDatePicker = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 14px;
    height: 19px;
  }
`;
const StyledDivMarginTop = styled.div`
  margin-top: 5%;
`;
const StyledSmallerSpan = styled.div`
  color: #2d2d2d;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-left: 4px;
`;
const StyledAvatarDiv = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 50%;
  position: relative;
`;
const StyledImgAvatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;
const StyledDivAvatarPosition = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 50%;
`;
const StyledSmallPlusBackgroundImageAbsolute = styled.div`
  height: 26px;
  width: 26px;
  background-color: #df0b1b;
  border-radius: 50%;
  align-items: center;
  margin-left: 70%;
  margin-top: -25%;
  position: absolute;
`;
const StyledSmallPlusImageAbsolute = styled.div`
  color: #fff;
  font-size: 14px;
`;

const StyledSpanErrorMessage = styled.span`
  color: ${props => props.theme.errorColor};
  font-size: 14px;
`;

type Props = {
  employee: ResponseEmployeeData;
  handleChangeDate: (date: Date | null, name: string) => void;
  setAvatar: (name: string) => string;
  onSubmit: () => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  provinces: Provinces[];
  handleChangeSelected: (event: any) => void;
  handleChangeInputStreet: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeAvatar: (urlImage: string) => void;
  validateForm: () => boolean;

  setAddress: (address: string) => void;
  setLocation: (location: ResponseLocation) => void;
};

export default function PersonalInformationView(props: Props) {
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const { firstName, lastName } = props.employee;
    if (!validFullNameLength(firstName, lastName))
      setErrorMessage("Full name length could not be larger than 30 letters")
    else {
      setErrorMessage("");
    }
  },[props.employee.firstName, props.employee.lastName]);

  const onChangeAvatar = () => {
    $("#uploadImageAvatar").click();
  };

  let isValid = props.validateForm();
  return (
    <div className="w-100">
      <StyledBigTitle>Personal Information</StyledBigTitle>
      <div className="mt-3">
        {props.employee.profileUrl ? (
          <StyledAvatarDiv>
            <StyledDivAvatarPosition>
              <StyledImgAvatar src={props.employee.profileUrl} alt="avatar" />
            </StyledDivAvatarPosition>
            <StyledSmallPlusBackgroundImageAbsolute>
              <StyledSmallPlusImageAbsolute onClick={onChangeAvatar}>
                <AddOutlinedIcon />
              </StyledSmallPlusImageAbsolute>
            </StyledSmallPlusBackgroundImageAbsolute>
          </StyledAvatarDiv>
        ) : (
          <StyledDivImgDependent>
            <StyledImgDependent>
              <StyledCurrentName>
                {props.setAvatar(
                  props.employee.firstName + " " + props.employee.lastName
                )}
                <StyledSmallPlusBackgroundImage>
                  <StyledSmallPlusImage onClick={onChangeAvatar}>
                    <AddOutlinedIcon />
                  </StyledSmallPlusImage>
                </StyledSmallPlusBackgroundImage>
              </StyledCurrentName>
            </StyledImgDependent>
          </StyledDivImgDependent>
        )}
        <UploadAvatar
          hidden={true}
          addFile={props.handleChangeAvatar}
          folderName="Employee/ProfileUrl"
        />
      </div>
      <div className="row pt-3">
        <div className="col-sm-4 mt-3">
          <BNPInput
            onChangeInput={props.handleChangeInput}
            type="text"
            name="firstName"
            placeholder="First Name"
            value={props.employee.firstName}
            tabIndex={1}
          />
        </div>
        <div className="col-sm-4 mt-3">
          <BNPInput
            onChangeInput={props.handleChangeInput}
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={props.employee.lastName}
            tabIndex={2}
          />
        </div>
        <div className="col-sm-4 mt-3">
          <StyledDivDatePicker>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                disableFuture
                InputAdornmentProps={{ position: "start" }}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline1"
                placeholder="Date of birth"
                value={props.employee.dateOfBirth ?
                  convertTimeZone("Canada/Eastern", props.employee.dateOfBirth)
                  :
                  null}
                onChange={(date) => props.handleChangeDate(date, "dateOfBirth")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                tabIndex={3}
                role="application"
                aria-hidden="true"
              />
            </MuiPickersUtilsProvider>
          </StyledDivDatePicker>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-sm-6 mt-3">
          <BNPInput
            type="text"
            name="email"
            placeholder="Email"
            value={props.employee.email}
            onChangeInput={props.handleChangeInput}
            tabIndex={4}
          />
        </div>
        <div className="col-sm-6 mt-3">
          <StyledInputMask
            mask="999-999-9999"
            value={props.employee.phoneNumber || ""}
            onChange={props.handleChangeInput}
            name="phoneNumber"
            placeholder="Phone number"
            tabIndex={5}
            role="application"
            aria-hidden="true"
          />
        </div>
      </div>

      <StyledDivMarginTop>
        <div className="row">
          <StyledSmallerSpan>Mailing address</StyledSmallerSpan>
          <div className="col-sm-12">
            <GoogleLocationInput
              id="location"
              address={props.employee.employeeLocation.street}
              setAddress={props.setAddress}
              setLocation={props.setLocation}
              placeholder="Street"
              tabIndex={6}
            />
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-sm-4 mt-3">
            <BNPSelect
              options={props.provinces}
              placeholder="Province"
              name="provinceId"
              value={props.employee.employeeLocation.provinceId || "-1"}
              onChange={props.handleChangeSelected}
              tabIndex={7}
            />
          </div>
          <div className="col-sm-4 mt-3">
            <BNPInput
              onChangeInput={props.handleChangeInputStreet}
              type="text"
              name="cityName"
              placeholder="City"
              value={props.employee.employeeLocation.cityName}
              tabIndex={8}
            />
          </div>
          <div className="col-sm-4 mt-3">
            <StyledInputMask
              mask="*** ***"
              value={props.employee.employeeLocation.postCode || ""}
              onChange={props.handleChangeInputStreet}
              name="postCode"
              placeholder="Post code"
              tabIndex={9}
              role="application"
              aria-hidden="true"
            />
          </div>
        </div>
      </StyledDivMarginTop>
      <div className="row mt-3">
        <div className="col-lg-10 ml-auto d-flex align-items-center">
          <StyledSpanErrorMessage>
            {errorMessage}
          </StyledSpanErrorMessage>
        </div>
        <div className="col-lg-2 ml-auto">
          <StyledButtonSubmit
            id="buttonSaveProfile"
            type="submit"
            disabled={!isValid}
            onClick={props.onSubmit}
            submit={isValid}
            tabIndex={10}
          >
            Save
          </StyledButtonSubmit>
        </div>
      </div>
    </div>
  );
}
