import React from "react";
import styled from "styled-components";
import AppStoreLogo from "../../components/assets/images/download-on-the-app-store.png";
import GooglePlayLogo from "../../components/assets/images/get-it-on-google-play.png";
import AppStoreQRCodeImg from "../../components/assets/images/beniplus-employee-app-store-qr-code.png";
import GooglePlayQRCodeImg from "../../components/assets/images/beniplus-employee-google-play-qr-code.png";

const StyledQRCodeCol = styled.div`
  display: flex;
  margin: 15px;
  justify-content: center;
  width: auto !important;
  align-items: center;
}
`

const StyledQRCodeWrapper = styled.div`
  text-align: center;
`

const StyledStoreLogo = styled.img`
  width: auto;
  height: auto;
  max-width: ${(props: any) => props.logoWidth ? props.logoWidth : "auto" };
  max-height: ${(props: any) => props.logoHeight ? props.logoHeight : "auto" };
  margin-bottom: ${(props: any) => props.logoAndQrBetweenMargin ? props.logoAndQrBetweenMargin : "auto" };
`

const StyledQRCodeImg = styled.img`
  width: auto;
  height: auto;
  max-width: ${(props: any) => props.qrWidth ? props.qrWidth : "auto" };
  max-height: ${(props: any) => props.qrHeight ? props.qrHeight : "auto" };
`

const StyledQRBar = styled.div`
  width: 1px;
  height: 215px;
  background-color: #DDDDDD;
  margin: 0px 20px;
`

type QRCodeInfoProps = {
  logoWidth?: string;
  logoHeight?: string;
  qrWidth?: string;
  qrHeight?: string;
  isDisplaySeparateBar?: boolean;
  logoAndQrBetweenMargin?: string;
}

export function QRCodeInfo(props: QRCodeInfoProps) {
  return (
    <StyledQRCodeCol className="col">
        <StyledQRCodeWrapper style={{marginRight: "12.5px"}} >
            <a href="https://apps.apple.com/us/app/beniplus/id6477992402" target="_blank">
            <StyledStoreLogo alt="app-store-logo" src={AppStoreLogo} {...props} />
            </a>
            <div>
            <StyledQRCodeImg alt="app-store-qr-code" src={AppStoreQRCodeImg} {...props}/>
            </div>
        </StyledQRCodeWrapper>
        {props.isDisplaySeparateBar && <StyledQRBar />}
        <StyledQRCodeWrapper style={{marginLeft: "12.5px"}} >
            <a href="https://play.google.com/store/apps/details?id=com.beniplus.ca" target="_blank">
            <StyledStoreLogo alt="google-play-logo" src={GooglePlayLogo} {...props} />
            </a>
            <div>
            <StyledQRCodeImg alt="google-play-qr-code" src={GooglePlayQRCodeImg} {...props} />
            </div>
        </StyledQRCodeWrapper>
    </StyledQRCodeCol>
  )
  
}
