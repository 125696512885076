import React, { useEffect, useState } from "react";
import {
  Balance,
  ClaimData,
  Condition,
  FilterCondition,
  PendingAmount,
  PendingAmountByCategory,
  SearchParamClaim,
} from "./list-claim-type";
import moment from "moment";
import {
  StyledBigTitle,
  StyledButtonSubmit,
  StyledContentBox,
  StyledHiddenForMobile,
  StyledHiddenForWeb,
  StyledMainContent,
} from "../../shared/employee-styled";
import styled, {css} from "styled-components";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {Box, MenuItem} from "@material-ui/core";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import {formatterUSD} from "../../../cores/helpers/format-usd";
import {Link} from "react-router-dom";
import theme from "../../../cores/helpers/theme";
import InfiniteScroll from "react-infinite-scroll-component";
import {centToUsd} from "../../../cores/helpers/cent-to-usd";
import {BenefitCoverage} from "../setup-wizard/employee-type";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import BNPDatePicker from "../../../cores/helpers/date-picker/date-picker";
import {claimManagementURL, walletSearchParam, walletsURL,} from "../../../cores/constants/url-config";
import {FE_DATE_FORMAT} from "../../../cores/utils/format/date-time-format";
import { getCurrentLimit, getExportListEmployeeClaims } from "../../../services/employee-claims-services";
import { WalletBenefit } from "../../shared/components/wallet-benefit/WalletBenefit";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { exportExcel } from "../../../cores/helpers/export-file/export-file";
import {StyledDefaultOption, StyledOption, StyledSelect} from "../../shared/styled";
const StyledSpanDetail = styled.span`
  color: ${(props) => props.theme.primaryColor};
  margin-top: 8px;
`;

const StyledLinkDetail = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
  float: right;
  display: flex;
  :hover {
    text-decoration: none;
  }
`;

const StyledDivBoxClaimLink = styled(Link)`
  display: block;
  width: 100%;
  border-radius: 3px;
  background-color: #f2f2f5;
  padding: 15px 17px;
  margin-top: 0.5rem;
  color: #2d2d2d;

  :hover {
    background-color: #e8edf2;
    text-decoration: none;
    color: #2d2d2d;
  }

  ${(props: { bordercolor: string }) =>
    props.bordercolor &&
    css`
      border-left: 3px solid ${props.bordercolor};
    `}
`;

const StyledDivBoxClaim = styled.div`
  display: block;
  width: 100%;
  border-radius: 3px;
  background-color: #f2f2f5;
  padding: 15px 17px;
  margin-top: 0.5rem;
  color: #2d2d2d;

  :hover {
    background-color: #e8edf2;
    text-decoration: none;
    color: #2d2d2d;
  }

  ${(props: { bordercolor: string }) =>
    props.bordercolor &&
    css`
      border-left: 3px solid ${props.bordercolor};
    `}
`;

const StyledSpanStatus = styled.span`
  color: ${theme.primaryColor};
  font-weight: bold;
  line-height: 22px;
  width: 70%;
`;

const StyledAddClaim = styled.div`
  height: 55px;
  position: fixed;
  left: 0;
  bottom: -5px;
  width: 100%;
  color: #fff;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  margin-top: 1rem;
  a {
    color: #fff;
  }
  z-index: 100;
`;
const StyledBtnAddClaimForMobile = styled(StyledButtonSubmit)`
  border: none;
  border-radius: unset;
`;

const StyledBtnAddClaim = styled(StyledButtonSubmit)`
  background-color: ${(props) => props.theme.primaryColor};
  height: 55px;
  max-width: 150px;
  float: right;
  margin-right: 20px;
`;

const StyledSpanTitle = styled.span`
  font-size: 18px;
  line-height: 32px;
  font-weight: bold;
  color: ${(props) => props.theme.primaryColor};
`;

const StyledContentBoxCustom = styled(StyledContentBox)`
  height: unset;
`;

const StyledSpanLink = styled.span`
  position: relative;
  top: -2px;
`;

const StyledActionButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #e0e0e0;
  border-radius: 5px;
`;

export const StyledDivExportExcel = styled.div`
  color: #007540;
`;

type Props = {
  openingClaimSummary?: ClaimData;
  claimsData: ClaimData[];
  filterConditions: FilterCondition[];
  filter: string | null;
  condition: string | null;
  conditions: Condition[];
  searchParam: SearchParamClaim;
  hasMore: boolean;
  employeeBenefitCoverage: BenefitCoverage[];
  findColorStatusById: (statusId: string) => string;
  findStatusNameById: (statusId: string) => string;
  setFilterConditions: (event: any) => void;
  handleFilterConditionChange: (event: any) => void;
  handleClaimChangeDate: (date: Date | null, name: string) => void;
  fetchMoreData: () => void;
  openReceipt: boolean;
  handleShowClaimModal: (id?: number | number[]) => void;
  // wallet
  pendingAmount: number;
  balances: Balance[];
  proRated: boolean;
  isAvailable: boolean;
  pendingAmountByCategory: PendingAmountByCategory;
  enableMonthlyLimit: boolean;
};

export default function ListClaimView(props: Props) {
  const { enableMonthlyLimit } = props;
  const [currentLimit, setCurrentLimit] = useState(0);
  const [walletUtilization, setWalletUtilization] = useState(0);

  useEffect(() => {
    const utilization = calculateWalletUtilization();
    setWalletUtilization(utilization);
  }, [props.employeeBenefitCoverage]);

  const getBalance = () => {
    return {
      available: getTotalAvailableAmount(),
    }
  };

  // Calculate claimed amount for a wallet
  const calculateClaimedAmount = (limit: number, total: number, pendingAmountByCategory: PendingAmount): number => {
    // Check if pendingAmountByCategory exists and then access its properties, defaulting to 0 if undefined
    const pendingISSClaimAmount = pendingAmountByCategory && pendingAmountByCategory.pendingISSClaimAmount ? pendingAmountByCategory.pendingISSClaimAmount : 0;
    const pendingApproveClaimAmount = pendingAmountByCategory && pendingAmountByCategory.pendingApproveClaimAmount ? pendingAmountByCategory.pendingApproveClaimAmount : 0;

    return (!limit && limit !== 0) ? 0 : 
      Math.max(total - limit - (pendingISSClaimAmount + pendingApproveClaimAmount), 0);
  }

  // Calculate wallet utilization for all wallets
  const calculateWalletUtilization = () => {
    let totalClaimed = 0;
    let totalAllocatedAmount = 0;

    props.employeeBenefitCoverage.forEach((employeeBenefit) => {
      const claimedAmount = calculateClaimedAmount(
        employeeBenefit.limit,
        employeeBenefit.total,
        props.pendingAmountByCategory[employeeBenefit.id]
      );
      let claimedAmountInUsd = centToUsd(claimedAmount);
      let allocatedAmountInUsd = centToUsd(
        props.proRated && employeeBenefit.proRatedAmount !== undefined ?
          employeeBenefit.proRatedAmount : employeeBenefit.total
      )
      totalClaimed += claimedAmountInUsd;
      totalAllocatedAmount += allocatedAmountInUsd;
    })

    const walletUtilization = totalAllocatedAmount > 0 ? (totalClaimed / totalAllocatedAmount) * 100 : 0;
    return walletUtilization;
  }

  // Get availble amount of each wallets
  const getAvailableAmount = (proRated: boolean, proRatedAmount: number|undefined, total: number, limit: number) => {
      let availableAmount = 0;
      if (proRated && proRatedAmount !== undefined) {
        const difference = proRatedAmount - (total - limit);
        availableAmount = difference <= 0 ? 0 : Math.abs(difference);
      } else {
        availableAmount = limit <= 0 ? 0 : limit;
      }
      return availableAmount;
  }

  const getTotalAvailableAmount = () => {
    let sum = 0;
    props.employeeBenefitCoverage.forEach((employeeBenefit) => {
      let availableAmount = getAvailableAmount(
        props.proRated,
        employeeBenefit.proRatedAmount,
        employeeBenefit.total,
        employeeBenefit.limit
      );
      const availableAmountInUSD = centToUsd(availableAmount);
      sum += availableAmountInUSD;
    });
    return sum;
  }

  useEffect(() => {
    const fetchData = async () => {
      if (enableMonthlyLimit) {
        await getCurrentLimit().then(resp => {
          setCurrentLimit(resp.data.currentLimit);
        });
      }
    };
    fetchData();
  }, [enableMonthlyLimit])

  let balance = getBalance();
  return (
    <StyledMainContent>
      <StyledHiddenForMobile>
        <div className="row">
          <div className="col-sm-8">
            <StyledBigTitle>Claim Management</StyledBigTitle>
          </div>
          <div className="col-sm-4">
            {/* button add claim for web */}
            <StyledBtnAddClaim
              title={!props.isAvailable ? "You can only submit a claim after eligibility date" : undefined}
              submit={props.isAvailable}
              onClick={() => props.handleShowClaimModal()}
              disabled={!props.isAvailable}
            >
              Add Claim
            </StyledBtnAddClaim>
          </div>
        </div>
      </StyledHiddenForMobile>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-lg-4 mt-4">
            <StyledContentBoxCustom>
              <div className="row">
                <StyledSpanTitle className="col-9">
                  Available amount{": "}
                  {formatterUSD("currency", "USD").format(
                    balance.available
                  )}
                </StyledSpanTitle>
                <StyledSpanDetail className="col-3">
                  <StyledLinkDetail to={walletsURL + walletSearchParam}>
                    <StyledSpanLink>Detail</StyledSpanLink>
                    <ArrowForwardIosIcon className="icon" />
                  </StyledLinkDetail>
                </StyledSpanDetail>
              </div>
              {enableMonthlyLimit &&
                <div className="mt-2">
                  Current Limit: {formatterUSD("currency", "USD").format(centToUsd(currentLimit))}
                </div>
              }

              <div className="mt-2">
                Pending amount:{" "}
                {formatterUSD("currency", "USD").format(
                  centToUsd(props.pendingAmount)
                )}
              </div>

              <div className="mt-2">
                Wallet utilization:{" "}
                {walletUtilization.toFixed(2)} %
              </div>

              {/* available balance and total vs spent per enrollment */}
              {props.employeeBenefitCoverage.map((employeeBenefit, index) => (
                <WalletBenefit
                  key={index}
                  title={employeeBenefit.name}
                  limit={employeeBenefit.limit}
                  total={employeeBenefit.total ? employeeBenefit.total : 0}
                  proRated={props.proRated}
                  proRatedAmount={employeeBenefit.proRatedAmount}
                  isAvailable={props.isAvailable}
                  pendingAmountByCategory={props.pendingAmountByCategory[employeeBenefit.id]}
                  calculateClaimedAmount={calculateClaimedAmount}
                  getAvailableAmount={getAvailableAmount}
                />
              ))}
            </StyledContentBoxCustom>
          </div>
          <div className="col-lg-8 mt-4">
            <StyledContentBox>
              <div>
                <div className="row">
                  <StyledHiddenForMobile className="col-2">
                    <StyledSpanTitle>Claim</StyledSpanTitle>
                  </StyledHiddenForMobile>
                  <div className="col-lg-2 mt-2">
                    <BNPDatePicker
                      onChange={props.handleClaimChangeDate}
                      value={props.searchParam.from}
                      placeholder="From date"
                      name="from"
                      maxDate={props.searchParam.to}
                      tabIndex={1}
                    />
                  </div>
                  <div className="col-lg-2 mt-2">
                    <BNPDatePicker
                      value={props.searchParam.to}
                      onChange={props.handleClaimChangeDate}
                      placeholder="To date"
                      name="to"
                      minDate={props.searchParam.from}
                      tabIndex={2}
                    />
                  </div>
                  <div className="col-lg-2 mt-2">
                    <BNPSelect
                      options={props.filterConditions}
                      value={props.filter}
                      name="filterCondition"
                      placeholder="Filter by"
                      onChange={props.setFilterConditions}
                      tabIndex={3}
                    />
                  </div>
                  <div className="col-lg-2 mt-2">
                    {props.conditions.length > 0 && (
                      <BNPSelect
                        name={"conditions"}
                        value={props.condition}
                        options={props.conditions}
                        placeholder={"Select available conditions"}
                        onChange={props.handleFilterConditionChange}
                        tabIndex={4}
                      />
                    )}
                  </div>
                  <div className="col-lg-2 mt-2">
                      <StyledSelect
                        value={-1}
                        tabIndex={5}
                      >
                        <MenuItem value={-1}>
                          <StyledDefaultOption>
                            <PrintOutlinedIcon className="icon"/> Export data
                          </StyledDefaultOption>
                        </MenuItem>
                        <MenuItem value="1">
                          <StyledOption
                            onClick={() => {
                              getExportListEmployeeClaims(props.searchParam).then(
                                (result) => {
                                  exportExcel(result.data);
                                }
                              );
                            }}
                          >
                            <StyledDivExportExcel>
                              <SystemUpdateAltOutlinedIcon className="icon"/>{" "}
                              Export via Excel
                            </StyledDivExportExcel>
                          </StyledOption>
                        </MenuItem>
                      </StyledSelect>
                    </div>
                </div>

                <div className="mt-3">
                  {/* <StyledSpanLargerTitle>This month</StyledSpanLargerTitle> */}
                  {(props.claimsData.length === 0 && !props.openingClaimSummary) &&
                      <div className="text-center">No claim</div>}
                  {props.openingClaimSummary && (
                    <div>
                      <StyledHiddenForMobile>
                        <StyledDivBoxClaim
                          bordercolor={props.findColorStatusById(
                            props.openingClaimSummary.claimStatus
                          )}
                          onClick={() =>
                            props.handleShowClaimModal()
                          }
                        >
                          <div className="d-flex">
                            <StyledSpanStatus>
                              {props.findStatusNameById(props.openingClaimSummary.claimStatus)}
                            </StyledSpanStatus>
                            <span className="w-30 text-right op-0-7">
                              {props.openingClaimSummary.submittedDate
                                ? moment(props.openingClaimSummary.submittedDate).format(FE_DATE_FORMAT)
                                : ""}
                            </span>
                          </div>
                          <div className="fw-bold">
                            {formatterUSD("currency", "USD").format(
                              centToUsd(props.openingClaimSummary.totalAmount)
                            )}
                          </div>
                        </StyledDivBoxClaim>
                      </StyledHiddenForMobile>
                      <StyledHiddenForWeb>
                        <StyledDivBoxClaim
                          bordercolor={props.findColorStatusById(
                            props.openingClaimSummary.claimStatus
                          )}
                          onClick={() => props.handleShowClaimModal()}
                        >
                          <div className="d-flex">
                            <StyledSpanStatus>
                              {props.findStatusNameById(props.openingClaimSummary.claimStatus)}
                            </StyledSpanStatus>
                            <span className="w-30 text-right op-0-7">
                              {props.openingClaimSummary.submittedDate
                                ? moment(props.openingClaimSummary.submittedDate).format(FE_DATE_FORMAT)
                                : ""}
                            </span>
                          </div>
                          <div className="fw-bold">
                            {formatterUSD("currency", "USD").format(
                              centToUsd(props.openingClaimSummary.totalAmount)
                            )}
                          </div>
                        </StyledDivBoxClaim>
                      </StyledHiddenForWeb>
                    </div>
                  )}
                  <InfiniteScroll
                    dataLength={props.claimsData.length}
                    next={props.fetchMoreData}
                    hasMore={false}
                    loader={""}
                  >
                    {/* list claim */}
                    {props.claimsData.map((claim, index) =>
                      <StyledDivBoxClaimLink
                        bordercolor={props.findColorStatusById(
                          claim.claimStatus
                        )}
                        key={index}
                        to={`${claimManagementURL}detail/?id=${claim.id}`}
                      >
                        <div className="d-flex">
                          <StyledSpanStatus>
                            {props.findStatusNameById(claim.claimStatus)}
                          </StyledSpanStatus>
                          {claim.extendStatus && <StyledSpanStatus>{claim.extendStatus}</StyledSpanStatus>}
                          <span className="w-30 text-right op-0-7">
                            {claim.submittedDate
                              ? moment(claim.submittedDate).format(FE_DATE_FORMAT)
                              : ""}
                          </span>
                        </div>
                        <div className="fw-bold">
                          {formatterUSD("currency", "USD").format(
                            centToUsd(claim.totalAmount)
                          )}
                        </div>
                      </StyledDivBoxClaimLink>
                    )}
                  </InfiniteScroll>
                  {props.hasMore && (
                    <Box width="100%" marginTop={1} textAlign="center">
                      <StyledActionButton title="load more" onClick={props.fetchMoreData}>
                        Load more...
                      </StyledActionButton>
                    </Box>
                  )}
                </div>
              </div>
            </StyledContentBox>
          </div>
        </div>
        {/* button add claim for mobile */}
        <StyledHiddenForWeb>
          <StyledAddClaim>
            <StyledBtnAddClaimForMobile
              title={!props.isAvailable ? "You can only submit a claim after eligibility date" : undefined}
              onClick={() => props.handleShowClaimModal()}
              submit={props.isAvailable}
              disabled={!props.isAvailable}
            >
              Add Claim
            </StyledBtnAddClaimForMobile>
          </StyledAddClaim>
        </StyledHiddenForWeb>
      </div>
    </StyledMainContent>
  );
}
