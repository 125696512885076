const urls = {
  development: "http://127.0.0.1:5555",
  production: "https://api-prod.beniplus.ca",
} as any;
const API_URL = urls[process.env.NODE_ENV];
export default API_URL;

export const maxAge = "900";

export const homePage = "/";
export const signInURL = "/sign-in/";
export const signUpURL = "/sign-up/";
export const forgotPasswordURL = "/forgot-password/";
export const updatePasswordURL = "/update-password/";
export const unsubcribeURL = "/unsubcribe/**";
export const privacyURL = "/privacy/";
export const supportURL = "/support/";
export const termsOfServicesURL = "/terms-of-services/";

export const claimManagementURL = "/claim-management/";
export const claimManagementDetailURL = "/claim-management/detail/";
export const walletsURL = "/wallets/";
export const profileURL = "/profile/";
export const dependentURL = "/dependent/";
export const notificationUrl = "/notifications/";
export const bankingInfoURL = "/profile/banking-info/";
export const changePasswordURL = "/profile/change-password/";
export const documentsURL = "/documents/";
export const contactURL = "/contact/";
export const marketplaceURL = "/marketplace/";
export const marketplaceDetailURL = "/marketplace/detail/";
export const marketplaceSearchURL = "/marketplace/search/";

// search param default
export const walletSearchParam =
  "?page=1&rowsPerPage=10&sortType=DESC&columnName=createdDate";

// setting
export const setupBasicInformationURL = "/employee/setup/basic-information";
export const setupBenefitURL = "/employee/setup/benefit-coverage";
export const setupBankingURL = "/employee/setup/personal-banking";
export const setupDependentsURL = "/employee/setup/dependents";
export const reviewInformationURL = "/employee/setup/review-information";

// re-allocation
export const reAllocationURL = "/re-allocation";
export const reviewReAllocationURL = "/re-allocation/review";
