import { postAsync, getAsync, putAsync, deleteAsync, getFileAsync } from "../cores/utils/http-client";
import { AxiosResponse } from "axios";
import { ClaimModel } from "../components/pages/receipt/receipt/claim-type";
import { SearchParamClaim } from "../components/pages/view-list-claim/list-claim-type";

const URL_Claim = "/manage-claim/api";

export const getExpenseTypes = (
  enrollmentId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/claims/expense-types/${enrollmentId}`;

  return getAsync(url);
};

export const postCreateClaim = (): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/claims`;

  return postAsync(url, {});
};

export const getCheckDuplicateClaimAmount = (claimId: number): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/claims/check/${claimId}`;

  return getAsync(url);
};

export const getRemoveDuplicateClaimAmount = (claimId: number): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/claims/remove/${claimId}`;

  return deleteAsync(url);
};

export const putSubmitClaim = (model: ClaimModel): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/claims/submit`;

  return putAsync(url, model);
};

///////////////////////////////////////////////////////
export const getAllClaimPolicy = (): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/claims/claim-policy`;

  return getAsync(url);
};

export const getAllClaimByUser = (
  param: SearchParamClaim
): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/employees/claims`;

  return getAsync(url, param);
};

export const getExportListEmployeeClaims = (
  param: SearchParamClaim
): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/employees/export`;

  return getFileAsync(url, {...param, page: null});
};

export const getClaimOpeningSummary = (): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/employees/claimOpeningSummary`;

  return getAsync(url);
};

export const getCurrentLimit = (): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/employees/currentLimit`;
  return getAsync(url);
};

export const getClaimInfoById = (claimId: number): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/employees/claim-info`;

  let param = { claimId: claimId };

  return getAsync(url, param);
};
