export type NotificationType = {
  emailNotificationType: string;
  enabled: boolean;
};

export type NotificationData = {
  ownerNotifications: NotificationType[];
  adminNotifications: NotificationType[];
  employeeNotifications: NotificationType[];
};

export const NotificationDescription: any = {
  EMPLOYEE_CLAIM_REJECT: "Claim Rejected",
  CONFIRMATION_EMPLOYEE: "Claim Submitted",
  NOTIFY_EMPLOYER_NSF: "Non Sufficient Funds",
  NOTIFY_EMPLOYER_IS_PAID: "Claim Paid",
  NOTIFY_EMPLOYER_IS_RRSP_PAID: "RRSP Claim Paid",
  NOTIFY_EMPLOYEE_IS_PAID: "Claim Paid",
  NOTIFY_EMPLOYEE_IS_RRSP_PAID: "RRSP Claim Paid",
  REQUEST_DEPOSIT: "Deposit Requested",
  ADMIN_APPROVED_DEPOSIT: "Deposit Approved",
  REQUEST_WITHDRAW: "Withdraw Requested",
  ADMIN_APPROVED_WITHDRAW: "Withdraw Approved",
  MONTHLY_CLAIM_REPORT: "Monthly Claim Report",
  NOTIFY_EMPLOYER_GROUP_INSURANCE_AUTO_TOPUP_AMOUNT: "Group Insurance Monthly Auto Topup Amount",
};

export const NotificationTypeOwner = [
  "REQUEST_DEPOSIT",
  "ADMIN_APPROVED_DEPOSIT",
  "NOTIFY_EMPLOYER_NSF",
];

export const NotificationTypeAdmin = [
  "NOTIFY_EMPLOYER_IS_PAID",
  "NOTIFY_EMPLOYER_IS_RRSP_PAID",
  "MONTHLY_CLAIM_REPORT",
  "NOTIFY_EMPLOYER_GROUP_INSURANCE_AUTO_TOPUP_AMOUNT",
];

export const NotificationTypeEmployee = [
  "EMPLOYEE_CLAIM_REJECT",
  "CONFIRMATION_EMPLOYEE",
  "NOTIFY_EMPLOYEE_IS_PAID",
  "NOTIFY_EMPLOYEE_IS_RRSP_PAID",
  "REQUEST_WITHDRAW",
  "ADMIN_APPROVED_WITHDRAW",
];
