import React, {useEffect, useMemo} from 'react';
import {StyledMainContent} from "../../shared/employee-styled";
import MarketplaceLandingPage from "./marketplace-landing-page";
import {Option} from "../../../cores/helpers/select/select";
import {getAllServices} from "../../../services/marketplace-service";
import MarketplaceSearchComponent from "./component/marketplace-search-component";
import {RouteComponentProps} from "react-router-dom"
import {SearchVendorParams} from "./marketplace-type";
import {marketplaceSearchURL} from "../../../cores/constants/url-config";
import styled from "styled-components";

const StyledContentBox = styled.div`
  background: #fff;
  padding: 25px 21px;
  border-radius: 5px;
  @media (max-width: 599px) {
    padding: 15px 10px;
  }
  max-width: 1920px;
  margin: 0 auto;
`;

type Props = {} & RouteComponentProps;

export type Location = {
  address: string;
  lat: number;
  lng: number;
};

function Marketplace(props: Props) {
  const previousSearchParams = props.location.state ? props.location.state.searchParams : null;
  const [services, setServices] = React.useState<Option[]>([]);
  const [serviceIds, setServiceIds] = React.useState<number[]>([]);
  const [location, setLocation] = React.useState<Location>({
    address: '',
    lat: 0,
    lng: 0
  });
  const [searchKey, setSearchKey] = React.useState<string>("");
  const [searchParams, setSearchParams] = React.useState<SearchVendorParams>({
    searchKey: "",
    expenseTypeIds: [],
    languageCodes: [],
    ratingFrom: -1,
    workingHour: "",
    distanceOptionId: "ALL",
    distanceFrom: 0,
    distanceTo: null,
    latitude: 0,
    longitude: 0,
    sortBy: [],
    page: 1,
    perPage: 8
  });

  useEffect(() => {
    console.log('Marketplace');
  }, [props.location]);

  useEffect(() => {
    getAllServices().then(res => {
      setServices(res.data);
    });

    if (!!previousSearchParams) {
      setSearchParams(previousSearchParams);
      setServiceIds(previousSearchParams.expenseTypeIds);
      setLocation({
        address: '',
        lat: previousSearchParams.latitude,
        lng: previousSearchParams.longitude
      });
      setSearchKey(previousSearchParams.searchKey);
    }
  }, []);

  const handleSearch = () => {
    const newSearchParams: SearchVendorParams = {
      ...searchParams,
      latitude: location.lat,
      longitude: location.lng,
      expenseTypeIds: serviceIds,
      searchKey: searchKey
    };

    props.history.push({
      pathname: marketplaceSearchURL,
      state: {searchParams: newSearchParams, from: props.location.pathname}
    })
  };

  const marketplaceSearchComponent =
      <MarketplaceSearchComponent
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        services={services}
        serviceIds={serviceIds}
        location={location}
        setServiceIds={setServiceIds}
        setLocation={setLocation}
        handleSearch={handleSearch}
      />;

  return (
    <StyledMainContent>
      <StyledContentBox>
        <div>
          <MarketplaceLandingPage
            searchComponent={marketplaceSearchComponent}
            searchParams={searchParams}
            {...props}
          />
        </div>
      </StyledContentBox>
    </StyledMainContent>
  );
}

export default Marketplace;