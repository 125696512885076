import React, { Component } from "react";
import { Subject, Subscription } from "rxjs";
import Snackbar from "@material-ui/core/Snackbar";
import {
  SnackbarContentWrapper,
  variantIcon
} from "./snackbar-content-wrapper";

// The Main Subject/Stream to be listened on.
const mainSubject = new Subject();
// This function is used to publish data to the Subject via next().
export type MessageData = {
  message: string;
  variant: keyof typeof variantIcon | null;
};
type State = {
  data: MessageData;
};
export const publishMessage = (data: MessageData) => mainSubject.next(data);

export class Message extends Component<any, State> {
  // Used for unsubscribing when our component unmounts

  unsubscribe: Subscription;
  state: State = {
    data: {
      message: "",
      variant: null
    }
  };
  constructor(props: any) {
    super(props);

    this.unsubscribe = mainSubject.subscribe(data => {
      let messageData = data as MessageData;
      if (!messageData.variant) {
        messageData.variant = null;
      }
      this.setState({ data: messageData });
    });
  }
  componentWillUnmount() {
    this.unsubscribe.unsubscribe();
  }

  handleClose = () => {
    this.setState({
      data: {
        message: "",
        variant: null
      }
    });
  };
  render() {
    return this.state.data.variant ? (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        style={{ top: "56px" }}
        open={!!this.state.data.message}
        autoHideDuration={5000}
        onClose={this.handleClose}
        onClick={this.handleClose}
      >
        <SnackbarContentWrapper
          onClose={this.handleClose}
          variant={this.state.data.variant}
          message={this.state.data.message}
        />
      </Snackbar>
    ) : (
      ""
    );
  }
}
