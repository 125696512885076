import React, { Component } from "react";
import { RouteChildrenProps } from "react-router";
import {
  BenefitInfo,
  BenefitData,
  EmployeeLocation
} from "../employee-type";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import {
  getAllInfoEmployee,
  putBenefitInfo,
  getBenefitsForSetUp,
  getEmployeeLocationInfo,
  updateEmployeeLocationInfo
} from "../../../../services/employee-registration-service";
import { centToUsd, usdToCent } from "../../../../cores/helpers/cent-to-usd";
import SetupBenefitCoverageView from "./setup-benefit-coverage-view";
import SetUpEmployeeLocationView from "./set-up-employee-location-view";
import SetUpWizardView from "../setup";
import {
  claimManagementURL,
  setupBankingURL
} from "../../../../cores/constants/url-config";
import { getIsSetupDone } from "../../../../cores/utils/helpers";
import { Redirect } from "react-router-dom";

type State = {
  benefitData: BenefitData;
  benefitInfo: BenefitInfo[];
  isValid: boolean;
  isProRated: boolean;
  proRatedAmount: number;
  availableDate: Date;
  remainDays: number;
  isAvailable: boolean;
  allowEdit: boolean;
  employeeLocation: EmployeeLocation;
};

type Props = RouteChildrenProps;

export default class SetupBenefitCoverage extends Component<Props, State> {
  state: State = {
    benefitData: {
      enrollments: [],
      totalAmount: 0,
    } as BenefitData,

    benefitInfo: [],
    isValid: false,
    isProRated: false,
    proRatedAmount: 0,
    availableDate: new Date(),
    remainDays: 0,
    isAvailable: false,
    allowEdit: true,
    employeeLocation: {
      id: 0,
      street: "",
      cityName: "",
      provinceId: 0,
      provinceName: "",
      postCode: "",
    },
  };

  async componentDidMount() {
    addLoading();
    if (getIsSetupDone() === "True")
      return;

    await this.getBenefitsData();
    await getAllInfoEmployee().then((result) => {
      this.setDataWhenReloadPage(result);
    });
    await this.getEmployeeLocationData();

    removeLoading();
  }

  async getBenefitsData() {
    let result = await getBenefitsForSetUp();
    let benefitData = result.data ? result.data : {};

    this.setState({ benefitData: benefitData });
  }

  async getEmployeeLocationData() {
    let result = await getEmployeeLocationInfo();

    this.setState({ employeeLocation: result.data});
  }

  isValidLocationData = (employeeLocation: EmployeeLocation) => {
    const {street, cityName, provinceId, postCode} = employeeLocation;
    return !!street && !!cityName && !!postCode && provinceId > 0;
  }

  isRequiredLocationData = () => {
    const benefitInfoNameList = this.state.benefitInfo.map(b => {
      if (this.state.benefitData.enrollments.length > 0) {
        const matchedEnrollments = this.state.benefitData.enrollments.find(e => e.id === b.enrollmentId);
        return matchedEnrollments && matchedEnrollments.name;
      }
    });
    const needValidateAddressBenefitInfo = benefitInfoNameList.includes("RRSP") || benefitInfoNameList.includes("Personal Insurance (e.g. life, disability)");
    return needValidateAddressBenefitInfo && !this.isValidLocationData(this.state.employeeLocation);
  }

  setDataWhenReloadPage = (result: any) => {
    let benefitCoverage = [];
    if (result.data.benefitCoverage) {
      if (this.state.benefitData.enrollments.length > 1) {
        if(result.data.benefitCoverage.benefitCoverages.length > 0) {
          // change value usd to cent of amount benefit coverage
          (result.data.benefitCoverage.benefitCoverages as Array<any>).forEach(
            (f) => {
              f.amount = centToUsd(f.amount);
              f.proRatedAmount = centToUsd(f.proRatedAmount);
            }
          );
          benefitCoverage = result.data.benefitCoverage.benefitCoverages;
        } else {
          benefitCoverage.push({
            enrollmentId: this.state.benefitData.enrollments[0].id,
            amount: 0,
            proRatedAmount: 0
          });
        }
      } else {
        const proratedAmount = result.data.proRated ? result.data.proRatedAmount : this.state.benefitData.totalAmount;
        benefitCoverage.push({
          enrollmentId: this.state.benefitData.enrollments[0].id,
          amount: centToUsd(this.state.benefitData.totalAmount),
          proRatedAmount: centToUsd(proratedAmount)
        });
      }
    }

    this.setState({
      benefitInfo: benefitCoverage,
      isProRated: result.data.proRated,
      proRatedAmount: result.data.proRatedAmount,
      availableDate: result.data.availableDate,
      remainDays: result.data.remainDays,
      isAvailable: result.data.available,
      allowEdit: result.data.allowEdit
    });
  };

  //#region Benefit
  handleChangInputBenefit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(event.target.value);

    let benefitInfo: BenefitInfo[] = Object.assign(this.state.benefitInfo);

    let index = benefitInfo.findIndex((benefitInfoItem) => {
      return benefitInfoItem.enrollmentId === Number(event.target.name);
    });

    if(!this.state.isProRated) {
      // Normal flow
      if (index !== -1) {
        if (val < 0) benefitInfo[index].amount = 0;
        else benefitInfo[index].amount = val;
      }
  
      // total amount employee change
      let totalBenefit = this.totalBenefitCoverageAmountLeft(benefitInfo);
  
      // if total < 0 then set amount = 0
      if (totalBenefit < 0) {
        if (index > -1) {
          benefitInfo[index].amount = 0;
        }
      }
    } else {
      // Pro rated flow 
      if (index !== -1) {
        if (val < 0) {
          benefitInfo[index].amount = benefitInfo[index].proRatedAmount = 0;
          this.setState({ benefitInfo });
          return;
        }
        else benefitInfo[index].proRatedAmount = val;
      }

      let totalProRated = this.totalBenefitCoverageProRatedAmountLeft(benefitInfo);

      if (totalProRated < 0) {
        if (index > -1) {
          benefitInfo[index].amount = benefitInfo[index].proRatedAmount = 0;
          this.setState({ benefitInfo });
          return;
        }
      }
      const percent = val * 10000 / (this.state.proRatedAmount === undefined ? 0 : this.state.proRatedAmount);
      benefitInfo[index].amount = Number((this.state.benefitData.totalAmount * percent / 10000).toFixed(2));
      const totalAmountLeft = this.totalBenefitCoverageAmountLeft(benefitInfo);
      if (Math.abs(totalAmountLeft) === 1) {
        benefitInfo[index].amount = Number(((this.state.benefitData.totalAmount * percent) / 10000).toFixed(2)) + Number(totalAmountLeft/100);
      }
    }

    this.setState({ benefitInfo });
  };

  returnNumberOfYear = () => {
    const d = new Date();
    let year = d.getFullYear();

    let isLeapYear = year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
    return isLeapYear ? 366 : 365;
  }

  validateBenefitInfo = (totalAmountLeft: number): boolean => {
   return totalAmountLeft === 0 && !this.isRequiredLocationData();
  };

  handleChangCheckboxBenefit = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.allowEdit) return
    let benefitInfo: BenefitInfo[] = Object.assign(this.state.benefitInfo);
    let isValid = this.state.isValid;

    //if checked = true then add
    if (event.target.checked) {
      let benefitInfoItem: BenefitInfo = {
        id: 0,
        enrollmentId: Number(event.target.name),
        amount: 0,
        proRatedAmount: 0,
        // amountLastYear: 0,
        // proRatedAmountLastYear: 0,
        rolloverAmount: 0
      };
      benefitInfo.push(benefitInfoItem);
    }
    // else remove
    else {
      let index = benefitInfo.findIndex((benefitInfoItem) => {
        return benefitInfoItem.enrollmentId === Number(event.target.name);
      });

      if (index > -1) {
        benefitInfo.splice(index, 1);
        let totalBenefit = this.totalBenefitCoverageAmountLeft(benefitInfo);
        if(totalBenefit !== 0) {
          isValid = false;
        }
      }
    }
    this.setState({
      benefitInfo,
      isValid
    });
  };

  findBenefitInfoById = (benefitId: number): BenefitInfo => {
    let benefitInfo = this.state.benefitInfo.find((benefitInfoItem) => {
      return benefitInfoItem.enrollmentId === benefitId;
    });

    return benefitInfo
      ? benefitInfo
      : {
        enrollmentId: 0,
        amount: 0,
        proRatedAmount: 0,
        // amountLastYear: 0,
        // proRatedAmountLastYear: 0,
        rolloverAmount: 0
      };
  };

  totalBenefitCoverageAmountLeft = (benefitInfo: BenefitInfo[]): number => {
    let totalAmount = this.state.benefitData.totalAmount;

    if(
      this.state.isProRated &&
      (this.state.proRatedAmount === 0 || this.state.remainDays === 0)
    ) {
      totalAmount = 0;
    }

    let amount = centToUsd(totalAmount);

    benefitInfo.forEach((benefitInfoItem) => {
      amount -= benefitInfoItem.amount;
    });

    return usdToCent(amount);
  };

  totalBenefitCoverageProRatedAmountLeft = (benefitInfo: BenefitInfo[]): number => {
    let totalAmount = this.state.remainDays !== 0 ? this.state.proRatedAmount : 0;

    let amount = centToUsd(totalAmount);

    benefitInfo.forEach((benefitInfoItem) => {
      amount -= benefitInfoItem.proRatedAmount;
    });

    return usdToCent(amount);
  };

  handleSubmitBenefitInfo = () => {
    if(!this.state.allowEdit) {
      this.props.history.push(setupBankingURL);
      return;
    }

    let benefit: BenefitInfo[] = [];

    let benefitInfo: BenefitInfo[] = Object.assign([], this.state.benefitInfo);

    for (let index = 0; index < benefitInfo.length; index++) {
      const element = benefitInfo[index];
      if (element.amount !== undefined) {
        benefit.push(element);
      }
    }

    benefit.forEach((f) => {
      f.amount = usdToCent(f.amount);
      f.proRatedAmount = usdToCent(f.proRatedAmount)
    });

    putBenefitInfo(benefit)
      .then(() => {
        this.props.history.push(setupBankingURL);
      })
      .catch(() => {
        $("#buttonSaveBenefit").removeAttr("disabled");
      });
  };

  handleSubmitLocationInfo = async (employeeLocation: EmployeeLocation) => {
    if ( this.isValidLocationData(employeeLocation) ) {
      const result: any = await updateEmployeeLocationInfo(employeeLocation);
      this.setState({ employeeLocation: result.data })
    }
  }

  removeBenefitAmountNull = (benefitInfo: BenefitInfo[]) => {
    let benefit: BenefitInfo[] = [];

    benefitInfo.forEach((benefitItem) => {
      if (benefitItem.amount > 0) {
        benefit.push(benefitItem);
      }
    });

    return benefit;
  };
  //#endRegion Benefit

  render() {
    return getIsSetupDone() === "True" ? (
      <Redirect to={claimManagementURL} />
    ) : (
      <SetUpWizardView
        children={
          <>
          <SetupBenefitCoverageView
            benefitData={this.state.benefitData}
            benefitInfo={this.state.benefitInfo}
            isValid={this.state.isValid}
            findBenefitInfoById={this.findBenefitInfoById}
            totalBenefitCoverageAmountLeft={this.totalBenefitCoverageAmountLeft}
            totalBenefitCoverageProRatedAmountLeft={this.totalBenefitCoverageProRatedAmountLeft}
            handleChangCheckbox={this.handleChangCheckboxBenefit}
            handleChangInput={this.handleChangInputBenefit}
            handleSubmit={this.handleSubmitBenefitInfo}
            validateBenefitInfo={this.validateBenefitInfo}
            isProRated={this.state.isProRated}
            availableDate={this.state.availableDate}
            remainDays={this.state.remainDays}
            isAvailable={this.state.isAvailable}
            allowEdit={this.state.allowEdit}
          />
          {this.isRequiredLocationData() &&
            <SetUpEmployeeLocationView
              employeeLocation={this.state.employeeLocation}
              handleSubmitLocationInfo={this.handleSubmitLocationInfo}
              isValidLocationData={this.isValidLocationData}
            />
          }
          </>
        }
      />
    );
  }
}
