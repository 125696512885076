import React from "react";
import styled from "styled-components";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { subscribe, unsubscribe, checkSubscribe } from "../../../services/common-service";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { IOSSwitch } from "../../../cores/helpers/switch/ios-switch";

const StyledVerifyPage = styled.div`
  height: calc(100vh - 75px);
`;
const StyledVerifiedUserIcon = styled(VerifiedUserIcon)`
  width: 150px !important;
  height: auto !important;
  color: ${props => props.theme.primaryColor};
`;
const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  width: 150px !important;
  height: auto !important;
  color: ${props => props.theme.primaryColor};
`;
const StyledDivGroupVerify = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: center;

  @media (min-width: 320px) {
    top: 50px;
  }
  @media (min-width: 767px) {
    top: 30%;
  }
`;

const StyledRequestMessage = styled.div`
  margin-top: 1rem;
`;

const StyledNoticeMessage = styled.div`
  margin-top: 1rem;
  color: #4fb55d;
`;

const StyledTitleMessage = styled.div`
  color: ${props => props.theme.primaryColor};
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;

  @media (min-width: 320px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (min-width: 1366px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const StyledDescriptionMessage = styled.div`
  color: #2d2d2d;
  margin-top: 18px;
  text-align: center;

  @media (min-width: 320px) {
    font-size: 13px;
    line-height: 17px;
  }
  @media (min-width: 1366px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
type State = {
  message: string;
  isChecked: boolean;
  email: string;
  isExisted: boolean;
};
type Props = {};

export default class Unsubcribe extends React.Component<Props, State> {
  state: State = {
    message: "",
    isChecked: false,
    email: "",
    isExisted: true
  };

  constructor(props: Props){
    super(props);
    this.onSwitchChange = this.onSwitchChange.bind(this);
  }

  async componentDidMount() {
    let pathArr = window.location.href.split('/');
    let email = pathArr[pathArr.length - 1];
    let isChecked = false;
    let isExisted = true;
    
    await checkSubscribe(email.toLowerCase().trim()).then(response => {
      isChecked = response.data;
    }).catch(response => {
      isExisted = false;
    });

    this.setState({ email: email, isChecked: isChecked, isExisted: isExisted });
  }

  async onSwitchChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean)
  {
    let message = "Your settings have been successfully saved!";
    if(checked) {
      await subscribe(this.state.email.toLowerCase().trim());
    } else {
      await unsubscribe(this.state.email.toLowerCase().trim());
    }
    this.setState({ message: message, isChecked: checked });
  };

  render() {
    return (
      <StyledVerifyPage>
        <StyledDivGroupVerify>
        {!this.state.isExisted ?(<>
          <StyledErrorOutlineIcon />
          <StyledRequestMessage>
            <StyledTitleMessage>Email not existed</StyledTitleMessage>
          </StyledRequestMessage>
        </>):(<>
          <StyledVerifiedUserIcon />
          <StyledRequestMessage>
            <StyledTitleMessage>
              Unsubscribe from marketing<br/>notifications transactional emails
            </StyledTitleMessage>
            <StyledDescriptionMessage>
              Transactional emails let you know the activity below
              <StyledNoticeMessage>{this.state.message}</StyledNoticeMessage>
              <IOSSwitch checked={this.state.isChecked} onChange={this.onSwitchChange}/> Marketing emails
            </StyledDescriptionMessage>
          </StyledRequestMessage>
        </>)}
        </StyledDivGroupVerify>
      </StyledVerifyPage>
    );
  }
}
