import React from "react";

import DetailClaimView from "./claim-detail-view";
import {ClaimInfo, Balance, PendingAmountByCategory} from "../list-claim-type";
import { statusClaim, rejectOption } from "../list-claim-model";
import {
  relationships,
  Dependent,
  Relationship,
  BenefitInfo,
  BenefitCoverage,
} from "../../setup-wizard/employee-type";
import { RouteChildrenProps } from "react-router";
import {
  getClaimInfoById,
  getExpenseTypes,
  putSubmitClaim,
  getCheckDuplicateClaimAmount,
  getRemoveDuplicateClaimAmount,
} from "../../../../services/employee-claims-services";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import {
  ReceiptData,
  ClaimPolicy,
  TypeOfExpense,
  ClaimModel,
  ReceiptUpdate,
  ReceiptImageInfo,
} from "../../receipt/receipt/claim-type";
import { publishMessage, MessageData } from "../../../../cores/utils/message";
import { variantIcon } from "../../../../cores/utils/snackbar-content-wrapper";
import {
  getAllReceiptById,
  getDataReceiptById,
  putUpdateReceipt,
  deleteReceipt,
} from "../../../../services/employee-receipt-services";

import {
  showClaimModal,
  closeClaimModal,
  ClaimModal,
} from "../add-claim-modal/claim-modal";
import {
  showReceiptModal,
  closeReceiptModal,
  ReceiptModal,
} from "../add-claim-modal/receipt-modal";
import {
  closeConfirmModal,
  ConfirmDialog,
  showConfirmModal,
} from "../../../../cores/helpers/confirm-modal/confirm-modal";
import { getFileUrl } from "../../../../services/file-service";
import { toUTCTime } from "../../../../cores/helpers/to-utc-time";
import {
  getDataClaim,
  getWalletBalance,
  setTypeOfPlan,
} from "../../receipt/claim-function";
import { centToUsd, usdToCent } from "../../../../cores/helpers/cent-to-usd";
import {
  reAllocationURL
} from "../../../../cores/constants/url-config";
import { setReAllocationStatus } from "../../../../cores/utils/helpers";
import DuplicateClaimModal, { showDuplicateClaimModal } from "../add-claim-modal/claim-duplicate-modal";
import {getPendingAmountByCategory} from "../../../../services/wallet-service";

type Props = RouteChildrenProps;
type State = {
  claimInfo: ClaimInfo;
  claimId: number;
  enrollments: BenefitCoverage[];
  enrollmentsType: BenefitCoverage[];
  receipts: ReceiptData[];
  dependents: Dependent[];
  relationships: Relationship[];
  benefitsInfo: BenefitInfo[];
  claimPolicies: ClaimPolicy[];
  expanseTypes: TypeOfExpense[];
  employeeBenefitCoverage: BenefitCoverage[];
  employeeBenefitCoverageType: BenefitCoverage[];
  stepClaim: number;
  dataSubmitClaim: ClaimModel;
  isValidPolicy: boolean;
  receiptEdit: ReceiptUpdate;
  isOtherExpanse: boolean;
  isEdit: boolean;
  isValidAmount: boolean;
  isConfirmValid: boolean;
  isPdfFile: boolean;
  // wallet
  balances: Balance[];
  isProRated: boolean;
  isAvailable: boolean;
  isAddingMore: boolean;
  pendingAmountByCategory: PendingAmountByCategory
};

export default class DetailClaim extends React.Component<Props, State> {
  state: State = {
    claimInfo: {} as ClaimInfo,
    claimId: 0,

    receipts: [],
    benefitsInfo: [],
    claimPolicies: [],
    dependents: [],
    expanseTypes: [],
    relationships: relationships,
    employeeBenefitCoverage: [],
    employeeBenefitCoverageType: [],
    stepClaim: 0,
    dataSubmitClaim: {
      claimId: 0,
      claimPolicys: [],
    },
    isValidPolicy: false,
    receiptEdit: {
      receiptId: 0,
      amount: 0,
      attachmentUrl: "",
      claimerId: -1,
      claimerType: "",
      employeeBenefitCoverageId: -1,
      expenseDate: null,
      expenseTypeId: -1,
      expenseTypeOther: "",
      notes: "",
      fileUrl: "",
    },
    isOtherExpanse: false,
    isEdit: false,
    isValidAmount: false,
    isConfirmValid: false,
    enrollments: [],
    enrollmentsType: [],
    // wallet
    balances: [],
    isPdfFile: false,
    isProRated: false,
    isAvailable: false,
    isAddingMore: false,
    pendingAmountByCategory: {}
  };

  async componentDidMount() {
    let id = this.props.location.search.split("=")[1];

    if (id) {
      addLoading();

      await this.getClaimInfoById(Number(id));
      let responseClaimData = await getDataClaim();

      setReAllocationStatus(responseClaimData.reAllocationStatus);
      if (responseClaimData.reAllocationStatus === "REQUIRED") {
        this.props.history.push(reAllocationURL);
      }

      this.setState({
        dependents: responseClaimData.dependents,
        benefitsInfo: responseClaimData.benefitsInfo,
        claimPolicies: responseClaimData.claimPolicies,
        employeeBenefitCoverage: responseClaimData.employeeBenefitCoverage,
        enrollments: responseClaimData.enrollments,
        enrollmentsType: responseClaimData.enrollmentsType,
        isProRated: responseClaimData.proRated,
        isAvailable: responseClaimData.isAvailable
      });

      removeLoading();

      this.getWalletBalance();
    }
  }

  //#region  get data

  async getWalletBalance() {
    const [balances, pendingAmountByCategoryResponse] = await Promise.all([getWalletBalance(), getPendingAmountByCategory()]);
    const pendingAmountByCategory = pendingAmountByCategoryResponse.data;
    // set employee benefit coverage
    let employeeBenefitCoverage = setTypeOfPlan(
      this.state.benefitsInfo,
      this.state.enrollments,
      balances ? balances : [],
        pendingAmountByCategory
    );

    // set employee benefit coverage type
    let employeeBenefitCoverageType = setTypeOfPlan(
      this.state.benefitsInfo,
      this.state.enrollmentsType,
      balances ? balances : [],
        pendingAmountByCategory
    );

    this.setState({
      balances,
      employeeBenefitCoverage,
      employeeBenefitCoverageType,
      pendingAmountByCategory,
    });
  }

  async getClaimInfoById(id: number) {
    await getClaimInfoById(id).then((result) => {
      let claimInfo: ClaimInfo = Object.assign({}, this.state.claimInfo);
      claimInfo = result.data;

      if (claimInfo.receipts.length && claimInfo.receipts[0].attachmentUrl)
        getFileUrl(claimInfo.receipts[0].attachmentUrl).then((response) => {
          claimInfo.receipts[0].fileUrl = response.data;

          this.setState({
            claimInfo: claimInfo,
            claimId: Number(id),
          });
        });
      else
        this.setState({
          claimInfo: claimInfo,
          claimId: Number(id),
        });
    });
  }
  // get all receipt by claim
  async getAllReceiptById(id: number) {
    let param = { claimId: id };
    await getAllReceiptById(param).then((result) => {
      let dataSubmitClaim: ClaimModel = Object.assign(
        this.state.dataSubmitClaim
      );
      // set claimId
      dataSubmitClaim.claimId = id;

      let benefitInfo = this.state.benefitsInfo.find((benefitInfo) => {
        return benefitInfo.id === result.data[0].employeeBenefitCoverageId;
      });

      let receiptEdit: ReceiptUpdate = {
        receiptId: result.data[0].id,
        amount: centToUsd(result.data[0].amount),
        attachmentUrl: result.data[0].attachmentUrl,
        claimerId: result.data[0].claimerId,
        claimerType: result.data[0].claimerType,
        employeeBenefitCoverageId: result.data[0].employeeBenefitCoverageId,
        expenseDate: result.data[0].expenseDate,
        expenseTypeId: result.data[0].expenseTypeId,
        expenseTypeOther: result.data[0].expenseTypeOther,
        notes: result.data[0].notes,
        fileUrl: "",
      };

      if (benefitInfo)
        this.getTypeOfExpense(benefitInfo.enrollmentId, receiptEdit, false);

      this.setState({
        receipts: result.data,
        receiptEdit,
        dataSubmitClaim: dataSubmitClaim,
      });
    });
  }

  async getTypeOfExpense(
    enrollmentId: number,
    receipt: ReceiptUpdate,
    isChangeSelect: boolean
  ) {
    let resultExpanseTypes = await getExpenseTypes(enrollmentId);
    let expanseTypes: TypeOfExpense[] = resultExpanseTypes.data
      ? resultExpanseTypes.data
      : [];

    let isOtherExpanse = expanseTypes.some((expanseType) => {
      return (
        expanseType.id === receipt.expenseTypeId && expanseType.name === "Other"
      );
    });

    // if wellnest.
    if (enrollmentId === 2 && isChangeSelect) {
      let expanseType = expanseTypes.find((expanseType) => {
        return expanseType.name === "Other";
      });

      if (expanseType) {
        receipt.expenseTypeId = expanseType.id;
        receipt.expenseTypeOther = "";
        isOtherExpanse = true;
      }
    }

    this.setState({ expanseTypes, isOtherExpanse, receiptEdit: receipt });
  }
  //#endRegion get data

  findNameStatusById = (statusId: string) => {
    let status = statusClaim.find((statusClaim) => {
      return statusClaim.id === statusId;
    });

    return status ? status.name : "";
  };
  findRelationshipById = (relationshipId: string) => {
    let relationship = relationships.find((relationship) => {
      return relationship.id === relationshipId;
    });

    return relationship ? relationship.name : "Me";
  };
  findRejectById = (rejectId: string) => {
    let reject = rejectOption.find((option) => {
      return option.id === rejectId;
    });

    return reject ? reject.name : "";
  };

  // #region claim

  handleShowClaimModal = (id?: number) => {
    // if have claimId
    if (id) {
      //then get all receipt by claimId
      this.getAllReceiptById(id);
    }

    // show claim modal
    showClaimModal();
  };

  handleChangeStepClaim = (step: number) => {
    this.setState({ stepClaim: step });
  };

  handleChangCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    let dataSubmitClaim = Object.assign({}, this.state.dataSubmitClaim);

    // if checked = true then add value checked
    if (checked) {
      dataSubmitClaim.claimPolicys.push(Number(value));
    } else {
      // remove value checked
      let index = dataSubmitClaim.claimPolicys.indexOf(Number(value));
      if (index > -1) {
        dataSubmitClaim.claimPolicys.splice(index, 1);
      }
    }

    // validate data
    let isValid = this.validateSubmitClaim(dataSubmitClaim);
    this.setState({ dataSubmitClaim: dataSubmitClaim, isValidPolicy: isValid });
  };

  validateSubmitClaim = (dataSubmitClaim: ClaimModel) => {
    if (dataSubmitClaim.claimPolicys.length === this.state.claimPolicies.length)
      return true;
    return false;
  };

  showReceiptModalEdit = (receipt: ReceiptData) => {
    let benefitInfo = this.state.benefitsInfo.find((benefitInfo) => {
      return benefitInfo.id === receipt.employeeBenefitCoverageId;
    });

    let receiptEdit: ReceiptUpdate = {
      receiptId: receipt.id,
      amount: receipt.amount / 100,
      attachmentUrl: receipt.attachmentUrl,
      claimerId: receipt.claimerId,
      claimerType: receipt.claimerType,
      employeeBenefitCoverageId: benefitInfo ? benefitInfo.enrollmentId : -1,
      expenseDate: receipt.expenseDate,
      expenseTypeId: receipt.expenseTypeId ? receipt.expenseTypeId : -1,
      expenseTypeOther: receipt.expenseTypeOther,
      notes: receipt.notes,
      fileUrl: "",
    };

    getFileUrl(receipt.attachmentUrl).then((response) => {
      receiptEdit.fileUrl = response.data;

      let isPdf = false;

      if (receipt.attachmentUrl.match("/*.pdf")) {
        isPdf = true;
      }

      this.setState({
        receiptEdit: receiptEdit,
        isEdit: false,
        isPdfFile: isPdf,
      });

      removeLoading();
    });
    closeClaimModal();
    showReceiptModal();
  };

  handleSubmitClaim = async () => {
    const res = await getCheckDuplicateClaimAmount(this.state.claimId);
    if (res.status === 200) {
      if (res.data.accept) {
        this.submitClaim(true)
      } else {
        showDuplicateClaimModal()
      }
    }
  };

  submitClaim = async (check: boolean) => {
    if (check) {
      this.setState({ isValidPolicy: false });
      putSubmitClaim(this.state.dataSubmitClaim).then(() => {
        this.refresh()
        // close modal
        closeClaimModal();
        //show message success
        this.message("Your claim has been submitted successfully.", "success");
      });
    } else {
      const res = await getRemoveDuplicateClaimAmount(this.state.claimId);
      if (res.status === 204) {
        this.refresh()
        this.message("Your claim has been disabled.", "success");
      }
    }
  }

  refresh = () => {
    // get again list claim
    this.getClaimInfoById(this.state.claimId);

    // set default value receipt when edit
    this.setDefaultReceipt();
    // set default data submit claim
    this.setDefaultClaim();
    this.setState({ stepClaim: 0, isEdit: false });
  }
  //#endRegion claim

  //#region receipt
  handleChangeFile = (data: ReceiptImageInfo) => {
    closeClaimModal();

    this.createClaim(data);
  };

  async createClaim(data: any) {
    let dataSubmitClaim = Object.assign({}, this.state.dataSubmitClaim);
    let receiptEdit: ReceiptUpdate = Object.assign(this.state.receiptEdit);

    // get amount receipt
    let resultAmount = await getDataReceiptById(data.id);

    // set data
    if (resultAmount.data) {
      receiptEdit.amount = resultAmount.data.amount / 100;
      receiptEdit.attachmentUrl = resultAmount.data.attachmentUrl;
      receiptEdit.receiptId = data.id;
      receiptEdit.claimerId = -1;
    }
    dataSubmitClaim.claimId = resultAmount.data.claimId;

    getFileUrl(resultAmount.data.attachmentUrl).then((response) => {
      receiptEdit.fileUrl = response.data;

      this.setState({ dataSubmitClaim, receiptEdit, isEdit: true });
    });

    removeLoading();

    showReceiptModal();
  }

  handleChangeClaimer = (event: any) => {
    const value = Number(event.target.value);
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);

    // check value != -1
    if (value !== -1) {
      //if value = 0 set claimerType = EMPLOYEE
      if (value === 0) {
        receipt.claimerType = "EMPLOYEE";
      } else {
        // set claimerType = DEPENDENTS
        receipt.claimerType = "DEPENDENTS";
      }
      // set claimerId = value
      receipt.claimerId = value;
    } else {
      receipt.claimerId = -1;
      receipt.claimerType = "";
    }

    this.setState({ receiptEdit: receipt });
  };

  handleChangeExpanseType = (event: any) => {
    const value = Number(event.target.value);
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);

    let isOtherExpanse = false;

    let expanseType = this.state.expanseTypes.find((f) => {
      return f.id === value;
    });

    // if value = (Other)
    if (expanseType) {
      if (expanseType.name === "Other") {
        isOtherExpanse = true;
      }
      receipt.expenseTypeOther = "";
    }

    // set expenseTypeId
    receipt.expenseTypeId = value;

    this.setState({
      receiptEdit: receipt,
      isOtherExpanse: isOtherExpanse,
    });
  };

  handleChangeEmployeeBenefitCoverage = (event: any) => {
    const value = Number(event.target.value);
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);

    receipt.employeeBenefitCoverageId = value;
    // get type of expense
    if (value > -1) {
      if (value === 2) this.getTypeOfExpense(value, receipt, true);
      else this.getTypeOfExpense(value, receipt, false);
    }

    this.setState({ receiptEdit: receipt });
  };

  handleChangInput = (event: any) => {
    const { name, value } = event.target;

    let receipt = Object.assign(this.state.receiptEdit);
    let isValidAmount = false;

    receipt[name] = value;

    // if amount then check value > 0
    if (name === "amount" && Number(value) < 0) {
      // show error message
      isValidAmount = true;
    }

    this.setState({
      receiptEdit: receipt,
      isValidAmount: isValidAmount,
    });
  };

  handleChangeDate = (date: Date | null) => {
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);

    receipt.expenseDate = date;

    this.setState({ receiptEdit: receipt });
  };

  validateEdit = () => {
    if (!this.state.isOtherExpanse) {
      if (
        this.state.receiptEdit.amount > 0 &&
        this.state.receiptEdit.claimerId > -1 &&
        this.state.receiptEdit.employeeBenefitCoverageId > 0 &&
        this.state.receiptEdit.expenseDate &&
        this.state.receiptEdit.expenseTypeId > 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.state.receiptEdit.amount > 0 &&
        this.state.receiptEdit.claimerId > -1 &&
        this.state.receiptEdit.employeeBenefitCoverageId > 0 &&
        this.state.receiptEdit.expenseDate &&
        this.state.receiptEdit.expenseTypeId > 0 &&
        this.state.receiptEdit.expenseTypeOther
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleEdit = (isEdit: boolean) => {
    this.setState({ isEdit: isEdit });
  };

  updateReceipt = () => {
    let currentBenefitCoverage = this.state.employeeBenefitCoverageType.find(
      (m) => m.id === this.state.receiptEdit.employeeBenefitCoverageId
    ) as BenefitCoverage;

    let limit = currentBenefitCoverage.limit;
    if (this.state.isProRated) {
      let proRateAmount = currentBenefitCoverage.proRatedAmount ? currentBenefitCoverage.proRatedAmount : 0;
      limit = proRateAmount - (currentBenefitCoverage.total - currentBenefitCoverage.limit)
    }

    if (limit / 100 < this.state.receiptEdit.amount) {
      let isConfirmValid = true;
      this.setState({ isConfirmValid });
      showConfirmModal();
    } else {
      this.handleSubmitReceipt();
    }
  };

  handleSubmitReceipt = () => {
    let receipt: ReceiptUpdate = Object.assign({}, this.state.receiptEdit);
    // set amount save
    receipt.expenseDate = receipt.expenseDate ? toUTCTime(new Date(receipt.expenseDate)) : toUTCTime(new Date());
    receipt.amount = usdToCent(this.state.receiptEdit.amount);

    let benefitInfo = this.state.benefitsInfo.find((benefitInfo) => {
      return benefitInfo.enrollmentId === receipt.employeeBenefitCoverageId;
    });
    if (benefitInfo && benefitInfo.id) {
      receipt.employeeBenefitCoverageId = benefitInfo.id;
    }
    putUpdateReceipt(receipt).then(() => {
      this.message("Your receipt has been edited successfully.", "success");

      // get again all receipt
      this.getAllReceiptById(this.state.dataSubmitClaim.claimId);
      // set default receipt edit
      this.setDefaultReceipt();

      this.setState({ isConfirmValid: false, isAddingMore: false });
      closeConfirmModal();
      closeReceiptModal();
      showClaimModal();
    });
  };

  handleChangeEditImage = (data: any) => {
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);
    // set attachmentUrl
    receipt.attachmentUrl = data.attachmentUrl;

    getFileUrl(data.attachmentUrl).then((response) => {
      receipt.fileUrl = response.data;

      this.setState({ receiptEdit: receipt });
    });

    // get amount
    this.getAmount(data.id);
  };

  async getAmount(receiptId: number) {
    let receipt: ReceiptUpdate = Object.assign(this.state.receiptEdit);
    // get amount receipt
    let resultAmount = await getDataReceiptById(receiptId).then();

    // set amount show UI
    receipt.amount = resultAmount.data.amount / 100;

    this.setState({ receiptEdit: receipt });
  }

  deleteReceipt = (receiptId: number) => {
    deleteReceipt(receiptId).then(() => {
      let receipts: ReceiptData[] = Object.assign(this.state.receipts);

      // find index receipt have to remove
      let index = receipts.findIndex((receipt) => {
        return receipt.id === receiptId;
      });
      // remove out of list
      receipts.splice(index, 1);

      this.setState({ receipts: receipts });

      // get again list claim
      this.getClaimInfoById(this.state.claimId);
      this.setDefaultReceipt();
      closeConfirmModal();
      showClaimModal();
      this.message("You have successfully deleted the receipt.", "success");
    });
  };

  deleteAllReceipts = () => {}

  addMoreReceipt = () => {
    this.setState({isAddingMore: true})

    // choose image receipt
    // $("#uploadReceipt").click();
  };

  setDefaultReceipt = () => {
    let receiptEdit: ReceiptUpdate = {
      receiptId: 0,
      amount: 0,
      attachmentUrl: "",
      claimerId: -1,
      claimerType: "",
      employeeBenefitCoverageId: -1,
      expenseDate: null,
      expenseTypeId: -1,
      expenseTypeOther: "",
      notes: "",
      //todo
      fileUrl: "",
    };
    this.setState({ receiptEdit });
  };
  setDefaultClaim = () => {
    let dataSubmitClaim: ClaimModel = {
      claimId: 0,
      claimPolicys: [],
    };
    this.setState({ dataSubmitClaim });
  };

  handleCloseClaimModal = () => {
    this.setDefaultReceipt();
    this.setState({isAddingMore: false});
    closeClaimModal();
  };

  handleCloseReceiptModal = () => {
    if (this.state.isEdit) {
      this.getAllReceiptById(this.state.dataSubmitClaim.claimId);
    }

    closeReceiptModal();
    showClaimModal();
  };

  //#endRegion receipt

  message = (message: string, type: keyof typeof variantIcon) => {
    let data = {} as MessageData;
    data.message = message;
    data.variant = type;
    publishMessage(data);
  };
  namePlanTypeOfEmployee = () => {
    let currentBebefit = this.state.employeeBenefitCoverage.find(
      (m) => m.id === this.state.receiptEdit.employeeBenefitCoverageId
    );
    return currentBebefit ? currentBebefit.name : "";
  };

  findEnrollmentIdFromTypeOfClaim = (typeOfClaim: string) => {
    if (this.state.enrollments) {
      const enrollment = this.state.enrollments.find((enrollment: any) => enrollment.name === typeOfClaim);
      return enrollment ? enrollment.id : -1;
    }
    return -1
  }

  findLimitAmount = (typeOfClaim: string) => {
    if(this.state.enrollments && this.state.pendingAmountByCategory && this.state.pendingAmountByCategory[this.findEnrollmentIdFromTypeOfClaim(typeOfClaim)]) {
      return this.findLimitAmountLegacy(typeOfClaim) -
          this.state.pendingAmountByCategory[this.findEnrollmentIdFromTypeOfClaim(typeOfClaim)].pendingApproveClaimAmount -
          this.state.pendingAmountByCategory[this.findEnrollmentIdFromTypeOfClaim(typeOfClaim)].pendingISSClaimAmount
    }
    return this.findLimitAmountLegacy(typeOfClaim)
  }

  findLimitAmountLegacy = (typeOfClaim: string) => {
    switch (typeOfClaim) {
      case "Healthcare Spending Account": {
        let hsaLimit = this.state.balances.find((m) => {
          return m.accountName === "hsa_limit";
        });

        return hsaLimit ? hsaLimit.balance : 0;
      }
      case "Wellness Spending Account": {
        let wellnessLimit = this.state.balances.find((m) => {
          return m.accountName === "wellness_limit";
        });
        return wellnessLimit ? wellnessLimit.balance : 0;
      }
      case "Personal Insurance (e.g. life, disability)": {
        let insuranceLimit = this.state.balances.find((m) => {
          return m.accountName === "insurance_limit";
        });
        return insuranceLimit ? insuranceLimit.balance : 0;
      }
      case "Charitable Giving": {
        let charityLimit = this.state.balances.find((m) => {
          return m.accountName === "charity_limit";
        });
        return charityLimit ? charityLimit.balance : 0;
      }
      case "RRSP": {
        let rrspLimit = this.state.balances.find((m) => {
          return m.accountName === "rrsp_limit";
        });
        return rrspLimit ? rrspLimit.balance : 0;
      }
      case "Canada Revenue Agency": {
        let craLimit = this.state.balances.find((m) => {
          return m.accountName === "cra_limit";
        });
        return craLimit ? craLimit.balance : 0;
      }
      default:
        return 0;
    }
  };

  findProRatedAmount = (typeOfClaim: string): number => {
    let benefit = this.state.employeeBenefitCoverage.find(m => m.name === typeOfClaim);
    return !benefit ? 0 : !benefit.proRatedAmount ? 0 : benefit.proRatedAmount;
  }

  findTotalAmountOfEnrollment = (typeOfClaim: string): number => {
    let benefit = this.state.employeeBenefitCoverage.find(m => m.name === typeOfClaim);
    return !benefit ? 0 : benefit.total;
  }

  render() {
    return (
      <div className="col-12">
        <DetailClaimView
          findNameStatusById={this.findNameStatusById}
          findRelationshipById={this.findRelationshipById}
          claimInfo={this.state.claimInfo}
          findRejectById={this.findRejectById}
          handleShowClaimModal={this.handleShowClaimModal}
          findLimitAmount={this.findLimitAmount}
          isProRated={this.state.isProRated}
          findProRatedAmount={this.findProRatedAmount}
          findTotalAmountOfEnrollment={this.findTotalAmountOfEnrollment}
          isAvailable={this.state.isAvailable}
        />
        {/* claim modal add/edit claim */}
        <ClaimModal
          dependents={this.state.dependents}
          expanseTypes={this.state.expanseTypes}
          receipts={this.state.receipts}
          relationships={this.state.relationships}
          step={this.state.stepClaim}
          validateEdit={this.validateEdit}
          claimPolicies={this.state.claimPolicies}
          dataSubmitClaim={this.state.dataSubmitClaim}
          isValidPolicy={this.state.isValidPolicy}
          handleChangCheckbox={this.handleChangCheckbox}
          handleChangeFile={this.handleChangeFile}
          handleChangeStepClaim={this.handleChangeStepClaim}
          handleSubmitClaim={this.handleSubmitClaim}
          showReceiptModalEdit={this.showReceiptModalEdit}
          addMoreReceipt={this.addMoreReceipt}
          handleCloseClaimModal={this.handleCloseClaimModal}
          isAddingMore={this.state.isAddingMore}
          deleteReceipt={this.deleteReceipt}
          deleteAllReceipts={this.deleteAllReceipts}
        />
        {/* receipt modal add/edit receipt */}
        <ReceiptModal
          claimId={this.state.dataSubmitClaim.claimId}
          folderName="Employee/Claim"
          deleteReceipt={this.deleteReceipt}
          dependents={this.state.dependents}
          employeeBenefitCoverage={this.state.employeeBenefitCoverageType}
          expanseTypes={this.state.expanseTypes}
          handleChangInput={this.handleChangInput}
          handleChangeClaimer={this.handleChangeClaimer}
          handleChangeDate={this.handleChangeDate}
          handleChangeEditImage={this.handleChangeEditImage}
          handleChangeEmployeeBenefitCoverage={
            this.handleChangeEmployeeBenefitCoverage
          }
          handleChangeExpanseType={this.handleChangeExpanseType}
          handleEdit={this.handleEdit}
          handleSubmit={this.updateReceipt}
          isEdit={this.state.isEdit}
          isOtherExpanse={this.state.isOtherExpanse}
          validateEdit={this.validateEdit}
          isValidAmount={this.state.isValidAmount}
          receiptEdit={this.state.receiptEdit}
          relationships={this.state.relationships}
          handleCloseReceiptModal={this.handleCloseReceiptModal}
          isPdfFile={this.state.isPdfFile}
          statusClaim={this.state.claimInfo.status}
          proRated={this.state.claimInfo.isProRated}
          isAvailable={this.state.isAvailable}
        />
        <DuplicateClaimModal
          submitClaim={this.submitClaim} />
        {
          this.state.isConfirmValid ? (
            <ConfirmDialog
              title="Warning"
              content={
                "You are submitting the total claim more than the amount that you set to " +
                this.namePlanTypeOfEmployee() +
                ". Do you want to continue?"
              }
              handleOnOk={this.handleSubmitReceipt}
            />
          ) : (
              <ConfirmDialog
                title="Delete"
                content="Are you sure you want to delete this receipt?"
                handleOnOk={() =>
                  this.deleteReceipt(this.state.receiptEdit.receiptId)
                }
              />
            )
        }
      </div>
    );
  }
}
