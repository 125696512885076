import {
  postAsync,
  getAsync,
  putAsync,
  deleteAsync
} from "../cores/utils/http-client";
import { AxiosResponse } from "axios";
import {
  Receipt,
  ReceiptUpdate
} from "../components/pages/receipt/receipt/claim-type";

const URL_Claim = "/manage-claim/api";

export const getAllReceiptById = (param: {
  claimId: number;
}): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/receipts`;

  return getAsync(url, param);
};

export const postCreateReceipts = (model: Receipt): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/receipts`;

  return postAsync(url, model);
};

export const putUpdateReceipt = (
  model: ReceiptUpdate
): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/receipts`;

  return putAsync(url, model);
};

export const deleteReceipt = (id: number): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/receipts/${id}`;

  return deleteAsync(url);
};

export const postUploadImageReceipts = (
  model: FormData
): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/receipts/upload-receipt`;

  return postAsync(url, model);
};

export const getDataReceiptById = (
  receiptId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/receipts/${receiptId}`;

  return getAsync(url);
};
