import React, {Fragment} from "react";
import {Modal} from "@material-ui/core";
import styled from "styled-components";
import {
  StyledButtonCloseModal,
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledIconCloseModal
} from "../employee-styled";

type ModalType = {
  modalsize?: "sm" | "md" | "lg";
}

const StyledModal = styled(Modal)<ModalType>`
    max-width: ${props => !!props.modalsize ?
            (props.modalsize === "sm" ? "400px" :
                    props.modalsize === "md" ? "600px" :
                            "800px") :
            "1000px"};
    margin: 1.75rem auto;
    position: relative;
`;

const StyledModalContent = styled.div`
    max-height: 90vh;
`

const StyledModalBody = styled.div`
    min-height: 300px;
`;

type BNPModalProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  body: React.ReactElement;
  footer: React.ReactElement;
  modalSize?: "sm" | "md" | "lg";
};

export function BNPModal(props: BNPModalProps) {
  return (
    <Fragment>
      <StyledModal modalsize={props.modalSize} open={props.open} onClose={props.handleClose}>
        <StyledModalContent className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>{props.title}</StyledHeaderTitleModal>
            <StyledButtonCloseModal type="button" onClick={props.handleClose}>
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <StyledModalBody className="mh-100 overflow-auto modal-body">
            {props.body}
          </StyledModalBody>
          <div className="modal-footer">
            {props.footer}
          </div>
        </StyledModalContent>
      </StyledModal>
    </Fragment>
  );
}
