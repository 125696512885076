import React from "react";
import styled, { css } from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledButtonSubmit,
} from "../../../shared/employee-styled";
import {
  ReceiptData,
  TypeOfExpense,
  ClaimPolicy,
  ClaimModel,
  ReceiptImageInfo,
} from "../../receipt/receipt/claim-type";
import moment from "moment";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { Dependent, Relationship } from "../../setup-wizard/employee-type";
import { CheckBox } from "../../../../cores/helpers/checkbox/checkbox";
import {
  findExpanseTypesById,
  findClaimerById,
} from "../../receipt/claim-function";
import UploadImage from "../../../../cores/helpers/file-upload-receipt/upload-image";
import {FE_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";
import { Box } from "@material-ui/core";

const StyledFormUpload = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: 1rem;

  input {
    color: transparent;
    width: 100%;
    height: 100px;
    float: right;
  }
  input::-webkit-file-upload-button {
    visibility: hidden;
  }
  input::before {
    content: "Add your receipt";
    color: ${(props) => props.theme.primaryColor};
    background-color: rgba(242, 242, 245, 0.4);
    display: inline-block;
    border: 1px solid #ecedf0;
    border-radius: 3px;
    padding: 2.3rem;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    width: 100%;
    height: 100px;
    text-align: center;
  }

  input:active {
    outline: 0;
  }
  input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`;

const StyledDivSubmit = styled.div`
  position: relative;
  width: 100%;
  margin-top: 3rem;
  @media (min-width: 992px) {
    margin: 3rem auto 0;
  }
`;
const StyledDivBoxClaim = styled.div`
  width: 100%;
  height: 100px;
  margin-top: 1rem;
  background-color: rgba(242, 242, 245, 0.4);
  border: 1px solid #ecedf0;
  padding: 15px 17px;
  border-radius: 3px;
  cursor: pointer;
`;

const StyledSpan = styled.span`
  color: ${(props) => props.theme.primaryColor} !important;
  font-weight: bold;
`;

const StyledSpanCancel = styled.span`
  color: #003049;
  font-weight: bold;
  cursor: pointer;
`;

const StyledSpanDelete = styled.span`
  color: ${props => props.theme.secondaryColor};
  cursor: pointer;
`;

export const StyledButtonDelete = styled.button`
  width: 100%;
  position: relative;
  background-color: #f9fbfd;
  border: 1px solid #ecedf0;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  color: #96aac9;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 50px;

  ${(props: { submit: boolean }) =>
    props.submit &&
    css`
      background-color: ${(props) => props.theme.secondaryColor};
      color: #ffffff;

      :hover {
        opacity: 0.8;
      }
      a {
        color: #ffffff;
      }
    `}
`;

const StyledModalDialog = styled.div`
  text-align: left;
  max-width: 600px;
  margin: 1.75rem auto;
  width: 100%;
  pointer-events: none;
  
  @media(max-width: 599px) {
    width: 100vw;
    height: 100vh;
    margin: 0;
    .modal-content {
      height: 118%;
    }
  }
`;

const StyledActionButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #e0e0e0;
  border-radius: 5px;
`;

const StyledDivRemove = styled.div`
  color: ${(props) => props.theme.secondaryColor};
  width: 50%;
`;
const StyledDivAdd = styled.div`
  width: 50%;
  text-align: right;
  color: ${(props) => props.theme.primaryColor};
`;

type Props = {
  step: number;
  receipts: ReceiptData[];
  dependents: Dependent[];
  relationships: Relationship[];
  expanseTypes: TypeOfExpense[];
  claimPolicies: ClaimPolicy[];
  dataSubmitClaim: ClaimModel;
  isValidPolicy: boolean;
  handleChangeFile: (data: ReceiptImageInfo) => void;
  handleChangCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitClaim: () => void;
  handleChangeStepClaim: (step: number) => void;
  showReceiptModalEdit: (receipt: ReceiptData) => void;
  addMoreReceipt: () => void;
  handleCloseClaimModal: () => void;
  validateEdit: () => boolean;
  isAddingMore?: boolean;
  statusClaim?: string;
  deleteReceipt: (receiptId: number) => void;
  deleteAllReceipts: () => void;
};

export function ClaimModal(props: Props) {
  const findCheckedById = (claimPolicyId: number) => {
    let index = props.dataSubmitClaim.claimPolicys.indexOf(claimPolicyId);

    if (index > -1) return true;
    return false;
  };

  let isValid = props.validateEdit();

  return (
    <div className="modal" id="addClaimModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          {/* header */}
          <StyledHeaderModal>
            <StyledHeaderTitleModal>
              {props.step ? "Agreement" : (props.statusClaim === "OPEN" ? "Add Claims" : "Receipt Management")}
            </StyledHeaderTitleModal>

            <StyledButtonCloseModal
              name="btn-close"
              onClick={props.handleCloseClaimModal}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          {/* end header */}
          {/* body */}
          <div className="modal-body">
            <div className="row">
              {props.step ? (
                <div className="col-12">
                  <div>
                    Before you can submit your claim, you need to agree on these
                    following terms and conditions:
                  </div>
                  {props.claimPolicies.map((claimPolicy, index) => (
                    <div className="mt-4" key={index}>
                      <CheckBox
                        title={claimPolicy.description}
                        checked={findCheckedById(claimPolicy.id)}
                        value={claimPolicy.id}
                        onChangeCheckbox={props.handleChangCheckbox}
                      />
                    </div>
                  ))}

                  <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                      <StyledButtonSubmit
                        submit={props.isValidPolicy}
                        disabled={!props.isValidPolicy}
                        className="mt-4"
                        onClick={props.handleSubmitClaim}
                      >
                        Agree and Continue
                      </StyledButtonSubmit>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 text-center mt-3">
                      <StyledSpanCancel
                        onClick={() => {
                          props.handleChangeStepClaim(0);
                        }}
                      >
                        Cancel
                      </StyledSpanCancel>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-12">
                  {props.receipts.length > 0 ? (
                    <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
                      {props.receipts.map((receipt, index) => (
                        <StyledDivBoxClaim
                          key={index}
                          onClick={() => props.showReceiptModalEdit(receipt)}
                        >
                          <div className="w-100">
                            <StyledSpan>
                              {findExpanseTypesById(
                                receipt.expenseTypeId,
                                props.expanseTypes
                              )}
                            </StyledSpan>
                            <span className="float-right op-0-7">
                              {receipt.expenseDate
                                ? moment(receipt.expenseDate).format(FE_DATE_FORMAT)
                                : ""}
                            </span>
                          </div>
                          <div className="mt-1">
                            {findClaimerById(
                              receipt.claimerId,
                              props.dependents
                            ).firstName +
                              " " +
                              findClaimerById(
                                receipt.claimerId,
                                props.dependents
                              ).lastName}
                          </div>
                          <div className="w-100">
                            <span className="fw-bold">
                              {receipt.amount
                                ? formatterUSD("currency", "USD").format(
                                    receipt.amount / 100
                                  )
                                : ""}
                            </span>
                            <StyledSpanDelete
                              className="float-right op-0-7"
                              onClick={(e) => {
                                e.stopPropagation();
                                props.deleteReceipt(receipt.id);
                              }}>
                              Delete
                            </StyledSpanDelete>
                          </div>
                        </StyledDivBoxClaim>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                  <StyledFormUpload
                    hidden={props.receipts.length && !props.isAddingMore ? true : false}
                  >
                    <UploadImage
                      urlImage=""
                      addFile={props.handleChangeFile}
                      folderName="Employee/Claim"
                      receiptId={0}
                      claimId={0}
                      isAddNew={true}
                    />
                  </StyledFormUpload>
                  {(props.receipts.length > 0 && props.statusClaim === "OPEN") && (
                    <Box width="100%" textAlign="center" marginTop={1}>
                    <StyledActionButton title="Add more claim" onClick={props.addMoreReceipt}>
                      Add more claim
                    </StyledActionButton>
                  </Box>
                  )}
                  <StyledDivSubmit>
                    <StyledButtonSubmit
                      submit={isValid}
                      disabled={!isValid}
                      onClick={() => props.handleChangeStepClaim(1)}
                    >
                      {props.statusClaim === "OPEN" ? "Submit Your Claims" : "Submit Your Claim"}
                    </StyledButtonSubmit>
                    {props.receipts.length > 1 && <StyledButtonDelete
                      submit={isValid}
                      disabled={!isValid}
                      onClick={props.deleteAllReceipts}
                    >
                      Delete All Receipts
                    </StyledButtonDelete>}
                  </StyledDivSubmit>
                </div>
              )}
            </div>
          </div>
          {/* end body */}
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showClaimModal() {
  ($("#addClaimModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeClaimModal() {
  ($("#addClaimModal") as any).modal("hide");
}
