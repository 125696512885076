// @ts-nocheck
import React from "react";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {
  StyledMainContent,
  StyledContentBox,
} from "../../shared/employee-styled";
import {
  profileURL,
  bankingInfoURL,
  changePasswordURL,
  dependentURL,
  notificationUrl,
} from "../../../cores/constants/url-config";

const StyledLinkPersonal = styled(Link)`
  color: #2d2d2d;
  font-size: 14px;
  ${css`
    :hover {
      opacity: 0.8;
      color: #000;
      text-decoration: none;
    }
  `}
  ${(props: { active: boolean }) =>
    props.active &&
    css`
      color: #000;
      font-weight: bolder;
      opacity: 1;

      :hover {
        opacity: 0.8;
        color: #000;
      }
      a {
        color: #000;
      }
    `}
`;
const LinkNavItem = ({ isActive, to, label, iconClass }: any) => {
  return (
    <li className="list-group-item">
      <StyledLinkPersonal active={isActive} to={to}>
        {iconClass} {label}
      </StyledLinkPersonal>
    </li>
  );
};
export default class Profile extends React.Component<any, any> {
  async componentDidMount() {
    this.setState({
      isActive: false,
      pathName: document.location.pathname,
    });
  }
  render() {
    const listNav = [
      {
        label: "Personal Information",
        icon: <PermIdentityOutlinedIcon />,
        to: profileURL,
      },
      {
        label: "Dependents",
        icon: <PeopleAltOutlinedIcon />,
        to: dependentURL,
      },
      {
        label: "Banking Information",
        icon: <AccountBalanceOutlinedIcon />,
        to: bankingInfoURL,
      },
      {
        label: "Change Password",
        icon: <LockOpenIcon />,
        to: changePasswordURL,
      },
      {
        label: "Notifications",
        icon: <NotificationsOutlinedIcon />,
        to: notificationUrl,
      },
    ];
    return (
      <div className="col-12">
        <StyledMainContent>
          <div className="row">
            <div className="col-lg-3 mb-3">
              <StyledContentBox>
                <ul className="list-group">
                  {listNav.map((m, index) =>
                    document.location.pathname === m.to.split("?")[0] ? (
                      <LinkNavItem
                        to={m.to}
                        iconClass={m.icon}
                        label={m.label}
                        isActive
                      />
                    ) : (
                      <LinkNavItem
                        to={m.to}
                        iconClass={m.icon}
                        label={m.label}
                      />
                    )
                  )}
                </ul>
              </StyledContentBox>
            </div>
            <div className="col-lg-9 content">
              <StyledContentBox>{this.props.children}</StyledContentBox>
            </div>
          </div>
        </StyledMainContent>
      </div>
    );
  }
}
