import React, { useState, useEffect, useMemo } from "react";
import { addLoading, removeLoading } from "../../../../../cores/utils/loading";
import { getEmailNotifications, updateEmailNotifications } from "../../../../../services/notification-service";
import Profile from "../../profile";
import {
  NotificationType,
  NotificationData,
  NotificationTypeAdmin,
  NotificationTypeEmployee,
  NotificationTypeOwner,
} from "./notifications-type";
import NotificationsView from "./notifications-view";

export default function Notifications() {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [isValidButton, setIsValidButton] = useState<boolean>(false);

  useEffect(() => {
    getEmailNotificationsData();
  }, []);

  async function getEmailNotificationsData() {
    const result: any = await getEmailNotifications();
    setNotifications(result.data.items);
    setIsOwner(result.data.owner);
  }

  const notificationDataMemo: NotificationData = useMemo(() => {
    const ownerNotifications: NotificationType[] = [];
    const adminNotifications: NotificationType[] = [];
    const employeeNotifications: NotificationType[] = [];
    notifications.forEach((notification) => {
      if (NotificationTypeOwner.includes(notification.emailNotificationType)) {
        ownerNotifications.push(notification);
      }
      if (NotificationTypeAdmin.includes(notification.emailNotificationType)) {
        adminNotifications.push(notification);
      }
      if (
        NotificationTypeEmployee.includes(notification.emailNotificationType)
      ) {
        employeeNotifications.push(notification);
      }
    });

    return { ownerNotifications, adminNotifications, employeeNotifications };
  }, [JSON.stringify(notifications)]);

  function handleChangeCheckbox(e: any) {
    const notificationClone = notifications.map((noti) => ({ ...noti }));
    const idx = notificationClone.findIndex(
      (noti) => (noti.emailNotificationType === e.target.value)
    );
    notificationClone[idx].enabled = e.target.checked;
    setNotifications(notificationClone);
    setIsValidButton(true);
  }

  async function handleSubmit() {
    addLoading();
    await updateEmailNotifications(notifications);
    await getEmailNotificationsData();
    setIsValidButton(false);
    removeLoading();
  }

  return (
    <Profile
      children={
        <NotificationsView
          notificationData={notificationDataMemo}
          isOwner={isOwner}
          handleChangeCheckbox={handleChangeCheckbox}
          handleSubmit={handleSubmit}
          isValidButton={isValidButton}
        />
      }
    />
  );
}
