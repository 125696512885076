import React from "react";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import styled from "styled-components";
import { Agreement, BankingInfo } from "../../../setup-wizard/employee-type";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledPTitle,
  StyledButtonSubmit,
} from "../../../../shared/employee-styled";
import { BNPInput } from "../../../../../cores/helpers/input/input";
import { CheckBox } from "../../../../../cores/helpers/checkbox/checkbox";
import UploadImageCheque from "../../../../../cores/helpers/upload-cheque-banking/image-upload";
import Cheque from "../../../../../cores/config/component/images/cheque.jpg";
import { showTermsAndConditionsModal } from "../../../../../cores/helpers/terms-and-conditions/terms-and-conditions-modal";
import { convertTimeZone } from "../../../../../cores/utils/helpers";

type Props = {
  handleChangeInput: (event: any) => void;
  handleChangeCheckbox: (event: any) => void;
  handleChangeVoidCheque: (file: any) => void;
  handleOCRCheque: (file: any) => void;
  isOCRCheckingCompleted: boolean;
  handleChangeDate: (date: Date | null, name: string) => void;
  handleSubmitBankingInfo: () => void;
  bankingInfo: BankingInfo;
  isValid: boolean;
  folderName: string;
  agreementData: Agreement[];
  fileUrlEdit: string;
};

const StyledDivDatePicker = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 14px;
    height: 19px;
  }
`;

const StyledImgCheque = styled.img`
  max-height: 100%;
  max-width: 100%;
`;
const StyledSpan = styled.span`
  font-size: 12px !important;
`;

const StyledModalDialog = styled.div`
  max-width: 700px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
`;

export default function BankingInfoModal(props: Props) {
  const findChecked = (agreementId: number) => {
    let isAgree = props.bankingInfo.agreements.some((agree) => {
      return agree === agreementId;
    });

    return isAgree;
  };

  React.useEffect(() => {
    let $termsAndConditions = document.getElementById("termsAndConditions");

    if ($termsAndConditions) {
      $termsAndConditions.removeEventListener(
        "click",
        showTermsAndConditionsModal
      );
      $termsAndConditions.addEventListener(
        "click",
        showTermsAndConditionsModal
      );
    }
  });

  return (
    <div className="modal" id="bankingModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>
              Edit banking information
            </StyledHeaderTitleModal>
            <StyledButtonCloseModal type="button" onClick={closeBankingModal}>
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <div className="modal-body">
            <div className="row m-0">
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <StyledPTitle className="mb-2">Void Cheque</StyledPTitle>
                  <div className="mt-2">
                    <UploadImageCheque
                      addFile={props.handleChangeVoidCheque}
                      addBlob={props.handleOCRCheque}
                      urlImage={props.fileUrlEdit}
                      folderName={props.folderName}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-4">
                  <StyledPTitle className="mb-2">Account Number</StyledPTitle>
                  <BNPInput
                    maxLength={12}
                    name="accountNumber"
                    className="mt-2"
                    value={props.bankingInfo.accountNumber}
                    onChangeInput={props.handleChangeInput}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <StyledPTitle className="mb-2">Transit Number</StyledPTitle>

                  <BNPInput
                    maxLength={5}
                    className="mt-2"
                    value={props.bankingInfo.transitNumber}
                    onChangeInput={props.handleChangeInput}
                    name="transitNumber"
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <StyledPTitle className="mb-2">
                    Institution Number
                  </StyledPTitle>
                  <BNPInput
                    maxLength={3}
                    name="bankNumber"
                    className="mt-2"
                    value={props.bankingInfo.bankNumber}
                    onChangeInput={props.handleChangeInput}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <StyledPTitle className="mb-2">
                    E-signature{" "}
                    <StyledSpan> (Please type name to accept)</StyledSpan>
                  </StyledPTitle>
                  <BNPInput
                    name="eSignature"
                    className="mt-2"
                    value={props.bankingInfo.eSignature || ""}
                    onChangeInput={props.handleChangeInput}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <StyledPTitle className="mb-2">Date</StyledPTitle>
                  <StyledDivDatePicker>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        disableFuture
                        InputAdornmentProps={{ position: "start" }}
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline1"
                        placeholder="Date"
                        name="date"
                        value={props.bankingInfo.date ?
                          convertTimeZone("Canada/Eastern", props.bankingInfo.date)
                          :
                          null}
                        onChange={(date) =>
                          props.handleChangeDate(date, "date")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </StyledDivDatePicker>
                </div>

                <div className="col-12 mt-3 text-center">
                  <label>
                    <StyledImgCheque src={Cheque} alt="cheque" />
                  </label>
                </div>

                {props.agreementData.map((agreement, index) => (
                  <div className="col-lg-12 mt-4" key={index}>
                    <CheckBox
                      title={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: agreement.title,
                          }}
                        />
                      }
                      checked={findChecked(agreement.id)}
                      name={agreement.id.toString()}
                      onChangeCheckbox={props.handleChangeCheckbox}
                    />
                  </div>
                ))}
                <div className="col-lg-12 mt-2 mt-4">
                  <div className="col-lg-4 float-right">
                    <StyledButtonSubmit
                      type="submit"
                      disabled={!props.isValid}
                      onClick={props.handleSubmitBankingInfo}
                      submit={props.isValid}
                    >
                      Update now
                    </StyledButtonSubmit>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showBankingModal() {
  ($("#bankingModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeBankingModal() {
  ($("#bankingModal") as any).modal("hide");
}
