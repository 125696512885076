import React, {useEffect} from 'react';
import {StyledMainContent} from "../../shared/employee-styled";
import MarketplaceSearchPage from "./marketplace-search-page";
import {Option} from "../../../cores/helpers/select/select";
import {getAllServices} from "../../../services/marketplace-service";
import MarketplaceSearchComponent from "./component/marketplace-search-component";
import {RouteComponentProps} from "react-router-dom"
import {SearchVendorParams} from "./marketplace-type";
import styled from "styled-components";

const StyledContentBox = styled.div`
  background: #fff;
  padding: 25px 21px;
  border-radius: 5px;
  @media (max-width: 599px) {
    padding: 15px 10px;
  }
  max-width: 1920px;
  margin: 0 auto;
`;

type Props = {} & RouteComponentProps;

export type Location = {
  address: string;
  lat: number;
  lng: number;
};

function MarketplaceSearch(props: Props) {
  const previousSearchParams = props.location.state ? props.location.state.searchParams : null;
  const [services, setServices] = React.useState<Option[]>([]);
  const [serviceIds, setServiceIds] = React.useState<number[]>([]);
  const [location, setLocation] = React.useState<Location>({
    address: '',
    lat: 0,
    lng: 0
  });
  const [searchKey, setSearchKey] = React.useState<string>("");
  const [searchParams, setSearchParams] = React.useState<SearchVendorParams>({
    searchKey: "",
    expenseTypeIds: [],
    languageCodes: [],
    ratingFrom: -1,
    workingHour: "",
    distanceOptionId: "ALL",
    distanceFrom: 0,
    distanceTo: null,
    latitude: 0,
    longitude: 0,
    sortBy: [],
    page: 1,
    perPage: 8
  });

  useEffect(() => {
    getAllServices().then(res => {
      setServices(res.data);
    });

    if (!!previousSearchParams) {
      setSearchParams(previousSearchParams);
      setServiceIds(previousSearchParams.expenseTypeIds);
      setLocation({
        address: '',
        lat: previousSearchParams.latitude,
        lng: previousSearchParams.longitude
      });
      setSearchKey(previousSearchParams.searchKey);
    }
  }, []);

  const handleSearch = () => {
    setSearchParams({
      ...searchParams,
      latitude: location.lat,
      longitude: location.lng,
      expenseTypeIds: serviceIds,
      searchKey: searchKey
    });
  };

  const marketplaceSearchComponent =
    <MarketplaceSearchComponent
      searchKey={searchKey}
      setSearchKey={setSearchKey}
      services={services}
      serviceIds={serviceIds}
      location={location}
      setServiceIds={setServiceIds}
      setLocation={setLocation}
      handleSearch={handleSearch}
    />;

  return (
    <StyledMainContent>
      {/*<div className="container-fluid py-2 mt-3">*/}
        <StyledContentBox>
          <div>
            <MarketplaceSearchPage
              searchComponent={marketplaceSearchComponent}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              {...props}
            />
          </div>
        </StyledContentBox>
      {/*</div>*/}
    </StyledMainContent>
  );
}

export default MarketplaceSearch;