import React from "react";
import { Route } from "react-router-dom";
import {} from "react";
import SignInLayout from "./sign-in-layout";

const RouteLayoutSignIn = ({ component: Component, history, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <SignInLayout>
          <Component {...matchProps}></Component>
        </SignInLayout>
      )}
    />
  );
};

export default RouteLayoutSignIn;
