import React from "react";
import BenefitWalletsView from "./benefit-wallet-view";
import { Transactions, WalletSearchParams, Debit } from "./benefit-wallet-type";
import {
  getAllTransactions,
  postCashDebitPartner,
} from "../../../services/wallet-service";
import { getInfoByToken } from "../../../cores/utils/helpers";
import { RouteChildrenProps } from "react-router";
import {
  setPagingInfo,
  PagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import { addLoading, removeLoading } from "../../../cores/utils/loading";
import { filterData, Filter } from "../../../cores/helpers/filter/filter";
import {
  ConfirmDialog,
  showConfirmModal,
} from "../../../cores/helpers/confirm-modal/confirm-modal";
import { formatterUSD } from "../../../cores/helpers/format-usd";
import { usdToCent, centToUsd } from "../../../cores/helpers/cent-to-usd";
import { generateUUID } from "../../../cores/helpers/uuid";
import { publishMessage } from "../../../cores/utils/message";
import DebitModel, { closeDebitModal } from "./debit/debit-modal";
import { getWalletBalance } from "../receipt/claim-function";
import { SortType, SortTable } from "../../../cores/models/sort-table";
import { columnsTransactions } from "./benefit-wallet-model";

type Props = RouteChildrenProps;
type State = {
  transactions: Transactions[];
  searchParam: WalletSearchParams;
  pagingInfo: PagingInfo;
  transactionsFilter: Transactions[];
  debit: Debit;
  columns: SortTable[];
  // wallet
  availableAmount: number;
};
export default class BenefitWallets extends React.Component<Props, State> {
  state: State = {
    transactions: [],
    searchParam: {
      page: 1,
      perPage: 10,
      transactionType: "",
      columnName: null,
      sortType: null,
    },
    pagingInfo: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    transactionsFilter: [],
    debit: {
      employeeId: 0,
      partnerId: 0,
      referenceId: "",
      withdrawAmount: 0,
    },
    availableAmount: 0,
    columns: [],
  };

  async componentDidMount() {
    addLoading();
    this.getAllTransactions();
    this.getWalletBalance();
    removeLoading();
  }

  async getWalletBalance() {
    let balances = await getWalletBalance();

    let available = balances.find((m) => {
      return m.walletType === "physical";
    });

    this.setState({
      availableAmount: available ? centToUsd(available.balance) : 0,
    });
  }

  // get data if url change
  async UNSAFE_componentWillReceiveProps() {
    let paramSearch = this.getSearchByUrl();

    let response = filterData(
      Object.assign([], this.state.transactions),
      paramSearch.searchParam.page,
      paramSearch.searchParam.perPage,
      this.getParamFilter(paramSearch.searchParam),
      paramSearch.searchParam.columnName,
      paramSearch.searchParam.sortType
    );

    let pagingInfo = setPagingInfo(
      paramSearch.searchParam.page,
      response.pageCount,
      paramSearch.searchParam.perPage
    );

    this.setState({
      transactionsFilter: response.records,
      pagingInfo,
      columns: paramSearch.columns,
      searchParam: paramSearch.searchParam,
    });
  }

  getParamFilter = (paramSearch: WalletSearchParams) => {
    let params: Filter[] = [];

    if (paramSearch.transactionType) {
      params.push({
        name: "transactionType",
        value: paramSearch.transactionType,
      });
    }

    return params;
  };

  async getAllTransactions() {
    let resultTransactions = await getAllTransactions(
      getInfoByToken().employeeId
    );

    let transactions: Transactions[] = resultTransactions.data;

    let paramSearch = this.getSearchByUrl();
    let response = filterData(
      Object.assign([], transactions),
      paramSearch.searchParam.page,
      paramSearch.searchParam.perPage,
      this.getParamFilter(paramSearch.searchParam),
      paramSearch.searchParam.columnName,
      paramSearch.searchParam.sortType
    );

    let pagingInfo = setPagingInfo(
      paramSearch.searchParam.page,
      response.pageCount,
      paramSearch.searchParam.perPage
    );

    this.setState({
      transactions,
      pagingInfo,
      transactionsFilter: response.records,
      columns: paramSearch.columns,
      searchParam: paramSearch.searchParam,
    });
  }

  setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.page = 1;
    searchParam.perPage = Number(value);

    // set url search
    this.setSearchByParam(searchParam);
  };

  changePage = (page: number) => {
    let searchParam: WalletSearchParams = Object.assign(this.state.searchParam);
    searchParam.page = page;

    this.setSearchByParam(searchParam);
    this.setState({ searchParam: searchParam });
  };

  setSearchByParam = (searchParam: WalletSearchParams) => {
    let url = new URL(window.location.href);

    searchParam.page
      ? url.searchParams.set("page", searchParam.page.toString())
      : url.searchParams.delete("page");

    searchParam.perPage
      ? url.searchParams.set("rowsPerPage", searchParam.perPage.toString())
      : url.searchParams.delete("rowsPerPage");

    searchParam.transactionType
      ? url.searchParams.set("transactionType", searchParam.transactionType)
      : url.searchParams.delete("transactionType");

    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    this.props.history.push(url.search);
  };
  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search),
      page = urlParams.get("page"),
      rowsPerPage = urlParams.get("rowsPerPage"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      transactionType = urlParams.get("transactionType");

    let searchParam = Object.assign({}, this.state.searchParam);

    page ? (searchParam.page = Number(page)) : (searchParam.page = 1);

    rowsPerPage
      ? (searchParam.perPage = Number(rowsPerPage))
      : (searchParam.perPage = this.state.searchParam.perPage);

    transactionType
      ? (searchParam.transactionType = transactionType)
      : (searchParam.transactionType = "");

    if (columnName && sortType) {
      searchParam.columnName = columnName;
      searchParam.sortType = sortType;
    } else {
      searchParam.columnName = null;
      searchParam.sortType = null;
    }

    // set sort table
    let columns: SortTable[] = columnsTransactions();
    let index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName;
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    return {
      searchParam,
      columns,
    };
  };

  changeFilter = (event: any) => {
    let { name, value } = event.target,
      searchParam = Object.assign(this.state.searchParam);

    if (Number(value) === -1) value = null;

    searchParam[name] = value;

    searchParam.page = 1;

    // set url search
    this.setSearchByParam(searchParam);

    this.setState({ searchParam: searchParam });
  };

  sortTable = (columnId: string) => {
    let columnsHeaderTable: SortTable[] = Object.assign(this.state.columns);
    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParam.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    this.setSearchByParam(searchParam);
  };

  // debit

  checkSubmit = () => {
    let debit = Object.assign({}, this.state.debit, {
      withdrawAmount: usdToCent(this.state.debit.withdrawAmount),
    });

    const info = getInfoByToken();
    debit.employeeId = info.employeeId;
    debit.partnerId = info.partnerId;
    debit.referenceId = generateUUID();

    addLoading();

    postCashDebitPartner(debit).then(() => {
      this.getAllTransactions();
      this.getWalletBalance();

      publishMessage({
        message: "Withdraw request has been created.",
        variant: "success",
      });

      let adjustment = {
        employeeId: 0,
        partnerId: 0,
        referenceId: "",
        withdrawAmount: 0,
      };

      closeDebitModal();
      removeLoading();
      this.setState({ debit: adjustment });
    });
  };

  handleSubmitDebit = () => {
    showConfirmModal();
  };

  handleChangeInputDebit = (event: React.ChangeEvent<HTMLInputElement>) => {
    let debit = Object.assign(this.state.debit);
    let { name, value } = event.target;

    if (Number(value) > this.state.availableAmount) debit[name] = 0;
    else debit[name] = Number(value);

    this.setState({
      debit: debit,
    });
  };

  handleChangeAmount = () => {
    this.setState({
      debit: Object.assign({}, this.state.debit, {
        withdrawAmount: this.state.availableAmount,
      }),
    });
  };
  // end debit

  render() {
    return (
      <>
        <BenefitWalletsView
          transactionsFilter={this.state.transactionsFilter}
          pagingInfo={this.state.pagingInfo}
          setRowsPerPage={this.setRowsPerPage}
          changePage={this.changePage}
          changeFilter={this.changeFilter}
          searchParam={this.state.searchParam}
          columns={this.state.columns}
          sortTable={this.sortTable}
          availableAmount={this.state.availableAmount}
        />
        <DebitModel
          debit={this.state.debit}
          handleChangeInput={this.handleChangeInputDebit}
          handleSubmit={this.handleSubmitDebit}
          availableAmount={this.state.availableAmount}
          handleChangeAmount={this.handleChangeAmount}
        />
        <ConfirmDialog
          content={`Are you sure to withdraw ${formatterUSD("currency").format(
            this.state.debit.withdrawAmount
          )} to your bank account?`}
          title={"Withdraw"}
          handleOnOk={this.checkSubmit}
        />
      </>
    );
  }
}
