import React from "react";
import Profile from "../../profile";
import ChangePasswordView from "./change-password-view";
import { ResponseEmployeeData } from "../../../setup-wizard/employee-type";
import { addLoading, removeLoading } from "../../../../../cores/utils/loading";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  getProfileEmployee,
  putChangePassword,
} from "../../../../../services/employee-registration-service";
import { publishMessage } from "../../../../../cores/utils/message";
import { getToken } from "../../../../../cores/utils/helpers";

type State = {
  employee: ResponseEmployeeData;
  isValidButton: boolean;
};
export default class ChangePassword extends React.Component<any, State> {
  state: State = {
    employee: {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      dateOfBirth: "",
      phoneNumber: "",
      employeeLocation: {
        id: -1,
        street: "",
        cityName: "",
        provinceId: -1,
        provinceName: "",
        postCode: "",
      },
      passwordObject: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      profileUrl: "",
    } as ResponseEmployeeData,
    isValidButton: false,
  };
  async getInfo() {
    await getProfileEmployee().then((result) => {
      let personalInfo: ResponseEmployeeData = result.data;
      personalInfo.passwordObject = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      };
      this.setState({
        employee: personalInfo,
        isValidButton: true,
      });
    });
  }

  async componentDidMount() {
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.employee.passwordObject.newPassword) {
        this.state.isValidButton = false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("isFillPassword", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule(
      "isOldPasswordMatchNewPassword",
      (value) => {
        if (
          this.state.employee.passwordObject.oldPassword &&
          value === this.state.employee.passwordObject.oldPassword
        ) {
          return false;
        }
        return true;
      }
    );

    if (getToken()) {
      addLoading();
      this.getInfo();
      removeLoading();
    }
  }

  validForm = () => {
    if (
      this.state.employee.passwordObject.oldPassword === "" &&
      this.state.employee.passwordObject.newPassword === "" &&
      this.state.employee.passwordObject.confirmPassword === ""
    )
      return true;
    return false;
  };
  handleChangeInputPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    let employee = Object.assign(this.state.employee);
    const { name, value } = event.target;

    employee.passwordObject[name] = value;
    let valid = this.validForm();
    this.setState({
      employee: employee,
      isValidButton: valid,
    });
  };
  handleSavePassword = () => {
    let passwordObject = this.state.employee.passwordObject;

    putChangePassword(passwordObject)
      .then(() => {
        publishMessage({
          message: "Update password success",
          variant: "success",
        });
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.status === 500) {
            publishMessage({
              message: "We’ve run into a problem. Please try again later",
              variant: "error",
            });
          } else {
            this.setState({});
          }
        }
      });
  };
  render() {
    return (
      <Profile
        children={
          <ChangePasswordView
            employee={this.state.employee}
            handleChangeInputPassword={this.handleChangeInputPassword}
            handleSavePassword={this.handleSavePassword}
            isValidButton={this.state.isValidButton}
          />
        }
      />
    );
  }
}
