import { AxiosResponse } from "axios";
import { getAsync, postAsync, putAsync } from "../cores/utils/http-client";
import {
  EmployeeSignUpData,
  BenefitInfo,
  BankingInfo,
  Dependent,
  RequestProfile,
  PasswordObject,
  EmployeeLocation,
  EmployeeBasicInformation,
} from "../components/pages/setup-wizard/employee-type";

const URL_Employee = "/manage-employee/api";
const URL_Employer = "/manage-employer/api";

export const getEmployeeInfo = (param: {
  token: string;
}): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/info`;

  return getAsync(url, param);
};

export const getEmployeeLocationInfo = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/location-info`;

  return getAsync(url);
};

export const updateEmployeeLocationInfo = (body: EmployeeLocation): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/location-info`;

  return putAsync(url, body);
};

export const getBenefitsForSetUp = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/benefits-coverage/set-up`;

  return getAsync(url);
};

export const getBenefits = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/benefits-coverage`;

  return getAsync(url);
};

export const getBenefitsType = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/benefits-coverage/type`;

  return getAsync(url);
};

export const postEmployeeSignUp = (
  model: EmployeeSignUpData
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/sign-up`;

  return postAsync(url, model);
};

export const putBenefitInfo = (
  model: BenefitInfo[]
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/benefit-info`;

  return putAsync(url, model);
};

export const putEmployeeBasicInformation = (
  model: EmployeeBasicInformation
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/basic-information`;

  return putAsync(url, model);
};

export const putReAllocation = (
  model: BenefitInfo[]
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/re-allocation`;

  return putAsync(url, model);
};

export const putBankingInfo = (model: BankingInfo): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/banking-info`;

  return putAsync(url, model);
};

export const postFileToS3 = (model: FormData): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/files`;

  return postAsync(url, model);
};

export const putDependents = (model: Dependent[]): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/dependents`;

  return putAsync(url, model);
};

export const getAllInfoEmployee = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/all-info`;

  return getAsync(url);
};

export const getAgreementBankingInfo = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/agreement?type=3`;

  return getAsync(url);
};

export const getProfileEmployee = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/profile`;

  return getAsync(url);
};

export const getProvinces = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/locations/provinces`;

  return getAsync(url);
};

export const getCities = (id: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/locations/provinces/${id}/cities`;

  return getAsync(url);
};

export const putProfile = (model: RequestProfile): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/profile`;

  return putAsync(url, model);
};
export const putChangePassword = (
  model: PasswordObject
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/update-password`;

  return putAsync(url, model);
};

export const putChangeAvatar = (model: {
  profileUrl: string;
}): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/change-avatar`;

  return putAsync(url, model);
};

export const getEligibilityDate = (): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/employees/eligibility-date`;

  return getAsync(url);
};

export const getBriefPremiumPaymentSubscriptions = (employerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/premium-payment/employer/brief-subscriptions?employerId=${employerId}`;

  return getAsync(url);
};
