import { SortTable } from "../../shared/table-styled";

export const statusTransaction = [
  {
    id: "DEBIT",
    name: "Debit",
  },
  {
    id: "CREDIT",
    name: "Credit",
  },
];

export const columnsTransactions = (): SortTable[] => {
  return [
    {
      columnId: "txnTitle",
      columnName: "Title",
      sortType: null,
      width: 25,
    },
    {
      columnId: "txnDescription",
      columnName: "Description",
      sortType: null,
      width: 25,
    },
    {
      columnId: "clearedAmount",
      columnName: "cleared Amount",
      sortType: null,
      width: 12,
    },
    {
      columnId: "pendingAmount",
      columnName: "pending Amount",
      sortType: null,
      width: 12,
    },
    {
      columnId: "transactionType",
      columnName: "transaction Type",
      sortType: null,
      width: 13,
    },
    {
      columnId: "createdDate",
      columnName: "created Date",
      sortType: null,
      width: 13,
    },
  ];
};
