import React, {useMemo} from "react";
import styled from "styled-components";
import {formatterUSD} from "../../../../cores/helpers/format-usd";
import {centToUsd} from "../../../../cores/helpers/cent-to-usd";
import BNPProgressBar from "./progress-bar/BNPProgressBar";
import {PendingAmount} from "../../../pages/view-list-claim/list-claim-type";
import moment from "moment";

const StyledDivAmount = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
  line-height: 22px;
  width: 50%;
`;

const StyledBox = styled.div`
  background-color: #f2f2f5;
  padding: 15px 11px;
  border-radius: 5px;
  height: 100%;
  @media (max-width: 599px) {
    padding: 10px;
  }
`;

export const StyledTitle = styled.span`
  color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.subheadFont};
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
`;

const isBeforeResetBenefitDate = moment().month() === 1 && moment().date() <= 15;

export type WalletBenefitType = {
    title: string;
    total: number;
    limit: number;
    proRatedAmount?: number;
    proRated: boolean;
    isAvailable: boolean;
    pendingAmountByCategory: PendingAmount;
    calculateClaimedAmount: (
        limit: number,
        total: number,
        pendingAmountByCategory: PendingAmount
    ) => number;
    getAvailableAmount: (
        proRated: boolean,
        proRatedAmount: number|undefined,
        total: number,
        limit: number,
    ) => number;
};


export const WalletBenefit = (props: WalletBenefitType) => {
    const {claimedAmount, requestedAmount, pendingPaymentThisYear, pendingPaymentLastYear} = useMemo(() => {
        const claimedAmount = props.calculateClaimedAmount(
            props.limit,
            props.total,
            props.pendingAmountByCategory,
        );
        if (!props.pendingAmountByCategory)
            return ({
                claimedAmount,
                requestedAmount: 0,
                pendingPaymentThisYear: 0,
                pendingPaymentLastYear: 0
            });

        const {
            pendingApproveClaimAmount: requestedAmount,
            pendingISSClaimAmount: pendingPayment,
            pendingISSClaimAmountThisYear: pendingPaymentThisYear,
        } = props.pendingAmountByCategory;
        const pendingPaymentLastYear = Math.max(pendingPayment - pendingPaymentThisYear, 0);

        return {
            claimedAmount,
            requestedAmount,
            pendingPaymentThisYear,
            pendingPaymentLastYear
        }
    }, [props.total, props.limit, props.pendingAmountByCategory]);

    const {progressBarValues, progressBarColors} = useMemo(() => {
        if (isBeforeResetBenefitDate) {
            return {
                progressBarValues: [claimedAmount, pendingPaymentLastYear, pendingPaymentThisYear, requestedAmount],
                progressBarColors: ["primary", "#04A77A", "#04A77A", "#FFA400"]
            }
        }
        return {
            progressBarValues: [claimedAmount, pendingPaymentThisYear, requestedAmount],
            progressBarColors: ["primary", "#04A77A", "#FFA400"]
        }
    }, [claimedAmount, pendingPaymentThisYear, pendingPaymentLastYear, requestedAmount]);

    return (
        <StyledBox className="mt-3">
            <StyledTitle>{props.title}</StyledTitle>
            <div>
                <div className="w-100 mt-2 d-flex justify-content-between">
                    Claimed
                    <div style={{color: "#003049"}}>
                        {formatterUSD("currency", "USD").format(
                            centToUsd(claimedAmount)
                        )}
                    </div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                    Pending Payment
                    <div style={{color: "#04A77A"}}>
                        {formatterUSD("currency", "USD").format(
                            centToUsd(pendingPaymentThisYear)
                        )}
                    </div>
                </div>
                {pendingPaymentLastYear > 0 && <div className="w-100 d-flex justify-content-between">
                    Pending Payment From Previous Years
                    <div style={{color: "#04A77A"}}>
                        {formatterUSD("currency", "USD").format(
                            centToUsd(pendingPaymentLastYear)
                        )}
                    </div>
                </div>}
                <div className="w-100 d-flex justify-content-between">
                    Requested Claim
                    <div style={{color: "#FFA400"}}>
                        {formatterUSD("currency", "USD").format(
                            centToUsd(requestedAmount)
                        )}
                    </div>
                </div>

            </div>
            <BNPProgressBar values={progressBarValues}
                            total={props.proRated && props.proRatedAmount !== undefined ? props.proRatedAmount : props.total}
                            colors={progressBarColors}
            />
            <div className="d-flex">
                <div className="w-50">Available</div>
                <div className="w-50 text-right">Allocated</div>
            </div>
            <div className="d-flex mt-1">
                <StyledDivAmount>
                    {
                        formatterUSD("currency", "USD").format(centToUsd(
                            props.getAvailableAmount(props.proRated, props.proRatedAmount, props.total, props.limit)
                        ))
                    }
                </StyledDivAmount>
                <StyledDivAmount className="text-right">
                    {
                        formatterUSD("currency", "USD").format(centToUsd(
                            props.proRated && props.proRatedAmount !== undefined ?
                                props.proRatedAmount :
                                props.total
                        ))
                    }
                </StyledDivAmount>
            </div>
        </StyledBox>
    );
};