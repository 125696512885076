import React from 'react';
import styled from 'styled-components';
import ImgStack from "../../../../cores/config/component/images/img-stack.svg";
import {StyledHideForDesktop, StyledHideForMobile} from "../../../shared/employee-styled";

const Container = styled.div`
  display: flex;
  height: 374px;
`;

const LeftImage = styled.img<ImageProps>`
  width: ${props => props.width || '50%'};
  height: calc(100% - 10px);
  object-fit: cover;
  border-radius: 8px;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
`;

interface ImageProps {
  width: string;
  onClick?: () => void;
}

const RightContainer = styled.div<ImageProps>`
  width: ${props => props.width || '50%'};
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 20px;
`;

const CornerImage = styled.img<ImageProps>`
  width: ${props => `calc(${props.width} - 10px)` || 'calc(50% - 10px)'}; // Adjust according to the gap you want between images
  height: calc(50% - 10px); // Adjust according to the gap you want between images
  object-fit: cover;
  border-radius: 8px;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
`;

const StyledMobileImage = styled.img`
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
  border-radius: 8px;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  margin-top: 1rem;
`;

type Props = {
  images: string[];
  onClick?: (image: string) => void;
}

const PictureLayout = ({ images, onClick }: Props) => {

  const leftImageWidth = images.length > 3 ? '50%' : '70%';
  const rightContainerWidth = images.length > 3 ? '50%' : '30%';
  const cornerImageWidth = images.length > 3 ? '50%' : '100%';
  return (<>
    <StyledHideForMobile>
      <Container>
        <LeftImage
          src={images[0] || ImgStack}
          alt="Left Image"
          width={leftImageWidth}
          onClick={() => !!onClick && onClick(images[0])}
        />
        <RightContainer width={rightContainerWidth} >
          {images.slice(1).map((image, index) => (
            <CornerImage
              key={index}
              src={image || ImgStack}
              alt={`Corner Image ${index}`}
              width={cornerImageWidth}
              onClick={() => !!onClick && onClick(image)}
            />
          ))}
        </RightContainer>
      </Container>
    </StyledHideForMobile>
      <StyledHideForDesktop>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <StyledMobileImage src={images[0] || ImgStack} alt="Left Image"/>
              </div>
            </div>
            <div className="row">
              {images.slice(1).map((image, index) => (
                <div key={index} className="col-6">
                  <StyledMobileImage src={image || ImgStack} alt={`Corner Image ${index}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </StyledHideForDesktop>
  </>
)};

export default PictureLayout;
