import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import Header from "./header";
import { SideBar } from "./side-bar";
import { Footer } from "./footer";
import { getToken, setToken } from "../../cores/utils/helpers";
import { signInURL } from "../../cores/constants/url-config";
import { useHistory } from "react-router";
import jwt from "jsonwebtoken";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: "#fff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },

    [theme.breakpoints.down("sm")]: {
      padding: 0
    },
    minHeight: "calc(100vh - 156px)",
  },
}));

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    z-index: 300 !important;
    background-color: ${(props) => props.theme.sideBarColor[500]} !important;
    color: #ffffff !important;
    padding: 0;
    height: 100vh !important;
  }
`;

const EmployerLayout = ({ children }: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [isAuthenticated, seIsAuthenticated] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [children]);

  React.useEffect(() => {
    let resp = authenticate();
    if (resp) {
      seIsAuthenticated(resp);
    } else {
      history.push(signInURL);
    }
  }, []);

  function authenticate() {
    let urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get("token");
    if (token) {
      let jwtToken = jwt.decode(token);
      if (jwtToken && jwtToken instanceof Object) {
        let expriedAt = jwtToken["exp"] as number;
        setToken(token, new Date(expriedAt * 1000));
      }
    }
    return Boolean(getToken());
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      {isAuthenticated && (
        <React.Fragment>
          <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="default"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap>
                  <Header />
                </Typography>
              </Toolbar>
            </AppBar>

            <Hidden smUp implementation="css">
              <nav className={classes.drawer} aria-label="mailbox folders">
                <StyledDrawer
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  <div>
                    <div className={classes.toolbar}/>
                    <Divider/>
                    <SideBar/>
                  </div>
                </StyledDrawer>
              </nav>
            </Hidden>
            <Hidden smDown implementation="css">
              <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <StyledDrawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  <div>
                    <div className={classes.toolbar}/>
                    <Divider/>
                    <SideBar/>
                  </div>
                </StyledDrawer>
              </nav>
            </Hidden>
            <main className={classes.content}>
              <div className={classes.toolbar}/>
              <div>{children}</div>
            </main>
          </div>
          <Footer/>
        </React.Fragment>
      )}
    </>
  );
};
export default EmployerLayout;
